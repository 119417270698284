import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { MtObjectPanelData } from '@imerge22/platform/object-context';
import { MtNotificationBus } from '@imerge22/core/notification';
import { MtDataSourceService, ObjectDto } from '@imerge22/platform/base';
import { CommonUtils } from '@imerge22/platform/utils';
import { UserServiceService } from '@imerge22/base';
import { MtObjectPanelContext } from '@imerge22/spectrum';
import { MtSafeAny } from '@imerge22/core';

@Component({
  selector: 'uccs-panel-ecn',
  templateUrl: './panel-ecn.component.html',
  styleUrls: ['./panel-ecn.component.scss'],
})
export class PanelEcnComponent extends MtObjectPanelContext implements OnInit {
  @ViewChild('buttonAreaContent', { static: true }) buttonAreaContent: TemplateRef<void>;
  editing = false;
  object: ObjectDto = {};
  props: MtSafeAny;
  COED_NAME = 'UPLM_owning_user';
  selectedUser: string;
  userModel: MtSafeAny;

  constructor(
    public data: MtObjectPanelData,
    public userSrv: UserServiceService,
    public srv: MtDataSourceService
  ) {
    super();
  }

  ngOnInit() {
    this.srv.getObject(this.mtObjectDetailContext.mtObjectId).subscribe((object) => {
      this.object = object;
      this.props = object.props;
      this.userSrv.getUserById({ account: this.props[this.COED_NAME] }).subscribe((data) => {
        if (data && CommonUtils.isNotBlank(data.data)) {
          this.userModel = data.data[0];
          this.selectedUser = data.data[0].id;
        }
      });
    });
  }

  get editable() {
    const editingPanel = this.mtObjectDetailContext.panels.find((m) => m.instance?.mtEditing);
    return (
      this.mtObjectDetailContext.mtEditable &&
      (editingPanel == null || editingPanel.instance === this)
    );
  }

  /**
   * 面板编辑
   */
  edit() {
    this.editing = true;
  }

  /**
   * 取消面板编辑
   */
  cancelEdit() {
    this.editing = false;
    if (this.userModel) {
      this.selectedUser = this.userModel.id;
    } else {
      this.selectedUser = null;
    }
  }

  /**
   * 保存编辑
   */
  save() {
    this.userSrv.getUserById({ userId: this.selectedUser }).subscribe((data) => {
      if (data && CommonUtils.isNotBlank(data.data)) {
        this.userModel = data.data[0];
        this.props[this.COED_NAME] = data.data[0].account;
      }
      if (this.object != null) {
        const sub = this.srv
          .editObject(this.object, {
            panel: this.data.extra.panel,
            task: this.data.extra.objectData.extra.task,
          })
          .subscribe(
            () => {
              MtNotificationBus.emit({
                type: 'success',
                title: this.srv.getObjectTitle(this.object),
                message: 'i18n.platform.spectrum.object.saveSuccess',
              });
              this.editing = false;
              sub.unsubscribe();
            },
            (error: Error) => {
              MtNotificationBus.emit({
                type: 'error',
                title: this.srv.getObjectTitle(this.object),
                message:
                  error == null || error.message == null
                    ? 'i18n.platform.spectrum.object.saveFailed'
                    : error.message,
              });
              sub.unsubscribe();
            }
          );
      }
    });
  }
}
