import { CreateObjectService } from 'src/app/services/create-object.service';
import { Component, EventEmitter, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { ActivatedRoute } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Subscription } from 'rxjs';
import {
  CommonUtils,
  FilterOperator,
  GenericQueryService,
  MtMap,
  MtNotificationBus,
  MtSafeAny,
  MtTranslateService,
  MtWithConfig,
  Page,
  PageRequest,
  PassportService,
} from '@imerge22/core';
import { MtObjectComponentBase, MtSpectrumDataService, MtSpectrumDefineService, MtSpectrumSecurityService } from '@imerge22/spectrum';
import { EncodingService, LovComponent } from '@imerge22/encoding';
import { MtDataSourceService, ObjectDto } from '@imerge22/platform/base';

import { EwoService } from 'src/app/services/ewo.service';
import { PanelService } from 'src/app/services/panel.service';
import { EwoDetailComponent } from '../ewo-detail/ewo-detail.component';
import { MtWorkflowStarterComponent } from '@imerge22/platform/workflow/starter';
import { OrganizationService } from 'src/app/services/organization.service';
import { Sort } from '../../model';
import moment from 'moment';
import { MtDynamicTabBase } from '@imerge22/common';
import { EwoDetailActivateEvent } from '../ewo-detail/events';

@Component({
  selector: 'yc-my-ewo',
  templateUrl: './my-ewo.component.html',
  styleUrls: ['./my-ewo.component.scss'],
})
export class MyEwoComponent extends MtObjectComponentBase implements OnInit, OnDestroy, MtDynamicTabBase {
  constructor(
    defSvc: MtSpectrumDefineService,
    dataSvc: MtSpectrumDataService,
    secSvc: MtSpectrumSecurityService,
    private fb: FormBuilder,
    private querySrv: GenericQueryService,

    private route: ActivatedRoute,
    private translate: MtTranslateService,
    private passport: PassportService,
    private createService: CreateObjectService,
    private svc: EwoService,
    private codeSvc: EncodingService,
    public dataSrv: MtDataSourceService,
    private modal: NzModalService,
    private panelSvc: PanelService,
    private orgSrv: OrganizationService,
  ) {
    super(defSvc, dataSvc, secSvc);
  }

  @ViewChild(MtWorkflowStarterComponent, { static: true }) flowStart: MtWorkflowStarterComponent;
  @ViewChild('tabTitle') title: TemplateRef<void>;
  onCloseByInner: EventEmitter<void>;
  /** 控制tab页不可关闭 */
  shieldCloseBtn = true;

  // 表格属性
  querier: FormGroup;
  page: PageRequest = { index: 1, size: 10 };
  datas: Page<MtSafeAny> = { data: [], total: 0, totalPages: 0 };
  pagenumber: number[] = [10, 20, 30, 50, 100];
  subscribe: Subscription[] = [];
  params : MtSafeAny[] = [
    { prop: 'OWNER', operate: FilterOperator.Equals, value: this.passport.current.user.id },
  ];

  isVisible = false;
  isEditing = false;
  selectObj: MtSafeAny;
  isAdd = false;
  selectRow: any;
  orgList = [];
  sortOrder = {plan:null,createTime:null}
  sort = [];

  ngOnInit(): void {
    this.orgSrv.getOrganizationList().subscribe(item => {
      this.orgList = item.resultData.data;

    });
    this.querier = this.fb.group({
      itemId: [null],
      //name: [null],
      //objnum: [null],
      createTime: [null],
      plannedCompleteDate: [null],
    });

    this.search();

  }

  onActivated(): void { }
  onDeactivated(): void { }
  onDataChange(data: MtMap<any>): void { }
  activate(): void { }
  deactivate(): void { }

  sortChange(type?){
    // for(let item in this.sortOrder){
    //   console.log(item);

    // }
    if(this.sortOrder[type]){
      let params = {
        dir:Sort[this.sortOrder[type]],
        prop:null
      }
      if(type === 'plan'){
        params.prop = 'COMPLETE_TIME'
      }else if(type === 'createTime'){
        params.prop = 'CREATE_TIME'
      }
      this.sort = [params]
    }else{
      this.sort = [];
    }


    this.search();
  }

  ngAfterViewInit(): void {

    const x = this.createService.isAddObject.subscribe(res => {
      if (res === 'EWO') {
        setTimeout(() => {
          this.isAdd = true;
          this.isEditing = true;
          this.showEwoEdit('');
          this.createService.isAddObject.next('')
        }, 0);
      }
    });
    this.subscribe.push(x)
  }
  buttonSearch(){
    this.page = { index: 1, size: 10 };
    this.search();
  }

  search() {
    const keys = Object.keys(this.querier.value);
    const value = Object.values(this.querier.value).some((item) => Boolean(item) == true);

    const queryParams =  [...this.params];
    // 如果输入框有值就按照输入框条件查询 没值重置查询条件
    if (value) {
      keys.forEach((key) => {

        if (CommonUtils.isNotBlank(this.querier.value[key])) {
          // 将输入框的时间格式化
          if (this.querier.value.createTime && key == 'createTime') {
            this.querier.value.createTime[0].setHours(0, 0, 0, 0);

            queryParams.push({
              prop: 'CREATE_TIME',
              operate: FilterOperator.GreatThan,
              value: moment(this.querier.value.createTime[0]).format('YYYY-MM-DD') + ' 00:00:00',
            });

            this.querier.value.createTime[1].setHours(23, 59, 59, 999);
            queryParams.push({
              prop: 'CREATE_TIME',
              operate: FilterOperator.LessThan,
              value: moment(this.querier.value.createTime[1]).format('YYYY-MM-DD') + ' 23:59:59',
            });
          } else if (this.querier.value.plannedCompleteDate && key == 'plannedCompleteDate') {
            this.querier.value.plannedCompleteDate[0].setHours(0, 0, 0, 0);
            queryParams.push({
              prop: 'planned_complete_date',
              operate: FilterOperator.GreatThan,
              value: moment(this.querier.value.plannedCompleteDate[0]).format('YYYY-MM-DD') + ' 00:00:00',
            });
            this.querier.value.plannedCompleteDate[1].setHours(23, 59, 59, 999);
            queryParams.push({
              prop: 'planned_complete_date',
              operate: FilterOperator.LessThan,
              value: moment(this.querier.value.plannedCompleteDate[1]).format('YYYY-MM-DD') + ' 23:59:59',
            });
          } else if (this.querier.value.itemId && key == 'itemId'){
            queryParams.push({
              prop: 'ITEM_ID',
              operate: FilterOperator.Like,
              value: this.querier.value[key].trim(),
            });
          } else{
            queryParams.push({
              prop: key,
              operate: FilterOperator.In,
              values: [this.querier.value[key].trim()],
            });
          }
        }
      });
    }


    this.svc.getEWOList(this.page, queryParams, this.sort).subscribe(
      (result) => {
        const data = [...result.resultData.data];
        const list = data.map(item => item.CREATOR);
        this.querySrv
        .query<ObjectDto>({
          modelId: 'User',
          dataSource: 'rbac',
          conditions: {
            filters: [{ prop: 'id', operate: FilterOperator.In, values: list }],
          },
        }).subscribe(item => {
          this.datas.data = [...data.map(it => {
            const user = item.resultData.data.find(i => i.id === it.CREATOR);
            const orgName = this.orgList.find(orgItem => orgItem.id === it.dept_id);
            return {
              ...it,
              CREATOR_NAME: `${user.account}/${user.name}`,
              DEPT_NAME: orgName ? orgName.name : '--'
            };
          })];
        });
        this.datas.total = result.resultData.total;
      },
      (err: Error) => { }
    );
  }
  reload() {
    this.querier.reset();
    this.search();
  }
  //提出新申请
  addEwo() {
    this.isAdd = true;
    this.isEditing = true;
    this.showEwoEdit('');
  }
  // 提交
  submit() {
    console.log(this.selectRow);

  }

  editEwo(obj) {
    this.isAdd = false;
    this.showEwoEdit(obj._id);
  }

  // 显示EWO详情
  showEwoEdit(objId: string) {
    EwoDetailActivateEvent.emit({
      context: 'EWO',
      id: objId,
      type: 'EWORevision',
      extra: {
        mtModelCode: 'EWO',
        isAdd: this.isAdd
      }
    });

    // this.modal
    //   .create({
    //     nzTitle: this.isAdd ? '创建EWO' : 'EWO详情',
    //     nzContent: EwoDetailComponent,
    //     nzComponentParams: {
    //       isAdd: this.isAdd,
    //       mtModelCode: 'EWO',
    //       data: { id: objId, type: 'EWORevision' },
    //     },
    //     nzFooter: null,
    //     nzWidth: '90%',
    //     nzBodyStyle: { height: document.body.clientHeight - 75 + 'px' },
    //     nzMaskClosable: false,
    //     //nzClassName: 'mt-maximum',
    //     nzStyle: { top: '5px' },
    //   })
    //   .afterClose.subscribe((value) => {
    //     if (value) {
    //       this.search();
    //     }
    //   });
    // .afterClose.subscribe(() => {
    //   this.search();
    // });
  }
  // 单选
  oneItemChecked(row) {
    if(this.selectRow && this.selectRow._id === row._id) {
      this.selectRow = null;
    }
    else{
      this.selectRow = row;
    }
  }

  launchWorkflow() {

    //判断的是否可以发起流程
    var canSumbitWorkflow = true;
    if (!canSumbitWorkflow) {
      MtNotificationBus.emit({
        type: 'warning',
        title: 'i18n.platform.notification.errors.checkAudit',
        message: 'i18n.platform.notification.errors.fistPcn',
      });
      return;
    }
    const parameter = [{ objects: [
      {
        objectId: this.selectRow._id,
        target: true,
        serial: 1
      }
    ] }];

    //this.flowStart.mtName =  this.selectRow.OBJECT_NAME + '-' + (this.selectRow?.change_topic || '') ;
    this.flowStart.initName(this.selectRow.OBJECT_NAME + '-' + (this.selectRow?.change_topic || ''));

    this.flowStart.open(parameter);
  }

  startWorkflow() {
    // MtNotificationBus.emit({
    //   type: 'success',
    //   title: 'i18n.platform.notification.success.submitSuccesss',
    //   message: 'i18n.platform.notification.success.submitSuccesssText',
    // });
  }


  showModal() {
    this.isVisible = true;
  }
  handleCancel() {
    this.isVisible = false;
  }
  handleok() {
    this.isVisible = false;
  }

  ngOnDestroy() {
    this.subscribe.forEach(x => x.unsubscribe())
  }
}
