import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { MtWithConfig, MtConfigService, ObjectDto } from '@imerge22/platform/base';
import { MtSafeAny } from '@imerge22/core';

// 删除
interface StockInfoRequest {
  ENDDATE: string;
  MODELSTATUSCODE: string;
  PLATE: string;
  STARTDATE: string;
}
// 删除
interface InventoryInfoRequest {
  IT_GYS_IN: {
    item: { LIFNR: string }[];
  };
  I_BISMT1: string;
  I_ESOKZ: string;
  I_MODE: string;
  I_WERKS: string;
}
@Injectable({
  providedIn: 'root',
})
export class PanelService {
  @MtWithConfig() private spectrumServiceName: string;
  // 删除
  @MtWithConfig() private workflowServiceName: string;

  constructor(public http: HttpClient, public mtConfigSrv: MtConfigService) {}

  /**
   * 查询upmn零件信息
   */
  downloadTemplate() {
    return this.http
      .post(`srv://${this.spectrumServiceName}/data/template`, null, {
        responseType: 'blob',
        observe: 'response',
      })
      .pipe(map((data: HttpResponse<Blob>) => data));
  }

  /**
   * 获取对象的属性
   */
  // 删除
  getObjectPropertyValues(objectType: string, objectId: string) {
    return this.http.post(`srv://${this.spectrumServiceName}/mongodb/common/query`, {
      modelId: objectType,
      conditions: {
        filters: [{ prop: '_id', operate: 'In', values: [objectId] }],
      },
    });
  }

  /**
   * 保存对象基本属性
   */
  // 删除
  saveObjectPropertyValue(object: ObjectDto): Observable<MtSafeAny> {
    const data = {
      id: object.id,
      commonObject: {
        objectName: object.objectName,
        objectDesc: object.objectDesc,
      },
      prop: object.props,
    };
    return this.http.put(`srv://${this.spectrumServiceName}/base/model/edit`, data);
  }

  /** 获取申请进度信息 */
  // 删除
  getWorkProcess(objectId: string): Observable<MtSafeAny> {
    return this.http.post(`srv://${this.spectrumServiceName}/mongodb/common/query`, {
      modelId: 'Work_Process',
      cascades: ['plan_node', 'verify_node', 'analyse_node', 'decision_node', 'execution_node', 'close_node'],
      cacheable: false,
      conditions: {
        filters: [{ prop: 'id', operate: 'In', values: [objectId] }],
      },
    });
  }

  /**
   * 获取订单信息
   * @param item ENDDATE:结束日期 当前系统日期-20年; MODELSTATUSCODE:状态机; PLATE: 业务版块写死为卡车,客车,通机,船电; STARTDATE: 开始日期 取当前年份的第一天;
   */
  // 删除
  getStockInfo(item: StockInfoRequest): Observable<MtSafeAny> {
    return this.http.post(`srv://${this.spectrumServiceName}/sap/getOrderForecastQuantity`, item);
  }

  /**
   * 获取对象所在的流程信息
   * @param objectId 对象id
   */
  // 删除
  getProcessIns(objectId: string): Observable<MtSafeAny> {
    return this.http.post(`srv://${this.workflowServiceName}/common/query`, {
      modelId: 'ProcessInsObject',
      cascades: ['procIns'],
      cacheable: false,
      conditions: {
        filters: [
          { prop: 'objectId', operate: 'Equals', value: objectId },
          { prop: 'procIns.status', operate: 'In', values: ['Processing','SuccessEnd'] },
        ],
      },
    });
  }

  /**
   * 获取yc库存信息
   */
  // 删除
  getInventoryInfo(params: InventoryInfoRequest) {
    return this.http.post(`srv://${this.spectrumServiceName}/sap/getMaterialPriceOrInventoryInformation`, params);
  }
}
