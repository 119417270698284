<div class="modal-content">
  <div class="header">
    {{ 'koa.modal.addWorkHours' | translate }}
  </div>
  <div class="modal-body">
    <form style="margin-top: 20px;" nz-form [formGroup]="validateForm">
      <nz-form-item *ngFor="let item of detail">
        <nz-form-label [nzSpan]="7" [nzRequired]="item?.required">
          {{ item?.label }}
        </nz-form-label>
        <nz-form-control [nzSpan]="12">

          <textarea *ngIf="item.key == 'comment'" rows="4" nz-input [formControlName]="item?.key"></textarea>
          
          <!-- <nz-select
              *ngIf="item?.key == 'CustID'"
              nzShowSearch
              nzServerSearch
              nzPlaceHolder="输入用户名查询"
              [formControlName]="item?.key"
              [nzShowArrow]="false"
              [nzFilterOption]="nzFilterOption"
              (nzOnSearch)="searchCustomer($event)"
            >
            <nz-option *ngFor="let o of listOfCustomerOption" [nzLabel]="o?.text" [nzValue]="o?.value"></nz-option>
          </nz-select> -->
          <nz-select
              *ngIf="item?.key == 'prjid'"
              nzServerSearch
              nzPlaceHolder="请选择项目"
              [formControlName]="item?.key"
              [nzShowArrow]="false"
              (ngModelChange)="setMilestoneOptions($event)"
            >
            <nz-option *ngFor="let o of projectOption" [nzLabel]="o.projectLabel" [nzValue]="o?.muid"></nz-option>
          </nz-select>

          <nz-select
            *ngIf="item?.key == 'milestone'"
            nzServerSearch
            nzPlaceHolder="请选择里程碑阶段"
            [formControlName]="item?.key"
            [nzDisabled]="true"
            [nzShowArrow]="false"
            >
            <nz-option *ngFor="let o of milestoneOptions" [nzLabel]="o?.milestoneLabel" [nzValue]="o?.milestoneLabel"></nz-option>
          </nz-select>

          <nz-select
              *ngIf="item?.key == 'SUBMIT' || item?.key == 'workcheck'"
              [formControlName]="item?.key"
              nzAllowClear
              nzPlaceHolder=""
            >
              <nz-option
                *ngFor="let item of isSubmitCodeList"
                [nzValue]="item.code"
                [nzLabel]="item.name"
              ></nz-option>
          </nz-select>

          <nz-select
            *ngIf="item?.key == 'depid'"
            [formControlName]="item?.key"
            [nzDisabled]="true"
            nzPlaceHolder="请选择员工部门"
          >
            <nz-option
              *ngFor="let item of orgListOption"
              [nzValue]="item.code"
              [nzLabel]="item.name"
            ></nz-option>
        </nz-select>
          
          <input *ngIf="item.type == 'STRING' && item.key !== 'projectContent' && item.key !== 'comment' && item.key !== 'depid' && item.key !== 'milestone'" nz-input oninput="value=value.replace(/\s+/g,'')" [formControlName]="item?.key" />
          <input *ngIf="item.type == 'NUMBER' && item.key !== 'prjhours'" nz-input type="number" [formControlName]="item?.key" />

          <ng-container>
            <input *ngIf="item.key == 'prjhours'" nz-input type="number" [formControlName]="item?.key" />
            <span *ngIf="item.key == 'prjhours'">当前日期剩余可填工时：<span style="color: red;">{{timeLeft ? timeLeft : "0"}}人天</span></span>
          </ng-container>
          
          <ng-container *ngIf="item.key == 'projectManagerId' || item.type == 'RELATION' && item.key !== 'prjid'">
            <mt-workflow-assignee-selector
              class="search-item"
              [showOnly]='true'
              mtValueType="single-user"
              [formControlName]="item?.key"
            ></mt-workflow-assignee-selector>
          </ng-container>
          <nz-date-picker *ngIf="item.type == 'DATE'" [nzAllowClear]="false" [nzDisabledDate]="disabledDate" [formControlName]="item?.key"></nz-date-picker>
        </nz-form-control>
    </nz-form-item>
    </form>
    <nz-alert nzType="info" 
      [nzMessage]="'platform.base.obj.initialView' | translate"
      *ngIf="panels.length"
    >
    </nz-alert>
    <nz-tabset
      *ngIf="panels?.length"
    >
      <nz-tab *ngFor="let panel of panels; let i = index" [nzTitle]="panelTabTitle">
        <!-- 加载中状态 -->
        <nz-spin *ngIf="mtObjectInited[i ? mtObjectId : mtObjectItemId] !== 'loaded'" [nzIndicator]="indicatorTemplate" [nzSpinning]="mtObjectInited[i ? mtObjectId : mtObjectItemId] !== 'loaded'">
          <ng-template #indicatorTemplate>
            <i style="font-size: 40px; position: fixed; top: 50%" nz-icon nzType="loading"></i>
          </ng-template>
        </nz-spin>
        <ng-template #panelTabTitle>
          <div class="mt-tab-title">
            {{panel.panel.model.code}}{{'platform.base.obj.initialView'| translate}}
          </div>
        </ng-template>
        <!--加载完成后-->
        <ng-container *ngIf="mtObjectInited[mtObjectId] === 'loaded'">
          <!-- 注入控件显示 -->
          <ng-container *ngIf="haveAuthority; else noPermission">
            <ng-container [mtPanelRenderer]="panel" [mtActivate]="true"></ng-container>
          </ng-container>

          <!-- 无权限时显示 -->
          <ng-template #noPermission>
            <nz-result nzStatus="warning" [nzTitle]="'platform.spectrum.noPermission' | translate"> </nz-result>
          </ng-template>
        </ng-container>
      </nz-tab>
    </nz-tabset>
  </div>
  <div class="footer-content">
    <button nz-button
      nzShape="round"
      class="ant-btn-md"
      nz-popconfirm
      [nzPopconfirmTitle]="'platform.spectrum.acm.cancelProText' | translate"
      (nzOnConfirm)="close()"
      nzPopconfirmPlacement="left">
      <span> {{ 'platform.core.dict.cancel' | translate }}</span>
    </button>
    <button
      nz-button
      nzShape="round"
      nzType="primary"
      type="button"
      [disabled]="!validateForm.valid"
      [nzLoading]="isEditloading"
      (click)="submitEdit()"
    >
      <span>{{ 'platform.core.dict.determine' | translate }}</span>
    </button>
  </div>
</div>