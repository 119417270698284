import {
  ObjectDto,
  MtObjectEditExtra
} from '@imerge22/platform/base';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { MtDataSourceHanders } from '@imerge22/platform/base';
import { Injectable } from '@angular/core';
import { CreateObjectService } from './create-object.service';

@Injectable({ providedIn: 'root' })
export class UccsDataSourceHandlers extends MtDataSourceHanders {

  constructor(
    public http: HttpClient,
    public createSrv: CreateObjectService
  ) {
    super();
  }

  afterObjectEdit(commonObject: ObjectDto, extra?: MtObjectEditExtra): Observable<any> {
    return this.createSrv.synchronizes(commonObject.id);
  }
}
