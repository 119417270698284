import { MtEvent, MtEventBus, MtSafeAny } from '@imerge22/core';
import { MtDynamicTabsetActivateEventData } from '@imerge22/common';

interface MakeUpWorkHoursActivateEventData extends MtDynamicTabsetActivateEventData{
  id?: string;
  type?: string;
  extra?: MtSafeAny;
}

export class MakeUpWorkHoursActivateEvent {
  static $name = 'make-up-work-hours-activate';
  static emit(data: MakeUpWorkHoursActivateEventData) {
    MtEventBus.emit({
      name: MakeUpWorkHoursActivateEvent.$name,
      data,
    });
  }

  static is(event: MtEvent<MtSafeAny>): boolean {
    return event != null && event.name === MakeUpWorkHoursActivateEvent.$name;
  }

  static get(event: MtEvent<MtSafeAny>): MakeUpWorkHoursActivateEventData {
    if (MakeUpWorkHoursActivateEvent.is(event)) {
      return event.data;
    }
    return undefined;
  }
}
