import { Component, EventEmitter, forwardRef, OnInit, ViewChild } from '@angular/core';
import { MtDynamicTabBase } from '@imerge22/common';
import {
  MtConfigService,
  GenericQueryRequest,
  MtWithConfig,
  FilterOperator,
  MtMap,
  MtSafeAny,
  CommonUtils,
  MtNotificationBus,
} from '@imerge22/core';
import {
  IMtSpectrumDataSaveDTO,
  MtObjectDetailChildRegister,
  MtObjectDetailContext,
  MtObjectDetailPanelInfo,
  MtSpectrumDataService,
  MtSpectrumDefineService,
  MtSpectrumSecurityService
} from '@imerge22/spectrum';
import { MtDataSourceService } from '@imerge22/platform/base';
import { TableRenderComponent } from '@imerge22/platform/mt-spectrum';
import { map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'uccs-ebom',
  templateUrl: './ebom-tab.html',
  styleUrls: ['./ebom-tab.scss'],
  providers: [
    { provide: MtObjectDetailContext, useExisting: forwardRef(() => EbomTabComponent) },
  ],
})
export class EbomTabComponent extends MtObjectDetailContext implements MtDynamicTabBase, OnInit {

  @MtWithConfig() private spectrumServiceName: string;
  @ViewChild('tablePCN', { static: true }) tablePCN: TableRenderComponent;

  private get modelCode() {
    return 'Usage';
  }
  queryProps: GenericQueryRequest;
  queryDatas: GenericQueryRequest = {
    modelId: this.modelCode,
  };
  model: MtSafeAny = {};
  data: MtMap;
  pageSize: number;
  title = 'EBOM';
  onCloseByInner = new EventEmitter<void>();
  baseCol: string[] = ['_id', 'OBJECT_NAME', 'OBJECT_TYPE', 'ITEM_REVISION_ID', 'OWNER'];
  selectRow: MtSafeAny;
  openType: string;
  panelData: MtObjectDetailPanelInfo;
  isBlank = CommonUtils.isBlank;
  saving = false;

  constructor(
    defSvc: MtSpectrumDefineService,
    dataSvc: MtSpectrumDataService,
    secSvc: MtSpectrumSecurityService,
    private register: MtObjectDetailChildRegister,
    public srv: MtDataSourceService,
    public mtConfigSrv: MtConfigService
  ) {
    super(defSvc, dataSvc, secSvc);
  }

  ngOnInit() {
    this.queryProps = {
      dataSource: this.spectrumServiceName,
      modelId: 'Property',
      conditions: {
        filters: [
          {
            prop: 'model.code',
            operate: FilterOperator.Equals,
            value: this.modelCode,
          },
        ],
      },
    };
    this.defSvc.getPanels(this.modelCode)
      .pipe(
        map(panels => {
          return panels.find(panel => panel.code === 'Public');
        }),
        switchMap((panel) => {
          return this.register.getPanel(this, '$properties').pipe(map(component => ({
            panel,
            component,
            instanceInited: new EventEmitter()
          }) as MtObjectDetailPanelInfo));
        })
      ).subscribe(res => {
        this.panelData = res;
        this.panelData.instanceInited.subscribe(res => {
          this.panelData.instance.mtEditing = true;
        });
      });
  }

  onActivated(): void { }
  onDeactivated(): void { }
  onDataChange(data: MtMap): void { }

  selectChange(row) {
    this.selectRow = row;
  }

  add() {
    this.openType = 'add';
    this.model.objectName = null;
    const obj = this.dataSvc.createTempObject(this.modelCode);
    this.mtObjects[obj.id] = obj;
    this.mtObjectId = obj.id;
  }
  edit() {
    this.openType = 'edit';
    this.model.objectName = this.selectRow.OBJECT_NAME;
    const id = this.selectRow._id;
    this.mtObjects[id] = this.selectRow;
    this.mtObjectId = id;
  }
  // TODO: 待开发
  deleteRow() { }
  complete() {
    this.saving = true;
    const object = {
      objectType: this.modelCode,
      objectName: this.model.objectName,
    };
    if (this.openType === 'add') {
      this.srv.createObject(object)
        .pipe(
          switchMap((objectId) => this.srv.getObject(objectId).pipe(
            switchMap(newObject => {
              const change = this.mtPropertyValueChanges[this.mtObjectId];
              const data: IMtSpectrumDataSaveDTO = {
                id: objectId,
                data: {
                  [objectId]: {
                    changes: [...Object.keys(change)],
                    data: {
                      ...change,
                    },
                  }
                },
              };
              return this.dataSvc.save(data).pipe(map(() => newObject));
            })))
        ).subscribe((newObject) => {
          this.openType = null;
          this.saving = false;
          this.query();
          MtNotificationBus.emit({
            type: 'success',
            title: this.srv.getObjectTitle(newObject),
            message: 'i18n.platform.spectrum.object.createObjectSuccess',
          });
        },
          (err) => {
            this.saving = false;
            let errMsg = '';
            if (err == null) {
              errMsg = 'i18n.platform.common.callApiFail';
            } else if (typeof err === 'string') {
              errMsg = err;
            } else if (CommonUtils.isNotBlank(Object.keys(err))) {
              errMsg = err[Object.keys(err)[0]][0];
            }
            MtNotificationBus.emit({
              type: 'error',
              title: 'i18n.platform.common.error',
              message: errMsg
            });
          }
        );
    } else {
      // 编辑保存
      this.save().subscribe((result) => {
        this.openType = null;
        this.saving = false;
        this.query();
        MtNotificationBus.emit({
          type: 'success',
          title: 'i18n.platform.common.success',
          message: 'i18n.platform.common.updateSuccess',
        });
      }, err => {
        this.saving = false;
      });
    }
  }

  /** 重新查询 */
  query() {
    this.tablePCN.searchData();
    this.selectRow = null;
  }
}
