import { Injectable } from '@angular/core';
import { FilterOperator, IMtDataBindingAction, MtSafeAny } from '@imerge22/core';
import { MtSpectrumDataBinding, MtSpectrumDataItem, MtSpectrumDataSource } from '@imerge22/spectrum/objects';
import { ProjectDetailService } from '../root/modules/project-information/project-detail/services/project-detail.service';

@Injectable()
export class ChangeProjectBindingAction
  implements IMtDataBindingAction<MtSpectrumDataItem, MtSafeAny> {
  constructor(
    public dataBinding: MtSpectrumDataBinding,
    public dataSource: MtSpectrumDataSource,
    private projectDetailSrv: ProjectDetailService,
  ) {}

  inited() {}

  change?(previousValue: string, currentValue: string) {
    this.getProjectInfoById(currentValue);
  }

  private getProjectInfoById(projectId: string): void {
    let params = {
      prop: 'id',
      operate: FilterOperator.Equals,
      value: `'${projectId}'`,
    };
    const mainObjId = this.dataSource.mainObjectId;
    this.projectDetailSrv
      .getProjectDetailList({ index: 1, size: 99999 }, params)
      .subscribe((res: MtSafeAny) => {
        if (!res?.resultData || res.resultData.data.length < 1) {
          return;
        }
        const projectData = res?.resultData?.data[0];
        let projectManagerId = projectData.projectmanagerid;
        let milestoneOptions = JSON.parse(projectData?.value_info?.value).milestone;
        milestoneOptions.forEach(el => {
          el.milestoneLabel = el.explanation ? el.project_milestones + '-' + el.explanation: el.project_milestones;
        });
        let autoSelectItem = milestoneOptions.find(result => !result.actualFinishDate).milestoneLabel;
        autoSelectItem = autoSelectItem ? autoSelectItem : milestoneOptions.pop().milestoneLabel;
        this.dataSource.setValue(mainObjId, 'SUBMITBYID', projectManagerId);
        this.dataSource.setValue(mainObjId, 'SUBMITBY', projectManagerId);
        this.dataSource.setValue(mainObjId, 'milestone', autoSelectItem);
      });
  }
}
