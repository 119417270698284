/*
 * @Author: your name
 * @Date: 2021-09-12 21:07:03
 * @LastEditTime: 2021-09-12 22:01:55
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \web\src\app\services\organization.service.ts
 */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GenericQueryService, MtConfigService } from '@imerge22/core';
import { MtWithConfig } from '@imerge22/platform/base';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class OrganizationService {

  constructor(
    private http: HttpClient,
    private querySrv: GenericQueryService,
    public mtConfigSrv: MtConfigService
  ) { }
  @MtWithConfig() private spectrumServiceName: string;
  @MtWithConfig() private rbacServiceName: string;
  orgList =  null;
  orgList$ = new BehaviorSubject(null)
  getOrganizationList(){
    const req = {
      modelId: 'Organization',
      conditions: { filters: [] },
      dataSource: this.rbacServiceName,
      queryId:(new Date()).valueOf().toString()
    };
    return this.querySrv.query(req).pipe(map(item=>{
      if(item && item.success){

        this.orgList = item.resultData.data;
        this.orgList$.next(this.orgList);
      }
      return item;

    }));
    // return this.http.post(`srv://${this.spectrumServiceName}/mongodb/common/query`, req)
  }
}
