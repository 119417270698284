import { Injectable } from '@angular/core';
import { MtDynamicTabHandler, MtDynamicTabRegister } from '@imerge22/common';
import { MtEvent, MtSafeAny, MtMap } from '@imerge22/core';
import { SuperQueryConditionActivateEvent } from './events';
import { SuperQueryConditionComponent } from './super-query-condition.component';

@Injectable({ providedIn: 'root' })
export class SuperQueryConditionTabHandler extends MtDynamicTabHandler {
  match(context: string, event: MtEvent<MtSafeAny>): boolean {
    if (event.name === SuperQueryConditionActivateEvent.$name) {
      const data = SuperQueryConditionActivateEvent.get(event);
      return data?.context === context;
    }
    return false;
  }

  same(context: string, exist: MtMap, event: MtEvent<MtSafeAny>): boolean {
    if (event.name === SuperQueryConditionActivateEvent.$name) {
      const data = SuperQueryConditionActivateEvent.get(event);
      return data?.context === context;
    }
    return false;
  }
}

export function registerSuperQueryConditionDynamicTab() {
  MtDynamicTabRegister.register(SuperQueryConditionActivateEvent.$name, {
    component: SuperQueryConditionComponent,
    handler: SuperQueryConditionTabHandler
  });
}
