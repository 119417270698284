import { NzModalService } from 'ng-zorro-antd/modal';
import {
  Component,
  EventEmitter,
  forwardRef,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  TemplateRef,
  Type,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { combineLatest, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, skipWhile, switchMap, take, takeUntil, tap, throttleTime } from 'rxjs/operators';

import { MtDictCacheService, OrganizationService, UserServiceService } from '@imerge22/base';
import {
  CommonUtils,
  FilterOperator,
  MtConfigService,
  MtMap,
  MtNotificationBus,
  MtObjectUtil,
  MtSafeAny,
  MtTranslateService,
  MtWithConfig,
  Panel,
  PassportService,
  ProcessInstance,
} from '@imerge22/core';
// import { MtDataSourceService, MtObjectContext, MtObjectData, ObjectDto } from '@imerge22/platform/base';

import {
  MtDataSourceService,
  MtObjectComponentBase,
  MtObjectData,
  MtObjectEventBus,
  ObjectDto,
} from '@imerge22/platform/base';
import {
  MtObjectDetailChildRegister,
  MtObjectDetailContext,
  MtObjectDetailPanelInfo,
  MtSpectrumDataService,
  MtSpectrumDefineService,
  MtSpectrumSecurityService,
} from '@imerge22/spectrum';

import { MtContextDirective, MtDynamicTabBase } from '@imerge22/common';
import { WorkProcessDto } from 'src/app/dtos/work_process.dto';
import { PanelService } from 'src/app/services/panel.service';
import { NzTabsCanDeactivateFn } from 'ng-zorro-antd/tabs';

@Component({
  selector: 'mt-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss'],
  providers: [
    {
      provide: MtObjectDetailContext,
      useExisting: forwardRef(() => PanelComponent),
    },
  ],
})

export class PanelComponent extends MtObjectDetailContext implements MtObjectComponentBase, MtDynamicTabBase, OnInit, OnDestroy {
  constructor(
    public srv: MtDataSourceService,
    private translate: MtTranslateService,
    defSvc: MtSpectrumDefineService,
    dataSvc: MtSpectrumDataService,
    secSvc: MtSpectrumSecurityService,
    public mtConfigSrv: MtConfigService,
    private panelService: PanelService,
    private mtObjectDetailChildRegister: MtObjectDetailChildRegister,
    private dictSrv: MtDictCacheService,
    public modal: NzModalService,
    @Optional() private context: MtContextDirective,
  ) {
    super(defSvc, dataSvc, secSvc);

    MtObjectEventBus.pipe(takeUntil(this.$destroyed)).subscribe((e) => {
      if (e.type === 'detail-closed') {
        this.afterDestroy();
      }
    });
  }
  /** 数据对象 */
  @Input() data: MtObjectData;

  @Output() afterSaved = new EventEmitter();

  @ViewChild('tabTitle') title: TemplateRef<void>;
  onCloseByInner: EventEmitter<void>;

  /** 当前编辑对象 */
  object: ObjectDto;

  /** 进度 */
  workProcess?: WorkProcessDto;

  /** 对象显示的面板/视图 */
  allPanels: MtObjectDetailPanelInfo[];

  /** 编辑状态 */
  editing = false;

  // 判断是否是itemRev
  isShowButton = false;  

  /** Revision Type */
  mtRevisionType: string;
  currentProcessNode: string; // 当前的申请进度
  currentSelectedStep: string; // 当前选择的步骤
  panelIndex = 0;
  allowEdit = false; // 是否可编辑
  private $destroyed = new Subject<void>();

  public initLoading: boolean = false;
  private initViewSubject = new Subject<any>();
  // 对象状态列表
  statusCodeList = [];
  canDeactivate: NzTabsCanDeactivateFn = (fromIndex: number, toIndex: number) => {
    const previous = this.panels[this.panelIndex];
    if (previous && previous.instance && previous.instance.mtEditing) {
      // 当前tab正在编辑，提示信息
      MtNotificationBus.emit({
        type: 'warning',
        title: 'i18n.platform.common.warning',
        message: 'i18n.platform.spectrum.panel.notSaved',
      });
      return false;
    } else {
      return true;
    }
  }
  ngOnInit(): void {
    this.srv.updateCache({type: 'objectEdit', modelCode: this.data.extra.code, objectId: this.data.extra.id});
    this.verifyPermissions = true;
    if (this.srv.isItemRevision(this.data.type)) {
      const list = this.data?.extra?.statusCodes?.map(res => res.statusCode) || [];
      this.isShowButton = !list.includes('InWork');
    } else {
      this.isShowButton = true;
    }
    this.mtObjectId = this.data?.extra.id;
    this.mtRevisionType = this.data.extra.type;
    
    this.initViewSubject.pipe(
      debounceTime(500),
      distinctUntilChanged(),
    ).subscribe(object => {
      this.mtObjects[object?.id] = object;
      this.object = object;
      this.editing = false; // 打开详情，默认为查看
      this.afterInit();
      this.loadPanels();
    });
    this.initData();
    this.dictSrv.getItems('Object_state').subscribe((res) => {
      this.statusCodeList = res as MtSafeAny;
    });

    this.mtSaveChange.subscribe(res => {
      this.afterSaved.emit(this.mtRevisionType)
    });
  }

  ngOnDestroy(): void {
    this.afterDestroy();
    MtObjectEventBus.next({
      type: 'detail-closed',
      objectId: this.data.id,
      context: this.context == null ? null : this.context.mtContext,
    });
  }

  onActivated(): void {}
  onDeactivated(): void {}
  activate(): void {}
  deactivate(): void {}
  onDataChange(data: MtMap<MtSafeAny>): void {}
  isEn() {
    return this.translate.currentLang.startsWith('en');
  }
  initData() {
    // 查看模式
    this.srv.getObject(this.mtObjectId).subscribe((object) => {
      // this.dataSvc.getObject(this.mtObjectId, true, this.data.type).subscribe((object) => {
      this.initViewSubject.next(object);
      this.initViewSubject.complete();
    });
  }
  // 打开流程弹框
  openProcessView(id: string, tplHeader: TemplateRef<{}>, tplContent: TemplateRef<{}>, tplFooter: TemplateRef<{}>): void {
    this.modal.create({
      nzTitle: tplHeader,
      nzContent: tplContent,
      nzFooter: tplFooter,
      nzWrapClassName: 'workflow-detail-v2-page',
      nzClassName: 'mt-maximum',
      nzComponentParams: {
        id
      },
    });
  }
   // 赋值对象类型
   transferStatus(status: string) {
    let statusName = '';
    if (status) {
      const list = status.split(',');
      const objList = this.statusCodeList.filter(item => list.includes(item.code));
      objList.forEach((item, i) => {
        statusName = statusName + (this.isEn() ? item.code : item.name);
        if (i !== objList.length - 1) {
          statusName += ',';
        }
      });
    }
    return statusName;
  }
  private loadPanels() {
    this.mtObjectInitedEvent[this.mtObjectId]
      .pipe(
        skipWhile((status) => {
          return status !== 'loaded';
        }),
        switchMap((status) => {
          if (this.mtRevisionType == null) {
            const panelDefs: Panel[] = [];
            return of({ status, panelDefs });
          }
          return this.defSvc.getPanels(this.mtRevisionType).pipe(
            map((panelDefs) => {
              return {
                status,
                // 创建新对象，浅拷贝panel数据
                panelDefs: panelDefs.map((panel) => ({ ...panel })),
              };
            })
          );
        }),
        switchMap((data) => {
          if (data.panelDefs == null || data.panelDefs.length === 0) {
            const panels: MtObjectDetailPanelInfo[] = [];
            return of({ ...data, panels });
          }
          return combineLatest(
            data.panelDefs.map((m) =>
              this.mtObjectDetailChildRegister.getPanel(this, this.dataSvc.getPanelKey(m)).pipe(
                map((component) => {
                  const info: MtObjectDetailPanelInfo = {
                    panel: m,
                    component,
                  };
                  return info;
                })
              )
            )
          ).pipe(
            map((panels) => {
              return { ...data, panels };
            })
          );
        }),
        switchMap((data) => {
          // data.panels
          //   .filter((m) => !!m.panel.status)
          //   .forEach((item) => {
          //     if (item.panel) {
          //       item.panel.editable = true;
          //     
          //   });
          // return of(data);
          const modal = this.dataSvc.getModel(this.object.objectType);
          const params = {
            modelId: modal.id,
            modelCode: this.object.objectType,
            businessId: this.object.id
          };
          return this.secSvc.getViewPower(params).pipe(
            map((acls1) => {
              const panelPower = acls1.resultData;
              data.panels
                .filter((m) => !!m.panel.status)
                .forEach((item) => {
                  if (item.panel) {
                    let editable: boolean;
                    let edit;
                    if (panelPower != null && panelPower[item.panel.id] != null) {
                      edit = panelPower[item.panel.id];
                    }
                    editable = !edit || edit.UPDATE;
                    item.panel.editable = editable;
                    let visible: boolean;
                    let read;
                    if (panelPower != null && panelPower[item.panel.id] != null) {
                      read = panelPower[item.panel.id];
                    }
                    visible = !read || read.READ;
                    item.panel.visible = visible;
                  }
                });
              return data;
            })
          );
        }),
        takeUntil(this.$destroy)
      )
      .subscribe((data) => {
        const oldPanels = this.panels;
        const panels: MtObjectDetailPanelInfo[] = [];
        // const newPanels = data.panels.filter((m) => m.component != null && !!m.panel.visible && !!m.panel.status);
        const newPanels = data.panels.filter((m) => m.component != null && !!m.panel.status);
        for (const panel of newPanels) {
          const existPanel = oldPanels.find((m) => m.panel.id === panel.panel.id);
          if (existPanel != null) {
            panels.push(existPanel);
          } else {
            panels.push(panel);
          }
        }
        this.initLoading = true;
        // 过滤，只显示当前阶段的面板
        this.panels = panels;
      });
  }
  stopPropagation(e: MouseEvent) {
    if (e != null) {
      e.stopPropagation();
    }
  }
  onEditClick() {
    this.editing = !this.editing;
  }
  onCancelClick() {
    this.editing = false;
  }
}
