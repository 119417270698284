import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';

import { combineLatest, of, Subject } from 'rxjs';
import { catchError, map, skipWhile, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { NzModalService } from 'ng-zorro-antd/modal';

import { OrganizationService, UserServiceService } from '@imerge22/base';
import {
  CommonUtils,
  FilterOperator,
  MtMap,
  MtNotificationBus,
  MtObjectUtil,
  MtSafeAny,
  Panel,
  PassportService,
  ProcessInstance,
} from '@imerge22/core';
import { MtDataSourceService, MtObjectComponentBase, MtObjectData, ObjectDto } from '@imerge22/platform/base';
import {
  IMtSpectrumDataSaveDTO,
  MtObjectDetailChildRegister,
  MtObjectDetailContext,
  MtObjectDetailPanelInfo,
  MtSpectrumDataService,
  MtSpectrumDefineService,
  MtSpectrumSecurityService,
} from '@imerge22/spectrum';
import { DictService, PageRequest } from '@imerge22/platform/mt-core';
import { MtDynamicTabBase } from '@imerge22/common';

import { PanelService } from 'src/app/services/panel.service';
import { WorkflowViewerComponent } from '../../../workflow-viewer/workflow-viewer.component';

import { EwoDetailComponent } from '../../../ewo/ewo-detail/ewo-detail.component';

@Component({
  selector: 'yc-ecn-detail',
  templateUrl: './ecn-detail.component.html',
  styleUrls: ['./ecn-detail.component.scss'],
  providers: [
    {
      provide: MtObjectDetailContext,
      useExisting: forwardRef(() => EcnDetailComponent),
    },
  ],
})
export class EcnDetailComponent extends MtObjectDetailContext implements MtObjectComponentBase, OnInit, OnDestroy, MtDynamicTabBase {
  isAdmin: boolean;
  constructor(
    private router: Router,
    private modalSvc: NzModalService,
    public mtDataSourceService: MtDataSourceService,
    private mtObjectDetailChildRegister: MtObjectDetailChildRegister,
    private formBuilder: FormBuilder,
    private passport: PassportService,
    private organizationService: OrganizationService,
    private passportService: PassportService,
    private panelService: PanelService,
    private userServiceService: UserServiceService,
    private uqSrv: DictService,

    defSvc: MtSpectrumDefineService,
    dataSvc: MtSpectrumDataService,
    secSvc: MtSpectrumSecurityService
  ) {
    super(defSvc, dataSvc, secSvc);
  }

  @ViewChild('tabTitle') title: TemplateRef<void>;
  onCloseByInner: EventEmitter<void>;
  shieldCloseBtn?: boolean;
  onActivated(): void {}
  onDeactivated(): void {}
  onDataChange(data: MtMap<any>): void {}

  /** 是否为新增 */
  @Input() isAdd: boolean;

  /** 数据对象: id: 对象ID, type: 对象类型，'ECNRevision' */
  @Input() data: MtObjectData;

  /** EWO的编号 */
  @Input() ewo_no: string;

  /** EWORevisoin的id */
  @Input() ewo_revision_id: string;

  /** 可创建的ECN的编号 */
  @Input() ECN_List: string[];

  /** 业务模型Code */
  @Input() mtModelCode: string;

  /** 当前编辑对象 */
  object: ObjectDto;

  /** 对象显示的面板/视图 */
  allPanels: MtObjectDetailPanelInfo[];

  /** 编辑状态 */
  editing = false;

  /** 控制是否显示 */
  haveAuthority = true;

  /** Revision Type */
  mtRevisionType: string;

  // 是否可编辑
  allowEdit = false;

  validateForm: FormGroup = this.formBuilder.group({
    ewo_no: [null],
    ewo_revisoin_id: [null],
    ecn_no: [null],
    valid_date: [null],
    distribution_range: [null],
    planned_complete_date: [null],
  });

  isEditloading = false;
  isAllowEditProps = false;
  panelIndex = -1;

  // 流程查看器
  @ViewChild(WorkflowViewerComponent, { static: true }) workflowViewer: WorkflowViewerComponent;
  currentProcessIns: ProcessInstance; //流程实例

  showWorkflowView = false; // 是否显示流程模态框
  mtProcInsName = null;
  mtProcInsId = null;

  // 发放范围全部选项
  distributionRangeListOfOption = [];

  private $dataChange = new Subject<MtMap>();

  ngOnInit(): void {
    this.verifyPermissions = true;
    if (this.data?.extra) {
      this.isAdd = this.data.extra.isAdd;
      this.mtModelCode = this.data.extra.mtModelCode;
      this.ewo_no = this.data.extra.ewo_no;
      this.ewo_revision_id = this.data.extra.ewo_revision_id;
      this.ECN_List = this.data.extra.ECN_List;
    }

    this.mtObjectId = this.data?.id;
    this.mtRevisionType = this.data.type;

    this.loadDict('Distribution_scope');

    if (this.isAdd) {
      this.mtObjectChanges = {};
      this.mtPropertyValueChanges = {};
      this.mtObjectId = this.createTempObject(this.data.type); // Item版本id
      this.editing = true; // 新增时，默认显示为编辑状态
      this.isAllowEditProps = true;
      this.object = {
        id: this.mtObjectId,
        objectType: this.mtModelCode, // Item的Code
        creator: this.passportService.current.user.id,
        ewo_no: this.ewo_no,
        ewo_revision_id: this.ewo_revision_id,
        ewo_revision: this.ewo_revision_id,
        ecn_list: this.ECN_List,
      };

      this.validateForm.controls.ewo_no.setValue(this.object.ewo_no);
      this.validateForm.controls.ewo_revisoin_id.setValue(this.object.ewo_revisoin_id);

      const filters = [
        { prop: 'deleted', operate: FilterOperator.Equals, value: false },
        { prop: 'id', operate: FilterOperator.Equals, value: this.object.creator },
      ];
      this.userServiceService.getUsers(filters, { index: 1, size: 1 }).subscribe((res) => {
        if (res && res.total > 0) {
          const orgs = res.datas[0].orgRels;
          if (orgs && orgs.length > 0) {
            this.object.dept_id = orgs[0].orgId;
            this.object.deptName = orgs[0]?.organization?.name;
          }
        }
      });

      // this.loadPanels();
      this.init();
      this.$dataChange.next(this.data);

      // 关联EWO对象显示 EWO中的内容
      // tslint:disable-next-line: no-string-literal
      this.mtPropertyValueChanges[this.mtObjectId]['ewo_revision'] = this.ewo_revision_id;

      this.initPartChangeFromEwo();
    } else {
      // 查看模式
      // this.dataBindingCreateStatus = {};
      this.mtDataSourceService.getObject(this.mtObjectId).subscribe((object) => {
        // this.dataSvc.getObject(this.mtObjectId, true, this.data.type).subscribe((object) => {
        this.mtObjects[object.id] = object;
        this.object = object;
        this.editing = false; // 打开详情，默认为查看
        this.isAdmin = this.object?.creator === this.passport.current.user.id;
        this.setEditAuth();

        if (object != null) {
          this.panelService.getObjectPropertyValues(this.data.type, this.data.id).subscribe((result: MtSafeAny) => {
            if (result && result.data && result.data.length > 0) {
              const res = result.data[0];
              this.object.ewo_no = res?.ewo_no;
              this.object.ewo_revision_id = res?.ewo_revision_id;
              if (CommonUtils.isNotBlank(res?.planned_complete_date)) {
                this.object.planned_complete_date = new Date(res?.planned_complete_date);
              }

              if (CommonUtils.isNotBlank(res?.valid_date)) {
                this.object.valid_date = new Date(res?.valid_date);
              }
              this.object.distribution_range = res?.distribution_range;
              this.object.dept_id = res?.dept_id;
              // this.object.state = res?.state;

              // 编辑权限控制
              this.isAllowEditProps = res?.sync_ecn_sap_flag || false;
            }

            this.afterInit();
            this.loadPanels();

            // 根据对象组织机构id查找name
            if (CommonUtils.isNotBlank(this.object.dept_id)) {
              // tslint:disable-next-line: no-any
              this.organizationService.getOrgByIds([object.dept_id]).subscribe((res: any) => {
                if (res && res.total > 0) {
                  this.object.deptName = res.data[0].name;
                }
              });
            } else {
              this.object.deptName = '-';
            }
          });
        }
      });

      // 查看流程状态
      this.panelService.getProcessIns(this.mtObjectId).subscribe((res: MtSafeAny) => {
        if (res && res.success && res?.resultData?.total > 0) {
          this.currentProcessIns = res?.resultData?.data[0].procIns;
          this.mtProcInsId = res?.resultData?.data[0]?.procInsId;
          this.mtProcInsName = res?.resultData?.data[0]?.procIns?.name;
        }
      });
    }
  }
  /**
   * 处理从EWO复制更改零件信息
   */
  private initPartChangeFromEwo(): void {
    const ewoRevision = this.dataSvc.getObjectPropertyValues(this.ewo_revision_id) as { [key: string]: any };
    const ewoTechnicalSolutions = this.dataSvc.getObjectPropertyValues(ewoRevision?._value.ewo_TechnicalSolutions) as {
      [key: string]: any;
    };
    const partChanges = ewoTechnicalSolutions?._value.part_changes as [{ visible: boolean; part_change_item: string }];
    const spChangsObj = this.dataSvc.createTempObject('ImapctedScopeAndPartChangs');
    this.mtPropertyValueChanges[this.mtObjectId].parts_change_table = [
      {
        Imapct_and_part: spChangsObj.id,
      },
    ];
    this.mtPropertyValueChanges[spChangsObj.id] = {
      part_changes: [],
    };

    partChanges.forEach((item) => {
      const partChangesObj = this.dataSvc.createTempObject('PartChanges');
      const itemObj = this.dataSvc.getObjectPropertyValues(item.part_change_item) as { [key: string]: any };
      this.mtPropertyValueChanges[spChangsObj.id].part_changes.push({
        part_change_item: partChangesObj.id,
      });

      this.mtPropertyValueChanges[partChangesObj.id] = {
        Operate: itemObj.value?.Operate,
        Drawing_number: itemObj.value?.Drawing_number,
        Part_Name: itemObj.value?.Part_Name,
        Quantity: itemObj.value?.Quantity,
        Parent: itemObj.value?.Parent,
        YC_in_stock: itemObj.value?.YC_in_stock,
        Supplier_in_stock: itemObj.value?.Supplier_in_stock,
        Old_Part: itemObj.value?.Old_Part,
        Switching_Strategy: itemObj.value?.Switching_Strategy,
        fixed_date: itemObj.value?.fixed_date,
        masterChange: itemObj.value?.masterChange,
        Remark: itemObj.value?.Remark,
      };
    });
  }

  setEditAuth() {
    this.secSvc.getObjectAuthentication(this.mtObjectId, 'ECNRevision').subscribe((res) => {
      if (res && res.Write) {
        this.allowEdit = true;
      }
    });
  }

  ngOnDestroy(): void {
    this.afterDestroy();
  }

  private loadPanels() {
    this.mtObjectInitedEvent[this.mtObjectId]
      .pipe(
        skipWhile((status) => {
          return status !== 'loaded';
        }),
        switchMap((status) => {
          if (this.mtRevisionType == null) {
            const panelDefs: Panel[] = [];
            return of({ status, panelDefs });
          }
          return this.defSvc.getPanels(this.mtRevisionType).pipe(
            map((panelDefs) => {
              return {
                status,
                // 创建新对象，浅拷贝panel数据
                panelDefs: panelDefs.map((panel) => ({ ...panel })),
              };
            })
          );
        }),
        switchMap((data) => {
          if (data.panelDefs == null || data.panelDefs.length === 0) {
            const panels: MtObjectDetailPanelInfo[] = [];
            return of({ ...data, panels });
          }
          return combineLatest(
            data.panelDefs.map((m) =>
              this.mtObjectDetailChildRegister.getPanel(this, this.dataSvc.getPanelKey(m)).pipe(
                map((component) => {
                  const info: MtObjectDetailPanelInfo = {
                    panel: m,
                    component,
                  };
                  return info;
                })
              )
            )
          ).pipe(
            map((panels) => {
              return { ...data, panels };
            })
          );
        }),
        switchMap((data) => {
          return this.secSvc.getPanelAuthentication(this.mtObjectId, this.object?.objectType, this.mtObjectId, null, this.taskId).pipe(
            map((acls) => {
              data.panels
                .filter((m) => !!m.panel.status)
                .forEach((item) => {
                  if (item.panel) {
                    let editable: boolean;
                    if (acls != null && acls[item.panel.code] != null) {
                      editable = acls[item.panel.code].Write;
                    }
                    if (editable == null) {
                      editable = acls?.$object?.Write;
                    }
                    editable = !!editable;
                    item.panel.editable = editable;
                    if (editable) {
                      item.panel.visible = true;
                    } else {
                      let visible: boolean;
                      if (acls != null && acls[item.panel.code] != null) {
                        visible = acls[item.panel.code].Read;
                      }
                      if (visible == null) {
                        visible = acls?.$object?.Read;
                      }
                      visible = !!visible;
                      item.panel.visible = visible;
                    }
                  }
                });
              return data;
            })
          );
        }),
        takeUntil(this.$destroy)
      )
      .subscribe((data) => {
        const oldPanels = this.panels;
        const panels: MtObjectDetailPanelInfo[] = [];
        const newPanels = data.panels.filter((m) => m.component != null && !!m.panel.visible);
        for (const panel of newPanels) {
          const existPanel = oldPanels.find((m) => m.panel.id === panel.panel.id);
          if (existPanel != null) {
            panels.push(existPanel);
          } else {
            panels.push(panel);
          }
        }

        // 过滤，只显示当前阶段的面板
        this.panels = panels.filter((item) => item.panel.code === 'ecn_info');

        // 不在流程中时可编辑
        if (this.isAdd) {
          setTimeout(() => {
            this.panels.forEach((p) => {
              if (p.instance) {
                p.instance.mtEditing = true;
              }
            });
          }, 100);
        }
      });
  }

  stopPropagation(e: MouseEvent) {
    if (e != null) {
      e.stopPropagation();
    }
  }

  openEwo() {
    // 弹出框，不再弹出
    if (this.modalSvc.openModals.length > 0) {
      return;
    }
    if (this.object.ewo_revision_id) {
      // this.router.navigate(['/changes/ewo']);
      // setTimeout(() => {
      //   EwoDetailActivateEvent.emit({
      //     context: 'EWO',
      //     id: this.object.ewo_revision_id,
      //     type: 'EWORevision',
      //     extra: {
      //       mtModelCode: 'EWO',
      //       isAdd: false
      //     }
      //   });
      // }, 100);

      const data = { id: this.object.ewo_revision_id, type: 'EWORevision' };
      this.modalSvc.create({
        nzTitle: 'EWO详情',
        nzContent: EwoDetailComponent,
        nzComponentParams: {
          isAdd: false,
          mtModelCode: 'EWO',
          data: data,
        },
        nzFooter: null,
        nzWidth: '90%',
        nzBodyStyle: { height: document.body.clientHeight - 75 + 'px' },
        nzMaskClosable: false,
        //nzClassName: 'mt-maximum',
        nzStyle: { top: '5px' },
      });
    }
  }

  canleEdit() {
    this.editing = false;
    this.panels.forEach((p) => {
      if (p.instance) {
        p.instance.mtEditing = false;
      }
    });
  }

  startEdit() {
    this.editing = true;
    this.validateForm.controls.ewo_no.setValue(this.object.ewo_no);
    this.validateForm.controls.ecn_no.setValue(this.object.objectName);
    this.validateForm.controls.planned_complete_date.setValue(this.object.planned_complete_date);
    this.validateForm.controls.valid_date.setValue(this.object.valid_date);
    this.validateForm.controls.distribution_range.setValue(this.object.distribution_range);
    this.panels.forEach((p) => {
      if (p.instance && p.panel.editable) {
        p.instance.mtEditing = true;
      }
    });
  }

  // tslint:disable-next-line: no-any
  submitEdit(callBack?: any) {
    this.isEditloading = true;
    const props = {
      ewo_no: this.validateForm.controls.ewo_no.value,
      ewo_revision_id: this.ewo_revision_id,
      ewo_revision: this.ewo_revision_id,
      planned_complete_date: this.validateForm.controls.planned_complete_date.value,
      valid_date: this.validateForm.controls.valid_date.value,
      distribution_range: this.validateForm.controls.distribution_range.value,
      dept_id: this.object.dept_id,
    };
    // 新增
    if (this.isAdd) {
      const newObject: ObjectDto = {
        objectType: this.mtModelCode,
        objectName: this.validateForm.controls.ecn_no.value,
        item: { itemId: this.validateForm.controls.ecn_no.value },
        prop: props,
      };

      if (CommonUtils.isBlank(newObject.objectName)) {
        MtNotificationBus.emit({
          type: 'warning',
          title: '提示信息',
          message: 'ECN编号不能为空！',
        });

        return;
      }
      this.object.objectName = newObject.objectName;
      // 新增
      this.mtDataSourceService.createObject(newObject).subscribe((itemId) => {
        this.mtDataSourceService.getObjectRevisions(itemId).subscribe((resp: MtSafeAny) => {
          const objectId = resp[0].itemRevision.id;
          this.mtObjects[objectId] = this.mtObjects[this.mtObjectId];
          this.mtObjectChanges[objectId] = this.mtObjectChanges[this.mtObjectId];
          this.mtPropertyValueChanges[objectId] = this.mtPropertyValueChanges[this.mtObjectId];
          delete this.mtObjects[this.mtObjectId];
          delete this.mtObjectChanges[this.mtObjectId];
          delete this.mtPropertyValueChanges[this.mtObjectId];

          this.mtObjectId = objectId;
          this.savePanelData();
        });
      });
    } else {
      this.savePanelData(callBack);
    }
  }

  // 保存面板的内容
  // tslint:disable-next-line: no-any
  savePanelData(callBack?: any) {
    const ids = new Set<string>();
    const changes = this.getChanges(ids);
    if (this.isAllowEditProps) {
      if (!changes.data[this.mtObjectId]) {
        changes.data[this.mtObjectId] = { changes: [], data: {} };
      }

      changes.data[this.mtObjectId].changes.push('planned_complete_date', 'distribution_range');
      // tslint:disable-next-line: no-string-literal
      changes.data[this.mtObjectId].data['planned_complete_date'] = this.validateForm.controls.planned_complete_date.value || '';

      changes.data[this.mtObjectId].changes.push('valid_date');
      // tslint:disable-next-line: no-string-literal
      changes.data[this.mtObjectId].data['valid_date'] = this.validateForm.controls.valid_date.value || '';
      // tslint:disable-next-line: no-string-literal
      changes.data[this.mtObjectId].data['distribution_range'] = this.validateForm.controls.distribution_range.value || [];

      ids.add(this.mtObjectId);
    }
    if (ids.size === 0) {
      this.isEditloading = false;
      this.editing = false;
      this.panels.forEach((p) => {
        if (p.instance) {
          p.instance.mtEditing = false;
        }
      });

      MtNotificationBus.emit({
        type: 'success',
        title: this.isAdd ? '创建成功' : '更新成功',
        message: 'ECN编号为：' + this.object?.objectName,
      });
      this.isAdd = false;
      this.setEditAuth();
      return;
    }
    this.dataSvc
      .save(changes)
      .pipe(
        tap(() => {
          // 重新加载修改对象最新的数据
          for (const objId of ids) {
            this.dataSvc.getObject(objId, true).pipe(take(1)).subscribe();
            this.dataSvc.getObjectPropertyValues(objId, true).pipe(take(1)).subscribe();
          }
          this.objectDataInit(this.mtObjectId, this.mtObject.objectType, true);
        }),
        map((id) => true),
        catchError((err) => {
          this.isEditloading = false;
          return of(false);
        })
      )
      .subscribe((res) => {
        if (res) {

          this.mtObjectChanges = {};
          this.mtPropertyValueChanges = {};
          if (this.isAllowEditProps) {
            // this.object.ewo_no = this.validateForm.controls.ewo_no.value;
            // this.object.ecn_no = this.validateForm.controls.ecn_no.value;
            // this.object.change_topic = this.validateForm.controls.change_topic.value;
            this.object.planned_complete_date = this.validateForm.controls.planned_complete_date.value;
            this.object.valid_date = this.validateForm.controls.valid_date.value;
            this.object.distribution_range = this.validateForm.controls.distribution_range.value;
          }
          this.isEditloading = false;
          this.editing = false;

          // 提示信息
          MtNotificationBus.emit({
            type: 'success',
            title: this.isAdd ? '创建成功' : '更新成功',
            message: 'ECN编号为：' + this.object.objectName,
          });
          if (this.isAdd) {
            this.isAdd = false;
            this.setEditAuth();
          } else {
            this.panels.forEach((p) => {
              if (p.instance) {
                p.instance.mtEditing = false;
              }
            });
          }

          if (callBack) {
            callBack();
          }
        }
      }, err => this.isEditloading = false);
  }

  getChanges(ids: Set<string>) {
    const changes: IMtSpectrumDataSaveDTO = { id: this.mtObjectId, data: {} };
    let objIds = Object.keys(this.mtObjectChanges);
    for (const objId of objIds) {
      const change = this.mtObjectChanges[objId];
      let hasChange = false;
      if (change != null) {
        if (change.objectName != null) {
          if (changes.data[objId] == null) {
            changes.data[objId] = { changes: [], data: {} };
          }
          changes.data[objId].changes.push('$.objectName');
          changes.data[objId].data['$.objectName'] = change.objectName;
          hasChange = true;
        }
        if (change.item?.itemId != null) {
          if (changes.data[objId] == null) {
            changes.data[objId] = { changes: [], data: {} };
          }
          changes.data[objId].changes.push('$.item.itemId');
          changes.data[objId].data['$.item.itemId'] = change.item.itemId;
          hasChange = true;
        }
        if (MtObjectUtil.isNew(objId)) {
          if (changes.data[objId] == null) {
            changes.data[objId] = { changes: [], data: {} };
          }
          changes.data[objId].changes.push('$.objectType');
          changes.data[objId].data['$.objectType'] = this.mtObjects[objId].objectType;
        }
      }
      if (hasChange && !MtObjectUtil.isNew(objId)) {
        ids.add(objId);
      }
    }
    objIds = Object.keys(this.mtPropertyValueChanges);
    for (const objId of objIds) {
      const change = this.mtPropertyValueChanges[objId];
      if (change != null && Object.keys(change).length > 0) {
        const props = Object.keys(change);
        if (MtObjectUtil.isNew(objId) && props.length > 1) {
          if (changes.data[objId] == null) {
            changes.data[objId] = { changes: [], data: {} };
            changes.data[objId].changes.push('$.objectType');
            changes.data[objId].data['$.objectType'] = this.mtObjects[objId].objectType;
          }
        }

        // 只有$.objectType属性时，不需要添加到changes
        // 处理PartChanges 只有IsNewPart属性时，不需要添加到changes
        if (props.length === 1 && (props[0] === '$.objectType' || props[0] === 'IsNewPart')) {
          continue;
        }

        for (const prop of props) {
          if (changes.data[objId] == null) {
            changes.data[objId] = { changes: [], data: {} };
          }

          // 处理表格属性为空内容时不加入changes，如："part_changes", "impact_scope", "service_solution"]
          if (Array.isArray(change[prop]) && change[prop].length === 0) {
            continue;
          }

          if (change[prop] !== null) {
            changes.data[objId].changes.push(prop);
            changes.data[objId].data[prop] = change[prop];
          }
        }

        if (props.length > 0 && !MtObjectUtil.isNew(objId)) {
          ids.add(objId);
        }
      }

      // 移除中的空数据，及changes.data[objId] = { changes: [], data: {} };
      if (changes.data[objId] && changes.data[objId]?.changes.length === 0) {
        delete changes.data[objId];
        ids.delete(objId);
      }
    }

    return changes;
  }

  private init() {
    this.$dataChange.pipe(takeUntil(this.$destroy)).subscribe(() => {
      this.objectDataInit(this.mtObjectId, this.data.type, true);
      this.loadPanels();
    });
  }

  checkEditProps() {
    return this.isAllowEditProps && this.editing;
  }

  // 加载字典
  loadDict(codes: string, propertiesName: string = null) {
    if (CommonUtils.isBlank(this[propertiesName])) {
      this.uqSrv
        .queryDictWithChild(this.passportService.current.applicationId, { index: 1, size: 9999 } as PageRequest, {
          code: codes,
        })
        .subscribe((resp) => {
          if (resp && CommonUtils.isNotBlank(resp.datas)) {
            this.distributionRangeListOfOption = resp.datas.find((i) => i.nameEn === codes)?.child;
          }
        });
    }
  }

  //查看流程
  showWorkflowViewer() {
    this.workflowViewer.visible = true;
  }
}
