<span *ngIf="!dataBinding.editing">
  {{showText}}
</span>

<nz-select
  *ngIf="dataBinding.editing"
  [(ngModel)]="value"
  nzAllowClear
  nzPlaceHolder="请选择项目部门"
  (ngModelChange)="changeContactId($event)"
>
<nz-option
  *ngFor="let item of orgListOption"
  [nzValue]="item.code"
  [nzLabel]="item.name"
></nz-option>
</nz-select>