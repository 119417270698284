import { Injectable } from '@angular/core';

import { MtDynamicTabHandler, MtDynamicTabRegister } from '@imerge22/common';
import { MtEvent, MtSafeAny, MtMap } from '@imerge22/core';
import { ContractInfoDetailComponent } from './contract-info-detail.component';
import { ContractInfoActivateEvent } from './events';


@Injectable({ providedIn: 'root' })
export class ContractInfoDetailHandler extends MtDynamicTabHandler {
  match(context: string, event: MtEvent<MtSafeAny>): boolean {
    if (event.name === ContractInfoActivateEvent.$name) {
      const data = ContractInfoActivateEvent.get(event);
      return data?.context === context;
    }
    return false;
  }

  same(context: string, exist: MtMap, event: MtEvent<MtSafeAny>): boolean {
    if (event.name === ContractInfoActivateEvent.$name && exist.id === event.data.id) {
      const data = ContractInfoActivateEvent.get(event);
      return data?.context === context;
    }
    return false;
  }
}

export function registerContractInfoDetailDynamicTab() {
  MtDynamicTabRegister.register(ContractInfoActivateEvent.$name, {
    component: ContractInfoDetailComponent,
    handler: ContractInfoDetailHandler,
  });
}
