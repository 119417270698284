<nz-table
  #basicTable
  [nzData]="tableList"
  [nzBordered]="true"
  nzShowPagination
  [nzShowSizeChanger]="true"
  [(nzPageIndex)]="query.index"
  [(nzPageSize)]="query.size"
  [nzTotal]="query.total"
  [nzShowTotal]="measurementClassTotal"
>
  <thead>
    <tr>
      <th class="mt-center mt-fill">{{ 'common.panel.imapacted' | translate }}</th>
      <th *ngIf="mtEditing">
        <button
          nz-button
          nzShape="circle"
          nzType="primary"
          nz-tooltip
          nzTooltipPlacement="left"
          [nzTooltipTitle]="'platform.common.default.add' | translate"
          (click)="add()"
          style="margin-right: 15px"
        >
          <i nz-icon nzType="plus"></i>
        </button>
        <button nz-button nzType="primary" nzShape="round" (click)="openImport()">
          <i nz-icon nzType="edit"></i>{{ 'common.import' | translate }}
        </button>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let row of basicTable.data; index as i">
      <td>{{ row }}</td>
      <td *ngIf="mtEditing">
        <button
          nz-button
          nzType="danger"
          nzShape="circle"
          nz-tooltip
          [nzTooltipTitle]="'platform.common.default.delete' | translate"
          nzTooltipPlacement="left"
          nz-popconfirm
          [nzPopconfirmTitle]="'platform.common.default.deleteText' | translate"
          (nzOnConfirm)="delete(i)"
          nzPopconfirmPlacement="left"
        >
          <i nz-icon nzType="delete"></i>
        </button>
      </td>
    </tr>
  </tbody>
</nz-table>
<ng-template #measurementClassTotal let-range="range" let-total>
  {{ 'platform.core.dict.total' | translate: { total: total, range0: range[0], range1: range[1] } }}
</ng-template>

<nz-drawer
  [nzWidth]="400"
  [nzClosable]="true"
  [nzVisible]="showImportDrawer"
  nzPlacement="right"
  [nzTitle]="'root.panel.panelImapacted.importUplmTitle' | translate"
  (nzOnClose)="closeImport()"
  nzWrapClassName="mt-with-footer"
>
  <ng-template #indicatorTemplate>
    <i nz-icon nzType="loading" style="font-size: 24px"></i>
  </ng-template>
  <nz-spin
    [nzSpinning]="isSpinning"
    [nzIndicator]="indicatorTemplate"
    [nzTip]="'common.fileParsing' | translate"
  >
    <button nz-button nzType="primary" (click)="templateDownload()">
      <i nz-icon nzType="download" nzTheme="outline"></i>{{ 'common.templateDownload' | translate }}
    </button>
    <div nz-row [style.margin-top]="'20px'">
      <div nz-col nzSpan="6">{{ 'common.uploadEnclosure' | translate }}:</div>
      <div nz-col nzSpan="18">
        <button nz-button (click)="fileInput.click()">
          <i nz-icon nzType="upload"></i>{{ 'common.selectFile' | translate }}
        </button>
        <input
          #fileInput
          type="file"
          style="display: none"
          (change)="selectedFile($event)"
          multiple="false"
          accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        />
      </div>
    </div>
    <div nz-row *ngIf="fileName">
      <div nz-col nzSpan="6"></div>
      <div nz-col nzSpan="18">{{ 'common.file' | translate }} : {{ fileName }}</div>
    </div>
  </nz-spin>

  <div class="mt-drawer-footer">
    <button (click)="closeImport()" type="button" nz-button nzShape="round">
      <i nz-icon nzType="close"></i>{{ 'platform.common.cancel' | translate }}
    </button>
    <button
      nz-button
      nzShape="round"
      nzType="primary"
      type="button"
      (click)="closeImport(true)"
      [disabled]="isBlank(dataList)"
    >
      <i nz-icon nzType="check"></i>{{ 'platform.common.confirm' | translate }}
    </button>
  </div>
</nz-drawer>

<uccs-search-upmn #upmn [notShowSetp]="true"></uccs-search-upmn>
<ng-template #extraContent>
  <ng-container *ngIf="editable">
    <button nz-button nzType="primary" *ngIf="!mtEditing" nzShape="round" (click)="edit()">
      <i nz-icon nzType="edit"></i>{{ 'platform.common.default.edit' | translate }}
    </button>
    <nz-button-group class="mt-sharp-round" *ngIf="mtEditing">
      <button nz-button (click)="cancelEdit()" [disabled]="submiting">
        <i nz-icon nzType="close"></i>{{ 'platform.common.cancel' | translate }}
      </button>
      <button nz-button nzType="primary" (click)="save()" [disabled]="submiting">
        <i nz-icon nzType="save"></i>{{ 'platform.common.save' | translate }}
      </button>
    </nz-button-group>
  </ng-container>
</ng-template>
