import { MtDynamicTabsetActivateEventData } from '@imerge22/common';
import { MtEvent, MtEventBus, MtSafeAny } from '@imerge22/core';

interface MtProjectDetailData extends MtDynamicTabsetActivateEventData {
  id?: string;
  type?: string;
  extra?: MtSafeAny;
}

export class ProjectDetailActivateEvent {
  static $name = 'project-detail';
  static emit(data: MtProjectDetailData) {
    MtEventBus.emit({
      name: ProjectDetailActivateEvent.$name,
      data,
    });
  }

  static is(event: MtEvent<MtSafeAny>): boolean {
    return event != null && event.name === ProjectDetailActivateEvent.$name;
  }

  static get(event: MtEvent<MtSafeAny>): MtProjectDetailData {
    if (ProjectDetailActivateEvent.is(event)) {
      return event.data;
    }
    return undefined;
  }
}
