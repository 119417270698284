import { NzModalService } from 'ng-zorro-antd/modal';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Subject } from 'rxjs';

import {
  MtConfigService,
  MtMap,
  MtSafeAny,
} from '@imerge22/core';

import {
  MtDataSourceService,
  MtObjectData,
} from '@imerge22/platform/base';
import { RefreshListTabService } from '../../../working-hours/working-hours/services/refresh-list.service';

@Component({
  selector: 'k-customer-info',
  templateUrl: './customer-info-detail.component.html',
  styleUrls: ['./customer-info-detail.component.scss'],
})
export class CustomerInfoDetailComponent implements OnInit, OnDestroy {
  constructor(
    public srv: MtDataSourceService,
    public mtConfigSrv: MtConfigService,
    public modal: NzModalService,
    private refreshSub: RefreshListTabService,
  ) {
  }
  /** 数据对象 */
  @Input() data: MtObjectData;

  @ViewChild('tabTitle') title: TemplateRef<void>;
  onCloseByInner: EventEmitter<void>;

  private $destroyed = new Subject<void>();


  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  onActivated(): void {}
  onDeactivated(): void {}
  activate(): void {}
  deactivate(): void {}
  onDataChange(data: MtMap<MtSafeAny>): void {}

  stopPropagation(e: MouseEvent) {
    if (e != null) {
      e.stopPropagation();
    }
  }
  onEditClick() {
  }
  onCancelClick() {
  }

  public isSave(res: string): void {
    this.refreshSub.sendRefresh(res);
  }
}
