import { MtDynamicTabsetActivateEventData } from '@imerge22/common';
import { MtEvent, MtEventBus, MtSafeAny } from '@imerge22/core';

interface SuperQueryConditionActivateEventData extends MtDynamicTabsetActivateEventData{
  modelCode: string;
  isRevision: boolean;
  isUaesQuery: boolean;
}

export class SuperQueryConditionActivateEvent {
  static $name = 'super-query-condition';
  static emit(data: SuperQueryConditionActivateEventData) {
    MtEventBus.emit({
      name: SuperQueryConditionActivateEvent.$name,
      data,
    });
  }

  static is(event: MtEvent<MtSafeAny>): boolean {
    return event != null && event.name === SuperQueryConditionActivateEvent.$name;
  }

  static get(event: MtEvent<MtSafeAny>): SuperQueryConditionActivateEventData {
    if (SuperQueryConditionActivateEvent.is(event)) {
      return event.data;
    }
    return undefined;
  }
}
