import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FilterOperator, GenericQueryService, MtConfigService, MtSafeAny, MtWithConfig } from '@imerge22/core';
import { MtDynamicPropertyValueProvider } from '@imerge22/platform/base';
import { Observable, of } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class GetUserInfoService {
    @MtWithConfig() private rbacServiceName: string;
    @MtWithConfig() private workflowServiceName: string;
    constructor(
        private http: HttpClient,
        public mtConfigSrv: MtConfigService,
        private uqSrv: GenericQueryService,
        private propValueProvider: MtDynamicPropertyValueProvider
    ) { }

    public getUserInfoById(userId: string): Observable<MtSafeAny> {
        let params = {
            "dataSource": "rbac",
            "modelId": "User",
            "conditions": {
                "filters": [
                    { "prop": "deleted", "operate": FilterOperator.Equals, "value": false },
                    { "prop": "id", "operate": FilterOperator.Equals, "value": userId}
                ]
            }, 
            "cascades": ["roleRels", "orgRels"],
            "sorts": [{ "prop": "createTime", "dir": "Descend" }], "page": { "skip": 0, "take": 20 }
        }
        return this.http.post('srv://rbac/common/query/user/v3', params)
    }
}