import { Injectable } from '@angular/core';
import { MtDataSourceService } from '@imerge22/platform/base';
import {
  MtObjectContextCommandEvent,
  MtObjectContextCommandHandler,
  MtObjectContextCommands,
} from '@imerge22/platform/object-context';
import { of, Observable } from 'rxjs';
import { CreateObjectService } from 'src/app/services/create-object.service';
import { map } from 'rxjs/operators';
import { MtNotificationBus } from '@imerge22/core/notification';

@Injectable({ providedIn: 'root' })
export class MtObjectSynchronizesEcnHandler implements MtObjectContextCommandHandler {
  constructor(private srv: MtDataSourceService, public createSrv: CreateObjectService) {}

  available(commandId: string, event: MtObjectContextCommandEvent) {
    return of(
      this.srv.isItem(event.object.objectType) || this.srv.isItemRevision(event.object.objectType)
    );
  }

  execute(commandId: string, event: MtObjectContextCommandEvent): Observable<any> {
    return this.createSrv.synchronizes(event.objectId).pipe(
      map(
        (resp) => {
          MtNotificationBus.emit({
            type: 'success',
            title: 'i18n.platform.common.success',
            message: '同步成功',
          });
          return resp;
        },
        (err) => {
          console.log(err);
          MtNotificationBus.emit({
            type: 'error',
            title: 'i18n.platform.common.error',
            message: '同步失败',
          });
        }
      )
    );
  }
}

export function registerObjectSynchronizesEcn() {
  MtObjectContextCommands.registerCommand({
    id: 'synchronizesEcn',
    name: 'common.contextMenu.synchronizesEcn',
    icon: 'sync',
    handlerDefine: MtObjectSynchronizesEcnHandler,
  });
}
