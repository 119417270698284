import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  SimpleChanges,
} from '@angular/core';
import { InputBoolean } from 'ng-zorro-antd/core/util';
import { Subject, Subscription } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';

import { MtContextDirective } from '@imerge22/common';
import {
  FilterOperator,
  GenericQueryRequest,
  GenericQueryService,
  MtConfigService,
  MtSafeAny,
  MtWithConfig,
} from '@imerge22/core';
import { MtObjectNameBase, MtObjectNameRenderer, MtSpectrumDataBinding } from '@imerge22/spectrum';
import { CommonObject } from '@imerge22/core/data-models';
import { MtSpectrumDataService } from '@imerge22/spectrum/services';

import { MtUserService } from 'src/app/services/user.service';

@Component({
  selector: 'uccs-user-select',
  templateUrl: './user-select.component.html',
  styleUrls: ['./user-select.component.css'],
})
export class UserSelectComponent extends MtObjectNameBase implements OnInit, OnChanges, OnDestroy {
  constructor(
    @Optional() protected context: MtContextDirective,
    @Optional() public dataBinding: MtSpectrumDataBinding,
    private dataSvc: MtSpectrumDataService,
    private genericSvc: GenericQueryService,
    public userSrv: MtUserService,
    public mtConfigSrv: MtConfigService
  ) {
    super();
  }

  @MtWithConfig() private spectrumServiceName: string;
  @MtWithConfig() private mainDataSource: string;
  @MtWithConfig() private rbacServiceName: string;
  @Input() @InputBoolean() mtShowIcon = true;
  @Input() mtDefaultIcon = 'file';
  @Input() mtSubIcon: string;
  @Input() showDetail: boolean;

  object: CommonObject;
  name: string;

  private $sub: Subscription;
  private $destroy = new Subject<void>();

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.mtObjectId) {
      this.load();
    }
  }

  ngOnDestroy(): void {
    this.$destroy.next();
    this.$destroy.complete();
  }

  private load() {
    if (this.$sub != null) {
      this.$sub.unsubscribe();
      this.$sub = null;
    }
    this.$sub = this.dataSvc
      .getObject(this.mtObjectId)
      .pipe(
        takeUntil(this.$destroy),
        switchMap((obj) => {
          const o: MtSafeAny = obj as MtSafeAny;
          const userobj: GenericQueryRequest = {
            modelId: 'User',
            dataSource: this.rbacServiceName,
            cacheable: false,
            conditions: {
              filters: [{ prop: 'id', operate: FilterOperator.Equals, value: o.id }],
            },
          };
          return this.genericSvc.query(userobj).pipe(
            map((res: MtSafeAny) => {
              if (res?.total > 0) {
                return res?.resultData?.data[0]?.employeeNo + '-' + res?.resultData?.data[0]?.name;
              } else {
                return null;
              }
            })
          );
        })
      )
      .subscribe((name) => {
        this.name = name;
      });
  }
}

export function registerObjectNameComponent(params?: {
  [area: string]: { [property in keyof UserSelectComponent]?: MtSafeAny };
  $default?: { [property in keyof UserSelectComponent]?: MtSafeAny };
}) {
  MtObjectNameRenderer.register('User', UserSelectComponent, params);
}
