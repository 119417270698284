<div class="divBtns" *ngIf="checkShowButton()">
  <!--取消按钮-->
  <button *ngIf="!isAdd && editing" nz-button type="button" class="ant-btn-md" nzShape="round" (click)="canleEdit()">
    <em nz-icon nzType="undo"></em><span>{{ 'platform.common.cancel' | translate }}</span>
  </button>
  <!--编辑按钮-->
  <button *ngIf="!editing" nz-button type="button" class="ant-btn-md" nzShape="round" (click)="startEdit()">
    <em nz-icon nzType="edit"></em><span>{{ 'platform.common.default.edit' | translate }}</span>
  </button>

  <!--保存按钮-->
  <button
    nz-button
    *ngIf="editing"
    nzShape="round"
    nzType="primary"
    type="button"
    class="ant-btn-md"
    [nzLoading]="isEditloading"
    (click)="submitEdit()"
    [disabled]="isEditloading"
  >
    <em nz-icon nzType="check"></em>
    <span>{{ 'platform.common.save' | translate }}</span>
  </button>

  <!--创建ECN-->
  <button
    nz-button
    *ngIf="currentProcessNode == 'execution'"
    nzShape="round"
    nzType="primary"
    type="button"
    class="ant-btn-md"
    [nzLoading]="isAddEcnloading"
    (click)="createECN()"
    [disabled]="isAddEcnloading"
  >
    <!-- <em nz-icon nzType="plus"></em> -->
    <span>{{ 'common-del.ecn.createEcn' | translate }}</span>
  </button>
</div>

<div style="display: flex; flex-direction: column; flex-grow: 0 1">
  <!-- 基本信息-->
  <nz-card class="mt-autoheight" [nzTitle]="'common-del.basicInfo' | translate">
    <form nz-form [formGroup]="ewoForm">
      <!--第一行 EWO编号、参考EWO编号、申请人-->
      <div nz-row [nzGutter]="24">
        <div nz-col [nzSpan]="6">
          <!--EWO编号 -->
          <nz-form-item>
            <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="objectName">
              <span>
                {{ 'common-del.ewo.ewoNo' | translate }}
              </span>
            </nz-form-label>
            <nz-form-control [nzSm]="14" [nzXs]="24">
              <span class="mt-fill"> {{ object?.objectName }} </span>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="6">
          <!--参考EWO编号 -->
          <nz-form-item>
            <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="refer_ewo">
              <span style="white-space: initial">
                {{ 'common-del.ewo.referEwo' | translate }}
              </span>
            </nz-form-label>
            <nz-form-control [nzSm]="14" [nzXs]="24">
              <input *ngIf="checkEditProps()" nz-input formControlName="refer_ewo" (input)="onInput($event)" [nzAutocomplete]="autoUser" />
              <nz-autocomplete #autoUser>
                <nz-auto-option *ngFor="let item of referEwoList" [nzValue]="item.OBJECT_NAME" [nzLabel]="item.OBJECT_NAME">
                  <span *ngIf="item != null"> {{ item.OBJECT_NAME }}</span>
                </nz-auto-option>
              </nz-autocomplete>
              <!-- <nz-select *ngIf="checkEditProps()" id="refer_ewo" nzShowSearch formControlName="refer_ewo" nzAllowClear>
                <nz-option [nzLabel]="item.OBJECT_NAME" [nzValue]="item.OBJECT_NAME" *ngFor="let item of referEwoList"></nz-option>
              </nz-select> -->
              <span *ngIf="!checkEditProps()" class="mt-fill"> {{ object?.refer_ewo }} </span>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="6">
          <!--申请人 -->
          <nz-form-item>
            <nz-form-label [nzSm]="12" [nzXs]="24" nzFor="owner">
              <span style="white-space: initial">
                {{ 'common-del.creator' | translate }}
              </span>
            </nz-form-label>
            <nz-form-control [nzSm]="12" [nzXs]="24">
              <ng-container *ngIf="object?.creator != null">
                {{ object?.creator | userSync: '${name}(${account})' }}
              </ng-container>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="6">
          <!--申请人部门 -->
          <nz-form-item>
            <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="deptName">
              <span style="white-space: initial">
                {{ 'common-del.creatorDept' | translate }}
              </span>
            </nz-form-label>
            <nz-form-control [nzSm]="14" [nzXs]="24">
              <span>{{ object?.deptName }}</span>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      <!--第二行：更改主题、计划完成时间、ECR编号-->
      <div nz-row [nzGutter]="24">
        <div nz-col [nzSpan]="12">
          <!--更改主题 -->
          <nz-form-item>
            <nz-form-label [nzSm]="5" [nzXs]="24" nzFor="change_topic">
              <span>
                {{ 'common-del.changeTopic' | translate }}
              </span>
            </nz-form-label>
            <nz-form-control [nzSm]="19" [nzXs]="24">
              <input *ngIf="checkEditProps()" nz-input id="change_topic" formControlName="change_topic" maxlength="64" />
              <span *ngIf="!checkEditProps()" class="mt-fill"> {{ object?.change_topic }} </span>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="6">
          <!--计划完成时间 -->
          <nz-form-item>
            <nz-form-label [nzSm]="12" [nzXs]="24" nzFor="planned_complete_date">
              <span style="white-space: initial">
                {{ 'common-del.plannedCompleteDate' | translate }}
              </span>
            </nz-form-label>
            <nz-form-control [nzSm]="12" [nzXs]="24">
              <nz-date-picker style="width: 100%" *ngIf="checkEditProps()" formControlName="planned_complete_date"></nz-date-picker>
              <span *ngIf="!checkEditProps()" class="mt-fill"> {{ object?.planned_complete_date | translateTime: 'YYYY-MM-DD' }} </span>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="6">
          <!--ECR编号 -->
          <nz-form-item>
            <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="ecr_no">
              <span>
                {{ 'common-del.ewo.ecrNo' | translate }}
              </span>
            </nz-form-label>
            <nz-form-control [nzSm]="14" [nzXs]="24">
              <input *ngIf="checkEditProps()" nz-input id="ecr_no" formControlName="ecr_no" maxlength="64" />
              <span *ngIf="!checkEditProps()" class="mt-fill"> {{ object?.ecr_no }} </span>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      <!--第三行-只读-创建时不显示：申请人、创建时间、申请人部门、状态-->
      <div nz-row [nzGutter]="24" *ngIf="!isAdd">
        <div nz-col [nzSpan]="6">
          <nz-form-item>
            <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="createTime">
              <span>
                {{ 'common-del.createTime' | translate }}
              </span>
            </nz-form-label>
            <nz-form-control [nzSm]="14" [nzXs]="24">
              <span>{{ object?.createTime | translateTime: 'YYYY-MM-DD HH:mm' }}</span>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="12" *ngIf="mtProcInsId != null">
          <nz-form-item>
            <nz-form-label [nzSm]="5" [nzXs]="24" nzFor="objectStatus">
              <span>
                {{ 'common-del.workflowName' | translate }}
              </span>
            </nz-form-label>
            <nz-form-control [nzSm]="19" [nzXs]="24">
              <a style="white-space: break-spaces; text-align: left" nzType="link" (click)="showWorkflowViewer()">
                {{ mtProcInsName }}
              </a>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      <div nz-row [nzGutter]="24" *ngIf="!isAdd">
        <div nz-col [nzSpan]="20">
          <nz-form-item>
            <nz-form-label [nzSm]="3" [nzXs]="24" nzFor="relatedEcn">
              <span>
                {{ 'common-del.ewo.relatedEcn' | translate }}
              </span>
            </nz-form-label>
            <nz-form-control [nzSm]="21" [nzXs]="24">
              <span *ngFor="let item of relatedEcnList" style="padding: 0 10px 8px 0; line-height: 30px">
                <a nzType="link" (click)="openEcn(item)">
                  {{ item?.OBJECT_NAME }}
                </a>
              </span>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </form>
  </nz-card>
  <!-- 申请进度-->
  <nz-card class="mt-autoheight" [nzTitle]="'common-del.processStatus' | translate">
    <!--进度条-->
    <ng-container *ngIf="workProcess != null">
      <nz-steps nzType="default" class="steps-header" [nzCurrent]="stepIndex" (nzIndexChange)="onIndexChange($event)">
        <!-- 策划-->
        <nz-step
          [nzTitle]="'common-del.plan' | translate"
          *ngIf="workProcess?.plan_node?.Visibility"
          [nzStatus]="getStepStatus(workProcess?.plan_node)"
          nzIcon="solution"
          (click)="stepChange(workProcess?.plan_node, 'plan')"
          nzDescription="{{ workProcess?.plan_node?.Start_Time | translateTime: 'YYYY-MM-DD HH:mm' }}"
        ></nz-step>
        <!-- 验证-->
        <nz-step
          [nzTitle]="'common-del.verify' | translate"
          *ngIf="workProcess?.verify_node?.Visibility"
          [nzStatus]="getStepStatus(workProcess?.verify_node)"
          (click)="stepChange(workProcess?.verify_node, 'verify')"
          [nzDisabled]="!workProcess?.verify_node?.IsStarted"
          nzIcon="file-done"
          nzDescription="{{ workProcess?.verify_node?.Start_Time | translateTime: 'YYYY-MM-DD HH:mm' }}"
        ></nz-step>
        <!-- 分析-->
        <nz-step
          [nzTitle]="'common-del.analyse' | translate"
          *ngIf="workProcess?.analyse_node?.Visibility"
          [nzStatus]="getStepStatus(workProcess?.analyse_node)"
          (click)="stepChange(workProcess?.analyse_node, 'analyse')"
          [nzDisabled]="!workProcess?.analyse_node?.IsStarted"
          nzIcon="file-search"
          nzDescription="{{ workProcess?.analyse_node?.Start_Time | translateTime: 'YYYY-MM-DD HH:mm' }}"
        ></nz-step>
        <!-- 决策-->
        <nz-step
          [nzTitle]="'common-del.decision' | translate"
          *ngIf="workProcess?.decision_node?.Visibility"
          [nzStatus]="getStepStatus(workProcess?.decision_node)"
          (click)="stepChange(workProcess?.decision_node, 'decision')"
          [nzDisabled]="!workProcess?.decision_node?.IsStarted"
          nzIcon="audit"
          nzDescription="{{ workProcess?.decision_node?.Start_Time | translateTime: 'YYYY-MM-DD HH:mm' }}"
        ></nz-step>
        <!-- 执行-->
        <nz-step
          [nzTitle]="'common-del.excute' | translate"
          *ngIf="workProcess?.execution_node?.Visibility"
          [nzStatus]="getStepStatus(workProcess?.execution_node)"
          (click)="stepChange(workProcess?.execution_node, 'execution')"
          [nzDisabled]="!workProcess?.execution_node?.IsStarted"
          nzIcon="file-sync"
          nzDescription="{{ workProcess?.execution_node?.Start_Time | translateTime: 'YYYY-MM-DD HH:mm' }}"
        ></nz-step>
        <!-- 关闭-->
        <nz-step
          [nzTitle]="'common-del.close' | translate"
          *ngIf="workProcess?.close_node?.Visibility"
          [nzStatus]="getStepStatus(workProcess?.close_node)"
          (click)="stepChange(workProcess?.close_node, 'close')"
          [nzDisabled]="!workProcess?.close_node?.IsStarted"
          nzIcon="close-circle"
          nzDescription="{{ workProcess?.close_node?.Start_Time | translateTime: 'YYYY-MM-DD HH:mm' }}"
        ></nz-step>
      </nz-steps>
      <nz-divider></nz-divider>
      <div class="steps-content">
        <h3 *ngIf="currentSelectedStep == 'analyse'">EWO{{ 'common-del.analyse' | translate }}</h3>
        <h3 *ngIf="currentSelectedStep == 'execution'">EWO{{ 'common-del.excute' | translate }}</h3>
        <div *ngFor="let panel of panels; index as i">
          <h3 *ngIf="currentSelectedStep !== 'analyse' && currentSelectedStep !== 'execution'">{{ panel.panel | propI18n: 'name' }}</h3>
          <!-- 加载中状态 -->
          <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="mtObjectInited[mtObjectId] !== 'loaded'">
            <ng-template #indicatorTemplate>
              <i style="font-size: 40px; position: fixed; top: 50%" nz-icon nzType="loading"></i>
            </ng-template>
          </nz-spin>

          <!--加载完成后-->
          <ng-container *ngIf="mtObjectInited[mtObjectId] === 'loaded'">
            <!-- 注入控件显示 -->
            <ng-container *ngIf="haveAuthority; else noPermission">
              <ng-container [mtPanelRenderer]="panel" [mtActivate]="true"></ng-container>
            </ng-container>

            <!-- 无权限时显示 -->
            <ng-template #noPermission>
              <nz-result nzStatus="warning" [nzTitle]="'platform.spectrum.noPermission' | translate"> </nz-result>
            </ng-template>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </nz-card>
</div>

<!-- 查看流程 -->
<yc-workflow-viewer [currentProcessIns]="currentProcessIns" [isAdmin]="isAdmin"></yc-workflow-viewer>

<ng-template #tabTitle>{{ object?.objectName }}</ng-template>
