import { Component, OnInit, Optional } from '@angular/core';
import { MtSafeAny, MtConfigService } from '@imerge22/core';
import { MtSpectrumContrlBase, MtSpectrumControlRegister } from '@imerge22/spectrum/controls';
import { MtSpectrumDataBinding, MtSpectrumDataSource } from '@imerge22/spectrum/objects';
import { RelativeEcrService } from 'src/app/services/relative-ecr.service';
import { MtNotificationBus } from '@imerge22/core/notification';

@Component({
  selector: 'uccs-mt-relative-ecr-no',
  templateUrl: './mt-relative-ecr-no.component.html',
  styleUrls: ['./mt-relative-ecr-no.component.scss'],
})
export class MtRelativeEcrNoComponent extends MtSpectrumContrlBase<MtSafeAny> implements OnInit {
  visible: boolean;
  searchListData = [];
  searchData = {
    productDepartment: '',
    pageSize: 10,
    pageIndex: 1,
    total: 0,
  };
  ecrnosearchval: '';
  searchList = [];
  listOfCurrentPageData = [];
  ecrnoListData = [];
  searchListDataCopy = [];
  tablerow: undefined;
  initConfig(): void {}

  constructor(
    @Optional() public dataBinding: MtSpectrumDataBinding,
    public dataSource: MtSpectrumDataSource,
    public mtConfigSrv: MtConfigService,
    private relativeSrv: RelativeEcrService
  ) {
    super();
    this.init();
  }

  ngOnInit(): void {}

  init() {
    this.relativeSrv.getEcr().subscribe((res) => {
      this.ecrnoListData = res.resultData.data;
      this.searchListData = [];
      for (const iterator of res.resultData.data) {
        if (iterator.ECR_no !== undefined) {
          if (iterator.ECR_no !== '') {
            this.searchListData.push({ ecrno: iterator.ECR_no, id: iterator.id, style: '' });
          }
        }
      }
      this.searchListDataCopy = JSON.parse(JSON.stringify(this.searchListData));
      this.searchData.total = this.searchListData.length;
    });
  }

  /* 抽屉中搜索按钮查询事件 */
  search() {
    if (this.ecrnosearchval !== undefined) {
      this.ecrnosearchval = this.ecrnosearchval.trim() as MtSafeAny;
    }
    if (this.ecrnosearchval === '' || this.ecrnosearchval === undefined) {
      this.searchListData = this.searchListDataCopy;
      this.searchListData = [...this.searchListData];
      return;
    }
    this.searchListData = JSON.parse(JSON.stringify(this.searchListDataCopy));
    const arr = [];
    for (const iterator of this.searchListData.entries()) {
      if (iterator[1].ecrno.indexOf(this.ecrnosearchval) !== -1) {
        arr.push(this.searchListData[iterator[0]]);
      }
    }
    this.searchListData = arr;
    this.searchListData = [...this.searchListData];
    this.searchData.total = this.searchListData.length;
  }

  /* 列表点击高亮事件 */
  clickrow(obj: MtSafeAny, index: MtSafeAny) {
    for (const iterator of this.searchListData as MtSafeAny) {
      iterator.style = '';
    }
    this.tablerow = obj;
    const arr: MtSafeAny[] = this.searchListData as MtSafeAny[];
    arr[index].style = '#ECF5FF';
    this.searchListData = arr;
    this.searchListData = [...this.searchListData];
  }

  /* 抽屉中重置按钮点击事件 */
  reset() {
    this.ecrnosearchval = '';
    this.searchListData = this.searchListDataCopy;
    this.searchListData = [...this.searchListData];
    this.searchData.total = this.searchListData.length;
  }

  /* 搜索按钮点击事件 */
  open() {
    this.searchListData = JSON.parse(JSON.stringify(this.searchListDataCopy));
    this.searchListData = [...this.searchListData];
    this.visible = true;
  }

  /* 抽屉关闭按钮事件 */
  close() {
    for (const iterator of this.searchListData as MtSafeAny) {
      iterator.style = '';
    }
    this.tablerow = undefined;
    this.ecrnosearchval = '';
    this.visible = false;
  }

  onCurrentPageDataChange(listOfCurrentPageData: []): void {
    this.listOfCurrentPageData = listOfCurrentPageData;
  }

  /* 抽屉中确认按钮事件 */
  save() {
    const row = this.tablerow as MtSafeAny;
    const result = this.blur(row.ecrno);
    if (!result) {
      return;
    }
    this.dataBinding.value = row.ecrno;
    console.log(this.dataBinding);
    const table = this.dataSource.getValue(
      this.dataSource.mainObjectId,
      'ECR_no_table'
    ) as MtSafeAny[];
    table[this.dataBinding.mtRowIndex].ECR_Application_Form = row.id;
    this.dataSource.setValue(this.dataSource.mainObjectId, 'ECR_no_table', table);
    this.close();
  }

  blur(val?: string) {
    const tableArr = this.dataSource.getValue(
      this.dataSource.mainObjectId,
      'ECR_no_table'
    ) as MtSafeAny[];
    let count = 0;
    for (const iterator of tableArr) {
      if (iterator.ECR_No) {
        if (iterator.ECR_No === val) {
          count++;
        }
      }
    }
    if (count !== 0) {
      MtNotificationBus.emit({
        type: 'error',
        title: 'i18n.platform.common.error',
        message: 'i18n.ctrl.repeatErr',
      });
      return false;
    }
    return true;
  }
}

export function registerRelativeEcrNoCtrl() {
  MtSpectrumControlRegister.register('mtRelative-ecrno', MtRelativeEcrNoComponent);
}
