import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from "rxjs";
import { map } from 'rxjs/operators';

import { MtShortcutCommandEvent, MtShortcutCommandHandler, MtShortcutContext } from '@imerge22/spectrum';
import { CommonUtils, MtNotificationBus, MtSafeAny } from '@imerge22/core';
import { MtDataSourceService, MtObjectTreeNode, ObjectDto } from '@imerge22/platform/base';
import { WorkspaceTreeService } from '@imerge22/spectrum';
import { CreateObjectService } from 'src/app/services/create-object.service';

@Injectable({ providedIn: 'root' })
class CreateEcnObjectShortcutHandler implements MtShortcutCommandHandler {
  constructor(
    public srv: MtDataSourceService,
    public workspaceSrv: WorkspaceTreeService,
    private router: Router,
    public createService: CreateObjectService
  ) { }

  available(shortcutId: string, event: MtShortcutCommandEvent): Observable<boolean> {
    return of(true);
  }

  execute(shortcutId: string, event: MtShortcutCommandEvent): Observable<boolean> {
    // 跳转至我的工作区
    this.router.navigate(['/dashboard']);

    // 如果我的工作区树数据还未加载完，等待加载完成
    const interval = setInterval(() => {
      const workspace = this.workspaceSrv.getDefaultObj();
      if (workspace != null) {
        clearInterval(interval);
        this.createEcrObj(workspace);
      }
    }, 200);
    return of(true);
  }

  createEcrObj(workspace) {
    // 获取编码
    this.createService.getEncoding().subscribe((res: MtSafeAny) => {
      if (CommonUtils.isNotBlank(res.resultData)) {
        const object: ObjectDto = {
          objectType: 'ECR',
          objectName: res.resultData,
          item: { itemId: res.resultData },
        };
        // 创建对象
        this.srv.createObject(object).subscribe((objectId) => {
          const messageParam = { folder: workspace.title };
          // 获取提示信息
          this.srv.getObject(objectId).subscribe((newObject) => {
            MtNotificationBus.emit({
              type: 'success',
              title: this.srv.getObjectTitle(newObject),
              message: 'i18n.platform.spectrum.object.createSuccess',
              messageParam
            });
          });
          // 关联对象到文件夹
          this.srv
            .addToFolder(workspace.mtObjectId, objectId)
            .pipe(
              map((workspaceDto) => {
                const node: MtObjectTreeNode = {
                  id: workspaceDto.childObjectMuid,
                  type: 'object',
                  folderRelId: workspaceDto.id,
                };
                workspace.addChild(node);
                return true;
              })
            )
            .subscribe();
        });
      }
    });
  }
}

export function registerShortcutCreateEcnObject(id: string) {
  MtShortcutContext.registerShortcut({
    id,
    label: 'root.home.todo.createECRObject',
    order: 1,
    icon: 'file-add',
    handlerDefine: CreateEcnObjectShortcutHandler
  })
}
