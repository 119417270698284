import { Component, OnInit, EventEmitter, forwardRef } from '@angular/core';
import { ObjectDto, MtDataSourceService } from '@imerge22/platform/base';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { CommonUtils } from '@imerge22/platform/utils';
import { CreateObjectService } from 'src/app/services/create-object.service';
import { User, PassportService, MtSafeAny } from '@imerge22/core';
import { MtUserCacheService } from '@imerge22/base';

@Component({
  selector: 'uccs-new-object',
  templateUrl: './new-object.component.html',
  styleUrls: ['./new-object.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NewObjectComponent),
      multi: true,
    },
  ],
})
export class NewObjectComponent implements OnInit {
  private result: EventEmitter<any>;
  visible: boolean;
  model: any = {};
  object: ObjectDto;
  user: User = {};

  constructor(
    public srv: MtDataSourceService,
    public userSrv: MtUserCacheService,
    private passport: PassportService,
    public createService: CreateObjectService
  ) { }

  ngOnInit() {
    this.userSrv.getUserById(this.passport.current.user.id).subscribe((result) => {
      if (result) {
        this.user = result;
      }
    });
  }

  open(item: ObjectDto, reset?: boolean) {
    this.object = item;
    if (reset) {
      this.model = {};
      const obj = this.srv.getModel('ECN');
      if (obj != null) {
        this.model.modelId = obj.id;
      }
      this.createService.getEncoding().subscribe((code: MtSafeAny) => {
        if (CommonUtils.isNotBlank(code.resultData)) {
          this.model.code = code.resultData;
        }
      });
    }
    if (this.result == null) {
      this.result = new EventEmitter<any>();
    }
    this.visible = true;
    return this.result;
  }

  cancel() {
    this.visible = false;
    this.result.emit();
  }

  save() {
    this.model.organizationId = this.user.organizationId;
    this.visible = false;
    this.result.emit(this.model);
  }

  check() {
    if (CommonUtils.isBlank(this.model.name) || CommonUtils.isBlank(this.model.code)) {
      return true;
    }
    return false;
  }
}
