import { Component, EventEmitter, Input, OnInit, TemplateRef, ViewChild, Type } from '@angular/core';
import { MtDynamicTabBase } from '@imerge22/common';
import { CommonUtils, FilterOperator, MtMap, MtSafeAny, PageRequest, PassportService } from '@imerge22/core';
import { MtObjectData } from '@imerge22/spectrum';
import { rejects } from 'assert';
import moment from 'moment';
import { resolve } from 'path';
import { PanelService } from 'src/app/services/panel.service';
import { EcnService } from '../../service/ecn.service';

@Component({
  selector: 'yc-ecn-pages',
  templateUrl: './ecn-pages.component.html',
  styleUrls: ['./ecn-pages.component.scss'],
})
export class EcnPagesComponent implements OnInit, MtDynamicTabBase {
  // @Input()
  thHeader: string[];
  @Input() type: string;

  datas = [];
  selectRow = null
  dataTotal = this.datas.length;
  params: MtSafeAny[] = [

  ];
  sort = [];
  @ViewChild('tabTitle') title: TemplateRef<void>;
  onCloseByInner: EventEmitter<void>;
  /** 控制tab页不可关闭 */
  shieldCloseBtn = true;

  page: PageRequest = { index: 1, size: 10 };
  constructor(private passport: PassportService, private server: EcnService, private panelSvc: PanelService) { }
  data: MtMap<any>;

  onActivated(): void { }
  onDeactivated(): void { }
  onDataChange(data: MtMap<any>): void { }

  thHeaders = {
    my: [
      'root-del.ecn.number',
      'root-del.ecn.ewo_no',
      'root-del.ecn.valid_date',
      // 'root-del.ecn.createDate',
      // 'root-del.ecn.completePlanTime',
    ]
    , follow: [
      'root-del.ecn.number',
      'root-del.ecn.ewo_no',
      'root-del.ecn.valid_date',
      'root-del.ecn.isFollow',
      'root-del.ecn.applicant',
      'root-del.ecn.department',
      // 'root-del.ecn.createDate',
      // 'root-del.ecn.completePlanTime',
    ],
    all: [
      'root-del.ecn.number',
      'root-del.ecn.ewo_no',
      'root-del.ecn.valid_date',
      'root-del.ecn.isFollow',
      'root-del.ecn.applicant',
      'root-del.ecn.department',
      // 'root-del.ecn.createDate',
      // 'root-del.ecn.completePlanTime',
    ]
  };

  ngOnInit(): void {

    this.type = this.data.type;

    this.checkType();
    // this.onloadData();
  }


  loadFollowList() {
    this.page = { index: 1, size: 10 }
    const params: MtSafeAny[] = [
      { prop: 'followUserId', operate: FilterOperator.Equals, value: this.passport.current.user.id },
    ];
    let res = new Promise((resolve,reject)=>{
      this.server.getFollowedECNList(params).subscribe(res => {
        let ids = res || [];

        resolve(ids)
      },err=>{
        reject(err)
      })
    })
    return res;


  }
  checkType(event?) {
    switch (this.type) {
      case 'all':
        this.thHeader = this.thHeaders['all'];
        this.loadFollowList().then(list=>{
          this.paramsData(event);
          this.getData();
        });
        break;
      case 'follow':
        this.thHeader = this.thHeaders['follow'];
        this.loadFollowList().then(list=>{
          this.params.push(
            { prop: '_id', operate: FilterOperator.In, values: list },
          ),
          this.paramsData(event);
          this.getData();
        });

        // this.paramsData(event);
        break;
      case 'my':
        this.thHeader = this.thHeaders['my'];
        this.params.push({ prop: 'OWNER', operate: FilterOperator.Equals, value: this.passport.current.user.id });
        this.paramsData(event);
        this.getData();
        break;

      default:
        break;
    }
  }

  onloadData(event?) {
    this.params = [];
    this.sort = [];
    if(event?.sort){
      this.sort = event.sort;
      event = null;
    }
    this.checkType(event);
    // if (event) {
    //   // 点击分页触发
    //   this.paramsData(event)
    // }

    // this.getData();


  }


  paramsData(event) {
    if (event) {
      if (event.index) {
        this.page = event;
      } else {
        this.page.index = 1;  // 点击查询触发时页码置为1
        const keys = Object.keys(event);
        const value = Object.values(event).some((item) => Boolean(item) === true);
        if (value) {
          keys.forEach((key) => {
            if (CommonUtils.isNotBlank(event[key])) {
              if (key === 'itemId') {
                this.params.push({
                  prop: 'ITEM_ID',
                  operate: FilterOperator.Like,
                  value: event[key].trim(),
                });
              }
              // 将输入框的时间格式化
              else if (event.createTime && key === 'createTime') {
                event.createTime[0].setHours(0, 0, 0, 0);
                this.params.push({
                  prop: 'CREATE_TIME',
                  operate: FilterOperator.GreatThan,
                  value: moment(event.createTime[0]).format('YYYY-MM-DD') + ' 00:00:00',
                });

                event.createTime[1].setHours(23, 59, 59, 999);
                this.params.push({
                  prop: 'CREATE_TIME',
                  operate: FilterOperator.LessThan,
                  value: moment(event.createTime[1]).format('YYYY-MM-DD') + ' 23:59:59',
                });
              } else if (event.updateTime && key === 'updateTime') {
                event.updateTime[0].setHours(0, 0, 0, 0);
                this.params.push({
                  prop: 'planned_complete_date',
                  operate: FilterOperator.GreatThan,
                  value: moment(event.updateTime[0]).format('YYYY-MM-DD HH:mm:ss') + ' 00:00:00',
                });
                event.updateTime[1].setHours(23, 59, 59, 999);
                this.params.push({
                  prop: 'planned_complete_date',
                  operate: FilterOperator.LessThan,
                  value: moment(event.updateTime[1]).format('YYYY-MM-DD') + ' 23:59:59',
                });
              } else if (event.account && key === 'account') {
                this.params.push({
                  prop: 'CREATOR',
                  operate: FilterOperator.Equals,
                  value: event[key].trim(),
                });
              } else if (event.deptId && key === 'deptId') {
                this.params.push({
                  prop: 'dept_id',
                  operate: FilterOperator.Equals,
                  value: event[key].trim(),
                });
              }
            }
          });
        }
      }
    }
  }


  getData() {
    this.server.getECNList(this.page, this.params, this.sort).subscribe((result: MtSafeAny) => {
      this.datas = this.checkTypeResult([...result.resultData.data])
      this.dataTotal = result.resultData.total;

    },
      (err: Error) => { });
  }
  checkTypeResult(data) {
    let resData = [...data];
    switch (this.type) {
      case 'all':
        this.server.followList$.subscribe(followList => {
          resData = resData.map(item => {
            item.follow = false;
            if (followList.indexOf(item._id) !== -1) {
              item.follow = true;
            }
            return item;
          })

        })
        break;
      case 'follow':
        // resData = resData.map(item => {
        //   item.follow = true;
        //   item.loading = false;

        //   // const orgName = this.orgList.filter(orgItem => orgItem.id === item.dept_id)
        //   // item.DEPT_NAME = item.DEPT_NAME ? item.DEPT_NAME : orgName[0].name
        //   return item;
        // })
        this.server.followList$.subscribe(followList => {
          resData = resData.map(item => {
            item.follow = false;
            if (followList.indexOf(item._id) !== -1) {
              item.follow = true;
            }
            return item;
          }).filter(res => res.follow);
        });
        break;
      case 'my':
        break;

      default:
        break;

    }
    return resData;
  }


  clickSelectRow($e) {
    this.selectRow = $e;

  }
}
