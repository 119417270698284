import { Injectable } from '@angular/core';
import { MtDynamicTabHandler, MtDynamicTabRegister } from '@imerge22/common';
import { MtEvent, MtSafeAny, MtMap } from '@imerge22/core';
import { ContractInfoActivateEvent } from './events';
import { ContractInfoListComponent } from './contract-info-list/contract-info-list.component';

@Injectable({ providedIn: 'root' })
export class ContractInfoTabHandler extends MtDynamicTabHandler {
  match(context: string, event: MtEvent<MtSafeAny>): boolean {
    if (event.name === ContractInfoActivateEvent.$name) {
      const data = ContractInfoActivateEvent.get(event);
      return data?.context === context;
    }
    return false;
  }

  same(context: string, exist: MtMap, event: MtEvent<MtSafeAny>): boolean {
    if (event.name === ContractInfoActivateEvent.$name) {
      const data = ContractInfoActivateEvent.get(event);
      return data?.context === context;
    }
    return false;
  }
}

export function registerContractInfoDynamicTab() {
  MtDynamicTabRegister.register(ContractInfoActivateEvent.$name, {
    component: ContractInfoListComponent,
    handler: ContractInfoTabHandler,
    initializeComponent: true,
  });
}
