import { Component } from '@angular/core';
import { MtReportDataContext } from '@imerge22/spectrum';

@Component({
  selector: 'app-char2',
  templateUrl: './char2.component.html',
  styleUrls: ['./char2.component.scss']
})
export class Char2Component extends MtReportDataContext {

  options = {
    xAxis: {
      type: 'category',
      data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月']
    },
    yAxis: {
      type: 'value'
    },
    series: [{
      data: [120, 200, 150, 80, 70, 110, 130, 123, 233, 301, 222, 209],
      type: 'bar'
    }],
    color: "#5470c6"
  };
  loading = false;

  constructor() {
    super();
  }

  refresh() {
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
    }, 1000)
  }

}
