import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MtNotFind404Component } from '@imerge22/base';
import { AccessGuard } from '@imerge22/platform/base';
import { MtRoutes, AccessType } from '@imerge22/platform/utils';


const routes: MtRoutes = [
  { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
  {
    path: 'reset-pwd',
    loadChildren: () =>
      import('./reset-password/reset-password.module').then((m) => m.ResetPasswordModule),
  },
  {
    path: 'initialize',
    canLoad: [AccessGuard],
    canActivate: [AccessGuard],
    data: { initialized: false, access: { type: AccessType.Share } },
    loadChildren: () => import('./initializer/initializer.module').then(m => m.InitializerModule)
  },
  {
    path: '',
    canLoad: [AccessGuard],
    canActivate: [AccessGuard],
    data: { access: { type: AccessType.Share } },
    loadChildren: () => import('./root/root.module').then(m => m.RootModule)
  },
  { path: '**', component: MtNotFind404Component },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
