import { MtEvent, MtEventBus, MtSafeAny } from '@imerge22/core';
import { MtDynamicTabsetActivateEventData } from '@imerge22/common';

export class EwoFollowActivateEvent {
  static $name = 'ewo-follow-activate';
  static emit(data: MtDynamicTabsetActivateEventData) {
    MtEventBus.emit({
      name: EwoFollowActivateEvent.$name,
      data,
    });
  }

  static is(event: MtEvent<MtSafeAny>): boolean {
    return event != null && event.name === EwoFollowActivateEvent.$name;
  }

  static get(event: MtEvent<MtSafeAny>): MtDynamicTabsetActivateEventData {
    if (EwoFollowActivateEvent.is(event)) {
      return event.data;
    }
    return undefined;
  }
}
