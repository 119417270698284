<nz-table [nzBordered]="true" [nzShowTotal]="showTotal" [nzData]="tableData" [nzFrontPagination]="false" nzSize="small"
  [nzShowSizeChanger]="true" [nzLoadingDelay]="100" [(nzPageSize)]="page.size" [nzTotal]="total"
  [(nzPageIndex)]="page.index" [nzPageSizeOptions]="pagenumber" (nzPageIndexChange)="pageChange()"
  (nzPageSizeChange)="pageSizeNumber()">
  <thead>
    <tr>
      <ng-container *ngFor="let item of thHeader">
        <th nzAlign="center">{{ item | translate }}</th>
      </ng-container>

      <th class="mt-center" [nzShowSort]="true" [nzSortFn]="true" [(nzSortOrder)]="sortOrder.createTime"
        (nzSortOrderChange)="sortChange('createTime')">
        {{ 'common-del.createTime' | translate }}</th>
      <th class="mt-center" [nzShowSort]="true" [nzSortFn]="true" [(nzSortOrder)]="sortOrder.plan"
        (nzSortOrderChange)="sortChange('plan')">
        {{ 'common-del.plannedCompleteDate' | translate }}</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let dataItem of tableData" (click)="oneItemChecked(dataItem)"
      [class.mt-selected]="selectRow?._id == dataItem?._id">
      <td nzAlign="center">
        <a nz-button nzType="link" (click)="showDetail(dataItem._id)">{{ dataItem.OBJECT_NAME || '-' }}</a>
      </td>
      <td nzAlign="center">{{ dataItem.ewo_no || '-'}}</td>
      <td nzAlign="center">{{ (dataItem.valid_date | translateTime :'yyyy-MM-DD') || '-'}}</td>
      <td nzAlign="center" *ngIf="type === 'all' || type === 'follow'">
        <nz-switch [(ngModel)]="dataItem.follow" [nzControl]="true" [nzLoading]="dataItem.loading"
          (click)="switchChange(dataItem)"></nz-switch>
      </td>
      <td nzAlign="center" *ngIf="type === 'all' || type === 'follow'">
        {{ (dataItem.CREATOR | userSync:'${name}(${account})') || '-' }}
      </td>
      <td nzAlign="center" *ngIf="type === 'all' || type === 'follow'">
        {{ dataItem.DEPT_NAME || '-'}}
      </td>
      <td nzAlign="center">{{ (dataItem.CREATE_TIME | translateTime :'yyyy-MM-DD HH:mm') || '-'  }}</td>
      <td nzAlign="center">{{ (dataItem.planned_complete_date | translateTime :'yyyy-MM-DD') || '-'   }}</td>
    </tr>
  </tbody>
</nz-table>
<ng-template #showTotal let-range="range" let-total>
  {{ 'platform.core.dict.total' | translate: { total: total, range0: range[0], range1: range[1] } }}
</ng-template>
