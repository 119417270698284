import { Component, OnInit, Optional } from '@angular/core';
import { EncodingService } from '../../handlers/encoding.service';
import { MtSafeAny } from '@imerge22/core';
import { MtSpectrumContrlBase, MtSpectrumControlRegister } from '@imerge22/spectrum/controls';
import { MtSpectrumDataBinding } from '@imerge22/spectrum/objects';
import { MtSpectrumDataSource } from '@imerge22/spectrum/objects';

@Component({
    selector: 'uccs-code',
    template:
        `<button nz-button [disabled]="!dataBinding.editing"
        nzType="default" (click)="getCost()">{{'ctrl.calculate' | translate}}</button>`,
    styleUrls: [],
})
export class CostComponent extends MtSpectrumContrlBase<MtSafeAny> implements OnInit {
    constructor(
        @Optional() public dataBinding: MtSpectrumDataBinding,
        public codeSrv: EncodingService,
        public mtSpectrumDataSource: MtSpectrumDataSource
    ) {
        super();
    }
    ngOnInit() { }

    initConfig(): void {
        // throw new Error("Method not implemented.");
    }

    getCost() {
        const stockobjectId: string = this.mtSpectrumDataSource.getValue(
            this.mtSpectrumDataSource.mainObjectId,
            'Stock_strategy'
        ) as string;

        const StocksupplierobjectId: string = this.mtSpectrumDataSource.getValue(
            stockobjectId,
            'Stock_Supplier'
        ) as string;
        const stockstrategyTableobjectId: MtSafeAny[] = this.mtSpectrumDataSource.getValue(
            StocksupplierobjectId,
            'Stock_strategy_excel'
        ) as MtSafeAny[];
        let cost1 = 0;
        if (stockstrategyTableobjectId !== undefined) {
            for (const iterator of stockstrategyTableobjectId) {
                if ( iterator.Strategy === 'Scrapped' || iterator.Strategy === 'Repair') {
                    if (iterator.Cost !== '') {
                        if ( iterator.Cost !== undefined ) {
                            // tslint:disable-next-line: radix
                            cost1 += parseFloat(iterator.Cost);
                        }
                    }
                }
            }
        }
        const stockuaesobjectId: string = this.mtSpectrumDataSource.getValue(
            stockobjectId,
            'Stock_UAES'
        ) as string;
        const stockTableobjectId: MtSafeAny[] = this.mtSpectrumDataSource.getValue(
            stockuaesobjectId,
            'Stock_strategy_excel'
        ) as MtSafeAny[];
        let cost2 = 0;
        if (stockTableobjectId !== undefined) {
            for (const iterator of stockTableobjectId) {
                if ( iterator.Strategy === 'Scrapped' || iterator.Strategy === 'Repair') {
                    if (iterator.Cost !== '') {
                        if ( iterator.Cost !== undefined ) {
                            // tslint:disable-next-line: radix
                            cost2 += parseFloat(iterator.Cost);
                        }
                    }
                }
            }
        }

        const stocktransferobjectId: string = this.mtSpectrumDataSource.getValue(
            stockobjectId,
            'Stock_transfer'
        ) as string;
        const stockTableobjectId2: MtSafeAny[] = this.mtSpectrumDataSource.getValue(
            stocktransferobjectId,
            'Stock_strategy_excel'
        ) as MtSafeAny[];
        let cost3 = 0;
        if (stockTableobjectId2 !== undefined) {
            for (const iterator of stockTableobjectId2) {
                if ( iterator.Strategy === 'Scrapped' || iterator.Strategy === 'Repair') {
                    if (iterator.Cost !== '') {
                        if ( iterator.Cost !== undefined ) {
                            // tslint:disable-next-line: radix
                            cost3 += parseFloat(iterator.Cost);
                        }
                    }
                }
            }
        }

        const stockCustomerobjectId4: string = this.mtSpectrumDataSource.getValue(
            stockobjectId,
            'Stock_Customer'
        ) as string;
        const stockTableobjectId4: MtSafeAny[] = this.mtSpectrumDataSource.getValue(
            stockCustomerobjectId4,
            'Stock_strategy_excel'
        ) as MtSafeAny[];
        let cost4 = 0;
        if (stockTableobjectId4 !== undefined) {
            for (const iterator of stockTableobjectId4) {
                if ( iterator.Strategy === 'Scrapped' || iterator.Strategy === 'Repair') {
                    if (iterator.Cost !== '') {
                        if ( iterator.Cost !== undefined ) {
                            // tslint:disable-next-line: radix
                            cost4 += parseFloat(iterator.Cost);
                        }
                    }
                }
            }
        }

        let sum = cost1 + cost2 + cost3 + cost4;
        sum = this.roundFun(sum, 2);
        const costobjectId: string = this.mtSpectrumDataSource.getValue(
            this.mtSpectrumDataSource.mainObjectId,
            'Change_Cost'
        ) as string;
        const sumcost = this.mtSpectrumDataSource.getValue(costobjectId, 'Scrap_rework') as MtSafeAny;
        if (sumcost.value === undefined) {
            sumcost.value = 0;
        }
        const oldval = parseFloat(sumcost.value);
        sumcost.value = sum;
        this.mtSpectrumDataSource.setValue(costobjectId, 'Scrap_rework', sumcost);
        this.setTotalValue(sum, oldval);
    }

    setTotalValue(levelValue: number, oldval: number) {
        const costobjectId: string = this.mtSpectrumDataSource.getValue(
            this.mtSpectrumDataSource.mainObjectId,
            'Change_Cost'
        ) as string;
        const sumcost = this.mtSpectrumDataSource.getValue(costobjectId, 'Total_costs') as MtSafeAny;
        if (sumcost.value === undefined) {
            sumcost.value = 0;
        }
        sumcost.value = levelValue - oldval + parseFloat(sumcost.value);
        this.mtSpectrumDataSource.setValue(costobjectId, 'Total_costs', sumcost);
    }

    roundFun(value: number, n: number) {
        return Math.round(value * Math.pow(10, n)) / Math.pow(10, n);
    }
}
export function registerCostCtrl() {
    MtSpectrumControlRegister.register('mtCost', CostComponent);
}
