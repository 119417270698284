import { Injectable } from '@angular/core';

import { MtDynamicTabHandler, MtDynamicTabRegister } from '@imerge22/common';
import { MtEvent, MtSafeAny, MtMap } from '@imerge22/core';
import { ApprovedWorkHoursComponent } from './approved-work-hours.component';
import { ApprovedWorkHoursActivateEvent } from './events';


@Injectable({ providedIn: 'root' })
export class ApprovedWorkHoursDetailHandler extends MtDynamicTabHandler {
  match(context: string, event: MtEvent<MtSafeAny>): boolean {
    if (event.name === ApprovedWorkHoursActivateEvent.$name) {
      const data = ApprovedWorkHoursActivateEvent.get(event);
      return data?.context === context;
    }
    return false;
  }

  same(context: string, exist: MtMap, event: MtEvent<MtSafeAny>): boolean {
    if (event.name === ApprovedWorkHoursActivateEvent.$name && exist.id === event.data.id) {
      const data = ApprovedWorkHoursActivateEvent.get(event);
      return data?.context === context;
    }
    return false;
  }
}

export function registerApprovedWorkHoursDetailDynamicTab() {
  MtDynamicTabRegister.register(ApprovedWorkHoursActivateEvent.$name, {
    component: ApprovedWorkHoursComponent,
    handler: ApprovedWorkHoursDetailHandler,
  });
}
