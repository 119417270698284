import { registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import zh from '@angular/common/locales/zh';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';

import { NzConfig, NZ_CONFIG } from 'ng-zorro-antd/core/config';
import { NzNotificationService } from 'ng-zorro-antd/notification';

import {
  MtPageModule,
  registerDictInitializer,
  registerMeasureUnitInitializer,
} from '@imerge22/base';
import { registerBaseDataModel } from '@imerge22/base/data-models';
import { MtCommonModule } from '@imerge22/common';
import {
  ACCESS_SERVICE,
  BomConfig,
  combineBomConfig,
  DefaultAccessService,
  defaultBomConfig,
  MtCrossBrowserTabSessionStorage,
  MtDataBindingActions,
  MtEventBus,
  MT_CONFIG,
  PassportService,
  registerCoreDataModel,
} from '@imerge22/core';
import { MtNotificationService } from '@imerge22/core/notification';
import {
  MtLanguageInterceptor,
  MtTranslateModule,
  MtTranslateProviders,
  MtTranslateService,
} from '@imerge22/core/translate';
import { MtEncodingModule } from '@imerge22/encoding';
import {
  AuthInterceptor,
  ExceptionInterceptor,
  JsonInterceptor,
  MtConfigService,
  MtDataSourceHanders,
  MtDataSourceSynchroService,
  MtLanguageService,
  MT_SPECTRUM_BOM_CONFIG,
  registerModelTreeInitializer,
  UrlInterceptor,
} from '@imerge22/platform/base';
import { ModelManagementComponent } from '@imerge22/platform/mt-spectrum';
import {
  MtObjectContextCommands,
  registerObjectCommandCopy,
  registerObjectCommandCreateFolder,
  registerObjectCommandCut,
  registerObjectCommandDelete,
  registerObjectCommandDownload,
  registerObjectCommandPaste,
  registerObjectCommandPreview,
  registerObjectCommandRefresh,
  registerObjectCommandRemove,
  registerObjectCommandRename,
  registerObjectCommandRevision,
  registerObjectCommandTransferOwner,
} from '@imerge22/platform/object-context';
import {
  MtObjectCreatorModule,
  registerObjectCommandAdd as MtRegisterObjectCommandAdd,
  registerObjectCommandSaveAs,
} from '@imerge22/platform/object-creator';
import {
  MtObjectDetailModule,
  registerDefaultObjectDetailComponent,
} from '@imerge22/platform/object-detail';
import { MtObjectTitleModule } from '@imerge22/platform/object-title';
import { registerObjectCommandFolderContextRemove } from '@imerge22/platform/object-tree-table';
import {
  MtPanelPropertiesModule,
  registerDefaultPanelPropertiesComponent,
} from '@imerge22/platform/panel-properties';
import { registerProcessInsDetailDynamicTab } from '@imerge22/platform/process-ins-detail';
import { registerTaskTodoDetailV2DynamicTab } from '@imerge22/platform/task-todo-detail';
import {
  registerGlobalQueryProcessDynamicTab,
  registerGlobalQueryTabComponent,
} from '@imerge22/platform/workflow/global-query';
import {
  registerCopyToDynamicTab,
  registerMyProcessDynamicTab,
  registerOwnerDynamicTab,
  registerParticipatedDynamicTab,
} from '@imerge22/platform/workflow/my-process';
import { registerObjectCommandProcess } from '@imerge22/platform/workflow/starter';
import { registerHandledTasksDynamicTab } from '@imerge22/platform/workflow/task-query';
import {
  registerFollowUp,
  registerHomeTabsDynamicTab,
  registerMyProcess,
  registerTodoUser,
  registerUserDataProvider,
  registerWorkspace,
} from '@imerge22/spectrum';
import { MtSpectrumControlRegister, MtSpectrumRuntimeCtrl } from '@imerge22/spectrum/controls';
import { MtFormEditorComponent, MtFormEditorModule } from '@imerge22/spectrum/form-editor';
import {
  DetailHeadTableRegiest,
  MtObjectDetailChildRegister,
  MtSpectrumObjectsModule,
  registerDefaultObjectNameComponent,
  registerObjectDetailDynamicTab,
} from '@imerge22/spectrum/objects';
import { registerObjectFormPanel, registerObjectPropertiesPanel } from '@imerge22/spectrum/panels';
import {
  registerModelDefineInitializer,
  registerSpectrumModelTreeInitializer,
} from '@imerge22/spectrum/services';

import { registerObjManagementDynamicTab } from '@imerge22/platform/workflow/obj-management';
import { registerObjDetailDynamicTab } from '@imerge22/platform/workflow/obj-management';
import { MtSpectrumComponentsModule } from '@imerge22/spectrum/components';
import { APP_CODE } from './../app-code';
import { ECRNoDataBindingAction } from './actions/ecrno-action';
import { ECRProductNoDataBindingAction } from './actions/product-no-action';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { registerChangeCtrl } from './ctrls/ctrls-module/ecr-no/ecr-no.component';
import { registerCostCtrl } from './ctrls/ctrls-module/ecr_computers';
import { registerPackSearchCtrl } from './ctrls/ctrls-module/ecr_no_search/ecr_no_search.component';
import { registerPackDictCtrl } from './ctrls/ctrls-module/ecr_pack_dictsort_input';
import { registerPackInfomationCtrl } from './ctrls/ctrls-module/ecr_pack_infomation';
import { CtrlsModule } from './ctrls/ctrls.module';
import { registerExampleCtrl } from './ctrls/example/example.component';
import { registerChangeItemInformatinDataInput } from './ctrls/mt-change-item-information-data-input/mt-change-item-information-data-input';
import { registerChangeItemInformationModelDownloaderCtrl } from './ctrls/mt-change-item-information-model-downloader/mt-change-item-information-model-downloader.component';
import { registerFinishTimeCtrl } from './ctrls/mt-finish-time/mt-finish-time.component';
import { registerImpactProductDataInput } from './ctrls/mt-impact-product-data-input/mt-impact-product-data-input.component';
import { registerImpactProductModelDownloaderCtrl } from './ctrls/mt-impact-product-downloader/mt-impact-product-downloader.component';
import { registerRelativeEcrNoCtrl } from './ctrls/mt-relative-ecr-no/mt-relative-ecr-no.component';
import { registerTestCtrl } from './ctrls/test/test.component';
import { registerObjectNameComponent } from './ctrls/user-select/user-select.component';
import { registerBomDynamicTab } from './root/bom/ebom/ebom-tab/ebom.tab.handler';
import {
  registerEcnAllDynamicTab,
  registerEcnDetailDynamicTab,
  registerFollowEcnDynamicTab,
  registerMyEcnDynamicTab,
} from './root/changes/ecn/ecn.handler';
import { registerEwoAllDynamicTab } from './root/changes/ewo/ewo-all/my-ewo.handler';
import { registerEwoDetailDynamicTab } from './root/changes/ewo/ewo-detail/my-ewo.handler';
import { registerEwoFollowDynamicTab } from './root/changes/ewo/ewo-follow/my-ewo.handler';
import { registerMyEwoDynamicTab } from './root/changes/ewo/my-ewo/my-ewo.handler';
import { UccsCreateObjectModule } from './root/create-object';
import { registerObjectCommandAdd } from './root/create-object/add.handler';
import { registerObjectSynchronizesEcn } from './root/create-object/synchronizes-ecn.handler';
import { registerChar2 } from './root/home/reports/char2/char2.handler';
import { registerShortcutCreateEcnObject } from './root/home/shortcuts/add-ecn-object';
import { registerContractInfoDetailDynamicTab } from './root/modules/business/contract-info/contract-info-detail/contract-info-detail.handler';
import { registerContractInfoDynamicTab } from './root/modules/business/contract-info/contract-info.tab.handler';
import { registerCustomerInfoDetailDynamicTab } from './root/modules/business/customer-info/customer-info-detail/customer-info-detail.handler';
import { registerCustomerInfoDynamicTab } from './root/modules/business/customer-info/customer-info.tab.handler';
import { registerEmployeeInvolvedProjectsDynamicTab } from './root/modules/project-information/employee-involved-projects/employee-involved-projects.tab.handler';
import { registerEmployeeInvolvedProjectsDetailDynamicTab } from './root/modules/project-information/employee-involved-projects/employee-involved-projects/employee-involved-projects.handler';
import { registerProjectDetailTabDynamicTab } from './root/modules/project-information/project-detail/project-detail.tab.handler';
import { registerProjectDetailDynamicTab } from './root/modules/project-information/project-detail/project-detail/project-detail.handler';
import { registerApprovedWorkHoursDynamicTab } from './root/modules/working-hours/approved-work-hours/approved-work-hours.tab.handler';
import { registerApprovedWorkHoursDetailDynamicTab } from './root/modules/working-hours/approved-work-hours/approved-work-hours/approved-work-hours.handler';
import { registerMakeUpWorkHoursDynamicTab } from './root/modules/working-hours/make-up-work-hours/make-up-work-hours.tab.handler';
import { registerWorkingHoursDynamicTab } from './root/modules/working-hours/working-hours/working-hours.tab.handler';
import { registerWorkingHoursDetailDynamicTab } from './root/modules/working-hours/working-hours/working-hours/working-hours.handler';
import {
  registerEBomComponent,
  registerEcnComponent,
  registerImapactedComponent,
  registerMBomComponent,
  registerPBomComponent,
  registerSBomComponent,
  UccsPanelModule,
} from './root/panel/panel.module';
import { registerPfepLogisticsDynamicTab } from './root/pefp/logistics-tab/logistics/logistics.handler';
import { registerPfepProjectDynamicTab } from './root/pefp/project-tab/project/project.handler';
import { registerSuperQueryConditionDynamicTab } from './root/super-query/super-query-condition/super-query-condition.tab.handler';
import { registerSuperQueryResultDynamicTab } from './root/super-query/super-query-result/super-query-result.tab.handler';
import { UccsDataSourceHandlers } from './services/datasource-handlers.service';
import { registerProjectAccountingDynamicTab } from './root/modules/project-information/project-accounting/project-accounting.tab.handler';
import { registerProjectAccountingDetailDynamicTab } from './root/modules/project-information/project-accounting/project-accounting/project-accounting.handler';
import { registerProjectHoursAccountingDynamicTab } from './root/modules/project-information/project-hours-accounting/project-hours-accounting.tab.handler';
import { registerProjectHoursAccountingDetailDynamicTab } from './root/modules/project-information/project-hours-accounting/project-hours-accounting/project-hours-accounting.handler';
import { registerProjectDetailOrgChangeCtrl } from './ctrls/project-detail-org-change/project-detail-org-change.component';
import { registerUserSelectCtrl } from './ctrls/mt-user-select/mt-user-select.component';
import { ChangeProjectBindingAction } from './actions/change-project';
import { registerKSelectProject } from './ctrls/k-select-project/k-select-project.component';
import { registerTaskTodoListTabsDynamicTab } from './root/workspace/task-todo-list-tabs/task-todo-list-tabs.handler';
import { MtTranslateTimePipe } from './translate-time.pipe';

registerSuperQueryConditionDynamicTab();
registerSuperQueryResultDynamicTab();

registerLocaleData(zh);
registerModelTreeInitializer();
registerSpectrumModelTreeInitializer();

MtCrossBrowserTabSessionStorage.init();

ModelManagementComponent.formEditorComponent = MtFormEditorComponent;

const nzConfig: NzConfig = {
  button: { nzSize: 'small' },
  card: {
    nzSize: 'small',
  },
  table: {
    nzSize: 'small',
  },
  notification: {
    nzPlacement: 'bottomRight',
  },
};

const bomConfigInit: BomConfig = {
  hasDefaultBom: {
    MaterialRevision: true,
    ProcessRevision: true,
  },
  hasProcessTarget: ['MaterialRevision'],
  notVersionedMainModelIds: ['ECR'],
};
combineBomConfig(bomConfigInit);

const mtConfig = {
  appCode: APP_CODE,
  clientCode: 'PC',
  defaultLanguage: 'zh-CN',
  rbacServiceName: 'rbac',
  adminServiceName: 'admin',
  coreServiceName: 'rbac',
  encodingServiceName: 'encoding',
  spectrumServiceName: 'mainName',
  mainServiceName: 'main',
  workflowServiceName: 'workflow',
  fileServiceName: 'main',
  mainDataSource: 'mongodb',
  languages: [
    { key: 'en-US', name: 'English' },
    { key: 'zh-CN', name: '简体中文' },
  ],
  pageTitleKey: 'common.logo',
  bomConfig: defaultBomConfig,
  // 流程配置
  process: {
    // 任务待办配置
    tasktodo: {
      /**
       * 添加处理人（加签）按钮是否不显示
       * 默认显示
       */
      notShowAddPeopleBtn: false,
      /**
       * 控制流程审批页面是否默认显示历史任务处理列表
       */
      historyTasksDisplay: false,
      /**
       * 控制任务审批驳回时是否必须填写驳回原因
       */
      mustRejectedContent: true,
    },
  },
};
// 注入对象详情控件
MtObjectDetailChildRegister.registerSummary(1, 'detail-head-table', DetailHeadTableRegiest);
// 初始化自定义面板
registerDefaultObjectDetailComponent();
registerDefaultPanelPropertiesComponent();
registerImapactedComponent();
registerEcnComponent();
registerMBomComponent();
registerSBomComponent();
registerPBomComponent();
registerEBomComponent();

registerGlobalQueryTabComponent();
registerBomDynamicTab();
registerPfepLogisticsDynamicTab();
registerPfepProjectDynamicTab();
registerHomeTabsDynamicTab();
registerTaskTodoListTabsDynamicTab();
// registerTaskTodoDetailDynamicTab();
registerTaskTodoDetailV2DynamicTab();
registerHandledTasksDynamicTab();
registerObjManagementDynamicTab();
registerGlobalQueryProcessDynamicTab();
registerProcessInsDetailDynamicTab();
// 初始化我的流程tab
registerMyProcessDynamicTab();
registerCopyToDynamicTab();
registerOwnerDynamicTab();
registerParticipatedDynamicTab();

// 初始化右键命令
registerObjectCommandCopy();
registerObjectCommandRefresh();
registerObjectCommandCut();
registerObjectCommandDelete();
registerObjectCommandPaste();
registerObjectCommandRemove();
registerObjectCommandRename();
registerObjectCommandCreateFolder();
registerObjectCommandProcess();
registerObjectCommandSaveAs();
registerObjectCommandTransferOwner();
registerObjectCommandRevision();
registerObjectCommandFolderContextRemove();
registerObjectSynchronizesEcn();
registerObjectCommandPreview();
registerObjectCommandDownload();
MtRegisterObjectCommandAdd('addObject');
registerObjectCommandAdd('addECNObject');
registerObjectCommandAdd('addChangeNotice', 'common.contextMenu.designChangeNotice');
MtObjectContextCommands.setMenuChildren('addECNObject', ['addChangeNotice']);
MtObjectContextCommands.setContextMenu('COMMON', ['copy']);
MtObjectContextCommands.setContextMenu('WORKSPACE_TREE', [
  'addObject',
  'addECNObject',
  'saveAs',
  'revision',
  'process',
  'synchronizesEcn',
  '-',
  'createFolder',
  'applyFor',
  'history',
  'transferOwner',
  'rename',
  '-',
  'copy',
  'cut',
  'paste',
  'remove',
  'delete',
  '-',
  'refresh',
  '-',
  'preview',
  'download',
]);
MtObjectContextCommands.setContextMenu('DEFAULT_TREE', ['copy']);
MtObjectContextCommands.setContextMenu('DEFAULT', ['copy']);
MtObjectContextCommands.setContextMenu('DEFAULT_GROUP', ['copy']);
MtObjectContextCommands.setContextMenu('TASK_TODO_TREE', ['copy']);
MtObjectContextCommands.setContextMenu('FOLDER_CONTEXT', ['copy', 'folder-context-remove']);

registerModelDefineInitializer();
registerDictInitializer();
registerMeasureUnitInitializer();

registerBaseDataModel();
registerCoreDataModel();

registerObjectDetailDynamicTab();

registerObjectFormPanel();
registerObjectPropertiesPanel();

registerExampleCtrl();
registerChangeCtrl();
registerCostCtrl();
registerPackInfomationCtrl();
registerPackDictCtrl();
registerPackSearchCtrl();
registerChangeItemInformationModelDownloaderCtrl();
registerImpactProductModelDownloaderCtrl();
registerFinishTimeCtrl();
registerRelativeEcrNoCtrl();
registerChangeItemInformatinDataInput();
registerImpactProductDataInput();

registerProjectDetailOrgChangeCtrl();
registerUserSelectCtrl();
registerKSelectProject();

// 注册首页TODO
registerWorkspace();
registerTodoUser();
registerFollowUp();
registerMyProcess();
// 注册首页快捷方式
registerShortcutCreateEcnObject('createECRObject');
registerShortcutCreateEcnObject('createECR1Object');
registerShortcutCreateEcnObject('createECR2Object');
registerShortcutCreateEcnObject('createECR3Object');
registerShortcutCreateEcnObject('createECR4Object');
registerShortcutCreateEcnObject('createECRO5bject');
// 注册首页报表
// registerReportDataTest('user-table1');
registerChar2('char2');
registerObjDetailDynamicTab();
registerUserDataProvider();

registerDefaultObjectNameComponent({
  detailTabTitle: {
    mtDisableClick: true,
  },
  editing: {
    mtDisableClick: true,
  },
  dropdown: {
    mtDisableClick: true,
  },
});
registerObjectNameComponent({
  detailTabTitle: {
    mtDisableClick: false,
    showDetail: false,
  },
  editing: {
    mtDisableClick: false,
    showDetail: false,
  },
  dropdown: {
    mtDisableClick: true,
    showDetail: true,
  },
});

// 删掉
// EWO页面注册
registerMyEwoDynamicTab();
registerEwoAllDynamicTab();
registerEwoFollowDynamicTab();
registerEwoDetailDynamicTab();
// 删掉
// ECN页面注册
registerMyEcnDynamicTab();
registerEcnAllDynamicTab();
registerFollowEcnDynamicTab();
registerEcnDetailDynamicTab();

// 注册用户信息页
registerCustomerInfoDynamicTab();
registerCustomerInfoDetailDynamicTab();

// 注册合同信息页
registerContractInfoDynamicTab();
registerContractInfoDetailDynamicTab();

// 注册项目信息相关页面
registerProjectDetailTabDynamicTab();
registerProjectDetailDynamicTab();
// 员工参与的项目
registerEmployeeInvolvedProjectsDynamicTab();
registerEmployeeInvolvedProjectsDetailDynamicTab();
// 项目核算
registerProjectAccountingDynamicTab();
registerProjectAccountingDetailDynamicTab();
// 项目工时核算
registerProjectHoursAccountingDynamicTab();
registerProjectHoursAccountingDetailDynamicTab();

// 注册工时信息相关页面
registerWorkingHoursDynamicTab();
registerWorkingHoursDetailDynamicTab();
// 已审批工时页面
registerApprovedWorkHoursDynamicTab();
registerApprovedWorkHoursDetailDynamicTab();
// 补填工时页面
registerMakeUpWorkHoursDynamicTab();

MtSpectrumControlRegister.register('mtRuntime', MtSpectrumRuntimeCtrl, {
  defaultCtrlFor: {
    type: 'RUNTIME',
    available: {
      singular: true,
      plural: false,
    },
  },
  powerControlled: false,
});

MtDataBindingActions.register('ECRNo', ECRNoDataBindingAction);
MtDataBindingActions.register('ChangeProject', ChangeProjectBindingAction);
MtDataBindingActions.register('ProductNo', ECRProductNoDataBindingAction);

registerTestCtrl();

@NgModule({
  declarations: [AppComponent, MtTranslateTimePipe],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MtTranslateModule,
    MtObjectDetailModule,
    MtPanelPropertiesModule,
    MtObjectCreatorModule,
    UccsPanelModule,
    UccsCreateObjectModule,
    AppRoutingModule,
    MtPageModule,
    MtEncodingModule,
    MtFormEditorModule,
    MtCommonModule,
    MtSpectrumObjectsModule,
    MtObjectTitleModule,
    CtrlsModule,
    MtSpectrumComponentsModule,
  ],
  providers: [
    { provide: NZ_CONFIG, useValue: nzConfig },
    { provide: MT_CONFIG, useValue: mtConfig },
    { provide: MT_SPECTRUM_BOM_CONFIG, useValue: defaultBomConfig },
    {
      provide: ACCESS_SERVICE,
      useClass: DefaultAccessService,
      deps: [MtConfigService, HttpClient, NzNotificationService, Router, MtTranslateService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UrlInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MtLanguageInterceptor,
      multi: true,
      deps: [MtTranslateService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ExceptionInterceptor,
      multi: true,
      deps: [Router, MtTranslateService, MtConfigService, PassportService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JsonInterceptor,
      multi: true,
    },
    { provide: MtDataSourceHanders, useClass: UccsDataSourceHandlers },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
      deps: [PassportService, HttpClient, MtConfigService],
    },
    MtTranslateProviders,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    languageService: MtLanguageService,
    notificationService: MtNotificationService,
    synchroSrv: MtDataSourceSynchroService,
    eventBus: MtEventBus
  ) {
    eventBus.start();
    notificationService.init();
    synchroSrv.init();
    languageService.initialize();
  }
}
