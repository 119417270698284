import { NzModalService } from 'ng-zorro-antd/modal';
import {
  Component,
  EventEmitter,
  forwardRef,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  TemplateRef,
  Type,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { combineLatest, of, Subject } from 'rxjs';
import { catchError, debounceTime, map, skipWhile, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { OrganizationService, UserServiceService } from '@imerge22/base';
import {
  CommonUtils,
  FilterOperator,
  MtConfigService,
  MtMap,
  MtNotificationBus,
  MtObjectUtil,
  MtSafeAny,
  MtTranslateService,
  MtWithConfig,
  Panel,
  PassportService,
  ProcessInstance,
} from '@imerge22/core';
// import { MtDataSourceService, MtObjectContext, MtObjectData, ObjectDto } from '@imerge22/platform/base';

import {
  MtDataSourceService,
  MtObjectComponentBase,
  MtObjectContext,
  MtObjectData,
  MtObjectEventBus,
  ObjectDto,
} from '@imerge22/platform/base';
import { MtObjectPanelComponents } from '@imerge22/platform/object-context';
import {
  IMtSpectrumDataSaveDTO,
  MtObjectDetailChildRegister,
  MtObjectDetailContext,
  MtObjectDetailPanelInfo,
  MtProcessInsActivateEvent,
  MtSpectrumDataService,
  MtSpectrumDefineService,
  MtSpectrumSecurityService,
} from '@imerge22/spectrum';

import { MtContextDirective, MtDynamicTabBase } from '@imerge22/common';
import { WorkProcessDto } from 'src/app/dtos/work_process.dto';
import { WorkProcessNodeDto } from 'src/app/dtos/work_process_node.dto';
import { EncodingService } from 'src/app/handlers/encoding.service';
import { CreateObjectService } from 'src/app/services/create-object.service';
import { PanelService } from 'src/app/services/panel.service';
import { EwoService } from 'src/app/services/ewo.service';
import { EcnDetailComponent } from '../../ecn/pages/ecn-detail/ecn-detail.component';
import { EcnDetailActivateEvent } from '../../ecn/events';
import { Router } from '@angular/router';
import { WorkflowViewerComponent } from '../../workflow-viewer/workflow-viewer.component';
import { EcnService } from '../../ecn/service/ecn.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'yc-ewo-detail',
  templateUrl: './ewo-detail.component.html',
  styleUrls: ['./ewo-detail.component.scss'],
  providers: [
    {
      provide: MtObjectDetailContext,
      useExisting: forwardRef(() => EwoDetailComponent),
    },
  ],
})
export class EwoDetailComponent extends MtObjectDetailContext implements MtObjectComponentBase, MtDynamicTabBase, OnInit, OnDestroy {
  constructor(
    private router: Router,
    public srv: MtDataSourceService,
    private register: MtObjectDetailChildRegister,
    private fb: FormBuilder,
    private orgSrv: OrganizationService,
    private passport: PassportService,
    private codeSvc: EncodingService,
    private panelSvc: PanelService,
    private userSrv: UserServiceService,
    private modal: NzModalService,
    private translate: MtTranslateService,
    private ewoSrv: EwoService,
    private ecnSrv: EcnService,
    defSvc: MtSpectrumDefineService,
    dataSvc: MtSpectrumDataService,
    secSvc: MtSpectrumSecurityService,
    private http: HttpClient,
    public mtConfigSrv: MtConfigService,
    @Optional() private context: MtContextDirective,
  ) {
    super(defSvc, dataSvc, secSvc);

    MtObjectEventBus.pipe(takeUntil(this.$destroyed)).subscribe((e) => {
      if (e.type === 'detail-closed') {
        // if (this.modalRef.state === NzModalState.OPEN) {
        //   this.modalRef.close();
        // }

        this.afterDestroy();
      }
    });
  }
  @MtWithConfig() private workflowServiceName: string;
  /** 是否为新增 */
  @Input() isAdd: boolean;

  /** 数据对象 */
  @Input() data: MtObjectData;

  /** 需要展开打任务信息
   *
   * area: 领域信息
   * state：阶段信息
   */
  @Input() task?: {
    area?: string;
    state?: string;
  };

  /** 业务模型Code */
  @Input() mtModelCode: string;

  @ViewChild(WorkflowViewerComponent, { static: true }) workflowViewer: WorkflowViewerComponent;
  currentProcessIns: ProcessInstance; //流程实例
  isAdmin: boolean;

  @ViewChild('tabTitle') title: TemplateRef<void>;
  onCloseByInner: EventEmitter<void>;

  /** 当前编辑对象 */
  object: ObjectDto;

  /** 申请进度 */
  workProcess?: WorkProcessDto;

  /** 对象显示的面板/视图 */
  allPanels: MtObjectDetailPanelInfo[];

  /** 编辑状态 */
  editing = false;

  /** Revision Type */
  mtRevisionType: string;

  panelNames = {
    // 策划
    plan: ['ewo_plan'],

    // 验证
    verify: ['EWO_Verify'],

    // 分析 EWO_Analyse
    analyse: [
      'EWO_Analyse_RD',
      'EWO_Analyse_Purchase',
      'EWO_Analyse_Order',
      'EWO_Analyse_Economics',
      'EWO_Analyse_Sell',
      'EWO_Analyse_Manufacture',
      'EWO_Analyse_Service',
      'EWO_Analyse_Quality',
      'EWO_Analyse_Market',
    ],

    // 决策
    decision: ['EWO_Decision'],

    // 执行
    execution: [
      'EWO_Execute_RD',
      'EWO_Execute_Purchase',
      'EWO_Execute_Order',
      'EWO_Execute_Economics',
      'EWO_Execute_Sell',
      'EWO_Execute_Manufacture',
      'EWO_Execute_Service',
      'EWO_Execute_Quality',
      'EWO_Execute_Market',
    ],

    // 关闭
    close: [''],
  };

  currentProcessNode: string; // 当前的申请进度
  currentSelectedStep: string; // 当前选择的步骤
  currentStepNode: WorkProcessNodeDto; // 当前阶段的节点

  summaryComponents: Type<MtSafeAny>[] = [];
  stepIndex = 0;
  panelIndex = -1;

  allowEdit = false; // 是否可编辑

  ewoForm: FormGroup;
  isEditloading = false;
  isAllowEditProps = false;

  isAddEcnloading: boolean;

  showWorkflowView = false; // 是否显示流程模态框
  mtProcInsName = null;
  mtProcInsId = null;

  referEwoList = [];
  relatedEcnList = []; //关联的ECN

  private $dataChange = new Subject<MtMap>();
  private $destroyed = new Subject<void>();

  ngOnInit(): void {
    this.verifyPermissions = true;
    if (this.data?.extra) {
      this.isAdd = this.data.extra.isAdd;
      this.mtModelCode = this.data.extra.mtModelCode;
    }
    this.getEwoList();
    this.ewoForm = this.fb.group({
      // objectName: [null, [MtValidators.validDisName]],
      refer_ewo: [null, []],
      ecr_no: [null, []],
      // editable: [true, [Validators.required]],
      // change_source: [null, []],
      change_topic: [null, []],
      planned_complete_date: [null, []],
    });

    this.mtObjectId = this.data?.id;
    this.mtRevisionType = this.data.type;
    if (this.isAdd) {
      this.mtObjectChanges = {};
      this.mtPropertyValueChanges = {};
      this.mtObjectId = this.createTempObject(this.data.type); // Item版本id
      this.editing = true; // 新增时，默认显示为编辑状态
      this.isAllowEditProps = true;
      this.object = {
        id: this.mtObjectId,
        objectType: this.mtModelCode, // Item的Code
        creator: this.passport.current.user.id,
      };

      const filters = [
        { prop: 'deleted', operate: FilterOperator.Equals, value: false },
        { prop: 'id', operate: FilterOperator.Equals, value: this.object.creator },
      ];
      this.userSrv.getUsers(filters, { index: 1, size: 1 }).subscribe((res: MtSafeAny) => {
        if (res && res.total > 0) {
          const orgs = res.datas[0].orgRels;
          if (orgs && orgs.length > 0) {
            this.object.dept_id = orgs[0].orgId;
            this.object.deptName = orgs[0]?.organization?.name;
          }
        }
      });

      // 生成一个新的code
      this.codeSvc.generateEncodingByRuleClass('YC_ECMS_SystemName', 'YC_ECMS_EWO_Code_Rule').subscribe((res: MtSafeAny) => {
        if (res.success && CommonUtils.isNotBlank(res.resultData)) {
          // 得到编码
          this.object.objectName = res.resultData;
          this.object.item = { itemId: res.resultData };
        }
      });

      // 新增时显示策划面板可编辑
      this.currentProcessNode = 'plan';

      // 申请进度初始为空
      this.workProcess = {
        plan_node: {
          IsStarted: true,
          // IsCompleted: true,
          Visibility: true,
          node_name: 'plan_node',
        },
        verify_node: {
          Visibility: true,
          node_name: 'verify_node',
        },
        analyse_node: {
          Visibility: true,
          node_name: 'analyse_node',
        },
        decision_node: {
          Visibility: true,
          node_name: 'decision_node',
        },
        execution_node: {
          Visibility: true,
          node_name: 'execution_node',
        },
        close_node: {
          Visibility: true,
          node_name: 'close_node',
        },
      };

      this.$dataChange.next(this.data);
      this.loadPanels();
    } else {
      // 查看模式
      // this.dataBindingCreateStatus = {};
      this.srv.getObject(this.mtObjectId).subscribe((object) => {
        // this.dataSvc.getObject(this.mtObjectId, true, this.data.type).subscribe((object) => {
        this.mtObjects[object.id] = object;
        this.object = object;
        this.editing = false; // 打开详情，默认为查看
        this.isAdmin = this.object?.creator === this.passport.current.user.id;
        this.setEditAuth();

        if (object != null) {
          this.panelSvc.getObjectPropertyValues(this.data.type, this.data.id).subscribe((result: MtSafeAny) => {
            if (result && result.resultData.data && result.resultData.data.length > 0) {
              const res = result.resultData.data[0];
              // this.object.change_source = res?.change_source;
              this.object.change_topic = res?.change_topic;
              this.object.ecr_no = res?.ecr_no;
              this.object.refer_ewo = res?.refer_ewo;
              this.object.deptName = res.DEPT_NAME;
              if (CommonUtils.isNotBlank(res?.planned_complete_date)) {
                this.object.planned_complete_date = new Date(res?.planned_complete_date);
              }
              this.object.dept_id = res?.dept_id;
              this.object.state = res?.state;
            }
            this.currentProcessNode = this.object.state;

            this.isAllowEditProps = this.currentProcessNode === 'plan';
            if (CommonUtils.isNotBlank(this.currentProcessNode)) {
              this.currentSelectedStep = this.currentProcessNode;
            }

            this.afterInit();
            this.loadPanels();

            // 根据对象组织机构id查找name
            if (CommonUtils.isNotBlank(this.object.dept_id)) {
              this.orgSrv.getOrgByIds([object.dept_id]).subscribe((res) => {
                if (res && res.resultData.total > 0) {
                  this.object.deptName = res.resultData.data[0].name;
                }
              });
            } else {
              if (!this.object.deptName) {
                this.object.deptName = '-';
              }
            }
          });
        }
      });

      // 查询申请进度
      this.panelSvc.getWorkProcess(this.mtObjectId).subscribe((res2) => {
        if (res2 && res2.resultData.total > 0) {
          this.workProcess = {
            plan_node: res2.resultData.data[0]['plan_node#values']?.plan_node,
            verify_node: res2.resultData.data[0]['verify_node#values']?.verify_node,
            analyse_node: res2.resultData.data[0]['analyse_node#values']?.analyse_node,
            decision_node: res2.resultData.data[0]['decision_node#values']?.decision_node,
            execution_node: res2.resultData.data[0]['execution_node#values']?.execution_node,
            close_node: res2.resultData.data[0]['close_node#values']?.close_node,
          };
        } else {
          console.warn('申请进度数据未找到！');
        }
      });

      // 查看流程状态
      this.panelSvc.getProcessIns(this.mtObjectId).subscribe((res: MtSafeAny) => {
        if (res && res.success && res?.resultData?.total > 0) {
          this.currentProcessIns = res?.resultData?.data[0].procIns;
          this.mtProcInsId = res?.resultData?.data[0]?.procInsId;
          this.mtProcInsName = res?.resultData?.data[0]?.procIns?.name;
        }
      });

      // 查询关联的ECN
      const query = [
        {
          prop: 'ewoRevisionId',
          operate: FilterOperator.In,
          values: [this.mtObjectId],
        },
      ];
      this.ecnSrv.getECNList({ index: 1, size: 50 }, query).subscribe((result: MtSafeAny) => {
        this.relatedEcnList = result?.resultData.data || [];
      });
    }
  }

  setEditAuth() {
    this.secSvc.getObjectAuthentication(this.mtObjectId, 'EWORevision').subscribe((res) => {
      if (res && res.Write) {
        this.allowEdit = true;
      }
    });
  }

  setcurrentProcessNode() {
    if (!this.workProcess || !this.workProcess.plan_node) {
      this.currentProcessNode = 'plan';
    } else if (this.workProcess.plan_node?.IsStarted && !this.workProcess.verify_node?.IsStarted) {
      this.currentProcessNode = 'plan';
    } else if (this.workProcess.verify_node?.IsStarted && !this.workProcess.analyse_node?.IsStarted) {
      this.currentProcessNode = 'verify';
    } else if (this.workProcess.analyse_node?.IsStarted && !this.workProcess.decision_node?.IsStarted) {
      this.currentProcessNode = 'analyse';
    } else if (this.workProcess.decision_node?.IsStarted && !this.workProcess.execution_node?.IsStarted) {
      this.currentProcessNode = 'decision';
    } else if (this.workProcess.execution_node?.IsStarted && !this.workProcess.close_node?.IsStarted) {
      this.currentProcessNode = 'execution';
    } else if (this.workProcess.close_node?.IsStarted) {
      this.currentProcessNode = 'close';
    } else {
      this.currentProcessNode = 'plan';
    }
  }

  getEwoList(searchKey?: string) {
    const page = { index: 1, size: 20 };
    const queryParams = [];

    if (CommonUtils.isNotBlank(searchKey)) {
      queryParams.push({
        prop: 'itemId',
        operate: FilterOperator.In,
        values: [searchKey],
      });
    }

    this.ewoSrv
      .getEWOList(page, queryParams)
      .pipe(debounceTime(200))
      .subscribe((res) => {
        this.referEwoList = res.resultData.data;
      });
  }

  onInput(event: Event): void {
    const value = (event.target as HTMLInputElement).value;
    if (CommonUtils.isNotBlank(value)) {
      this.referEwoList = value ? [value, value + value, value + value + value] : [];
      this.getEwoList(value);
    }
  }

  ngOnDestroy(): void {
    // this.$destroyed.next();
    // this.$destroyed.complete();
    // MtObjectEventBus.next({
    //   type: 'detail-closed'
    // });
    // if(this.modalRef.state === NzModalState.OPEN) {
    //   this.modalRef.close();
    // }
  }

  private loadPanels() {
    this.mtObjectInitedEvent[this.mtObjectId]
      .pipe(
        skipWhile((status) => {
          return status !== 'loaded';
        }),
        switchMap((status) => {
          if (this.mtRevisionType == null) {
            const panelDefs: Panel[] = [];
            return of({ status, panelDefs });
          }
          return this.defSvc.getPanels(this.mtRevisionType).pipe(
            map((panelDefs) => {
              return {
                status,
                // 创建新对象，浅拷贝panel数据
                panelDefs: panelDefs.map((panel) => ({ ...panel })),
              };
            })
          );
        }),
        switchMap((data) => {
          if (data.panelDefs == null || data.panelDefs.length === 0) {
            const panels: MtObjectDetailPanelInfo[] = [];
            return of({ ...data, panels });
          }
          return combineLatest(
            data.panelDefs.map((m) =>
              this.register.getPanel(this, this.dataSvc.getPanelKey(m)).pipe(
                map((component) => {
                  const info: MtObjectDetailPanelInfo = {
                    panel: m,
                    component,
                  };
                  return info;
                })
              )
            )
          ).pipe(
            map((panels) => {
              return { ...data, panels };
            })
          );
        }),
        switchMap((data) => {
          if (!this.isAdd) {
            const modal = this.dataSvc.getModel(this.object.objectType);
            const params = {
              modelId: modal.id,
              modelCode: this.object.objectType,
              businessId: this.object.id
            };
            return this.secSvc.getViewPower(params).pipe(
              map((acls1) => {
                const panelPower = acls1.resultData;
                data.panels
                  .filter((m) => !!m.panel.status)
                  .forEach((item) => {
                    if (item.panel) {
                      let editable: boolean;
                      let edit;
                      if (panelPower != null && panelPower[item.panel.id] != null) {
                        edit = panelPower[item.panel.id].find(o => o.permissionType === 'UPDATE');
                      }
                      editable = !edit || edit.permissionValue !== 'NO';
                      item.panel.editable = editable;
                      let visible: boolean;
                      let read;
                      if (panelPower != null && panelPower[item.panel.id] != null) {
                        read = panelPower[item.panel.id].find(o => o.permissionType === 'READ');
                      }
                      visible = !read || read.permissionValue !== 'NO';
                      item.panel.visible = visible;
                    }
                  });
                return data;
              })
            );
          } else {
            data.panels.filter((m) => !!m.panel.status).forEach((item) => {
              if (item.panel) {
                item.panel.editable = true;
                item.panel.visible = true;
              }
            });
            return of(data);
          }
        }),
        takeUntil(this.$destroy)
      )
      .subscribe((data) => {
        const oldPanels = this.panels;
        const panels: MtObjectDetailPanelInfo[] = [];
        const newPanels = data.panels.filter((m) => m.component != null && !!m.panel.visible);
        for (const panel of newPanels) {
          const existPanel = oldPanels.find((m) => m.panel.id === panel.panel.id);
          if (existPanel != null) {
            panels.push(existPanel);
          } else {
            panels.push(panel);
          }
        }
        if (this.task && this.task.area) {
          let pName = '';
          // 执行阶段
          if (this.task.state === 'execution') {
            pName = this.task.area.replace('Analyse', 'EWO_Execute');
          }
          // 分析阶段
          if (this.task.state === 'analyse') {
            pName = 'EWO_' + this.task.area;
          }

          panels.forEach((item) => {
            if (item.panel.code === pName) {
              const json = JSON.parse(item.panel.viewJson);
              if (json?.config?.controls[0]?.config) {
                json.config.controls[0].config.expanded = true;
                item.panel.viewJson = JSON.stringify(json);
              }
            }
          });
        }

        this.allPanels = panels; // panels.sort((a,b) => a.panel.seq - b.panel.seq);

        // 处理兼容历史数据
        if (CommonUtils.isBlank(this.currentProcessNode)) {
          this.setcurrentProcessNode();
        }
        // 过滤，只显示当前阶段的面板
        this.panels = this.allPanels.filter((p) => this.panelNames[this.currentProcessNode].indexOf(p.panel.code) > -1);
        if (this.panelIndex < 0 || this.panelIndex >= this.panels.length) {
          this.panelIndex = this.panels.length > 0 ? 0 : -1;
        }

        if (this.isAdd) {
          setTimeout(() => {
            this.panels.forEach((p) => {
              if (p.instance) {
                p.instance.mtEditing = true;
              }
            });
          }, 100);
        }
      });
  }

  stepChange(node: WorkProcessNodeDto, name) {
    // 未开始的阶段不能切换
    if (!node || !node.IsStarted || !node.Visibility || this.allPanels.length === 0) {
      return;
    }

    if ((this.currentStepNode && node === this.currentStepNode) || name === this.currentSelectedStep) {
      return;
    }

    // alert('测试暂时开放点击后续节点！！！');
    // 处于编辑状态, 且有更新内容时，提示是否保存当前阶段的信息
    if (this.editing && this.hasChanges()) {
      const msg = 'common.isNeedSave';
      this.translate.getByStartWithI18n(msg).subscribe((title) => {
        this.modal.confirm({
          nzTitle: title,
          // nzContent: this.orgsVM.current.name,
          // nzOkType: 'danger',
          nzIconType: 'question-circle',
          nzOnOk: () => {
            this.submitEdit(() => {
              this.doStepChange(node, name);
            });
          },
          nzOnCancel: () => {
            this.doStepChange(node, name);
          },
        });
      });
    } else {
      this.doStepChange(node, name);
    }
  }

  hasChanges() {
    if (!this.mtObjectChanges || Object.keys(this.mtObjectChanges).length === 0) {
      return false;
    }

    let change = false;

    for (const key in this.mtObjectChanges) {
      if (this.mtObjectChanges[key] && Object.keys(this.mtObjectChanges[key]).length > 0) {
        change = true;
        break;
      }
    }

    return change;
  }

  // 执行面板的切换
  doStepChange(node: WorkProcessNodeDto, name) {
    // this.mtObjectChanges = {};
    // this.mtPropertyValueChanges = {};

    this.currentStepNode = node;
    this.currentSelectedStep = name;

    // 面板切换后取消编辑状态
    this.panels.forEach((p) => {
      if (p.instance) {
        p.instance.mtEditing = false;
      }
    });
    // 过滤，只显示当前阶段的面板
    this.panels = this.allPanels.filter((p) => this.panelNames[name].indexOf(p.panel.code) > -1);

    this.editing = false; // 面板切换后取消编辑状态
  }

  stopPropagation(e: MouseEvent) {
    if (e != null) {
      e.stopPropagation();
    }
  }

  onIndexChange(index: number): void {
    this.stepIndex = index;
  }

  canleEdit() {
    this.editing = false;
    this.panels.forEach((p) => {
      if (p.instance) {
        p.instance.mtEditing = false;
      }
    });
  }

  startEdit() {
    this.editing = true;
    // this.ewoForm.controls.objectName.setValue(this.object.objectName);
    this.ewoForm.controls.refer_ewo.setValue(this.object.refer_ewo);
    this.ewoForm.controls.ecr_no.setValue(this.object.ecr_no);
    // this.ewoForm.controls.change_source.setValue(this.object.change_source);
    this.ewoForm.controls.change_topic.setValue(this.object.change_topic);
    this.ewoForm.controls.planned_complete_date.setValue(this.object.planned_complete_date);

    // if (
    //   this.currentProcessNode === 'plan' ||
    //   (this.currentStepNode && this.currentStepNode.IsStarted && !this.currentStepNode.IsCompleted)
    // ) {
    this.panels.forEach((p) => {
      if (p.instance && p.panel.editable) {
        p.instance.mtEditing = true;
      }
    });
    // }
  }

  // tslint:disable-next-line: no-any
  submitEdit(callBack?: any) {
    this.isEditloading = true;
    const props = {
      // ewo_no: this.object.objectName,
      refer_ewo: this.ewoForm.controls.refer_ewo.value,
      ecr_no: this.ewoForm.controls.ecr_no.value,
      // editable: [true, [Validators.required]],
      // change_source: this.ewoForm.controls.change_source.value, //变更为使用关系对象
      change_topic: this.ewoForm.controls.change_topic.value,
      planned_complete_date: this.ewoForm.controls.planned_complete_date.value,
      dept_id: this.object.dept_id,
    };
    // 新增
    if (this.isAdd) {
      // tslint:disable-next-line: no-string-literal
      props['state'] = 'plan';
      this.currentProcessNode = 'plan';
      const newObject: ObjectDto = {
        objectType: this.mtModelCode,
        objectName: this.object.objectName,
        item: { itemId: this.object.objectName },
        prop: props,
      };
      // 新增
      this.srv.createObject(newObject).subscribe((itemId) => {
        console.log('创建对象成功, id为：' + itemId);
        this.srv.getObjectRevisions(itemId).subscribe((resp: MtSafeAny) => {
          const objectId = resp[0].itemRevision.id;
          console.log('版本对象, id为：' + objectId);
          this.mtObjects[objectId] = this.mtObjects[this.mtObjectId];
          this.mtObjectChanges[objectId] = this.mtObjectChanges[this.mtObjectId];
          this.mtPropertyValueChanges[objectId] = this.mtPropertyValueChanges[this.mtObjectId];
          delete this.mtObjects[this.mtObjectId];
          delete this.mtObjectChanges[this.mtObjectId];
          delete this.mtPropertyValueChanges[this.mtObjectId];

          this.mtObjectId = objectId;
          this.savePanelData();
        }, err => this.isEditloading = false);
      }, err => this.isEditloading = false);
    } else {
      this.savePanelData(callBack);
    }
  }

  // 保存面板的内容
  // tslint:disable-next-line: no-any
  savePanelData(callBack?: any) {
    const ids = new Set<string>();
    const changes = this.getChanges(ids);
    if (this.isAdd) {
      this.initAnalyseData(changes, ids); // 初始化分析任务
    } else if (this.isAllowEditProps) {
      if (!changes.data[this.mtObjectId]) {
        changes.data[this.mtObjectId] = { changes: [], data: {} };
      }
      changes.data[this.mtObjectId].changes.push('refer_ewo', 'ecr_no', 'change_topic', 'planned_complete_date');
      // tslint:disable-next-line: no-string-literal
      changes.data[this.mtObjectId].data['refer_ewo'] = this.ewoForm.controls.refer_ewo.value || '';
      // tslint:disable-next-line: no-string-literal
      changes.data[this.mtObjectId].data['ecr_no'] = this.ewoForm.controls.ecr_no.value || '';
      // tslint:disable-next-line: no-string-literal
      changes.data[this.mtObjectId].data['change_topic'] = this.ewoForm.controls.change_topic.value || '';
      // tslint:disable-next-line: no-string-literal
      changes.data[this.mtObjectId].data['planned_complete_date'] = this.ewoForm.controls.planned_complete_date.value || '';

      ids.add(this.mtObjectId);
    }
    if (ids.size === 0) {
      this.isEditloading = false;
      this.editing = false;
      this.panels.forEach((p) => {
        if (p.instance) {
          p.instance.mtEditing = false;
        }
      });

      MtNotificationBus.emit({
        type: 'success',
        title: this.isAdd ? '创建成功' : '更新成功',
        message: 'EWO编号为：' + this.object?.objectName,
      });

      this.isAdd = false;
      this.setEditAuth();
      return;
    }
    this.dataSvc
      .save(changes)
      .pipe(
        tap(() => {
          // 重新加载修改对象最新的数据
          for (const objId of ids) {
            this.dataSvc.getObject(objId, true).pipe(take(1)).subscribe();
            this.dataSvc.getObjectPropertyValues(objId, true).pipe(take(1)).subscribe();
          }
          this.objectDataInit(this.mtObjectId, this.mtObject.objectType, true);
        }),
        map((id) => true),
        catchError((err) => {
          console.log(err);
          return of(false);
        })
      )
      .subscribe((res) => {
        if (res) {
          console.log('修改视图信息成功。');

          this.mtObjectChanges = {};
          this.mtPropertyValueChanges = {};
          if (this.isAllowEditProps) {
            this.object.refer_ewo = this.ewoForm.controls.refer_ewo.value;
            this.object.ecr_no = this.ewoForm.controls.ecr_no.value;
            this.object.change_topic = this.ewoForm.controls.change_topic.value;
            this.object.planned_complete_date = this.ewoForm.controls.planned_complete_date.value;
          }
          this.isEditloading = false;
          this.editing = false;
          // 提示信息
          MtNotificationBus.emit({
            type: 'success',
            title: this.isAdd ? '创建成功' : '更新成功',
            message: 'EWO编号为：' + this.object.objectName,
          });
          if (this.isAdd) {
            this.isAdd = false;
            this.setEditAuth();
            // this.modalRef.close(true);
          } else {
            this.panels.forEach((p) => {
              if (p.instance) {
                p.instance.mtEditing = false;
              }
            });
          }

          if (callBack) {
            callBack();
          }
        } else {
          this.isEditloading = false;
        }
      }, err => this.editing = false);
  }

  getChanges(ids: Set<string>) {
    const changes: IMtSpectrumDataSaveDTO = { id: this.mtObjectId, data: {} };
    let objIds = Object.keys(this.mtObjectChanges);
    for (const objId of objIds) {
      const change = this.mtObjectChanges[objId];
      let hasChange = false;
      if (change != null) {
        if (change.objectName != null) {
          if (changes.data[objId] == null) {
            changes.data[objId] = { changes: [], data: {} };
          }
          changes.data[objId].changes.push('$.objectName');
          changes.data[objId].data['$.objectName'] = change.objectName;
          hasChange = true;
        }
        if (change.item?.itemId != null) {
          if (changes.data[objId] == null) {
            changes.data[objId] = { changes: [], data: {} };
          }
          changes.data[objId].changes.push('$.item.itemId');
          changes.data[objId].data['$.item.itemId'] = change.item.itemId;
          hasChange = true;
        }
        if (MtObjectUtil.isNew(objId)) {
          if (changes.data[objId] == null) {
            changes.data[objId] = { changes: [], data: {} };
          }
          changes.data[objId].changes.push('$.objectType');
          changes.data[objId].data['$.objectType'] = this.mtObjects[objId].objectType;
        }
      }
      if (hasChange && !MtObjectUtil.isNew(objId)) {
        ids.add(objId);
      }
    }
    objIds = Object.keys(this.mtPropertyValueChanges);
    for (const objId of objIds) {
      const change = this.mtPropertyValueChanges[objId];
      if (change != null && Object.keys(change).length > 0) {
        const props = Object.keys(change);
        if (MtObjectUtil.isNew(objId) && props.length > 1) {
          if (changes.data[objId] == null) {
            changes.data[objId] = { changes: [], data: {} };
            changes.data[objId].changes.push('$.objectType');
            changes.data[objId].data['$.objectType'] = this.mtObjects[objId].objectType;
          }
        }

        // 只有$.objectType属性时，不需要添加到changes
        // 处理PartChanges 只有IsNewPart属性时，不需要添加到changes
        if (props.length === 1 && (props[0] === '$.objectType' || props[0] === 'IsNewPart')) {
          continue;
        }

        for (const prop of props) {
          if (changes.data[objId] == null) {
            changes.data[objId] = { changes: [], data: {} };
          }

          // 处理表格属性为空内容时不加入changes，如："part_changes", "impact_scope", "service_solution"]
          if (Array.isArray(change[prop]) && change[prop].length === 0) {
            continue;
          }

          if (change[prop] !== null) {
            changes.data[objId].changes.push(prop);
            changes.data[objId].data[prop] = change[prop];
          }
        }

        if (props.length > 0 && !MtObjectUtil.isNew(objId)) {
          ids.add(objId);
        }
      }

      // 移除中的空数据，及changes.data[objId] = { changes: [], data: {} };
      if (changes.data[objId] && changes.data[objId]?.changes.length === 0) {
        delete changes.data[objId];
        ids.delete(objId);
      }
    }

    return changes;
  }

  /**
   *
   * 初始化分析阶段的任务项
   */
  initAnalyseData(changes, ids: Set<string>) {
    const analyses = [
      // 研发领域
      {
        prop: 'Analyse_RD', // 属性名称
        type: 'ImpactAnalysis_RD', // 属性对应的业务模型名称
        tasks: [
          '修改模型/图纸',
          'P图下发/已验证供应商通知',
          '修改明细表/发放变更通知单',
          '修改电控数据',
          '修改技术文件',
          '合规信息公告变更',
          '旧状态零件作废ITEM',
        ],
      },

      // 采购领域
      {
        prop: 'Analyse_Purchase',
        type: 'ImpactAnalysis_Purchase',
        tasks: ['供应商布点', '供应商签收图纸', '供应商开模', '供应商交样', '供应商小批供货', '供应商PPAP'],
      },

      // 订单领域
      {
        prop: 'Analyse_Order',
        type: 'ImpactAnalysis_Order',
        tasks: ['平衡采购订单下达', '旧状态零件配对平衡采购'],
      },

      // 制造领域
      {
        prop: 'Analyse_Manufacture',
        type: 'ImpactAnalysis_Manufacture',
        tasks: [
          '设备变更',
          '首台流水号',
          // '工装变更',
          '铸造工装变更',
          '加工工装变更',
          '装试工装变更',
          // '产线变更',
          '铸造产线变更',
          '加工产线变更',
          '装试产线变更',
          // '工艺变更',
          '铸造工艺变更',
          '加工工艺变更',
          '装试工艺变更',
          '装配指南更改',
          '工艺文件更改',
          '实物的工艺验证',
        ],
      },

      // 销售领域
      {
        prop: 'Analyse_Sell',
        type: 'ImpactAnalysis_Sell',
        tasks: ['下发PCN及客户回签', '协议变更', '供货号变更', '售价变更', '客户验证', '跟整车厂沟通确定更改切换时间'],
      },

      // 服务领域
      {
        prop: 'Analyse_Service',
        type: 'ImpactAnalysis_Service',
        tasks: ['市场主动整改完成时间', '配件的储备', '售后BOM更改', '保留旧件模具'],
      },

      // 质量领域
      {
        prop: 'Analyse_Quality',
        type: 'ImpactAnalysis_Quality',
        tasks: ['零部件检验清单项目', '整机出厂检验作业项目'],
      },
    ];

    // 创建分析阶段的对象
    const ewoAnalyse = this.dataSvc.createTempObject('ImpactAnalysisModel');
    changes.data[ewoAnalyse.id] = {
      changes: ['$.objectType'],
      data: { '$.objectType': 'ImpactAnalysisModel' },
    };

    analyses.forEach((analyse) => {
      const newAnalyse = this.dataSvc.createTempObject(analyse.type);
      changes.data[newAnalyse.id] = {
        changes: ['$.objectType', 'after_tasks'],
        data: {
          '$.objectType': analyse.type,
          after_tasks: [], // 后续执行项
        },
      };

      // 添加任务
      analyse.tasks.forEach((task) => {
        const taskName = task;
        const newTask = this.dataSvc.createTempObject('Task');

        changes.data[newTask.id] = {
          changes: [
            '$.objectType',
            'Verification_item',
            'Not_involving',
            'owner_object_id',
            'owner_object_type',
            'owner_object_name',
            'owner_object_state',
            'owner_object_area',
          ],
          data: {
            '$.objectType': 'Task',
            Verification_item: taskName, // 任务名称
            Not_involving: false, // 不涉及，默认为false
            owner_object_id: this.mtObjectId,
            owner_object_type: 'EWORevision',
            owner_object_name: this.object.objectName,
            owner_object_state: 'plan',
            owner_object_area: analyse.prop,
          },
        };

        // changes.data[newAnalyse.id].changes.push(node);
        changes.data[newAnalyse.id].data.after_tasks.push({ task_obj: newTask.id });
        ids.add(newTask.id);
      });
      changes.data[ewoAnalyse.id].changes.push(analyse.prop);
      changes.data[ewoAnalyse.id].data[analyse.prop] = newAnalyse.id;
      ids.add(newAnalyse.id);
    });
    changes.data[this.mtObjectId].changes.push('ewo_analyse');
    // tslint:disable-next-line: no-string-literal
    changes.data[this.mtObjectId].data['ewo_analyse'] = ewoAnalyse.id;
  }

  // 创建ECN
  createECN() {
    this.isAddEcnloading = true;
    const ewoNo = this.object.objectName;

    this.ewoSrv.getEcnByEwo(ewoNo).subscribe((res) => {
      const ecnList = [];

      if (res && res.listECNInfo && res.listECNInfo.length > 0) {
        res.listECNInfo.forEach((ecnInfo) => {
          if (CommonUtils.isNotBlank(ecnInfo.ecnId)) {
            ecnList.push(ecnInfo.ecnId);
          }
        });

        if (ecnList.length > 0) {
          // this.router.navigate(['/changes/ecn']);
          // setTimeout(() => {
          //   EcnDetailActivateEvent.emit({
          //     context: 'ECN',
          //     id: null,
          //     type: 'ECNRevision',
          //     extra: {
          //       mtModelCode: 'ECN',
          //       isAdd: true,
          //       ewo_no: this.object.objectName,
          //       ewo_revision_id: this.mtObjectId,
          //       ECN_List: ecnList,
          //     },
          //   });
          // }, 100);
          // this.isAddEcnloading = false;

          this.modal
            .create({
              nzTitle: '创建ECN',
              nzContent: EcnDetailComponent,
              nzComponentParams: {
                isAdd: true,
                mtModelCode: 'ECN',
                data: { id: null, type: 'ECNRevision' },
                ewo_no: this.object.objectName,
                ewo_revision_id: this.mtObjectId,
                ECN_List: ecnList,
              },
              nzFooter: null,
              nzWidth: '90%',
              nzBodyStyle: { height: document.body.clientHeight - 75 + 'px' },
              nzMaskClosable: false,
              // nzClassName: 'mt-maximum',
              nzStyle: { top: '5px' },
            })
            .afterClose.subscribe(() => {
              this.isAddEcnloading = false;
            });
        }
      }

      if (ecnList.length === 0) {
        this.isAddEcnloading = false;

        MtNotificationBus.emit({
          type: 'warning',
          title: '提示信息',
          message: '在TC中未查询到可创建的ECN信息！',
        });
      }
    });
  }

  //打开ECN页签
  openEcn_old(item) {
    this.router.navigate(['/changes/ecn']);
    setTimeout(() => {
      EcnDetailActivateEvent.emit({
        context: 'ECN',
        id: item?._id,
        type: 'ECNRevision',
        extra: {
          mtModelCode: 'ECN',
          isAdd: false,
          ewo_no: this.object.objectName,
          ewo_revision_id: this.mtObjectId,
        },
      });
    }, 100);
  }

  //打开ECN弹框
  openEcn(item) {
    // 弹出框，不再弹出
    if (this.modal.openModals.length > 0) {
      return;
    }

    const data = { id: item?._id, type: 'ECNRevision' };
    this.modal.create({
      nzTitle: 'ECN详情',
      nzContent: EcnDetailComponent,
      nzComponentParams: {
        isAdd: false,
        mtModelCode: 'ECN',
        data: data,
      },
      nzFooter: null,
      nzWidth: '90%',
      nzBodyStyle: { height: document.body.clientHeight - 75 + 'px' },
      nzMaskClosable: false,
      //nzClassName: 'mt-maximum',
      nzStyle: { top: '5px' },
    });
  }

  isItem() {
    return ['Item', 'ItemRevision'].includes(this.srv.getObjectType(this.data.type));
  }

  getStepStatus(wp: WorkProcessNodeDto) {
    if (wp.IsCompleted) {
      return 'finish';
    } else if (wp.IsStarted) {
      return 'process';
    } else {
      return 'wait';
    }
  }

  private init() {
    this.$dataChange
      .pipe(
        // switchMap(() => this.loadTaskInfo()),
        takeUntil(this.$destroy)
      )
      .subscribe(() => {
        // this.afterInit();
        this.objectDataInit(this.mtObjectId, this.data.type, true);
        this.loadPanels();
      });
  }

  onDataChange(data: MtMap): void {
    if (data.id != null && this.data !== data.id) {
      this.$dataChange.next(this.data);
    }
  }

  checkEditProps() {
    return this.isAllowEditProps && this.editing;
  }

  checkShowButton() {
    if (this.isAdd) {
      // 新增间断，可以编辑策划内容
      return true;
    } else if (this.allowEdit && this.currentProcessNode === 'plan') {
      // 策划间断，可以编辑自己的ewo
      return this.object.creator === this.passport.current.user.id;
    } else if (this.allowEdit && this.currentProcessNode === this.currentSelectedStep) {
      // 当前进度时，根据面板权限来显示是否可编辑
      return this.allowEdit && this.mtEditable;
    }

    return false;
  }

  //查看流程
  showWorkflowViewer() {
    // this.workflowViewer.visible = true;
    this.http.post(`srv://${this.workflowServiceName}/proc/instance`, {
      modelId: 'ProcessInstance',
      dataSource: this.workflowServiceName,
      conditions: {
        filters: [{ prop: 'id', operate: FilterOperator.Equals, value: this.currentProcessIns.id }]
      },
      cascades: ['procDefRev.procDef', 'objects.commonObject.objectStatus'],
      sorts: [{ prop: 'startTime' }],
      }).subscribe((res: MtSafeAny) => {
        const result = res.resultData?.data?.[0];
        if (result) {
          MtProcessInsActivateEvent.emit({
            context: this.context.mtContext,
            processId: result.id,
            process: result,
          });
        }
      });
  }

  onActivated(): void {}
  onDeactivated(): void {}
  activate(): void {}
  deactivate(): void {}
}
