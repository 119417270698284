/*
 * @Author: your name
 * @Date: 2021-09-10 07:53:41
 * @LastEditTime: 2021-10-07 20:08:37
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \web\src\app\services\ewo.service.ts
 */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { GenericQueryService, PageRequest, MtConfigService, MtWithConfig, SortDirection, MtSafeAny } from '@imerge22/core';
import { map, timeout } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class EwoService {
  followList = null;
  followList$ = new BehaviorSubject(null);
  constructor(private http: HttpClient, private querySrv: GenericQueryService, public mtConfigSrv: MtConfigService) {}

  @MtWithConfig() private spectrumServiceName: string;

  /**
   * ewo查询
   */
  getEWOList(page: PageRequest, query?: any[], sort?: any[]): Observable<MtSafeAny> {
    const date = new Date().valueOf();
    if (!(sort && sort.length > 0)) {
      sort = [{ prop: 'CREATE_TIME', dir: SortDirection.Descend }];
    }
    return this.http.post(`srv://${this.spectrumServiceName}/mongodb/common/query`, {
      modelId: 'EWORevision',
      conditions: {
        filters: query,
      },
      page: {
        skip: (page.index - 1) * page.size,
        take: page.size,
        'verifyStart':1,
        'verifyEnd':10000
      },
      sorts: sort,
      cacheable: false,
      cascades: null,
      queryId: date,
    });
  }

  // 获取关注列表
  getFollowedEWOList(query?: any[]): Observable<MtSafeAny> {
    const res = this.querySrv
      .query<MtSafeAny>({
        modelId: 'UserFollow',
        dataSource: this.spectrumServiceName,
        // cascades: ['EWO'],
        conditions: {
          filters: query,
        },
        // sorts: [{ prop: 'objectName', dir: SortDirection.Descend }],
      })
      .pipe(
        map((item) => {
          if (item.resultData && item.resultData.data) {
            let datas = item.resultData.data || [];
            datas = datas.filter((item) => item.followType.toUpperCase() === 'EWO');
            let ids = [];
            if (datas.length > 0) {
              ids = datas.map((item) => item.followObjId);
            }
            this.followList = ids;
            this.followList$.next(this.followList);
          }

          return item;
        })
      );
    return res;
  }

  // 用户是否关注获取
  attention(params: MtSafeAny): Observable<MtSafeAny> {
    // this.http.post(`srv://${this.spectrumServiceName}/
    return this.http.post(`srv://${this.spectrumServiceName}/common/query`, params);
  }
  // 关注操作
  changeFollow(params: MtSafeAny): Observable<MtSafeAny> {
    return this.http.post(`srv://${this.spectrumServiceName}/user/userFollow`, params);
  }

  /**
   * tc 零件查询
   * @ids {图号}
   */
  getPartInfo(ids: string[]) {
    const requestData = { DATAINFO: [] };
    ids.forEach((id) => {
      requestData.DATAINFO.push({
        itemId: id,
        itemRevisionId: '',
        revRule: '1', // 0 为最新版本   1 为最新发布版
      });
    });
    return this.http.post(`srv://${this.spectrumServiceName}/tcInterface/part/componentsQuery`, requestData).pipe(timeout(300000));
  }

  /**
   * 影响分析
   * 备注：参数为数组 业务需求为一次只能查询一个 零件的影响分析
   */
  getUsedAnalysisInfo(data) {
    console.log(this.spectrumServiceName);
    const requestData = {
      DATAINFO: [
        {
          itemId: data,
          itemRevisionId: '',
          revRule: '1',
        },
      ],
    };
    return this.http.post(`srv://${this.spectrumServiceName}/tcInterface/total/componentsQuery`, requestData).pipe(timeout(500000));
  }

  /**
   * 获取EWO可创建的ECN编号
   *
   * @param {*} ewoNo EWO编号
   *
   *  返回 结果
   *  {
   *  "listECNInfo": [
   *     {
   *      "creationDate": "2019-12-25 14:41:34",
   *       "ecnId": "E2019122501",
   *       "ewoId": "EWO00000002",
   *       "owner": "TEST11 (test11)",
   *       "status": "编制中"
   *     }
   *   ],
   *   "msg":""
   * }
   */
  getEcnByEwo(ewoNo): Observable<MtSafeAny> {
    return this.http.post(`srv://${this.spectrumServiceName}/tcInterface/relation/componentsQuery`, {
      ECNInfoByEWO: [ewoNo],
    });
  }
}
