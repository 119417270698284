import { MtEvent, MtEventBus, MtSafeAny } from '@imerge22/core';
import { MtDynamicTabsetActivateEventData } from '@imerge22/common';

export class MyEwoActivateEvent {
  static $name = 'my-ewo-activate';
  static emit(data: MtDynamicTabsetActivateEventData) {
    MtEventBus.emit({
      name: MyEwoActivateEvent.$name,
      data,
    });
  }

  static is(event: MtEvent<MtSafeAny>): boolean {
    return event != null && event.name === MyEwoActivateEvent.$name;
  }

  static get(event: MtEvent<MtSafeAny>): MtDynamicTabsetActivateEventData {
    if (MyEwoActivateEvent.is(event)) {
      return event.data;
    }
    return undefined;
  }
}
