<nz-drawer
  [nzWidth]="520"
  [nzClosable]="true"
  [nzVisible]="visible"
  nzPlacement="right"
  [nzTitle]="'platform.spectrum.object.addNew' | translate"
  (nzOnClose)="cancel()"
  nzWrapClassName="mt-with-footer"
>
  <table class="mt-table">
    <tr>
      <th class="mt-right">{{ 'platform.spectrum.object.type' | translate }}</th>
      <td class="mt-fill"><mt-model-title [mtModelId]="model?.modelId"></mt-model-title></td>
    </tr>
    <tr>
      <th class="mt-right"><span class="mt-danger"> * </span>{{ 'platform.spectrum.object.code' | translate }}</th>
      <td class="mt-fill"><input nz-input [(ngModel)]="model.code" /></td>
    </tr>
    <tr>
      <th class="mt-right"><span class="mt-danger"> * </span>{{ 'platform.spectrum.object.name' | translate }}</th>
      <td class="mt-fill"><input nz-input [(ngModel)]="model.name" /></td>
    </tr>
    <tr>
    <!-- <tr>
      <th class="mt-right">{{ 'platform.spectrum.object.objOwnerGroup' | translate }}</th>
      <td class="mt-fill">
        <nz-select
          [(ngModel)]="model.owningGroupId"
          [nzAllowClear]="true"
        >
          <nz-option
            *ngFor="let item of ownerGroups"
            [nzValue]="item.id"
            [nzLabel]="item.name"
          ></nz-option>
        </nz-select>
      </td>
    </tr> -->
    <tr>
      <th class="mt-right">{{ 'platform.spectrum.object.desc' | translate }}</th>
      <td class="mt-fill">
        <textarea nz-input [(ngModel)]="model.desc" [nzAutosize]="{ minRows: 4, maxRows: 8 }"></textarea>
      </td>
    </tr>
  </table>
  <div class="mt-drawer-footer">
    <button (click)="cancel()" type="button" nz-button nzShape="round">
      <i nz-icon nzType="close"></i>{{ 'platform.common.cancel' | translate }}
    </button>
    <button
      nz-button
      nzShape="round"
      nzType="primary"
      type="button"
      (click)="save()"
      [disabled]="check()"
    >
      <i nz-icon nzType="check"></i>{{ 'platform.common.nextStep' | translate }}
      <!-- <i nz-icon nzType="check"></i>{{ 'platform.common.confirm' | translate }} -->
    </button>
  </div>
</nz-drawer>
