<!--
 * @Author: your name
 * @Date: 2021-09-10 07:53:41
 * @LastEditTime: 2021-10-29 10:51:25
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \web\src\app\root\changes\ecn\component\search-form\search-form.component.html
-->
<div class="search-form">
  <div class="search-form__item">
    <input nz-input [(ngModel)]="searchFrom.itemId" [placeholder]="'root-del.ecn.number' | translate" />
  </div>


  <div class="search-form__item" *ngIf="type !== 'my'">
    <input nz-input [(ngModel)]="searchFrom.account" [placeholder]="'common-del.creator' | translate" (input)="onInput($event)"
      [nzAutocomplete]="autoUser" />
    <nz-autocomplete #autoUser>
      <nz-auto-option *ngFor="let user of userOptions" [nzValue]="user.id" [nzLabel]="user.account ">
        <span *ngIf="user!=null"> {{ user.name + ' ( ' + user.account+ ' )' }}</span>
      </nz-auto-option>
    </nz-autocomplete>
  </div>

  <div class="search-form__item" *ngIf="type !== 'my'">
    <nz-select nzShowSearch [(ngModel)]="searchFrom.deptId" nzAllowClear [nzPlaceHolder]="'common-del.creatorDept' | translate" >
      <nz-option [nzLabel]="item.name" [nzValue]="item.id" *ngFor="let item of orgList"></nz-option>
    </nz-select>
  </div>


  <div class="search-form__item">
    <nz-range-picker nzSize="default" style="width: 280px"
      [nzPlaceHolder]="['common-del.createTime' | translate, 'common-del.createTime' | translate]"
      [(ngModel)]="searchFrom.createTime"></nz-range-picker>
  </div>


  <div class="search-form__item mt-btns">
    <button nz-button nzType="primary" (click)="formSubmit()">
      {{ 'root-del.processTiming.search' | translate }}
    </button>
  </div>
  <div class="search-form__item mt-btns">
    <button nz-button nzType="default" (click)="formReset()">
      {{ 'root-del.processTiming.reset' | translate }}
    </button>
  </div>
  <div class="search-form__item mt-btns" *ngIf="type === 'my'">
    <button nz-button nzType="primary" [disabled]="selectRow == null" (click)="launchWorkflow()">
      {{ 'root-del.ewo.submit' | translate }}
    </button>
  </div>

</div>

<mt-workflow-starter (mtSubmit)="startWorkflow()"></mt-workflow-starter>
