import { Component, Input, OnInit } from '@angular/core';
import { ProcessInstance } from '@imerge22/core';




@Component({
  selector: 'yc-workflow-viewer',
  templateUrl: './workflow-viewer.component.html',
  styleUrls: ['./workflow-viewer.component.scss']
})
export class WorkflowViewerComponent implements OnInit {


  /** 流程实例 */
  @Input() currentProcessIns: ProcessInstance;

  @Input() isAdmin: boolean = false;
  visible = false;

  constructor() { }

  ngOnInit(): void {
  }
}
