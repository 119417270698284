import { Injectable } from '@angular/core';
import { MtDynamicTabHandler, MtDynamicTabRegister } from '@imerge22/common';
import { MtEvent, MtSafeAny, MtMap } from '@imerge22/core';
import { ProjectDetailActivateEvent } from './events';
import { ProjectDetailListComponent } from './project-detail-list/project-detail-list.component';

@Injectable({ providedIn: 'root' })
export class ProjectDetailTabHandler extends MtDynamicTabHandler {
  match(context: string, event: MtEvent<MtSafeAny>): boolean {
    if (event.name === ProjectDetailActivateEvent.$name) {
      const data = ProjectDetailActivateEvent.get(event);
      return data?.context === context;
    }
    return false;
  }

  same(context: string, exist: MtMap, event: MtEvent<MtSafeAny>): boolean {
    if (event.name === ProjectDetailActivateEvent.$name) {
      const data = ProjectDetailActivateEvent.get(event);
      return data?.context === context;
    }
    return false;
  }
}

export function registerProjectDetailTabDynamicTab() {
  MtDynamicTabRegister.register(ProjectDetailActivateEvent.$name, {
    component: ProjectDetailListComponent,
    handler: ProjectDetailTabHandler,
    initializeComponent: true,
  });
}
