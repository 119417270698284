<div>
  <div class="baseInfo">
    <table class="mt-table">
      <tr>
        <th>{{ 'platform.spectrum.object.objectCode' | translate }}：</th>
        <td>
          <div nz-row nzGutter="5">
            <div nz-col nzSpan="9">
              <input nz-input [(ngModel)]="model.ITEM_ID" />
            </div>

            <div nz-col nzSpan="9" *ngIf="data.extra.isUaesQuery">
              <label nz-checkbox [ngModel]="true">
                {{ 'platform.spectrum.search.searchLocalLib' | translate }}
              </label>
              <br />
              <label nz-checkbox nzDisabled [ngModel]="false">
                {{ 'platform.spectrum.search.SearchEdmLib' | translate }}
              </label>
            </div>
          </div>
        </td>
      </tr>
      <tr>
        <th>{{ 'platform.spectrum.object.objectName' | translate }}：</th>
        <td>
          <div nz-row nzGutter="5">
            <div nz-col nzSpan="9" *ngIf="data.extra.isUaesQuery">
              <input nz-input [(ngModel)]="model.OBJECT_NAME" />
            </div>
            <div nz-col nzSpan="3" *ngIf="!data.extra.isUaesQuery">
              <nz-select
                [(ngModel)]="model['OBJECT_NAME' + RELATION]"
                nzAllowClear
                [nzPlaceHolder]="'platform.common.relation' | translate"
              >
                <nz-option
                  *ngFor="let item of stringSelectType"
                  [nzValue]="item.value"
                  [nzLabel]="getSelectName(item)"
                ></nz-option>
              </nz-select>
            </div>
            <div nz-col nzSpan="6" *ngIf="!data.extra.isUaesQuery">
              <input nz-input [(ngModel)]="model.OBJECT_NAME" />
            </div>
          </div>
        </td>
      </tr>
      <tr>
        <th>{{ 'platform.spectrum.search.valid' | translate }}：</th>
        <td>
          <div nz-row nzGutter="5">
            <div nz-col nzSpan="9">
              <nz-select [(ngModel)]="model.Valid" nzAllowClear nzShowSearch nzMode="default">
                <nz-option
                  *ngFor="let m of 'Valid' | dictItems | async"
                  [nzValue]="m.code"
                  [nzLabel]="m.name"
                ></nz-option>
              </nz-select>
            </div>
          </div>
        </td>
      </tr>
      <tr>
        <th>{{ 'platform.spectrum.render.classification' | translate }}：</th>
        <td>
          <div nz-row>
            <div nz-col nzSpan="9">
              <nz-tree-select
                style="width: 100%"
                [nzExpandedKeys]="expandKeys"
                [nzNodes]="modelTreeNodes"
                nzShowSearch
                [nzDropdownStyle]="{ 'max-height': '300px' }"
                [nzPlaceHolder]="'platform.spectrum.render.classification' | translate"
                [(ngModel)]="model.OBJECT_TYPE"
                (ngModelChange)="changeTree($event)"
              >
              </nz-tree-select>
            </div>
          </div>
        </td>
      </tr>
      <!-- <tr>
        <th>{{'platform.spectrum.render.founder' | translate}}：</th>
        <td>
          <div nz-row nzGutter="5">
            <div nz-col  nzSpan="6">
              <mt-user-input [(ngModel)]="model.CREATOR"></mt-user-input>
            </div>
          </div>
        </td>
      </tr>
      <tr>
        <th>{{'platform.spectrum.render.addDate' | translate}}：</th>
        <td>
          <div nz-row nzGutter="5">
            <div nz-col  nzSpan="6">
              <nz-select
                [(ngModel)]="model['CREATE_TIME' + RELATION]"
                (ngModelChange)="changeDateType('CREATE_TIME')"
                [nzPlaceHolder]="'platform.common.relation' | translate"
              >
                <nz-option *ngFor="let item of numberOrDateSelectType" [nzValue]="item.value" [nzLabel]="getSelectName(item)"></nz-option>
              </nz-select>
            </div>
            <div nz-col  nzSpan="18">
                <ng-container *ngIf="model['CREATE_TIME' + RELATION] === 'Between'; else dateTemp1">
                  <nz-range-picker [(ngModel)]="model.CREATE_TIME"></nz-range-picker>
                </ng-container>
                <ng-template #dateTemp1>
                  <nz-date-picker [(ngModel)]="model.CREATE_TIME"></nz-date-picker>
                </ng-template>
            </div>
          </div>
        </td>
      </tr>
      <tr>
        <th>{{'platform.spectrum.render.lastEidtDate' | translate}}：</th>
        <td>
          <div nz-row nzGutter="5">
            <div nz-col  nzSpan="6">
              <nz-select
                [(ngModel)]="model['UPDATE_TIME' + RELATION]"
                (ngModelChange)="changeDateType('UPDATE_TIME')"
                [nzPlaceHolder]="'platform.common.relation' | translate"
              >
                <nz-option *ngFor="let item of numberOrDateSelectType" [nzValue]="item.value" [nzLabel]="getSelectName(item)"></nz-option>
              </nz-select>
            </div>
            <div nz-col  nzSpan="18">
              <ng-container *ngIf="model['UPDATE_TIME' + RELATION] === 'Between'; else dateTemp2">
                <nz-range-picker [(ngModel)]="model.UPDATE_TIME"></nz-range-picker>
              </ng-container>
              <ng-template #dateTemp2>
                <nz-date-picker [(ngModel)]="model.UPDATE_TIME"></nz-date-picker>
              </ng-template>
            </div>
          </div>
        </td>
      </tr> -->
    </table>
  </div>
  <!--  + ' ' + ('platform.spectrum.search.versionInfo' | translate) -->
  <nz-card class="mt-autoheight" *ngFor="let item of cards" [nzTitle]="item.parentModel.code">
    <table class="mt-table">
      <ng-container>
        <tr *ngFor="let subItem of item.childList">
          <th style="width: 20%">{{ getLanguageName(subItem) }}:</th>
          <td>
            <ng-container
              *ngIf="
                subItem.type === 'STRING' || subItem.type === 'NUMBER' || subItem.type === 'DATE';
                else other
              "
            >
              <div nz-row nzGutter="5">
                <div nz-col nzSpan="3">
                  <nz-select
                    [(ngModel)]="model[subItem.code + RELATION]"
                    nzAllowClear
                    (ngModelChange)="changeDateType(subItem.code)"
                    [nzPlaceHolder]="'platform.common.relation' | translate"
                  >
                    <nz-option
                      *ngFor="
                        let item of subItem.type === 'STRING'
                          ? stringSelectType
                          : numberOrDateSelectType
                      "
                      [nzValue]="item.value"
                      [nzLabel]="getSelectName(item)"
                    ></nz-option>
                  </nz-select>
                </div>
                <div nz-col nzSpan="18">
                  <ng-container [ngSwitch]="subItem.type">
                    <ng-container *ngSwitchCase="'DATE'">
                      <ng-container *ngIf="model[subItem.code + RELATION] === 'Between'">
                        <nz-range-picker [(ngModel)]="model[subItem.code]"></nz-range-picker>
                      </ng-container>
                      <ng-container *ngIf="model[subItem.code + RELATION] !== 'Between'">
                        <nz-date-picker [(ngModel)]="model[subItem.code]"></nz-date-picker>
                      </ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="'STRING'">
                      <div nz-row nzGutter="5">
                        <div nz-col nzSpan="8">
                          <input nz-input [(ngModel)]="model[subItem.code]" />
                        </div>
                      </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'NUMBER'">
                      <div nz-row nzGutter="5">
                        <div nz-col nzSpan="8">
                          <div
                            nz-row
                            *ngIf="model[subItem.code + RELATION] !== 'Between'; else numberBet"
                          >
                            <input
                              type="number"
                              min="1"
                              nz-input
                              [(ngModel)]="model[subItem.code]"
                            />
                          </div>
                          <ng-template #numberBet>
                            <div nz-col nzSpan="12">
                              <input
                                type="number"
                                min="1"
                                nz-input
                                [(ngModel)]="model[subItem.code + suffixs[0]]"
                              />
                            </div>
                            <div nz-col nzSpan="12">
                              <input
                                type="number"
                                min="1"
                                nz-input
                                [(ngModel)]="model[subItem.code + suffixs[1]]"
                              />
                            </div>
                          </ng-template>
                        </div>
                        <div nz-col nzSpan="4" *ngIf="subItem.extra2">
                          <nz-select style="width: 100%;" [(ngModel)]="model[subItem.code + UNIT]">
                            <nz-option
                              *ngFor="let m of subItem.extra1 | unitItems | async"
                              [nzValue]="m.code"
                              [nzLabel]="m.code"
                            ></nz-option>
                          </nz-select>
                        </div>
                      </div>
                    </ng-container>
                  </ng-container>
                </div>
              </div>
            </ng-container>
            <ng-template #other>
              <div style="width: 24.6%;" [ngSwitch]="subItem.type">
                <ng-container *ngSwitchCase="'BOOL'">
                  <nz-select
                    style="width: 50%;"
                    [(ngModel)]="model[subItem.code]"
                    nzAllowClear
                    nzMode="default"
                  >
                  <nz-option [nzValue]="true" [nzLabel]="'platform.common.yes' | translate"></nz-option>
                  <nz-option [nzValue]="false" [nzLabel]="'platform.common.no' | translate"></nz-option>
                  <nz-option [nzValue]="'null'" [nzLabel]="'platform.common.notSet' | translate"></nz-option>
                  </nz-select>
                </ng-container>
                <ng-container *ngSwitchCase="'LIST_OF_VIEW'">
                  <nz-select
                    style="width: 50%;"
                    [(ngModel)]="model[subItem.code]"
                    nzAllowClear
                    nzMode="default"
                  >
                    <nz-option
                      *ngFor="let m of subItem.extra1 | dictItems | async"
                      [nzValue]="m.code"
                      [nzLabel]="m.name"
                    ></nz-option>
                  </nz-select>
                </ng-container>
                <ng-container *ngSwitchCase="'RELATION'">
                  <mt-object-input
                    [mtModelCode]="'components'"
                    [(ngModel)]="model[subItem.code]"
                  ></mt-object-input>
                </ng-container>
                <ng-container *ngSwitchCase="'MODEL'">
                  <mt-object-model-input
                    [mtModelCode]="'components'"
                    [(ngModel)]="model[subItem.code]"
                  ></mt-object-model-input>
                </ng-container>
              </div>
            </ng-template>
          </td>
        </tr>
      </ng-container>
    </table>
  </nz-card>
  <div nz-row style="margin: 10px;" [class.btnRow]="model.OBJECT_TYPE">
    <button nz-button nzSize="defalut" nzType="primary" (click)="search()">
      {{ 'platform.common.default.query' | translate }}
    </button>
    <!-- <button nz-button nzSize="defalut" nzType="primary" class="btnLeft" (click)="openSaveDrawer()">
      {{ 'platform.spectrum.search.saveQueryCriteria' | translate }}
    </button>
    <button
      nz-button
      nzSize="defalut"
      nzType="primary"
      class="btnLeft"
      (click)="openSearchDrawer()"
    >
      {{ 'platform.spectrum.search.savedQueryCriteria' | translate }}
    </button> -->
    <button nz-button nzSize="defalut" nzType="primary" class="btnLeft" (click)="reset()">
      {{ 'platform.common.default.reset' | translate }}
    </button>
  </div>
  <!-- 撑高div -->
  <div style="height: 50px;" *ngIf="model.OBJECT_TYPE"></div>
  <!-- <i
    nz-icon
    nzType="up-circle"
    nzTheme="fill"
    nz-tooltip
    nzSize="large"
    [nzTooltipTitle]="'platform.common.toTop' | translate"
    nzTooltipPlacement="top"
    class="toTop"
  ></i> -->
</div>

<nz-drawer
  [nzWidth]="420"
  [nzClosable]="true"
  [nzVisible]="drawerSave.visible"
  nzPlacement="right"
  [nzTitle]="'platform.common.save' | translate"
  nzWrapClassName="mt-with-footer"
  (nzOnClose)="closeSaveDrawer()"
>
  <div nz-row>
    <div nz-col nzSpan="8">{{ 'platform.spectrum.search.versionName' | translate }}：</div>
    <div nz-col nzSpan="16">
      <input
        nz-input
        [(ngModel)]="drawerSave.saveName"
        (ngModelChange)="changeName($event)"
        [placeholder]="'platform.core.default.pleaseInput' | translate"
      />
    </div>
  </div>
  <div class="mt-drawer-footer">
    <button (click)="closeSaveDrawer()" type="button" nz-button nzShape="round">
      <i nz-icon nzType="close"></i>{{ 'platform.common.cancel' | translate }}
    </button>
    <button
      class="btnLeft"
      nz-button
      nzShape="round"
      nzType="primary"
      type="button"
      (click)="closeSaveDrawer(true)"
      [disabled]="drawerSave.disabled"
    >
      <i nz-icon nzType="check"></i>{{ 'platform.common.save' | translate }}
    </button>
  </div>
</nz-drawer>
<nz-drawer
  [nzWidth]="420"
  [nzClosable]="true"
  [nzVisible]="drawerSearch.visible"
  nzPlacement="right"
  [nzTitle]="'platform.common.default.query' | translate"
  nzWrapClassName="mt-with-footer"
  (nzOnClose)="closeSearchDrawer()"
>
  <div nz-row>
    <div nz-col nzSpan="10">{{ 'platform.spectrum.search.selectSaveCondition' | translate }}：</div>
    <div nz-col nzSpan="14">
      <nz-select
        style="width: 100%;"
        [(ngModel)]="drawerSearch.selectVersion"
        (ngModelChange)="selectChage($event)"
        nzAllowClear
        [nzLoading]="drawerSearch.loading"
        [nzPlaceHolder]="'platform.core.default.pleaseSelect' | translate"
      >
        <nz-option
          *ngFor="let item of vsesions"
          [nzValue]="item"
          [nzLabel]="item.profileName"
        ></nz-option>
      </nz-select>
    </div>
  </div>
  <div class="mt-drawer-footer">
    <button (click)="closeSearchDrawer()" type="button" nz-button nzShape="round">
      <i nz-icon nzType="close"></i>{{ 'platform.common.cancel' | translate }}
    </button>
    <button
      class="btnLeft"
      nz-button
      nzShape="round"
      nzType="primary"
      type="button"
      (click)="closeSearchDrawer(true)"
      [disabled]="drawerSearch.disabled"
    >
      <i nz-icon nzType="check"></i>{{ 'platform.common.confirm' | translate }}
    </button>
  </div>
</nz-drawer>
