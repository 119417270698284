import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MtConfigService, MtWithConfig } from '@imerge22/platform/base';
import { map } from 'rxjs/operators';
import { MtSafeAny } from '@imerge22/core';
@Injectable({
  providedIn: 'root'
})
export class EncodingService {

  @MtWithConfig() private spectrumServiceName: string;
  @MtWithConfig() private encodingServiceName: string;
  constructor(public http: HttpClient, public mtConfigSrv: MtConfigService) { }

  /**
   * 生成码值
   */
  generateEncoding() {
    return this.http.get(`srv://${this.spectrumServiceName}/encoding/ecr`);
  }

  /**
   * 获取更改单号
   */
  getChangeOrder() {
    return this.http.get(`srv://${this.spectrumServiceName}/encoding/changeOrder`).pipe(
      map((resp: MtSafeAny) => {
        return resp;
      })
    );
  }

  /**
   * 上报表单申请编号指派
   */
  getReportedCode() {
    return this.http.get(`srv://${this.spectrumServiceName}/encoding/ecr/reported`);
  }

  // 删除
  /**
 * 根据系统和规则类名查询编码规则
 * @param system 系统名称
 * @param ruleClass 规则类名
 * @returns 编码信息，如：{data: "0001", success: true}
 */
  generateEncodingByRuleClass(system: string, ruleClass: string) {
    return this.http.post(`srv://${this.encodingServiceName}/encoding/getEncodingCode`, {
      "systemKey": system,
      "classNameKey": ruleClass
    });
  }

}
