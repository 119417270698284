import { Injectable } from '@angular/core';
import { MtDynamicTabHandler, MtDynamicTabRegister } from '@imerge22/common';
import { MtEvent, MtSafeAny, MtMap } from '@imerge22/core';
import { SuperQueryResultActivateEvent } from './events';
import { SuperQueryResultComponent } from './super-query-result.component';

@Injectable({ providedIn: 'root' })
export class SuperQueryResultTabHandler extends MtDynamicTabHandler {
  match(context: string, event: MtEvent<MtSafeAny>): boolean {
    if (event.name === SuperQueryResultActivateEvent.$name) {
      const data = SuperQueryResultActivateEvent.get(event);
      return data?.context === context;
    }
    return false;
  }

  same(context: string, exist: MtMap, event: MtEvent<MtSafeAny>): boolean {
    if (event.name === SuperQueryResultActivateEvent.$name) {
      const data = SuperQueryResultActivateEvent.get(event);
      return data?.context === context && data?.extra?.titleParam?.count === exist?.extra?.titleParam?.count;
    }
    return false;
  }
}

export function registerSuperQueryResultDynamicTab() {
  MtDynamicTabRegister.register(SuperQueryResultActivateEvent.$name, {
    component: SuperQueryResultComponent,
    handler: SuperQueryResultTabHandler
  });
}
