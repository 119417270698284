import { MtEvent, MtEventBus, MtSafeAny } from '@imerge22/core';
import { MtDynamicTabsetActivateEventData } from '@imerge22/common';

interface ProjectHoursAccountingActivateEventData extends MtDynamicTabsetActivateEventData{
  id?: string;
  type?: string;
  extra?: MtSafeAny;
}

export class ProjectHoursAccountingActivateEvent {
  static $name = 'project-hours-accounting-activate';
  static emit(data: ProjectHoursAccountingActivateEventData) {
    MtEventBus.emit({
      name: ProjectHoursAccountingActivateEvent.$name,
      data,
    });
  }

  static is(event: MtEvent<MtSafeAny>): boolean {
    return event != null && event.name === ProjectHoursAccountingActivateEvent.$name;
  }

  static get(event: MtEvent<MtSafeAny>): ProjectHoursAccountingActivateEventData {
    if (ProjectHoursAccountingActivateEvent.is(event)) {
      return event.data;
    }
    return undefined;
  }
}
