<ng-container *ngIf="!dataBinding.editing; else selectProject">
    {{ currentProjectName }}
</ng-container>
<ng-template #selectProject>
    <nz-select
        nzServerSearch
        nzPlaceHolder="请选择项目"
        [(ngModel)]="selectProjectMuid"
        [nzShowArrow]="false"
        (ngModelChange)="setProjectMuid($event)"
    >
        <nz-option *ngFor="let o of projectOption" [nzLabel]="o.projectLabel" [nzValue]="o?.muid"></nz-option>
    </nz-select>
</ng-template>
