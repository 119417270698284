import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GenericQueryService, MtConfigService, MtSafeAny, MtWithConfig, PageRequest, SortDirection } from '@imerge22/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PanelService } from 'src/app/services/panel.service';

@Injectable({
  providedIn: 'root'
})
export class EcnService {
  constructor(
    private http: HttpClient,
    private querySrv: GenericQueryService,
    public mtConfigSrv: MtConfigService,
    private panelSvc: PanelService,
  ) { };

  followList =  null;
  followList$ = new BehaviorSubject(null)

  @MtWithConfig() private coreServiceName: string;
  @MtWithConfig() private spectrumServiceName: string;

  getECNList(page: PageRequest, query?: any[],sort?:any[]): Observable<MtSafeAny> {
    const date = (new Date()).valueOf();
    if(!(sort && sort.length > 0)) sort = [{ prop: 'CREATE_TIME', dir: SortDirection.Descend }];
    return this.http
      .post(`srv://${this.spectrumServiceName}/mongodb/common/query`, {
        modelId: 'ECNRevision',
        conditions: {
          filters: query
        },
        page: {
          skip: (page.index - 1) * page.size,
          take: page.size,
          'verifyStart':1,
          'verifyEnd':10000
        },
        sorts: sort,
        cacheable: false,
        cascades: null,
        queryId:date
      });
  }

  getFollowedECNList(query?: any[]): Observable<MtSafeAny> {
    const res = this.querySrv.query<MtSafeAny>({
      modelId: 'UserFollow',
      dataSource: this.spectrumServiceName,
      // cascades: ['EWO'],
      conditions: {
        filters: query,
      },
      //sorts: [{ prop: 'objectName', dir: SortDirection.Descend }],
    }).pipe(map(item=>{
      if(item.resultData.data || item.resultData.data.length > 0){
        let datas = item.resultData.data || [];
        datas = datas.filter(item=>item.followType.toUpperCase() === 'ECN')
        let ids = [];
        if(datas.length > 0){
          ids = datas.map(item=>item.followObjId)
        }
        this.followList = ids;
        this.followList$.next(this.followList);

        return ids;
      }


      return item;

    }));
    return res;
  }


  attentionChange(params: MtSafeAny): Observable<MtSafeAny> {
    return this.http.post(`srv://${this.spectrumServiceName}/user/userFollow`, params);
  }
}
