<div class="mt-root-column">
  <form class="mt-form-query" nz-form [formGroup]="querier" [nzLayout]="'inline'">
    <!-- 流程名称 -->
    <nz-form-item>
      <nz-form-control>
        <input
          nz-input
          formControlName="name"
          [placeholder]="'platform.base.home.processInsName' | translate"
        />
      </nz-form-control>
    </nz-form-item>
    <!-- 流程状态 -->
    <!-- <nz-form-item>
      <nz-form-control>
        <nz-select
          formControlName="status"
          nzAllowClear
          [nzPlaceHolder]="'platform.base.home.processStatus' | translate"
        >
          <nz-option
            *ngFor="let item of workflowStatus"
            [nzValue]="item.code"
            [nzLabel]="getLanguageName(item)"
          ></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item> -->
    <!-- 开始结束时间 -->

    <nz-form-item id="date-picker">
      <nz-form-control>
        <nz-range-picker
          nzSize="default"
          formControlName="startTime"
          style="width: 100%; height: 32px"
          [nzPlaceHolder]="['开始日期', '开始日期']"
        ></nz-range-picker>
      </nz-form-control>
    </nz-form-item>

    <!-- 查询重置按钮 -->
    <nz-form-item>
      <nz-form-control class="mt-btns">
        <button nz-button nzType="primary" (click)="search()">
          <i nz-icon nzType="search"></i>
          {{ 'platform.core.dict.query' | translate }}
        </button>
        <button nz-button nzType="primary" (click)="reload()">
          <i nz-icon nzType="reload"></i>
          {{ 'platform.core.dict.reset' | translate }}
        </button>

        <button 
          type="button"
          nz-button
          nzType="primary"
          [disabled]="!setOfCheckedId.size"
          nz-popconfirm
          nzPopconfirmTitle="是否确定审核通过?"
          (nzOnConfirm)="approveSubmit()"
          [nzLoading]="loading"
          nzPopconfirmPlacement="left"
        >
          {{ 'koa.workflow.approveProcess' | translate }}
        </button>
      </nz-form-control>
    </nz-form-item>
  </form>
  <nz-table
    [nzData]="datas.data"
    [nzFrontPagination]="false"
    [nzTotal]="datas.total"
    [(nzPageIndex)]="page.index"
    [(nzPageSize)]="page.size"
    [nzBordered]="true"
    [nzShowTotal]="showTotal"
    nzSize="small"
    [nzShowSizeChanger]="true"
    [nzLoadingDelay]="100"
    (nzPageIndexChange)="searchData()"
    (nzPageSizeChange)="searchData()"
    [nzScroll]="getStyle()"
    nzBordered
  >
    <thead>
      <tr>
        <th
          nzWidth="30px"
          class="check-th"
          [nzChecked]="checked"
          (nzCheckedChange)="onAllChecked($event)"
          nzLeft
        ></th>
        <!-- 流程实例名 -->
        <th nzLeft [nzWidth]="'150px'" class="mt-center">
          {{ 'platform.base.home.processInsName' | translate }}
        </th>
        <!-- 员工	-->
        <th nzLeft class="mt-center" [nzWidth]="'80px'">
          员工
        </th>
        <!-- 人天 -->
        <th nzLeft class="mt-center" [nzWidth]="'50px'">
          人天
        </th>
        <!-- 员工部门 -->
        <th class="mt-center" [nzWidth]="'100px'">
          员工部门
        </th>
        <!-- 项目	 -->
        <th class="mt-center" [nzWidth]="'150px'">
          项目	
        </th>
        <!-- 里程碑阶段		 -->
        <th class="mt-center" [nzWidth]="'150px'">
          里程碑阶段
        </th>
         <!-- 工时日期 -->
         <th class="mt-center" [nzWidth]="'100px'">
          工时日期
        </th>
        <!-- 是否客户现场	 -->
        <th class="mt-center" [nzWidth]="'100px'">
          是否客户现场
        </th>
        <!-- 工作内容 -->
        <th class="mt-center" [nzWidth]="'300px'">
          工作内容
        </th>
        <!-- 发起时间 -->
        <th class="mt-center" [nzWidth]="'150px'">
          发起时间
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of datas.data">
        <td
        class="check-td"
          [nzChecked]="setOfCheckedId.has(item.id)"
          [nzDisabled]="item.procIns.status !== 'Processing'"
          (nzCheckedChange)="onItemChecked(item.id, $event)"
          nzLeft
        ></td>
        <td nzLeft class="mt-center">
          <span class="mt-link" (click)="openWorkflowDetail(item)">
            {{ item?.procIns?.name }}
          </span>
        </td>
        <td nzLeft class="mt-center">{{ item?.workHoursObject?.eid | userSync: '${employeeNo}-${name}' }}</td>
        <td nzLeft class="mt-center">{{ item?.workHoursObject?.value?.prjhours }}<span *ngIf="item?.workHoursObject?.value?.prjhours">天</span></td>
        <td class="mt-center">{{ item?.workHoursObject?.value?.depName }}</td>
        <td class="mt-center">{{ item?.workHoursObject?.projectname }}</td>
        <td class="mt-center">{{ item?.workHoursObject?.value?.milestone }}</td>
        <td class="mt-center">{{ item?.workHoursObject?.value?.workdate | translateTime: 'yyyy-MM-DD' }}</td>
        <td class="mt-center">{{ item?.workHoursObject?.value?.workcheck | dictItemName: 'Yes_or_no' | async }}</td>
        <td class="mt-center">{{ item?.workHoursObject?.value?.comment }}</td>
        <td class="mt-center">{{ item.startTime | translateTime: 'yyyy-MM-DD HH:mm:ss' }}</td>
      </tr>
    </tbody>
  </nz-table>

  <!-- <mt-common-table
    class="my-workflow-table"
    #tablePCN
    [datasources]="'rbac'"
    [queryProps]="queryProps"
    [queryDatas]="queryDatas"
    defaultColumnWidth="200"
  ></mt-common-table> -->
</div>
<ng-template #showTotal let-range="range" let-total>
  {{ 'platform.core.dict.total' | translate: { total: total, range0: range[0], range1: range[1] } }}
</ng-template>


<ng-template #tabTitle>
  {{ 'platform.base.dashboard.taskTodo' | translate }}
</ng-template>
