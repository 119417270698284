import { Component, EventEmitter, OnInit, Optional, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MtDictCacheService, UserServiceService } from '@imerge22/base';
import { MtContextDirective, MtDynamicTabBase } from '@imerge22/common';
import {
  FilterOperator,
  MtMap,
  MtNotificationBus,
  MtSafeAny,
  MtTranslateService,
  PageRequest,
  PassportService,
} from '@imerge22/core';
import { MtDataSourceService, MtObjectComponentBase } from '@imerge22/platform/base';
import {
  MtObjectData,
  MtSpectrumDataService,
  MtSpectrumDefineService,
  MtSpectrumSecurityService,
} from '@imerge22/spectrum';
import { NzModalService } from 'ng-zorro-antd/modal';
import { MakeUpWorkHoursService } from '../services/make-up-work-hours.service';
// import { MtWorkflowStarterComponent } from '../../starter';

import { GetModelByCodeService } from '../../../common/services/get-model-by-code.service';
import { MakeUpWorkHoursModalComponent } from '../make-up-work-hours-modal/make-up-work-hours-modal.component';
import { MakeUpWorkHoursActivateEvent } from '../make-up-work-hours/events';

@Component({
  templateUrl: './make-up-work-hours-list.component.html',
  styleUrls: ['./make-up-work-hours-list.component.scss'],
})
export class MakeUpWorkHoursListComponent
  extends MtObjectComponentBase
  implements MtDynamicTabBase, OnInit, MtObjectComponentBase {
  // @ViewChild('workflowStarter', { static: true }) flowStart: MtWorkflowStarterComponent;

  // 配置tab是否显示关闭按钮
  shieldCloseBtn = true;
  // 对象状态列表
  statusCodeList = [];
  // 搜索用户
  isSearchUserLoading = false;
  isUserLoading = false;
  checked = false;
  userList = [];
  userTotal = 0;
  querier: FormGroup;
  page = { pageNo: 1, pageSize: 10 };
  userPage: PageRequest = { index: 1, size: 10 };
  datas = { data: [], total: 0, totalPages: 0 };
  // 防抖
  searchNameShakeProof = null;
  // 存放选中的id
  setOfCheckedId = new Set<MtSafeAny>();
  public dynamicDataArr: Array<MtSafeAny> = [];
  public currentUserId: string = '';
  private currentUserSelectItem: MtSafeAny;
  constructor(
    defSvc: MtSpectrumDefineService,
    dataSvc: MtSpectrumDataService,
    secSvc: MtSpectrumSecurityService,
    private MakeUpWorkHoursService: MakeUpWorkHoursService,
    private fb: FormBuilder,
    private userSrv: UserServiceService,
    private modal: NzModalService,
    public srv: MtDataSourceService,
    private dictSrv: MtDictCacheService,
    private translate: MtTranslateService,
    private passport: PassportService,
    private getModelByCode: GetModelByCodeService,
    @Optional() public data: MtObjectData,
    @Optional() private context: MtContextDirective
  ) {
    super(defSvc, dataSvc, secSvc);
  }
  @ViewChild('tabTitle') title: TemplateRef<void>;
  onCloseByInner = new EventEmitter<void>();
  onActivated(): void {}
  onDeactivated(): void {}
  onDataChange(data: MtMap): void {}
  activate(): void {}
  deactivate(): void {}
  ngOnInit(): void {
    this.querier = this.fb.group({
      projectCode: [null],
      projectName: [null],
      depId: [null],
      time: [null],
    });
    this.dictSrv.getItems('Object_state').subscribe((res) => {
      this.statusCodeList = res as MtSafeAny;
    });
    this.currentUserId = this.passport.current.user.id;
    this.getModalInfo();
    this.searchData();
  }
  // 批量删除
  onBatchDelClick() {
    const params = [];
    this.deleteObj([...this.setOfCheckedId].map((item) => item.muid));
    return;
  }
  // 选择单个的对像
  onItemChecked(item: MtSafeAny, checked: boolean): void {
    this.updateCheckedSet(item, checked);
    this.refreshCheckedStatus();
    this.checkRevisionObjects(item.revisionObjects, checked);
  }
  checkRevisionObjects(list: MtSafeAny[], checked: boolean) {
    if (list) {
      list.forEach((res, i) => {
        res.disabled = checked;
        this.updateCheckedSet(res, checked);
        if (i === list.length - 1) {
          this.refreshCheckedStatus();
        }
      });
    }
  }
  // 全选触发
  onAllChecked(checked: boolean): void {
    this.datas.data.forEach((item) => {
      this.updateCheckedSet(item, checked);
      this.checkRevisionObjects(item.revisionObjects, checked);
    });
    this.refreshCheckedStatus();
  }
  updateCheckedSet(item: MtSafeAny, checked: boolean): void {
    if (checked && item.userId == this.currentUserId) {
      this.setOfCheckedId.add(item);
      this.currentUserSelectItem = item;
    } else {
      this.setOfCheckedId.delete(item);
    }
  }
  refreshCheckedStatus(): void {
    this.checked = this.datas.data.every((res) => this.setOfCheckedId.has(res));
  }
  onUserSearch(value: string): void {
    if (this.searchNameShakeProof !== null) {
      clearTimeout(this.searchNameShakeProof);
    }
    this.searchNameShakeProof = setTimeout(() => {
      this.isSearchUserLoading = true;
      this.userPage.index = 1;
      this.userTotal = 0;
      this.userList = [];
      this.loadMoreUser(value);
    }, 600);
  }

  loadMoreUser(value?: string) {
    this.isUserLoading = true;
    if (this.userTotal > this.userPage.index * this.userPage.size || !this.userTotal) {
      const filter = [];
      if (value) {
        filter.push({ prop: 'name', operate: FilterOperator.Like, value });
        filter.push({ prop: 'account', operate: FilterOperator.Like, value });
      }
      this.userSrv.getUsers(filter, this.userPage).subscribe(
        (res: MtSafeAny) => {
          this.isSearchUserLoading = false;
          this.isUserLoading = false;
          this.userList = [...this.userList, ...res.datas];
          this.userPage.index += 1;
          this.userTotal = res.total;
        },
        () => {
          this.isSearchUserLoading = false;
          this.isUserLoading = false;
        }
      );
    }
  }
  reload() {
    this.setOfCheckedId = null;
    this.setOfCheckedId = new Set<string>(); // 已选择的id
    // 清空输入框
    this.querier.reset();
    // 将查询条件恢复默认
    this.page = { pageNo: 1, pageSize: 10 };
    this.searchData();
  }

  searchData() {
    let userId: string = this.passport.current.user.id;
    // 如果输入框有值就按照输入框查询
    this.MakeUpWorkHoursService.getMakeUpWorkHoursList(this.page).subscribe((res: MtSafeAny) => {
      if (!res.success) {
        return;
      }
      console.log(222222, res, this.page)
      this.datas.data = res.resultData.data;
      this.datas.total = res.resultData.total;
      this.refreshCheckedStatus();
    });
  }
  search() {
    this.page.pageNo = 1;
    this.searchData();
  }
  deleteObj(item: string[]) {
    this.secSvc.delObj(item).subscribe((res) => {
      if (res.success) {
        MtNotificationBus.emit({
          type: 'success',
          title: 'i18n.platform.notification.success.success',
          message: 'i18n.platform.notification.success.deleteSuccess',
        });
        this.setOfCheckedId = null;
        this.setOfCheckedId = new Set<string>(); // 已选择的id
        this.searchData();
      }
    });
  }
  onStartProcessClick(item) {
    const parameter = [
      {
        objects: [
          {
            objectId: item.id,
            target: true,
            serial: 1,
          },
        ],
      },
    ];
    // this.flowStart.initName(item.code + '-' + item.name);
    // this.flowStart.open(parameter).subscribe(res => {
    //   if (res) {
    //     this.searchData();
    //   }
    // });
  }
  openModal(detail?) {
    let params = {};
    if (this.currentUserSelectItem) {
      params = { ...this.currentUserSelectItem };
    } else {
      // for (const item of this.dynamicDataArr) {
      //   params[item.key] = null;
      // };
    }
    console.log('传入model的动态参数params', this.dynamicDataArr);
    let detailObj = [];
    let filterArr: Array<string> = [
      'eid',
      'depid',
      'prjid',
      'milestone',
      'workdate',
      'comment',
      'workcheck',
      'prjhours',
    ];
    for (let index = 0; index < this.dynamicDataArr.length; index++) {
      const item = this.dynamicDataArr[index];
      if (filterArr.indexOf(item.key) !== -1) {
        detailObj.push(item);
      }
    }
    const drawerRef = this.modal.create({
      nzContent: MakeUpWorkHoursModalComponent,
      nzComponentParams: {
        detail: detailObj,
        params: params,
        // modalTitle: this.translate.currentLang.startsWith('en') ? 'Add Customer Info' : '新增客户信息'
      },
      nzFooter: null,
      nzWidth: '75%',
      nzBodyStyle: { height: document.body.clientHeight - 75 + 'px', padding: '0px' },
      nzClosable: false,
      nzMaskClosable: false,
      nzStyle: { top: '40px' },
    });
    drawerRef.afterClose.subscribe((res) => {
      if (res) {
        if (detail) {
          this.setOfCheckedId = null;
          this.setOfCheckedId = new Set<string>(); // 已选择的id
          this.searchData();
        } else {
          this.reload();
        }
      }
    });
  }

  isEn() {
    return this.translate.currentLang.startsWith('en');
  }
  transferStatus(status: string) {
    let statusName = '';
    if (status) {
      const list = status.split(',');
      const objList = this.statusCodeList.filter((item) => list.includes(item.code));
      objList.forEach((item, i) => {
        statusName = statusName + (this.isEn() ? item.code : item.name);
        if (i !== objList.length - 1) {
          statusName += ',';
        }
      });
    }
    return statusName;
  }
  goToDetail(item) {
    MakeUpWorkHoursActivateEvent.emit({
      context: 'MakeUpWorkHours',
      id: item?.value?._id,
      type: item?.value?.OBJECT_TYPE,
      extra: {
        ...item?.value,
      },
    });
  }

  private currentUserIsAdmin(): boolean {
    const userInfo = JSON.parse(localStorage.getItem('user_info'));
    if (
      userInfo.roleRels.filter((item) => item == 'INIT_GROUP_ROLE' || item == 'INIT_ROLE ').length >
      0
    ) {
      return true;
    } else {
      return false;
    }
  }
  private getCurrentUserIsDepartmentHeadOrgId(): Array<string> {
    const userInfo = JSON.parse(localStorage.getItem('user_info'));
    let index = userInfo.roleRels.findIndex((item) => item == 'r0RM8YjjSOSk8ASPLnxgFQ');
    if (index !== -1) {
      return userInfo.orgRels;
    } else {
      return [];
    }
  }

  private getModalInfo(): void {
    this.dynamicDataArr = [];
    const isEnLang: boolean = this.translate.currentLang.startsWith('en');
    const modelCode: string = 'k8_manHourRevision';
    const filleterModelArr: Array<string> = [
      'eid',
      'depid',
      'prjid',
      'milestone',
      'workdate',
      'workcheck',
      'prjhours',
      'comment',
      'SUBMIT',
      'SUBMITBY',
      'SUBMITTIME',
    ];
    let modelInfoList = [];
    this.getModelByCode.getModelByCode(modelCode).subscribe((res: MtSafeAny) => {
      if (!res || res.length < 1) {
        return;
      }
      modelInfoList = res;
      for (const key of filleterModelArr) {
        let basicInfo: any;
        modelInfoList.forEach((item) => {
          if (item.code == key) {
            basicInfo = {
              key: key,
              label: isEnLang ? item?.nameEn : item?.name,
              content: null,
              type: item?.type,
              seq: item?.seq,
              descEn: item?.descEn,
              arrayed: item?.arrayed,
              required: item?.validate?.required,
            };
            this.dynamicDataArr.push(basicInfo);
          }
        });
      }
    });
  }
}
