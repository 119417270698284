import { Component, EventEmitter, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import moment from 'moment';
import { NzModalService } from 'ng-zorro-antd/modal';
import { debounceTime } from 'rxjs/operators';

import { CommonUtils, FilterOperator, GenericQueryService, MtMap, MtSafeAny, MtTranslateService, Page, PageRequest, PassportService } from '@imerge22/core';
import { MtObjectComponentBase, MtSpectrumDataService, MtSpectrumDefineService, MtSpectrumSecurityService } from '@imerge22/spectrum';
import { SystemParameter, UserServiceService } from '@imerge22/base';
import { MtDynamicTabBase } from '@imerge22/common';

import { EwoService } from 'src/app/services/ewo.service';
import { OrganizationService } from 'src/app/services/organization.service';
import { PanelService } from 'src/app/services/panel.service';
import { Sort } from '../../model';
import { EwoDetailComponent } from '../ewo-detail/ewo-detail.component';
import { EwoDetailActivateEvent } from '../ewo-detail/events';
import { ObjectDto } from '@imerge22/platform/base';

@Component({
  selector: 'yc-ewo-follow',
  templateUrl: './ewo-follow.component.html',
  styleUrls: ['./ewo-follow.component.scss'],
})

export class EwoFollowComponent extends MtObjectComponentBase implements OnInit, MtDynamicTabBase {

  constructor(
    defSvc: MtSpectrumDefineService,
    dataSvc: MtSpectrumDataService,
    secSvc: MtSpectrumSecurityService,
    private querySrv: GenericQueryService,
    private fb: FormBuilder,
    private translate: MtTranslateService,
    private passport: PassportService,
    private userSrv: UserServiceService,
    private svc: EwoService,
    private panelSvc: PanelService,
    private modal: NzModalService,
    private orgSvc: OrganizationService,
  ) {
    super(defSvc, dataSvc, secSvc);
  }

  @ViewChild('tabTitle') title: TemplateRef<void>;
  onCloseByInner: EventEmitter<void>;
  /** 控制tab页不可关闭 */
  shieldCloseBtn = true;

  // 表格属性
  querier: FormGroup;
  page: PageRequest = { index: 1, size: 10 };
  datas: Page<MtSafeAny> = { data: [], total: 0, totalPages: 0 };
  pagenumber: number[] = [10, 20, 30, 50, 100];
  userOptions: string[] = [];
  orgList = [];
  row: any;
  select = true;
  params = [];
  sortOrder = {plan:null,createTime:null}
  sort = [];



  ngOnInit(): void {
    this.querier = this.fb.group({
      itemId: [null],
      account: [null],
      deptId: [null],
      createTime: [null],
      plannedCompleteDate: [null],
    });
    this.loadFollowList();
    this.orgSvc.getOrganizationList().subscribe(item => {
      this.orgList = item.resultData.data;
    });

  }

  onActivated(): void { }
  onDeactivated(): void { }
  onDataChange(data: MtMap<any>): void { }
  activate(): void { }
  deactivate(): void { }

  sortChange(type?){
    // for(let item in this.sortOrder){
    //   console.log(item);

    // }
    let params = {
      dir:Sort[this.sortOrder[type]],
      prop:null
    }
    if(type === 'plan'){
      params.prop = 'COMPLETE_TIME'
    }else if(type === 'createTime'){
      params.prop = 'CREATE_TIME'
    }
    this.sort = [params]
    this.search();
  }

  loadFollowList() {
    this.page = { index: 1, size: 10 }
    const params: MtSafeAny[] = [
      { prop: 'followUserId', operate: FilterOperator.Equals, value: this.passport.current.user.id },
      { prop: 'followType', operate: FilterOperator.Equals, value: 'EWO' },
    ];
    this.svc.getFollowedEWOList(params).subscribe(item => {
      if (item.resultData && item.resultData.data) {
        let datas = item.resultData.data || [];
        datas = datas.filter((res) => res.followType.toUpperCase() === 'EWO');
        let ids = [];
        if (datas.length > 0) {
          ids = datas.map((res) => res.followObjId);
        }
        this.search(ids);
      }
    });
  }
  search(ids: string[] = []) {
    const keys = Object.keys(this.querier.value);
    const value = Object.values(this.querier.value).some((item) => Boolean(item) == true);
    const queryParams = [...this.params];
    // 如果输入框有值就按照输入框条件查询 没值重置查询条件
    if (value) {
      keys.forEach((key) => {
        if (CommonUtils.isNotBlank(this.querier.value[key])) {
          // 将输入框的时间格式化
          if (this.querier.value.createTime && key === 'createTime') {
            this.querier.value.createTime[0].setHours(0, 0, 0, 0);
            queryParams.push({
              prop: 'CREATE_TIME',
              operate: FilterOperator.GreatThan,
              value: moment(this.querier.value.createTime[0]).format('YYYY-MM-DD') + ' 00:00:00',
            });
            this.querier.value.createTime[1].setHours(23, 59, 59, 999);
            queryParams.push({
              prop: 'CREATE_TIME',
              operate: FilterOperator.LessThan,
              value: moment(this.querier.value.createTime[1]).format('YYYY-MM-DD') + ' 23:59:59',
            });
          } else if (this.querier.value.plannedCompleteDate && key === 'plannedCompleteDate') {
            this.querier.value.plannedCompleteDate[0].setHours(0, 0, 0, 0);
            queryParams.push({
              prop: 'planned_complete_date',
              operate: FilterOperator.GreatThan,
              value: moment(this.querier.value.plannedCompleteDate[0]).format('YYYY-MM-DD') + ' 00:00:00',
            });
            this.querier.value.plannedCompleteDate[1].setHours(23, 59, 59, 999);
            queryParams.push({
              prop: 'planned_complete_date',
              operate: FilterOperator.LessThan,
              value: moment(this.querier.value.plannedCompleteDate[1]).format('YYYY-MM-DD') + ' 23:59:59',
            });
          } else if (this.querier.value.itemId && key === 'itemId'){
            queryParams.push({
              prop: 'ITEM_ID',
              operate: FilterOperator.Like,
              value: this.querier.value[key].trim(),
            });
          } else if (this.querier.value.account && key == 'account') {
            queryParams.push({
              prop: 'CREATOR',
              operate: FilterOperator.Equals,
              value: this.querier.value[key].trim(),
            });
          } else if (this.querier.value.deptId && key == 'deptId') {
            queryParams.push({
              prop: 'dept_id',
              operate: FilterOperator.Equals,
              value: this.querier.value[key].trim(),
            });
          }
        }
      });
    }
    if (ids.length < 1){
      return false;
    }
    const query = [
      { prop: '_id', operate: FilterOperator.In, values: ids },
      ...queryParams
    ];
    this.svc.getEWOList(this.page, query, this.sort).subscribe(itemRes => {
      let orglist = [];
      if (this.orgSvc.orgList && this.orgSvc.orgList.length > 0) {
        orglist = this.orgSvc.orgList;
      }

      this.datas.total = itemRes.resultData.total || 0;
      const data = [...itemRes.resultData.data];
      this.datas.data = data.map(item => {
        item.follow = true;
        item.loading = false;

        const orgName = this.orgList.find(orgItem => orgItem.id === item.dept_id);
        item.DEPT_NAME = orgName ? orgName.name : '--';
        return item;
      });
      const list = data.map(item => item.CREATOR);
      this.querySrv
      .query<ObjectDto>({
        modelId: 'User',
        dataSource: 'rbac',
        conditions: {
          filters: [{ prop: 'id', operate: FilterOperator.In, values: list }],
        },
      }).subscribe(item => {
        this.datas.data = [...data.map(it => {
          const user = item.resultData.data.find(i => i.id === it.CREATOR);
          return {
            ...it,
            CREATOR_NAME: `${user.account}/${user.name}`
          };
        })];
      });
    });
  }
  // 重置
  reload() {
    this.querier.reset();
    this.search();
  }
  // 关注开关回调
  switchchange(row) {
    console.log(row);
  }

  onInput(value: string): void {
    // const value = (event.target as HTMLInputElement).value;

    if (CommonUtils.isNotBlank(value)) {
      this.userOptions = value ? [value, value + value, value + value + value] : [];

      this.userSrv.selectUserPage({ searchKey: value, requestCount: 100 }).pipe(debounceTime(200)).subscribe(res => {
        if (res && res.success) {
          this.userOptions = [];

          for (const user of res.resultData.users) {
            this.userOptions.push(user);
          }
        }
      });
    }
  }

  //显示EWO详情
  showEwo(objId: string) {
    EwoDetailActivateEvent.emit({
      context: 'EWO',
      id: objId,
      type: 'EWORevision',
      extra: {
        mtModelCode: 'EWO',
        isAdd: false
      }
    });

    // this.modal
    //   .create({
    //     nzTitle: 'EWO详情',
    //     nzContent: EwoDetailComponent,
    //     nzComponentParams: {
    //       isAdd: false,
    //       mtModelCode: 'EWO',
    //       data: { id: objId, type: 'EWORevision' },
    //     },
    //     nzFooter: null,
    //     nzWidth: '90%',
    //     nzBodyStyle: { height: document.body.clientHeight - 75 + 'px' },
    //     nzMaskClosable: false,
    //     //nzClassName: 'mt-maximum',
    //     nzStyle: { top: '5px' },
    //   })
    //   .afterClose.subscribe((value) => {
    //     if (value) {
    //       this.search();
    //     }
    //   });
    // .afterClose.subscribe(() => {
    //   this.search();
    // });
  }

  oneItemChecked(row) {
    this.row = row
  }

  // 关注开关回调
  switchChange(item) {
    this.modal.confirm({
      nzTitle: '<i>是否确认取消关注</i>',
      // nzContent: '<b>Some descriptions</b>',
      nzOnOk: () => {
        let itemKey;
        this.datas.data = this.datas.data.map((dataItem, key) => {
          if (item._id === dataItem._id) {
            dataItem.loading = true;
            itemKey = key;

          }
          return dataItem;
        })
        const params = {
          followType: 'EWO',
          followObjId: item._id, //ewoid
          followUserId: this.passport.current.user.id,
          isFollow: !item.follow,
        };
        this.svc.changeFollow(params).subscribe((res) => {
          if (res.success) {
            this.datas.data = this.datas.data.filter(data => data._id !== item._id);
          }else{
            this.datas.data[itemKey].loading = false;
          }
        });
      }
    })
  }
}
