import { Injectable } from '@angular/core';

import { MtDynamicTabHandler, MtDynamicTabRegister } from '@imerge22/common';
import { MtEvent, MtSafeAny, MtMap } from '@imerge22/core';

import { EwoAllActivateEvent } from './events';
import { EwoAllComponent } from './ewo-all.component';

@Injectable({ providedIn: 'root' })
export class EwoAllHandler extends MtDynamicTabHandler {
  match(context: string, event: MtEvent<MtSafeAny>): boolean {
    if (event.name === EwoAllActivateEvent.$name) {
      const data = EwoAllActivateEvent.get(event);
      return data?.context === context;
    }
    return false;
  }

  same(context: string, exist: MtMap, event: MtEvent<MtSafeAny>): boolean {
    if (event.name === EwoAllActivateEvent.$name) {
      const data = EwoAllActivateEvent.get(event);
      return data?.context === context;
    }
    return false;
  }
}

export function registerEwoAllDynamicTab() {
  MtDynamicTabRegister.register(EwoAllActivateEvent.$name, {
    component: EwoAllComponent,
    handler: EwoAllHandler,
    initializeComponent: true,
  });
}
