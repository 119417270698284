import { MtEvent, MtEventBus, MtSafeAny } from '@imerge22/core';
import { MtDynamicTabsetActivateEventData } from '@imerge22/common';

interface CustomerInfoActivateEventData extends MtDynamicTabsetActivateEventData{
  id?: string;
  type?: string;
  extra?: MtSafeAny;
}

export class CustomerInfoActivateEvent {
  static $name = 'customer-info-detail-activate';
  static emit(data: CustomerInfoActivateEventData) {
    MtEventBus.emit({
      name: CustomerInfoActivateEvent.$name,
      data,
    });
  }

  static is(event: MtEvent<MtSafeAny>): boolean {
    return event != null && event.name === CustomerInfoActivateEvent.$name;
  }

  static get(event: MtEvent<MtSafeAny>): CustomerInfoActivateEventData {
    if (CustomerInfoActivateEvent.is(event)) {
      return event.data;
    }
    return undefined;
  }
}
