
import {
  MtWithConfig,
  GenericQueryService,
  MtConfigService,
  GenericQueryRequestBase,
} from '@imerge22/core';
import { Injectable } from '@angular/core';
import { MtSpectrumDataService } from '@imerge22/spectrum';

@Injectable({
  providedIn: 'root',
})
export class RelativeEcrService {
  @MtWithConfig() private spectrumServiceName: string;
  constructor(public mtConfigSrv: MtConfigService, public dataSrv: MtSpectrumDataService) {}

  // 查询所有ecrrevision的对象
  getEcr() {
    let userobj: GenericQueryRequestBase;
    userobj = {
      modelId: 'ECRRevision',
      conditions: {
        filters: [],
      },
      page: {
        take: 999999,
      },
    };
    return this.dataSrv.searchPropertyValuesByProperty(userobj);
  }

  // 查询所有ecrrevision的对象
  getEcrPack() {
    let userobj: GenericQueryRequestBase;
    userobj = {
      modelId: 'ECRPackRevision',
      conditions: {
        filters: [],
      },
      page: {
        take: 999999,
      },
    };
    return this.dataSrv.searchPropertyValuesByProperty(userobj);
  }

  // 查询所有ecrrevision的对象
  getEcrReported() {
    let userobj: GenericQueryRequestBase;
    userobj = {
      modelId: 'ECRReportedRevision',
      conditions: {
        filters: [],
      },
      page: {
        take: 999999,
      },
    };
    return this.dataSrv.searchPropertyValuesByProperty(userobj);
  }

  getTotle() {
        let userObj: GenericQueryRequestBase;
        userObj = {
          modelId: 'ECRRevision',
          conditions: {
            filters: [],
          },
        };
        return this.dataSrv.searchPropertyValuesByProperty(userObj);
      }
      getEcrModel(totle: number) {
        let userObj: GenericQueryRequestBase;
        userObj = {
          modelId: 'ECRRevision',
          conditions: {
            filters: [],
          },
          page: {
            take: totle,
          },
        };
        return this.dataSrv.searchPropertyValuesByProperty(userObj);
      }

  // 查询所有ecrrevision的对象
  getEcrs(take?: number) {
    let userobj: GenericQueryRequestBase;
    userobj = {
      modelId: 'ECRRevision',
      conditions: {
        filters: [],
      },
      page: {
        take,
      },
    };

    return this.dataSrv.searchPropertyValuesByProperty(userobj);
  }
}
