import { APP_CODE } from './app-code';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { MtCrossBrowserTabSessionStorage } from '@imerge22/core';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

let inited = false;
function init() {
  inited = true;

  console.log('Main Init.');

  if (environment.production) {
    enableProdMode();
  }

  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
}

MtCrossBrowserTabSessionStorage.event.subscribe((e) => {
  if (e.key === `${APP_CODE}-PASSPORT-RETRUN`) {
    console.log(`${APP_CODE}-PASSPORT-RETRUN: ${e.newValue}`);
    sessionStorage.setItem(`${APP_CODE}-PASSPORT`, e.newValue);
    init();
  }
});
console.log(`Send: ${APP_CODE}-PASSPORT-GET`);
MtCrossBrowserTabSessionStorage.emit(`${APP_CODE}-PASSPORT-GET`, 'true');

setTimeout(() => {
  if (!inited) {
    init();
  }
}, 100);
