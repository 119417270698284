<mt-table-render
  #tablePCN
  [queryProps]="queryProps"
  [queryDatas]="queryDatas"
  [showExportExcel]="true"
  [sortFilterCode]="'ITEM_ID'"
  [onlyShowBaseCol]="['_id', 'OBJECT_NAME', 'OBJECT_TYPE', 'ITEM_REVISION_ID', 'OWNER']"
  [pageSize]="pageSize"
>
</mt-table-render>
