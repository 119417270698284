import { Injectable, Type } from '@angular/core';
import { MtObjectPanelHandler } from '@imerge22/platform/object-context';
import { of, Observable } from 'rxjs';
import { MtDataSourceService, MtObjectData } from '@imerge22/platform/base';
import { map, tap } from 'rxjs/operators';
import { MtObjectDetailPanelHandler, MtObjectDetailContext, MtObjectPanelContext } from '@imerge22/spectrum';
import { PanelPBomComponent } from './panel-p-bom.component';

@Injectable({ providedIn: 'root' })
export class PanelPBomHandler implements MtObjectDetailPanelHandler {
  constructor(private srv: MtDataSourceService) { }

  getComponent(detailContext: MtObjectDetailContext): Observable<Type<MtObjectPanelContext>> {
    return of(PanelPBomComponent);
  }
}
