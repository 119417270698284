import { Injectable } from '@angular/core';
import { MtDynamicTabHandler, MtDynamicTabRegister } from '@imerge22/common';
import { MtEvent, MtSafeAny, MtMap } from '@imerge22/core';
import { ProjectActivateEvent } from './event';
import { ProjectComponent } from './project.component';

@Injectable({ providedIn: 'root' })
export class ProjectHandler extends MtDynamicTabHandler {
  match(context: string, event: MtEvent<MtSafeAny>): boolean {
    if (event.name === ProjectActivateEvent.$name) {
      const data = ProjectActivateEvent.get(event);
      return data?.context === context;
    }
    return false;
  }

  same(context: string, exist: MtMap, event: MtEvent<MtSafeAny>): boolean {
    return false;
  }
}

export function registerPfepProjectDynamicTab() {
  MtDynamicTabRegister.register(ProjectActivateEvent.$name, {
    component: ProjectComponent,
    handler: ProjectHandler,
  });
}
