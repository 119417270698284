import { Injectable, Type } from '@angular/core';
import { of, Observable } from 'rxjs';
import { MtObjectDetailContext, MtObjectDetailPanelHandler, MtObjectPanelContext } from '@imerge22/spectrum';
import { PanelEcnComponent } from './panel-ecn.component';

@Injectable({ providedIn: 'root' })
export class PanelEcnHandler implements MtObjectDetailPanelHandler {

  getComponent(detailContext: MtObjectDetailContext): Observable<Type<MtObjectPanelContext>> {
    return of(PanelEcnComponent);
  }

}
