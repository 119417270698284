<ng-container *ngIf="!dataBinding?.editing; else editingTpl">
  {{ dataBinding.value }}
  <!-- 当前value值为空，并且配置了属性的描述信息时 -->
  <div class="mt-desc-tips" *ngIf="isShowTips()">
    {{ getDesc() }}
  </div>
</ng-container>
<ng-template #editingTpl>
  <ng-container *ngIf="showInput() | async; else showText">
    <input nz-input [(ngModel)]="dataBinding.value" />
  </ng-container>
  <ng-template #showText>
    <div>{{ dataBinding.value }}</div>
  </ng-template>

  <button
    *ngIf="showBtn"
    style="margin-left: 10px"
    nz-button
    [disabled]="loading"
    nzType="default"
    nz-popconfirm
    nzPopconfirmPlacement="left"
    [nzPopconfirmTitle]="'root.ecrNo.ecrNoReconfirm' | translate"
    (nzOnConfirm)="getCode()"
  >
    <i nz-icon [nzType]="loading ? 'loading' : 'zoom-in'"></i>
    {{ 'ctrl.designate' | translate }}
  </button>
</ng-template>
