<mt-table-render
  #tablePCN
  [queryProps]="queryProps"
  [queryDatas]="queryDatas"
  [showExportExcel]="true"
  [onlyShowBaseCol]="['_id', 'OBJECT_NAME', 'OBJECT_TYPE', 'ITEM_REVISION_ID', 'OWNER']"
  [pageSize]="pageSize"
  [isComponentSearch]="isUaesQuery"
  [footer]="tableFooter"
>
  <ng-template #tableFooter>
    <div class="mt-table-footer">
      <button nz-button nzType="primary" (click)="copyObject()" [disabled]="!checkSelectedData()">
        <i nz-icon nzType="copy"></i>{{ 'platform.common.copy' | translate }}
      </button>
    </div>
  </ng-template>
</mt-table-render>

<nz-drawer
  [nzWidth]="420"
  [nzClosable]="true"
  [nzVisible]="selectFolderVisible"
  nzPlacement="right"
  [nzTitle]="'platform.spectrum.search.selectFolder' | translate"
  (nzOnClose)="selectFolderVisible = false"
  nzWrapClassName="mt-with-footer"
>
  <mt-workspace-tree
    style="height: 70%"
    (mtObjectSelected)="selectedCallBack($event)"
    mtIsFolderTree="true"
  ></mt-workspace-tree>
  <div class="mt-drawer-footer">
    <button type="button" nz-button nzShape="round" (click)="selectFolderVisible = false">
      <i nz-icon nzType="close"></i><span>{{ 'platform.core.dict.cancel' | translate }}</span>
    </button>
    <button
      nz-button
      nzShape="round"
      nzType="primary"
      type="button"
      (click)="complete()"
      [disabled]="!selectedFolder"
    >
      <i nz-icon nzType="check"></i> <span>{{ 'platform.core.dict.determine' | translate }}</span>
    </button>
  </div>
</nz-drawer>
