import { Component, OnInit, Optional } from '@angular/core';
import { EncodingService } from '../../handlers/encoding.service';
import { MtSafeAny } from '@imerge22/core';
import { MtSpectrumContrlBase, MtSpectrumControlRegister } from '@imerge22/spectrum/controls';
import { MtSpectrumDataBinding, MtSpectrumDataSource } from '@imerge22/spectrum/objects';
import { MtDictCacheService } from '@imerge22/base';
import { MtTranslateService } from '@imerge22/core/translate';

@Component({
    selector: 'uccs-code',
    template: `<div nz-row>
                <label  nz-col
                    nzSpan="6" *ngFor="let item of checkOptions ; index as i">
                <label
                    nz-checkbox
                    [(nzChecked)]="item.checked"
                    [disabled]="!dataBinding.editing"
                    [(ngModel)]="item.checked" (ngModelChange)="refreshStatus($event)">
                        {{ getLanguageName(item) }}
                    </label>
                    <input [(ngModel)]="item.inputval" (blur)="blur()" nz-input [disabled]="item.checked == !dataBinding.editing"
                    *ngIf="i > checkOptions.length - 2 && dataBinding.editing" nzSize="small" />

                    <input [(ngModel)]="item.inputval" nz-input [disabled]="true"
                    *ngIf="i > checkOptions.length - 2 && !dataBinding.editing" nzSize="small" />
                </label>
                </div> `,
    styleUrls: ['./ctrs.css'],
})
export class PackDictComponent extends MtSpectrumContrlBase<MtSafeAny> implements OnInit {
    constructor(
        @Optional() public dataBinding: MtSpectrumDataBinding,
        public codeSrv: EncodingService,
        private srv: MtDictCacheService,
        private translate: MtTranslateService,
        public mtSpectrumDataSource: MtSpectrumDataSource
    ) {
        super();
        this.init();
    }
    checkOptions = [];

    disabled = true;

    list = []; // 用来接收复选框选择的值
    inputval = '';
    initConfig(): void {
        // throw new Error("Method not implemented.");
    }
    ngOnInit() { }

    init() {
        // return;
        const data = this.dataBinding.value as MtSafeAny;
        this.srv.getItems('Package_implementation_phase').subscribe((res) => {
            let orther = {} as MtSafeAny;
            if (data === undefined || data === null) {
                for (const iterator of res) {
                    if (iterator.code === 'Other') {
                        orther = iterator;
                    } else {
                        this.checkOptions.push({ iterator, checked: false });
                    }
                }
                orther = { iterator: orther, checked: false, inputval: ''};
                this.checkOptions.push(orther);
                return;
            }
            let count = 0;
            let orthercount = 0;
            for (const iterator of res) {
                for (const iterator1 of data) {
                    if (iterator.code === iterator1) {
                        count ++;
                        if (iterator.code === 'Other') {
                            orthercount ++ ;
                        }
                    }
                    if (iterator.code === 'Other') {
                        orther = iterator;
                    }
                }
                if (iterator.code === 'Other') {
                } else {
                    if (count === 0) {
                        this.checkOptions.push({ iterator, checked: false });
                    } else {
                        this.checkOptions.push({ iterator, checked: true });
                        this.list.push(iterator.code);
                    }
                }
                count = 0;
            }
            if (orthercount === 0) {
                orther = { iterator: orther, checked: false, inputval: ''};
            } else {
                orther = { iterator: orther, checked: true, inputval: ''};
            }
            orther.inputval = this.mtSpectrumDataSource.getValue(this.mtSpectrumDataSource.mainObjectId, 'Orther_remark');
            this.checkOptions.push(orther);
        });
    }
    // 字典中英互换
    getLanguageName(column: MtSafeAny) {
        if (this.translate.currentLang.startsWith('en')) {
            return column.iterator.origin.nameEn || column.iterator.origin.name;
        } else {
            return column.iterator.origin.name;
        }
    }
    refreshStatus(e: MtSafeAny): void {
        this.list = [];   // list 一定要定义为局部变量
        this.checkOptions.forEach(data => {
            if ( data.checked === true) {
                this.list.push(data.iterator.code);
                if (data.iterator.code === 'Other') {
                    this.disabled = false;
                }
            } else {
                if (data.iterator.code === 'Other') {
                    this.disabled = true;
                }
            }
        });
        const inputval = this.checkOptions[this.checkOptions.length - 1].inputval;
        this.mtSpectrumDataSource.setValue(this.mtSpectrumDataSource.mainObjectId, 'Packaged_Phase', this.list);
        this.mtSpectrumDataSource.setValue(this.mtSpectrumDataSource.mainObjectId, 'Orther_remark', inputval);
    }
    blur() {
        const inputval = this.checkOptions[this.checkOptions.length - 1].inputval;
        this.mtSpectrumDataSource.setValue(this.mtSpectrumDataSource.mainObjectId, 'Packaged_Phase', this.list);
        this.mtSpectrumDataSource.setValue(this.mtSpectrumDataSource.mainObjectId, 'Orther_remark', inputval);
    }
}
export function registerPackDictCtrl() {
    MtSpectrumControlRegister.register('mtPackDict', PackDictComponent);
}
