import { Injectable } from '@angular/core';

import { MtDynamicTabHandler, MtDynamicTabRegister } from '@imerge22/common';
import { MtEvent, MtSafeAny, MtMap } from '@imerge22/core';

import { MyEwoActivateEvent } from './events';
import { MyEwoComponent } from './my-ewo.component';

@Injectable({ providedIn: 'root' })
export class MyEwoHandler extends MtDynamicTabHandler {
  match(context: string, event: MtEvent<MtSafeAny>): boolean {
    if (event.name === MyEwoActivateEvent.$name) {
      const data = MyEwoActivateEvent.get(event);
      return data?.context === context;
    }
    return false;
  }

  same(context: string, exist: MtMap, event: MtEvent<MtSafeAny>): boolean {
    if (event.name === MyEwoActivateEvent.$name) {
      const data = MyEwoActivateEvent.get(event);
      return data?.context === context;
    }
    return false;
  }
}

export function registerMyEwoDynamicTab() {
  MtDynamicTabRegister.register(MyEwoActivateEvent.$name, {
    component: MyEwoComponent,
    handler: MyEwoHandler,
    initializeComponent: true,
  });
}
