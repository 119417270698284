import { Injectable } from '@angular/core';
import { IMtDataBindingAction, MtSafeAny } from '@imerge22/core';
import { MtSpectrumDataBinding, MtSpectrumDataItem } from '@imerge22/spectrum/objects';

@Injectable()
export class ECRNoDataBindingAction implements IMtDataBindingAction<MtSpectrumDataItem, MtSafeAny> {
  constructor(public dataBinding: MtSpectrumDataBinding) {}

  inited() {
    if (this.dataBinding.value == null) {
      this.dataBinding.value = 'example';
    }
  }
}
