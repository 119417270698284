import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    FilterOperator,
    GenericQueryService, MtConfigService,
    MtNotificationBus, MtSafeAny, MtWithConfig,
    PageRequest, ProcessInsTask, ProcessTaskStatus, SortDirection
} from '@imerge22/core';
import { Observable, of } from 'rxjs';
import { delay, map, repeatWhen, switchMap, takeWhile } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class RepeatSubmitProcessService {
    @MtWithConfig() private workflowServiceName: string;
    @MtWithConfig() private spectrumServiceName: string;
    @MtWithConfig() private rbacServiceName: string;
    public constructor(
        public mtConfigSrv: MtConfigService,
        private http: HttpClient,
        private querySrv: GenericQueryService,
    ) {}
    public repeatSubmit(data: Array<any>): Observable<any> {
        let count: number = 0;
        let errorMessage;
        const source = new Observable<any>(observer => {
            this.handleTask(data[count].id, data[count].reqData).subscribe(item => {
                count ++; // 模拟拿到数据,并修改状态，例如 进度条进度 todo....
                observer.complete(); // 请求完就complete，触发repeatWhen
            }, error => {
                // count ++; // 模拟拿到数据,并修改状态，例如 进度条进度 todo....
                // observer.complete(); // 请求完就complete，触发repeatWhen
                if (error?.countResultLessZero) {
                  MtNotificationBus.emit({
                      type: 'error',
                      title: 'i18n.platform.common.error',
                      message: error?.countResultLessZero.join(','),
                  });
                }
                errorMessage = 1;
                observer.error();
                throw error;
            });
        });
        return source.pipe(
            repeatWhen((notifications) => {
                return notifications.pipe(
                    delay(1000),
                    takeWhile(() => {
                        if (count > data.length - 1) {
                            MtNotificationBus.emit({
                                type: 'success',
                                title: 'i18n.platform.common.success',
                                message: 'i18n.platform.notification.success.submitSuccesss',
                            });
                        }
                        return count < data.length && !errorMessage;
                    }),
                    // 可设置20秒轮询超时时间，此20秒包含 请求时间 及 delay(2000) 的时间
                    // 例如在此处20秒大概是5次轮询后超时
                )
            })
        )
    }

    /**
     * 处理任务
     * @param procInsId 任务ID
     * @param reqData.result: 处理结果
     * @param reqData.remark: 处理意见
     * @param reqData.rejectTo: 驳回目标节点
     */
    handleTask(
        procInsId: string,
        reqData: { result: ProcessTaskStatus; remark: string; rejectTo?: string }
    ): Observable<MtSafeAny> {
        return this.http.put(
        `srv://${this.workflowServiceName}/workflow/instance/task/${procInsId}`,
        reqData
        );
    }
  /**
   * 获取待办工作流程
   *
   */
   getMyTaskList(page: PageRequest, query?: any[]): Observable<MtSafeAny> {
    return this.querySrv.query<ProcessInsTask>({
      modelId: 'ProcessInsTask',
      dataSource: this.workflowServiceName,
      cascades: ['procIns.objects', 'taskDef', 'procIns.procDefRev.procDef'],
      conditions: {
        filters: query,
        //   [
        //   // ...query,
        //   {
        //     prop: 'assignee',
        //     operate: FilterOperator.Equals,
        //     value: this.passport.current.user.id,
        //   },
        //   { prop: 'result', operate: FilterOperator.Null },
        // ],
      },
      page: {
        skip: (page.index - 1) * page.size,
        take: page.size,
      },
      sorts: [{ prop: 'startTime', dir: SortDirection.Descend }],
    });
  }
  // 获取待办工作流程与对象详情
  public getMyTaskAndObjectInfoList(page: PageRequest, query?: any[]): Observable<MtSafeAny> {
    return this.getMyTaskList(page, query).pipe(
      switchMap(processResult => {
        const processItemList = processResult?.resultData?.data;
        if (!processItemList || processItemList.length < 1) {
          return of([]);
        }
        const workHoursObjs: Array<string> = [];
        // 返回的流程关联的工时对象itemId
        for (const processItem of processItemList) {
          for (const object of processItem.procIns.objects) {
            workHoursObjs.push(`'${object.objectId}'`);
          }
        }
        return this.getWorkHoursItems(workHoursObjs).pipe(
          map(workHoursResult => {
            // 返回的数据工时对象数据
            const workHoursItems: Array<MtSafeAny> | undefined = workHoursResult?.resultData?.data;
            if (!workHoursItems) {
              return of([]);
            } else {
              processResult?.resultData?.data.forEach(processItem => {
                // 校验当前流程实例与返回的工时对象，匹配处理
                const index = workHoursItems.findIndex((workHoursItem: MtSafeAny) => {
                    const objectsIds = processItem.procIns.objects.map(object => object.objectId);
                    const wIndex: number = objectsIds.indexOf(workHoursItem.muid);
                    return wIndex !== -1;
                });
                if (index !== -1) {
                  workHoursItems[index].value = JSON.parse(workHoursItems[index]?.value_info?.value);
                  processItem.workHoursObject = workHoursItems[index];
                }
              });
              return processResult;
            }
          }),
          switchMap(newProcessResult => {
            // 获取工时对象中包含的组织信息
            const depIds: Array<string> =
            newProcessResult.resultData.data.map(processItem => processItem?.workHoursObject?.value?.depid).filter(Boolean);
            return this.getOrgListByIds(depIds).pipe(
              map(orgListResult => {
                  let orgItems = orgListResult?.resultData?.data;
                  newProcessResult.resultData.data.forEach(processItem => {
                    let index = orgItems.findIndex(orgItem => orgItem.id == processItem?.workHoursObject?.value?.depid);
                    if (index !== -1) {
                      processItem.workHoursObject.value.depName = orgItems[index].name;
                    }
                  });
                  return newProcessResult;
                }
              ),
            )
          })
        );
      })
    );
  }
  private getOrgListByIds(ids: Array<string>): Observable<MtSafeAny> {
    const param = {
        modelId: 'Organization',
        conditions: {filters: [{ prop: 'id', operate: FilterOperator.In, values: ids}]},
        cacheable: false,
        sorts: [{prop: 'name', dir: 'Ascend'}]};
    return this.http.post(`srv://${this.rbacServiceName}/common/query`, param);
  }
  private getWorkHoursItems(ids: Array<string>): Observable<MtSafeAny> {
    const param: MtSafeAny = {
      modelId: 'k8_manHourRevision',
      modelAlias: 't1',
      select :[
          't1.*',
          't2.id as eid',
          't3.id as prjid',
          't3.projectName as projectName'
      ],
      conditions: {
        filters: [
          { prop: 'id', operate: FilterOperator.In, values: ids}
        ],
      },
      sorts: [
        {
          prop: 'CREATE_TIME',
          dir: SortDirection.Descend
        }
      ],
      joins: [
        {
            property: 'eid',
            alias: 't2',
            operate: 'left_join',
        },
        {
            property: 'prjid',
            alias: 't3',
            operate: 'left_join'
        }
      ],
      page: {
        skip: 0,
        take: 999999,
        verifyStart: 1,
        verifyEnd: 10000
      },

    };
    return this.http.post(`srv://${this.spectrumServiceName}/mongodb/common/query/v3`, param);
  }
}
