<div class="body" *ngIf="initLoading">
  <div class="body-content">
    <div class="card-content">
      <ng-container *ngIf="haveAuthority; else noPermission">
        <nz-tabset
          *ngIf="panels?.length > 0 && mtObjects[mtObjectId] != null"
          [(nzSelectedIndex)]="panelIndex"
          [nzTabBarExtraContent]="panelExtra"
          [nzCanDeactivate]="canDeactivate"
        >
          <!-- 我的桌面面板Tab -->
          <nz-tab *ngFor="let panel of panels; let i = index" [nzTitle]="panelTabTitle">
            <ng-template #panelTabTitle>
              <div class="mt-tab-title" (dblclick)="modal.toggle()">
                {{ panel.panel | propI18n: 'name' }}
              </div>
            </ng-template>
            <ng-template #modalTitle>
              <ng-template [ngTemplateOutlet]="title"></ng-template>
              <div>/ {{ panel.panel | propI18n: 'name' }}</div>
            </ng-template>
            <ng-template #modalClose>
              <div class="modal-close-area" (click)="stopPropagation($event)">
                <ng-template [ngTemplateOutlet]="panel.instance?.extraContent"></ng-template>
              </div>
              <button class="ant-modal-close test">
                <span class="ant-modal-close-x">
                  <i class="ant-modal-close-icon" nz-icon nzType="close"></i>
                </span>
              </button>
            </ng-template>
            <ng-template
              [mtModalable]
              [mtModalMask]="false"
              [mtModalTitle]="modalTitle"
              [mtModalCloseArea]="modalClose"
              #modal="mtModalable"
            >
              <ng-container *ngIf="panel.panel.visible; else noPermission" [mtPanelRenderer]="panel" [mtActivate]="i == panelIndex"></ng-container>
            </ng-template>
          </nz-tab>
        </nz-tabset>
        <ng-template #panelExtra>
          <!-- <div class="mt-panel-extra" *ngIf="panelIndex !== -1 && panels[panelIndex].panel.editable"> -->
          <div class="mt-panel-extra" *ngIf="isShowButton && this.mtEditable && panels[panelIndex].panel.editable">
            <ng-template [ngTemplateOutlet]="panels[panelIndex]?.instance?.extraContent"></ng-template>
          </div>
        </ng-template>
      </ng-container>
      <!-- 无权限时显示 -->
      <ng-template #noPermission>
        <nz-result style="height: 300px;" nzStatus="warning" [nzTitle]="'platform.spectrum.noPermission' | translate">
        </nz-result>
      </ng-template>
    </div>
  </div>
</div>