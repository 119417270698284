import { Component, EventEmitter, OnInit, Optional, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MtDictCacheService, UserServiceService } from '@imerge22/base';
import { MtContextDirective, MtDynamicTabBase } from '@imerge22/common';
import {
  CommonUtils,
  FilterOperator,
  MtMap,
  MtNotificationBus,
  MtSafeAny,
  MtTranslateService,
  PageRequest,
  PassportService,
} from '@imerge22/core';
import { MtDataSourceService, MtObjectComponentBase } from '@imerge22/platform/base';
import { MtWorkflowStarterComponent } from '@imerge22/platform/workflow/starter';
import {
  MtObjectData,
  MtSpectrumDataService,
  MtSpectrumDefineService,
  MtSpectrumSecurityService,
} from '@imerge22/spectrum';
import { MtWorkflowFilterService } from '@imerge22/workflow';
import { NzModalService } from 'ng-zorro-antd/modal';
import { GetModelByCodeService } from '../../../common/services/get-model-by-code.service';
import { RefreshListTabService } from '../services/refresh-list.service';
import { RepeatSubmitService } from '../services/repeat-submit.service';
import { WorkingHoursService } from '../services/working-hours.service';
// import { MtWorkflowStarterComponent } from '../../starter';

import { WorkingHoursModalComponent } from '../working-hours-modal/working-hours-modal.component';
import { WorkingHoursDetailActivateEvent } from '../working-hours/events';

@Component({
  templateUrl: './working-hours-list.component.html',
  styleUrls: ['./working-hours-list.component.scss'],
})
export class WorkingHoursListComponent
  extends MtObjectComponentBase
  implements MtDynamicTabBase, OnInit, MtObjectComponentBase {
  // @ViewChild('workflowStarter', { static: true }) flowStart: MtWorkflowStarterComponent;

  // 配置tab是否显示关闭按钮
  shieldCloseBtn = true;
  // 提交审批流程时按钮loading展示
  isloading = false;

  // 列表获取数据时loading展示
  isTableloading = false;
  // 对象状态列表
  statusCodeList = [];
  // 搜索用户
  isSearchUserLoading = false;
  isUserLoading = false;
  checked = false;
  userList = [];
  userTotal = 0;
  querier: FormGroup;
  page: PageRequest = { index: 1, size: 10 };
  userPage: PageRequest = { index: 1, size: 10 };
  datas = { data: [], total: 0, totalPages: 0 };
  // 防抖
  searchNameShakeProof = null;
  // 存放选中的id
  setOfCheckedId = new Set<MtSafeAny>();
  @ViewChild(MtWorkflowStarterComponent, { static: true }) flowStart: MtWorkflowStarterComponent;
  public dynamicDataArr: Array<MtSafeAny> = [];
  private mtRevisionType: string = 'k8_manHourRevision'; 
  private currentSelectSingItem: MtSafeAny;
  constructor(
    defSvc: MtSpectrumDefineService,
    dataSvc: MtSpectrumDataService,
    secSvc: MtSpectrumSecurityService,
    private WorkingHoursService: WorkingHoursService,
    private fb: FormBuilder,
    private userSrv: UserServiceService,
    private modal: NzModalService,
    public srv: MtDataSourceService,
    private dictSrv: MtDictCacheService,
    private translate: MtTranslateService,
    private passport: PassportService,
    private getModelByCode: GetModelByCodeService,
    private refreshList: RefreshListTabService,
    private workFlowSvc: MtWorkflowFilterService,
    private repeatSubmitSvc: RepeatSubmitService,
    @Optional() public data: MtObjectData,
    @Optional() private context: MtContextDirective
  ) {
    super(defSvc, dataSvc, secSvc);
  }
  @ViewChild('tabTitle') title: TemplateRef<void>;
  onCloseByInner = new EventEmitter<void>();
  onActivated(): void { }
  onDeactivated(): void { }
  onDataChange(data: MtMap): void { }
  activate(): void { }
  deactivate(): void { }
  ngOnInit(): void {
    this.querier = this.fb.group({
      ITEM_ID: [null],
      projectName: [null],
      depId: [null],
      eid: [null],
    });
    this.dictSrv.getItems('Object_state').subscribe((res) => {
      this.statusCodeList = res as MtSafeAny;
    });
    this.setRefreshSubject();
    this.getModalInfo();
    this.searchData();
  }
  // 批量删除
  onBatchDelClick() {
    const params = [];
    this.deleteObj([...this.setOfCheckedId].map((item) => item.muid));
    return;
  }
  // 选择单个的对像
  onItemChecked(item: MtSafeAny, checked: boolean): void {
    this.updateCheckedSet(item, checked);
    this.refreshCheckedStatus();
    this.checkRevisionObjects(item.revisionObjects, checked);
  }
  checkRevisionObjects(list: MtSafeAny[], checked: boolean) {
    if (list) {
      list.forEach((res, i) => {
        res.disabled = checked;
        this.updateCheckedSet(res, checked);
        if (i === list.length - 1) {
          this.refreshCheckedStatus();
        }
      });
    }
  }
  // 全选触发
  onAllChecked(checked: boolean): void {
    this.datas.data.forEach((item) => {
      this.updateCheckedSet(item, checked);
      this.checkRevisionObjects(item.revisionObjects, checked);
    });
    this.refreshCheckedStatus();
  }
  updateCheckedSet(item: MtSafeAny, checked: boolean): void {
    if (checked && (item?.value?.current_status == 'NotSubmitted' || item?.value?.current_status == 'Rejected')) {
      this.currentSelectSingItem = item;
      this.setOfCheckedId.add(item);
    } else {
      this.setOfCheckedId.delete(item);
    }
  }
  refreshCheckedStatus(): void {
    this.checked = this.datas.data.filter(item => item.value.current_status == 'NotSubmitted' || item?.value?.current_status == 'Rejected').every((res) => this.setOfCheckedId.has(res));
  }
  onUserSearch(value: string): void {
    if (this.searchNameShakeProof !== null) {
      clearTimeout(this.searchNameShakeProof);
    }
    this.searchNameShakeProof = setTimeout(() => {
      this.isSearchUserLoading = true;
      this.userPage.index = 1;
      this.userTotal = 0;
      this.userList = [];
      this.loadMoreUser(value);
    }, 600);
  }

  loadMoreUser(value?: string) {
    this.isUserLoading = true;
    if (this.userTotal > this.userPage.index * this.userPage.size || !this.userTotal) {
      const filter = [];
      if (value) {
        filter.push({ prop: 'name', operate: FilterOperator.Like, value });
        filter.push({ prop: 'account', operate: FilterOperator.Like, value });
      }
      this.userSrv.getUsers(filter, this.userPage).subscribe(
        (res: MtSafeAny) => {
          this.isSearchUserLoading = false;
          this.isUserLoading = false;
          this.userList = [...this.userList, ...res.datas];
          this.userPage.index += 1;
          this.userTotal = res.total;
        },
        () => {
          this.isSearchUserLoading = false;
          this.isUserLoading = false;
        }
      );
    }
  }
  reload() {
    this.setOfCheckedId = new Set<MtSafeAny>();
    // 清空输入框
    this.querier.reset();
    // 将查询条件恢复默认
    this.page = { index: 1, size: 10 };
    this.searchData();
  }

  searchData() {
    this.isTableloading = true;
    const params: MtSafeAny = [
      {
        prop: 'SUBMIT',
        operate: FilterOperator.Contains,
        value: `'No'`,
      },
      {
        prop: 'current_status',
        operate: FilterOperator.NotEquals,
        value: `'Approved'`,
      }
    ];
    this.querier.get('eid').setValue(this.passport.current.user.id);
    const keys = Object.keys(this.querier.value);
    const value = Object.values(this.querier.value).some((item) => Boolean(item) === true);

    // 如果输入框有值就按照输入框查询
    if (value) {
      keys.map((key) => {
        if (CommonUtils.isNotBlank(this.querier.value[key])) {
          let queryObj = {};
          if (this.querier.value.eid && key === 'eid') {
            queryObj = {
              prop: 't2.id',
              operate: FilterOperator.In,
              values: [`'${this.querier.value[key]}'`],
            };
            params.push(queryObj);
          } else {
            queryObj = {
              prop: 't3.projectName',
              operate: FilterOperator.Contains,
              value: `'${this.querier.value[key]}'`,
            };
            params.push(queryObj);
          }
        }
      });
    }
    this.WorkingHoursService.getWorkingHoursList(this.page, params).subscribe(
      (res: MtSafeAny) => {
        const createUsers = [];
        res.resultData.data.forEach((item) => {
          if (item.createUser) {
            createUsers.push(item.createUser);
            if (item.revisionObjects && item.revisionObjects.length) {
              item.revisionObjects.forEach((ite) => {
                if (ite.createUser) {
                  createUsers.push(item.createUser);
                }
              });
            }
          }
        });
        const filter = [];
        if (createUsers && createUsers.length) {
          filter.push({ prop: 'id', operate: FilterOperator.In, values: createUsers });
          this.userSrv.getUsers(filter, { index: 1, size: 10 }).subscribe((item: MtSafeAny) => {
            res.resultData.data.forEach((element) => {
              const people = item.datas.find((va) => va.id === element.createUser);
              if (people) {
                element.createUserName = people.name + '/' + people.account;
              } else {
                element.createUserName = element.createUser;
              }
              if (element.revisionObjects && element.revisionObjects.length) {
                element.revisionObjects.forEach((ite) => {
                  const peo = item.datas.find((va) => va.id === ite.createUser);
                  if (peo) {
                    ite.createUserName = peo.name + '/' + peo.account;
                  } else {
                    ite.createUserName = ite.createUser;
                  }
                });
              }
            });
          });
        }
        res.resultData.data.forEach((item) => {
          let customerInfo = JSON.parse(item.value_info.value);
          item.value = JSON.parse(item.value_info.value);
        });

        this.WorkingHoursService.getOrgList().subscribe((el) => {
          if (!el?.resultData?.data || el?.resultData?.data?.length < 1) {
            return;
          }
          res.resultData.data.forEach((item) => {
            item.value.depName = el.resultData.data.find((org) => org.id == item.value.depid)?.name;
          });
        });
        this.WorkingHoursService.getProjectDetailList().subscribe((project: MtSafeAny) => {
          if (!project?.resultData?.data || project?.resultData?.data?.length < 1) {
            return;
          }
          res.resultData.data.forEach((item) => {
            let obj = project.resultData.data.find((el) => JSON.parse(el.value_info.value)._id == item.prjid)
            if (obj) {
              item.value.projectName = JSON.parse(obj?.value_info?.value)?.projectName;
            }
          });
        });
        this.datas.data = res.resultData.data;
        this.datas.total = res.resultData.total;
        this.isTableloading = false;
        this.refreshCheckedStatus();
      },
      (err: Error) => {
        this.isTableloading = false;
      }
    );
  }
  search() {
    this.page.index = 1;
    this.searchData();
  }
  deleteObj(item: string[]) {
    this.secSvc.delObj(item).subscribe((res) => {
      if (res.success) {
        MtNotificationBus.emit({
          type: 'success',
          title: 'i18n.platform.notification.success.success',
          message: 'i18n.platform.notification.success.deleteSuccess',
        });
        this.setOfCheckedId = null;
        this.setOfCheckedId = new Set<string>(); // 已选择的id
        this.searchData();
      }
    });
  }
  onStartProcessClick(item) {
    const parameter = [
      {
        objects: [
          {
            objectId: item.id,
            target: true,
            serial: 1,
          },
        ],
      },
    ];
    // this.flowStart.initName(item.code + '-' + item.name);
    // this.flowStart.open(parameter).subscribe(res => {
    //   if (res) {
    //     this.searchData();
    //   }
    // });
  }
  openModal(detail?) {
    let params = {};
    if (detail) {
      params = { ...detail };
    } else {
      // for (const item of this.dynamicDataArr) {
      //   params[item.key] = null;
      // };
      console.log('传入model的动态参数params', this.dynamicDataArr);
    }
    let detailObj = [];
    let filterArr: Array<string> = [
      'eid',
      'depid',
      'prjid',
      'milestone',
      'workdate',
      'comment',
      'workcheck',
      'prjhours',
    ];
    for (let index = 0; index < this.dynamicDataArr.length; index++) {
      const item = this.dynamicDataArr[index];
      if (filterArr.indexOf(item.key) !== -1) {
        detailObj.push(item);
      }
    }
    const drawerRef = this.modal.create({
      nzContent: WorkingHoursModalComponent,
      nzComponentParams: {
        detail: detailObj,
        // modalTitle: this.translate.currentLang.startsWith('en') ? 'Add Customer Info' : '新增客户信息'
      },
      nzFooter: null,
      nzWidth: '75%',
      nzBodyStyle: { height: document.body.clientHeight - 75 + 'px', padding: '0px' },
      nzClosable: false,
      nzMaskClosable: false,
      nzStyle: { top: '40px' },
    });
    drawerRef.afterClose.subscribe((res) => {
      this.setOfCheckedId = null;
      this.setOfCheckedId = new Set<string>();
      if (res) {
        if (detail) {
          this.searchData();
        } else {
          this.reload();
        }
      }
    });
  }

  isEn() {
    return this.translate.currentLang.startsWith('en');
  }
  transferStatus(status: string) {
    let statusName = '';
    if (status) {
      const list = status.split(',');
      const objList = this.statusCodeList.filter((item) => list.includes(item.code));
      objList.forEach((item, i) => {
        statusName = statusName + (this.isEn() ? item.code : item.name);
        if (i !== objList.length - 1) {
          statusName += ',';
        }
      });
    }
    return statusName;
  }

  goToDetail(item) {
    WorkingHoursDetailActivateEvent.emit({
      context: 'AddWorkingHours',
      id: item?.value?._id,
      type: item?.value?.OBJECT_TYPE,
      extra: {
        id: item?.value?._id,
        type: item?.value?.OBJECT_TYPE,
        ...item?.value
      }
    });
  }

  launchWorkflow(item?: MtSafeAny) {

    //判断的是否可以发起流程
    var canSumbitWorkflow = true;
    if (!canSumbitWorkflow) {
      MtNotificationBus.emit({
        type: 'warning',
        title: 'i18n.platform.notification.errors.checkAudit',
        message: 'i18n.platform.notification.errors.fistPcn',
      });
      return;
    }
    const parameter = [{
      objects: [
        {
          objectId: this.currentSelectSingItem?.value?._id,
          target: true,
          serial: 1
        }
      ]
    }];

    //this.flowStart.mtName =  item.OBJECT_NAME + '-' + (item?.change_topic || '') ;
    this.flowStart.initName(this.currentSelectSingItem?.value?.OBJECT_NAME + '-' + (this.currentSelectSingItem?.value?.projectName || ''));

    this.flowStart.open(parameter);
  }

  startWorkflow() {
  }

  public submitAllSelect(): void {
    this.isloading = true;
    let objects = [...this.setOfCheckedId];
    let objectIds: Array<string> = objects.map(item => item?.muid);
    this.workFlowSvc.getProcessFilterList(objectIds).subscribe(async (res: MtSafeAny) => {
      if (!res || !res?.resultData) {
        return;
      }
      let processDefList = res?.resultData;
      let params = [];
      objects.forEach(result => {
        let paramData = {};
        paramData = {
          procDefRevId: processDefList[0].activatedRevId,
          name: result?.value?.ITEM_ID + '-' + result?.value?.projectName,
          items: [{
            objects: [{
              objectId: result.muid,
              target: true,
              serial: 1,
            }]
          }],
          copyTo: null,
          desc: null,
          taskConfs: {"Activity_199z6mk": {}}
        };
        params.push(paramData);
      });
      await this.repeatSubmitSvc.repeatSubmit(params).toPromise().catch(() => {
        this.isloading = false;
      });
      this.isloading = false;
      this.reload();
    });
  }

  private currentUserIsAdmin(): boolean {
    const userInfo = JSON.parse(localStorage.getItem('user_info'));
    if (userInfo?.roleRels?.filter((item) => item == 'INIT_GROUP_ROLE' || item == 'INIT_ROLE ').length > 0) {
      return true;
    } else {
      return false;
    }
  }
  private getCurrentUserIsDepartmentHeadOrgId(): Array<string> {
    const userInfo = JSON.parse(localStorage.getItem('user_info'));
    if (!userInfo?.roleRels) {
      return [];
    }
    let index = userInfo?.roleRels?.findIndex((item) => item == 'r0RM8YjjSOSk8ASPLnxgFQ');
    if (index !== -1) {
      return [userInfo.orgRels[index]];
    } else {
      return [];
    }
  }

  private getModalInfo(): void {
    this.dynamicDataArr = [];
    const isEnLang: boolean = this.translate.currentLang.startsWith('en');
    const modelCode: string = this.mtRevisionType;
    const filleterModelArr: Array<string> = [
      'eid',
      'depid',
      'prjid',
      'milestone',
      'workdate',
      'workcheck',
      'prjhours',
      'comment',
      'SUBMITBY',
      'current_status'
    ];
    let modelInfoList = [];
    this.getModelByCode.getModelByCode(modelCode).subscribe((res: MtSafeAny) => {
      if (!res || res.length < 1) {
        return;
      }
      modelInfoList = res;
      for (const key of filleterModelArr) {
        let basicInfo: any;
        modelInfoList.forEach(item => {
          if (item.code == key) {
            basicInfo = {
              key: key,
              label: isEnLang ? item?.nameEn : item?.name,
              content: null,
              type: item?.type,
              seq: item?.seq,
              descEn: item?.descEn,
              arrayed: item?.arrayed,
              required: item?.validate?.required
            };
            this.dynamicDataArr.push(basicInfo);
          }
        })
      }
    })
  }

  private setRefreshSubject(): void {
    this.refreshList.register().subscribe(res => {
      if (res && res == this.mtRevisionType) {
        this.reload();
      }
    })
  }
}
