
<table class="mt-table">
  <tr>
    <th class="panel-ecn-th">{{ 'common.panel.ecnHandler' | translate }}</th>
    <td>
      <ng-container *ngIf="!editing; else show">
        <ng-container *ngIf="selectedUser">
          <i nz-icon nzType="user"></i>{{ selectedUser | user | async }}
        </ng-container>
      </ng-container>
      <ng-template #show>
        <mt-user-input [(ngModel)]="selectedUser"></mt-user-input>
      </ng-template>
    </td>
  </tr>
</table>

<ng-template #buttonAreaContent>
  <ng-container *ngIf="editable">
    <button nz-button nzType="primary" *ngIf="!editing" nzShape="round" (click)="edit()">
      <i nz-icon nzType="edit"></i>{{ 'platform.common.default.edit' | translate }}
    </button>
    <nz-button-group class="mt-sharp-round" *ngIf="editing">
      <button nz-button (click)="cancelEdit()">
        <i nz-icon nzType="close"></i>{{ 'platform.common.cancel' | translate }}
      </button>
      <button nz-button nzType="primary" (click)="save()">
        <i nz-icon nzType="save"></i>{{ 'platform.common.save' | translate }}
      </button>
    </nz-button-group>
  </ng-container>
</ng-template>
