import { Injectable, Type } from '@angular/core';
import { MtReportDataContext, MtReportDataContextRegister, MtReportDataEvent, MtReportDataHandler } from '@imerge22/spectrum';
import { Observable, of } from 'rxjs';
import { Char2Component } from './char2.component';

@Injectable({ providedIn: 'root' })
class CreateObjectShortcutHandler extends MtReportDataHandler {
  constructor() {
    super();
  }

  getComponent(): Type<MtReportDataContext> {
    return Char2Component;
  }
  available(key: string, e: MtReportDataEvent): Observable<boolean> {
    return of(true);
  }
}

export function registerChar2(id: string) {
  MtReportDataContextRegister.registerReportData({
    title: 'root.home.report.histogram',
    id,
    order: 1,
    handlerDefine: CreateObjectShortcutHandler
  });
}
