import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnInit } from '@angular/core';
import { MtMeasureUnitCacheService } from '@imerge22/base';
import { MtDynamicTabBase } from '@imerge22/common';
import {
  FilterGroupOperator,
  FilterOperator,
  GenericFilter,
  GenericFilterGroup,
  GenericQueryRequest,
  MtMap,
  MtNotificationBus,
  MtTranslateService,
} from '@imerge22/core';
import {
  ModelDto,
  MtConfigService,
  MtDataSourceService,
  MtWithConfig,
} from '@imerge22/platform/base';
import { CommonUtils } from '@imerge22/platform/utils';
import { NzTreeNodeOptions } from 'ng-zorro-antd/tree';
import { SuperQueryResultActivateEvent } from '../super-query-result/events';

@Component({
  selector: 'uccs-super-query-condition',
  templateUrl: './super-query-condition.component.html',
  styleUrls: ['./super-query-condition.component.scss'],
})
export class SuperQueryConditionComponent implements MtDynamicTabBase, OnInit {
  @MtWithConfig() private spectrumServiceName: string;
  count = 0;
  model: any = {};
  stringSelectType = [
    { type: '等于', value: 'Equals' },
    { type: '包含', value: 'Contains' },
    { type: '开始于', value: 'StartsWith' },
    { type: '结束于', value: 'EndsWith' },
  ];
  // 数字/日期时间
  numberOrDateSelectType = [
    { type: '等于', value: 'Equals' },
    { type: '大于', value: 'GreatThan' },
    { type: '大于或等于', value: 'GreatThanOrEquals' },
    { type: '小于', value: 'LessThan' },
    { type: '小于或等于', value: 'LessThanAndEquals' },
    { type: '范围', value: 'Between' },
  ];
  UNIT = 'Unit';
  RELATION = '_relation';
  SAVEKEY = 'saveSearchDataUniqueKey';
  ITEM_REVISION = 'ItemRevision';
  suffixs = ['start', 'end'];
  expandKeys = [];
  allAttributes = [];
  defaultModel: ModelDto = null;
  cards: { parentModel: any; childList: any[] }[] = [];
  modelTreeNodes: NzTreeNodeOptions[] = [];
  drawerSave: any = { visible: false };
  drawerSearch: any = { visible: false };
  vsesions: any = [];
  revisionStr = '';
  title = '高级查询';
  data: MtMap;
  onCloseByInner = new EventEmitter<void>();

  constructor(
    private srv: MtDataSourceService,
    private http: HttpClient,
    private translate: MtTranslateService,
    public mtConfigSrv: MtConfigService,
    private unitSrv: MtMeasureUnitCacheService
  ) {}

  ngOnInit() {
    this.reset();
    let modelCode = 'CommonObject';
    this.data.extra = this.data;
    if (this.data.extra && this.data.extra.modelCode) {
      modelCode = this.data.extra.modelCode;
      if (this.data.extra.isRevision) {
        // 如果指定处理版本数据
        // this.revisionStr = 'Revision';
      }
    }
    this.defaultModel = this.srv.getModel(modelCode);
    const modelCodeNodeOptions: NzTreeNodeOptions = {
      key: this.defaultModel.id,
      title: this.defaultModel.code,
    };
    const treeList = this.createModelTree(modelCodeNodeOptions);
    if (this.data.extra.notShowParent) {
      // 如果配置了不展示父节点
      this.modelTreeNodes.push(...treeList);
    } else {
      modelCodeNodeOptions.children = treeList;
      this.modelTreeNodes.push(modelCodeNodeOptions);
    }
  }

  onActivated(): void {}
  onDeactivated(): void {}
  onDataChange(data: MtMap): void {}

  private createModelTree(parent?: NzTreeNodeOptions) {
    const children = this.srv.getModelChildren(
      parent == null ? null : this.srv.getModelById(parent.key).code
    );
    const nodes = children.map((m) => {
      const node: NzTreeNodeOptions = {
        key: m.id,
        title: m.code,
      };
      node.children = this.createModelTree(node);
      if (node.children.length === 0) {
        node.isLeaf = true;
      }
      return node;
    });
    return nodes;
  }

  changeTree(value: string): void {
    this.cards = [];
    this.allAttributes = [];
    if (value != null) {
      // 根据id查找model
      const model = this.srv.getModelById(value);
      // 获取所有前辈模型
      const parentList = this.srv.getAncestorModels(model.code + this.revisionStr, true);
      // 获取模型所有自定义属性(itemrevision属性)
      this.srv.getModelProperties(model.code + this.revisionStr).subscribe((result) => {
        // 如果当前属性不是主对象（父模型id等于ITEM_REVISION的数据），不是数组类型、动态、表格、code=有效性的数据
        this.allAttributes = result.filter(
          (item) =>
            !item.arrayed &&
            item.type !== 'RUNTIME' &&
            item.type !== 'TABLE' &&
            item.parentModelId !== this.ITEM_REVISION &&
            item.code !== 'Valid'
        );
        if (this.allAttributes.length > 0) {
          // key=父类模型id
          const childMap = new Map<string, any[]>();
          let modelId;
          this.allAttributes.map((item) => {
            modelId = item.parentModelId;
            // 从父对象继承过来的属性modelId保存父对象的id，为空的就说明是当前对象的属性
            if (CommonUtils.isBlank(modelId)) {
              modelId = parentList[parentList.length - 1].id;
            }
            this.defaultValueHandler(item);
            if (childMap.has(modelId)) {
              childMap.get(modelId).push(item);
            } else {
              childMap.set(modelId, [item]);
            }
            // 处理数值类型默认单位
            if (
              item.type === 'NUMBER' &&
              item.extra2 &&
              CommonUtils.isBlank(this.model[item.code + this.UNIT])
            ) {
              this.model[item.code + this.UNIT] = item.extra2;
            }
          });

          parentList.map((item) => {
            if (childMap.has(item.id)) {
              if (
                // 'componentsRevision'
                item.code
                  .split('')
                  .reverse()
                  .join('')
                  .indexOf('Revision'.split('').reverse().join('')) === 0
              ) {
                item.code = item.code.slice(0, -8);
              }
              this.cards.push({
                parentModel: item,
                childList: childMap.get(item.id),
              });
            }
          });
        }
      });
    } else {
      this.expandKeys = [];
    }
  }

  /**
   * 处理关系下拉框默认赋值
   * @param item 当前要处理的项
   */
  defaultValueHandler(item: any) {
    const property = item.code + this.RELATION;
    if (item.type === 'STRING') {
      this.model[property] = 'Contains';
    } else if (item.type === 'DATE') {
      this.model[property] = 'Between';
    } else if (item.type === 'LIST_OF_VIEW') {
      this.model[property] = 'Equals';
    } else if (item.type === 'NUMBER') {
      this.model[property] = 'Equals';
    }
  }

  reset() {
    this.model = {
      ['OBJECT_NAME' + this.RELATION]: 'Contains',
    };
    this.cards = [];
    this.allAttributes = [];
  }

  search() {
    let modelId: string;
    if (CommonUtils.isBlank(this.model.OBJECT_TYPE)) {
      // 默认赋值处理
      modelId = this.defaultModel.code;
    } else {
      modelId = this.srv.getModelById(this.model.OBJECT_TYPE).code;
    }
    const filters = [];
    const queryData: GenericQueryRequest = {
      modelId: modelId + this.revisionStr,
      conditions: {
        filters,
        groups: [
          {
            operate: FilterGroupOperator.Or,
            filters: [],
            groups: [],
          },
        ],
      },
    };
    // 基本信息列数据处理
    this.codeParse(queryData.conditions, 'ITEM_ID');
    if (this.data.extra.isUaesQuery) {
      this.codeParse(queryData.conditions, 'OBJECT_NAME');
    } else {
      this.handlerRowData(filters, 'OBJECT_NAME', 'STRING');
    }
    this.handlerRowData(filters, 'Valid');
    // this.handlerRowData(filters, 'CREATOR');
    // this.handlerRowData(filters, 'CREATE_TIME', 'DATE');
    // this.handlerRowData(filters, 'UPDATE_TIME', 'DATE');
    // 模板属性数据处理
    if (CommonUtils.isNotBlank(this.allAttributes)) {
      this.allAttributes.map((item) => {
        this.handlerRowData(filters, item.code, item.type, item);
      });
    }
    // this.context.addOrActiveContext({
    //   id: 'search_result' + ++this.count,
    //   type: 'search-result',
    //   title: 'i18n.panels.tabTitle.searchReuslt',
    //   extra: {
    //     queryData,
    //     titleParam: { count: this.count },
    //     isUaesQuery: this.data.extra.isUaesQuery,
    //     pageSize: this.data.extra.isUaesQuery ? 100 : null
    //   }
    // });
    SuperQueryResultActivateEvent.emit({
      context: 'SEARCH_DEMO',
      extra: {
        queryData,
        titleParam: { count: ++this.count },
        isUaesQuery: this.data.extra.isUaesQuery,
        pageSize: this.data.extra.isUaesQuery ? 100 : null,
      },
    });
  }

  /**
   * 查询条件封装处理
   * @param filters 要到数据库查询的数组
   * @param code 处理的属性code
   * @param type 处理属性的类型
   * @param item 当前处理的属性model
   */
  handlerRowData(filters: any[], code: string, type?: string, item?: any) {
    const operate = this.model[code + this.RELATION];
    const value = this.model[code];
    let arr = [code + this.suffixs[0], code + this.suffixs[1]];
    if (type === 'NUMBER' && operate === 'Between') {
      // 如果是数值类型，关系为范围，判空
      arr = arr.filter((code2) => CommonUtils.isNotBlank(this.model[code2]));
      if (arr.length === 0) {
        return;
      }
    } else if (CommonUtils.isBlank(value)) {
      return;
    }
    // 筛选条件查询的model
    const row: GenericFilter = {
      prop: code,
      operate: FilterOperator.Equals,
    };
    if (type === 'NUMBER') {
      if (item.extra1) {
        if (operate === 'Between') {
          arr.map((code2) => {
            this.unitSrv.getUnit(item.extra1, this.model[code + this.UNIT]).subscribe((result) => {
              if (result != null) {
                row.value = this.model[code2] * result.rate;
                if (code2.endsWith(this.suffixs[0])) {
                  row.operate = FilterOperator.GreatThanOrEquals;
                  if (arr.length === 2) {
                    filters.push(JSON.parse(JSON.stringify(row)));
                  }
                } else {
                  row.operate = FilterOperator.LessThanAndEquals;
                }
              }
            });
          });
        } else {
          this.unitSrv.getUnit(item.extra1, this.model[code + this.UNIT]).subscribe((result) => {
            if (result != null) {
              row.value = value * result.rate;
            }
          });
        }
      } else {
        if (operate) {
          row.operate = operate;
        }
        row.value = value;
      }
    } else if (type === 'DATE') {
      if (operate == null || operate === FilterOperator.Equals) {
        // 日期类型的数据关系选择等于时，特殊处理，先添加一个大于等于选中时间0点的条件
        row.operate = FilterOperator.GreatThanOrEquals;
        value.setHours(0, 0, 0, 0);
        row.value = value;
        filters.push(JSON.parse(JSON.stringify(row)));
        // 小于等于
        row.operate = FilterOperator.LessThanAndEquals;
        value.setHours(23, 59, 59, 999);
        row.value = value;
      } else if (operate === 'Between') {
        // 范围
        row.operate = FilterOperator.GreatThanOrEquals;
        value[0].setHours(0, 0, 0, 0);
        row.value = value[0];
        filters.push(JSON.parse(JSON.stringify(row)));
        // 小于等于
        row.operate = FilterOperator.LessThanAndEquals;
        value[1].setHours(23, 59, 59, 999);
        row.value = value[1];
      } else {
        // 大于等于、小于，设置0点
        if (operate === FilterOperator.GreatThanOrEquals || operate === FilterOperator.LessThan) {
          value.setHours(0, 0, 0, 0);
        } else if (
          operate === FilterOperator.LessThanAndEquals ||
          operate === FilterOperator.GreatThan
        ) {
          value.setHours(23, 59, 59, 999);
        }
        row.operate = operate;
        row.value = value;
      }
    } else if (type === 'STRING') {
      if (operate) {
        row.operate = operate;
      }
      row.value = value;
    } else if (type === 'BOOL') {
      if (value === 'null') {
        row.operate = FilterOperator.Null;
      } else {
        row.operate = FilterOperator.Equals;
      }
      row.value = value;
    } else {
      row.value = value;
    }
    filters.push(row);
  }

  changeDateType(code: string) {
    // 如果选择范围，值为数组，其他情况为Date对象，当切换范围的时候根据数据清空value值
    if (
      (this.model[code + this.RELATION] !== 'Between' && Array.isArray(this.model[code])) ||
      (this.model[code + this.RELATION] === 'Between' && !Array.isArray(this.model[code]))
    ) {
      this.model[code] = null;
    }
  }

  codeParse(conditions: GenericFilterGroup, code: string) {
    const value: string = this.model[code];
    if (CommonUtils.isNotBlank(value)) {
      const codes = value.split(',');
      codes.map((item) => {
        let flag = true;
        const filterGroupa = conditions.groups[0];
        // 筛选条件查询的model
        const row: GenericFilter = {
          prop: code,
        };
        if (item.startsWith('*') && item.endsWith('*')) {
          row.value = item.substr(1);
          row.value = row.value.substr(0, row.value.length - 1);
          row.operate = FilterOperator.Like;
        } else if (item.endsWith('*')) {
          row.value = item.substr(0, item.length - 1);
          row.operate = FilterOperator.StartsWith;
        } else if (item.startsWith('*')) {
          row.value = item.substr(1);
          row.operate = FilterOperator.EndsWith;
        } else if (item.includes('*')) {
          row.value = item.substr(0, item.indexOf('*'));
          row.operate = FilterOperator.StartsWith;
          const obj = { filters: [{ ...row }] };
          filterGroupa.groups.push(obj);
          row.value = item.substr(item.indexOf('*') + 1);
          row.operate = FilterOperator.EndsWith;
          obj.filters.push(row);
          flag = false;
        } else {
          row.value = item;
          row.operate = FilterOperator.Equals;
        }
        if (flag) {
          filterGroupa.filters.push(row);
        }
      });
    }
  }

  getLanguageName(column) {
    if (this.translate.currentLang.startsWith('en')) {
      return column.nameEn || column.name;
    } else {
      return column.name;
    }
  }

  /**
   * 下拉菜单适配中英文
   */
  getSelectName(item) {
    if (this.translate.currentLang.startsWith('en')) {
      return item.value;
    } else {
      return item.type;
    }
  }

  changeName(value) {
    this.drawerSave.disabled = CommonUtils.isBlank(value);
  }
  openSaveDrawer() {
    this.drawerSave = { visible: true, disabled: true };
  }
  closeSaveDrawer(flag?: boolean) {
    if (flag) {
      this.drawerSave.disabled = true;
      const data = {
        profileKey: this.SAVEKEY,
        profileValue: JSON.stringify(this.model),
        profileName: this.drawerSave.saveName,
        multipled: true,
      };
      this.http.post(`srv://${this.spectrumServiceName}/profile/set`, data).subscribe(
        (res) => {
          MtNotificationBus.emit({
            type: 'success',
            title: 'i18n.platform.notification.success.saveSuccess',
            message: 'i18n.platform.notification.success.saveSuccess',
          });
          this.drawerSave.visible = false;
        },
        (err) => {
          console.log(err);
          MtNotificationBus.emit({
            type: 'error',
            title: 'i18n.platform.notification.errors.saveLoes',
            message: err.message,
          });
        },
        () => {
          this.drawerSave.disabled = false;
        }
      );
    } else {
      this.drawerSave.visible = false;
    }
  }

  selectChage(value) {
    this.drawerSearch.disabled = CommonUtils.isBlank(value);
  }
  openSearchDrawer() {
    this.drawerSearch = { visible: true, disabled: true, loading: true };
    this.http.get(`srv://${this.spectrumServiceName}/profile/${this.SAVEKEY}/query`).subscribe(
      (res) => {
        if (CommonUtils.isNotBlank(res)) {
          this.vsesions = res;
        }
      },
      (err) => {
        console.log(err);
        MtNotificationBus.emit({
          type: 'error',
          title: 'i18n.platform.notification.errors.lodingDateFailure',
          message: err.message,
        });
      },
      () => {
        this.drawerSearch.loading = false;
      }
    );
  }
  closeSearchDrawer(flag?: boolean) {
    if (flag) {
      this.model = JSON.parse(this.drawerSearch.selectVersion.profileValue);
      if (CommonUtils.isNotBlank(this.model.OBJECT_TYPE)) {
        this.changeTree(this.model.OBJECT_TYPE);
      }
    }
    this.drawerSearch.visible = false;
  }
}
