<nz-drawer
  [nzWidth]="520"
  [nzClosable]="true"
  [nzVisible]="visible"
  nzPlacement="right"
  [nzTitle]="'newObject.upmn.title' | translate"
  (nzOnClose)="cancel()"
  nzWrapClassName="mt-with-footer"
>
  <div>
    <div nz-row class="search-upmn-line">
      <div nz-col nzSpan="3" class="search-upmn-text">
        <span class="mt-danger">*</span>
        {{ 'newObject.upmn.code' | translate }}:
      </div>
      <div nz-col nzSpan="20">
        <input
          nz-input
          [placeholder]="'newObject.upmn.code' | translate"
          [(ngModel)]="model.itemId"
        />
      </div>
    </div>
    <div nz-row class="search-upmn-line">
      <div nz-col nzSpan="3" class="search-upmn-text">{{ 'newObject.upmn.name' | translate }}:</div>
      <div nz-col nzSpan="20">
        <input
          nz-input
          [placeholder]="'newObject.upmn.name' | translate"
          [(ngModel)]="model.objectName"
        />
      </div>
    </div>
    <div nz-row class="search-upmn-line">
      <div nz-col nzSpan="3" class="search-upmn-text">
        {{ 'newObject.upmn.version' | translate }}:
      </div>
      <div nz-col nzSpan="20">
        <input
          nz-input
          [placeholder]="'newObject.upmn.version' | translate"
          [(ngModel)]="model.itemRevisionId"
        />
      </div>
    </div>
    <div nz-row class="search-upmn-line">
      <div nz-col nzSpan="2"></div>
      <div nz-col nzSpan="21">
        <nz-radio-group [(ngModel)]="model.searchType">
          <label nz-radio nzValue="part">{{ 'newObject.upmn.partQuery' | translate }}</label>
          <label nz-radio nzValue="total">
            {{ 'newObject.upmn.affectedAssemblyQuery' | translate }}
          </label>
        </nz-radio-group>
      </div>
    </div>
    <div nz-row class="search-upmn-btn">
      <button
        style="margin-right: 10px"
        type="button"
        nz-button
        nzShape="round"
        [disabled]="isSearch"
        (click)="search()"
      >
        <i nz-icon nzType="search"></i>{{ 'platform.core.dict.query' | translate }}
      </button>
      <button type="button" nz-button nzShape="round" (click)="reset()">
        <i nz-icon nzType="reload"></i>{{ 'platform.core.dict.reset' | translate }}
      </button>
    </div>
  </div>
  <div class="search-upmn-table">
    <nz-table
      #basicTable
      [nzData]="tableList"
      [nzFrontPagination]="false"
      [nzBordered]="true"
      nzShowPagination
      [nzShowSizeChanger]="true"
      [(nzPageIndex)]="query.index"
      [(nzPageSize)]="query.size"
      [nzTotal]="query.total"
      (nzPageIndexChange)="changePage($event)"
      (nzPageSizeChange)="changePage($event)"
      [nzShowTotal]="measurementClassTotal"
      [nzFooter]="footer"
      [nzLoading]="isSearch"
    >
      <thead>
        <tr>
          <th
            nzShowCheckbox
            [(nzChecked)]="tableVM.selectAll"
            (nzCheckedChange)="checkAll($event)"
          ></th>
          <th class="search-upmn-th">{{ 'newObject.upmn.code' | translate }}</th>
          <th class="search-upmn-th">{{ 'newObject.upmn.name' | translate }}</th>
          <th class="search-upmn-th">{{ 'newObject.upmn.version' | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let data of basicTable.data"
          (click)="clickRow(data)"
          [class.select-color]="data.isChecked"
        >
          <td nzShowCheckbox [(nzChecked)]="data.isChecked"></td>
          <td>{{ data.itemId }}</td>
          <td>{{ data.objectName }}</td>
          <td>{{ data.itemRevisionId }}</td>
        </tr>
      </tbody>
    </nz-table>
  </div>
  <ng-template #measurementClassTotal let-range="range" let-total>
    {{
      'platform.core.dict.total' | translate: { total: total, range0: range[0], range1: range[1] }
    }}
  </ng-template>
  <ng-template #footer>
    <label
      nz-checkbox
      [(ngModel)]="tableVM.selectAll"
      (ngModelChange)="selecCheckChange($event, 'all')"
      >{{ 'common.selectAll' | translate }}</label
    >
    <label
      nz-checkbox
      [(ngModel)]="tableVM.selectInvert"
      (ngModelChange)="selecCheckChange($event, 'invert')"
      >{{ 'common.selectInvert' | translate }}</label
    >
  </ng-template>

  <div class="mt-drawer-footer">
    <button (click)="cancel()" type="button" nz-button nzShape="round">
      <i nz-icon nzType="close"></i>{{ 'platform.common.cancel' | translate }}
    </button>
    <button
      *ngIf="!notShowSetp"
      (click)="prevStep()"
      type="button"
      nzType="primary"
      nz-button
      nzShape="round"
    >
      <i nz-icon nzType="check"></i>{{ 'platform.common.prevStep' | translate }}
    </button>
    <button
      nz-button
      nzShape="round"
      nzType="primary"
      type="button"
      (click)="save()"
      [disabled]="checkSave()"
    >
      <i nz-icon nzType="check"></i
      >{{ 'platform.common.' + (notShowSetp ? 'confirm' : 'nextStep') | translate }}
    </button>
  </div>
</nz-drawer>
