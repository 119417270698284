import { Component, OnInit, Optional } from '@angular/core';
import { MtSafeAny } from '@imerge22/core';
import { MtSpectrumContrlBase, MtSpectrumControlRegister } from '@imerge22/spectrum/controls';
import { MtSpectrumDataBinding } from '@imerge22/spectrum/objects';

@Component({
  selector: 'uccs-ctrl-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss'],
})
export class TestComponent extends MtSpectrumContrlBase<MtSafeAny> implements OnInit {
  constructor(@Optional() public dataBinding: MtSpectrumDataBinding) {
    super();
  }

  ngOnInit(): void {}

  initConfig() {}
}

export function registerTestCtrl() {
  MtSpectrumControlRegister.register('uccsTest', TestComponent);
}
