import { MtEvent, MtEventBus, MtSafeAny, GenericQueryRequest } from '@imerge22/core';

interface TitleParam {
  count: number;
}

interface LogisticsActivateEventData {
  context?: string;
}

export class LogisticsActivateEvent {
  static $name = 'uccs-logistics';
  static emit(data: LogisticsActivateEventData) {
    MtEventBus.emit({
      name: LogisticsActivateEvent.$name,
      data,
    });
  }

  static is(event: MtEvent<MtSafeAny>): boolean {
    return event != null && event.name === LogisticsActivateEvent.$name;
  }

  static get(event: MtEvent<MtSafeAny>): LogisticsActivateEventData {
    if (LogisticsActivateEvent.is(event)) {
      return event.data;
    }
    return undefined;
  }
}
