import { MtDynamicTabsetActivateEventData } from '@imerge22/common';
import { MtEvent, MtEventBus, MtSafeAny, GenericQueryRequest } from '@imerge22/core';

interface TitleParam {
  count: number;
}

interface Extra {
  queryData: GenericQueryRequest;
  titleParam: TitleParam;
  isUaesQuery: boolean;
  pageSize: number;
}


interface SuperQueryResultActivateEventData extends MtDynamicTabsetActivateEventData {
  extra: Extra;
}

export class SuperQueryResultActivateEvent {
  static $name = 'super-query-result';
  static emit(data: SuperQueryResultActivateEventData) {
    MtEventBus.emit({
      name: SuperQueryResultActivateEvent.$name,
      data,
    });
  }

  static is(event: MtEvent<MtSafeAny>): boolean {
    return event != null && event.name === SuperQueryResultActivateEvent.$name;
  }

  static get(event: MtEvent<MtSafeAny>): SuperQueryResultActivateEventData {
    if (SuperQueryResultActivateEvent.is(event)) {
      return event.data;
    }
    return undefined;
  }
}
