import { Injectable } from '@angular/core';
import { MtDynamicTabHandler, MtDynamicTabRegister } from '@imerge22/common';
import { MtEvent, MtSafeAny, MtMap } from '@imerge22/core';
import { EbomTabComponent } from './ebom-tab';
import { EbomActivateEvent } from './events';

@Injectable({ providedIn: 'root' })
export class EbomTabHandler extends MtDynamicTabHandler {
  match(context: string, event: MtEvent<MtSafeAny>): boolean {
    if (event.name === EbomActivateEvent.$name) {
      const data = EbomActivateEvent.get(event);
      return data?.context === context;
    }
    return false;
  }

  same(context: string, exist: MtMap, event: MtEvent<MtSafeAny>): boolean {
    return false;
  }
}

export function registerBomDynamicTab() {
  MtDynamicTabRegister.register(EbomActivateEvent.$name, {
    component: EbomTabComponent,
    handler: EbomTabHandler,
  });
}
