<nz-drawer
  nzClosable
  nzWidth="82%"
  [nzVisible]="visible"
  nzPlacement="right"
  [nzTitle]="'platform.core.dict.lookProcess' | translate"
  (nzOnClose)="visible = false"
>
  <ng-container *ngIf="currentProcessIns">
    <mt-workflow-instance-detail
      [mtProcInsId]="currentProcessIns.id"
      [mtTaskTargetTemplate]="taskItemTemplate"
      [isAdmin]="isAdmin"
    ></mt-workflow-instance-detail>
    <ng-template #taskItemTemplate let-objectId>
      <mt-object-title
        [mtObjectId]="objectId"
        [mtExtraData]="{ task: currentProcessIns }"
      ></mt-object-title>
    </ng-template>
  </ng-container>
</nz-drawer>
