import { Component, OnInit, ViewChild } from '@angular/core';
import * as XLSX from 'xlsx';
import { MtNotificationBus } from '@imerge22/core/notification';
import { MtObjectDetailContext, MtObjectPanelContext } from '@imerge22/spectrum';
import { MtDataSourceService, ObjectDto } from '@imerge22/platform/base';
import { CommonUtils, downloader } from '@imerge22/platform/utils';
import { MtSafeAny } from '@imerge22/core';
import { PanelService } from 'src/app/services/panel.service';
import { SearchUpmnComponent } from '../../create-object/search-upmn/search-upmn.component';

@Component({
  selector: 'uccs-panel-imapacted',
  templateUrl: './panel-imapacted.component.html',
  styleUrls: ['./panel-imapacted.component.scss'],
})
export class PanelImapactedComponent extends MtObjectPanelContext implements OnInit {
  @ViewChild('upmn', { static: true }) upmn: SearchUpmnComponent;
  @ViewChild('fileInput', { static: true }) fileInput: MtSafeAny;
  tableList: MtSafeAny[] = [];
  query = {
    index: 1,
    size: 10,
    total: 0,
  };
  submiting = false;
  showImportDrawer = false;
  isSpinning = false;
  object: MtSafeAny;
  props: ObjectDto;
  fileName: string;
  excelData: MtSafeAny;
  dataList: MtSafeAny[];
  isBlank = CommonUtils.isBlank;
  TEMPLET_LINE = 2; // excel中模板数据所占行数
  public static get COED_NAME() {
    return 'Imapacted'; // 受影响项配置的code值
  }

  constructor(
    public mtObjectDetailContext: MtObjectDetailContext,
    // public data: MtObjectPanelData,
    public panelSrv: PanelService,
    public srv: MtDataSourceService
  ) {
    super();
  }

  ngOnInit() {
    this.srv.getObject(this.mtObjectDetailContext.mtObjectId).subscribe((object) => {
      const objectValue = this.mtObjectDetailContext.mtPropertyValues[this.mtObjectDetailContext.mtObjectId];
      this.object = object;
      // this.props = object.props;
      // if (
      //   CommonUtils.isNotBlank(this.props[PanelImapactedComponent.COED_NAME]) &&
      //   this.props[PanelImapactedComponent.COED_NAME][0] != null
      // ) {
      //   this.tableList = [...this.props[PanelImapactedComponent.COED_NAME]];
      // }
    });
  }

  get editable() {
    const editingPanel = this.mtObjectDetailContext.panels.find((m) => m.instance?.mtEditing);
    return (
      this.mtObjectDetailContext.mtEditable &&
      (editingPanel == null || editingPanel.instance === this)
    );
  }

  /**
   * 面板编辑
   */
  edit() {
    const editingPanel = this.mtObjectDetailContext.panels.find((m) => m.instance?.mtEditing);
    if (editingPanel != null) {
      return;
    }
    this.mtEditing = true;
    this.mtEditStatusChange.emit(true);
  }

  cancelEdit() {
    if (this.mtEditing) {
      this.mtEditing = false;
      this.mtEditStatusChange.emit(false);
      this.mtObjectDetailContext.cleanChanges();
    }
  }

  save() {
    this.submiting = true;
    this.mtObjectDetailContext.save().subscribe((result) => {
      this.submiting = false;
      if (result) {
        this.mtEditing = false;
        this.mtEditStatusChange.emit(false);
        this.mtObjectDetailContext.mtSaveChange.emit(this.panel);
      }
    });
  }

  // /**
  //  * 取消面板编辑
  //  */
  // cancelEdit() {
  //   if (
  //     CommonUtils.isNotBlank(this.props[PanelImapactedComponent.COED_NAME]) &&
  //     this.props[PanelImapactedComponent.COED_NAME][0] != null
  //   ) {
  //     this.tableList = [...this.props[PanelImapactedComponent.COED_NAME]];
  //   } else {
  //     this.tableList = [];
  //   }
  // }

  // /**
  //  * 保存编辑
  //  */
  // save() {
  //   this.props[PanelImapactedComponent.COED_NAME] = this.tableList;
  //   if (this.object != null) {
  //     const sub = this.srv
  //       .editObject(this.object, {
  //         panel: this.data.extra.panel,
  //         task: this.data.extra.objectData.extra.task,
  //       })
  //       .subscribe(
  //         () => {
  //           MtNotificationBus.emit({
  //             type: 'success',
  //             title: this.srv.getObjectTitle(this.object),
  //             message: 'i18n.platform.spectrum.object.saveSuccess',
  //           });
  //           sub.unsubscribe();
  //         },
  //         (error: Error) => {
  //           MtNotificationBus.emit({
  //             type: 'error',
  //             title: this.srv.getObjectTitle(this.object),
  //             message:
  //               error == null || error.message == null
  //                 ? 'i18n.platform.spectrum.object.saveFailed'
  //                 : error.message,
  //           });
  //           sub.unsubscribe();
  //         }
  //       );
  //   }
  // }

  /**
   * 添加受影响项
   */
  add() {
    this.upmn.open(null, true).subscribe((result) => {
      if (result != null) {
        result.map((item) => {
          if (!this.tableList.find((str) => str === item)) {
            this.tableList.push(item);
          }
        });
        this.tableList = [...this.tableList];
      }
    });
  }

  delete(i: number) {
    this.tableList.splice(i, 1);
    this.tableList = [...this.tableList];
  }

  openImport() {
    this.showImportDrawer = true;
  }

  /**
   * 关闭导入弹框
   */
  closeImport(flag?: boolean) {
    if (flag) {
      // 确定
      this.tableList = [...this.tableList, ...this.dataList];
    }
    this.showImportDrawer = false;
    this.dataList = [];
    this.fileName = null;
    this.fileInput.nativeElement.value = null;
  }

  /**
   * 模板下载
   */
  templateDownload() {
    this.panelSrv.downloadTemplate().subscribe(
      (resp) => {
        downloader.Download(
          resp.body,
          resp.headers.get('Content-Disposition').substr('attachment;fileName'.length)
        );
        MtNotificationBus.emit({
          type: 'success',
          title: 'i18n.platform.notification.success.exportModel',
          message: 'i18n.platform.notification.success.exportModelSuccess',
        });
      },
      (err: Blob) => {
        const reader = new FileReader();
        reader.onload = (e: MtSafeAny) => {
          let message = 'i18n.platform.notification.errors.objectTemplateDownloadLose';
          const obj = JSON.parse(e.target.result.toString());
          if (obj && obj.notAllowed) {
            message = obj.notAllowed[0];
          }
          MtNotificationBus.emit({
            type: 'error',
            title: 'i18n.platform.notification.errors.exportModel',
            message,
          });
        };
        reader.readAsText(err);
      }
    );
  }

  /**
   * 选择完文件触发事件
   */
  selectedFile(evt: MtSafeAny) {
    /* wire up file reader */
    const target: DataTransfer = evt.target as DataTransfer;
    if (target.files.length !== 1) {
      // throw new Error('Cannot use multiple files');
      this.fileName = null;
      return;
    }
    this.isSpinning = true;
    const reader: FileReader = new FileReader();
    reader.onload = (e: MtSafeAny) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary', dateNF: 'yyyy-mm-dd' });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      this.excelData = XLSX.utils.sheet_to_json(ws, { header: 1, raw: false }) as MtSafeAny[][];
      this.fileName = target.files[0].name;
      this.parseExcel();
    };
    reader.readAsBinaryString(target.files[0]);
  }

  /**
   * 解析文件
   */
  parseExcel() {
    let err = '';
    if (CommonUtils.isNotBlank(this.excelData) && this.excelData.length > this.TEMPLET_LINE) {
      this.excelData.splice(0, this.TEMPLET_LINE); // 删除模板行数据
      const list = [];
      this.dataList = list;
      this.excelData.map((row, i) => {
        if (
          CommonUtils.isBlank(row) ||
          (CommonUtils.isBlank(row[0]) &&
            CommonUtils.isBlank(row[1]) &&
            CommonUtils.isBlank(row[2]))
        ) {
          // 如果某一行没有填写数据，跳过
        } else if (
          CommonUtils.isBlank(row[0]) ||
          CommonUtils.isBlank(row[1]) ||
          CommonUtils.isBlank(row[2])
        ) {
          err += `第${i + 1 + this.TEMPLET_LINE}行数据不符合规则<br/>`;
        } else {
          // 编码，名称，版本
          list.push({ itemId: row[0], objectName: row[1], itemRevisionId: row[2] });
        }
      });
      if (err !== '') {
        err = '请检查数据并按照模板中的注意事项填写<br/>' + err;
      }
      if (CommonUtils.isBlank(list)) {
        err = '没有要上传的数据';
      }
    } else {
      err = '请下载模板，根据样例填写数据上传';
    }
    if (err !== '') {
      // 提示错误信息
      MtNotificationBus.emit({
        type: 'error',
        title: 'i18n.platform.common.error',
        message: err,
      });
    }
    this.isSpinning = false;
  }
}
