import { Component, EventEmitter, OnInit, TemplateRef, ViewChild, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DashboardService, SystemParameter } from '@imerge22/base';
import { MtDynamicTabBase } from '@imerge22/common';
import {
  CommonUtils,
  FilterOperator,
  GenericQueryRequest,
  MtConfigService,
  MtEventBus,
  MtEventName,
  MtMap,
  MtSafeAny,
  MtTranslateService,
  Page,
  PageRequest,
  PassportService,
  ProcessInstanceStatus,
} from '@imerge22/core';
import { MtObjectComponentBase } from '@imerge22/spectrum/objects';
import { MtSpectrumDataService, MtSpectrumDefineService, MtSpectrumSecurityService, MtTabNameEnum, MtTabsService } from '@imerge22/spectrum/services';
import { ActivatedRoute } from '@angular/router';
import { RepeatSubmitProcessService } from '../repeat-submit-process.service';

@Component({
  selector: 'mt-task-todo-list',
  templateUrl: './task-todo-list.component.html',
  styleUrls: ['./task-todo-list.component.scss']
})
export class MtTaskTodoListComponent extends MtObjectComponentBase implements MtDynamicTabBase, OnInit, OnDestroy {

  @ViewChild('tablePCN', { static: true }) public table: MtSafeAny;
  @ViewChild('tabTitle') title: TemplateRef<void>;
  onCloseByInner: EventEmitter<void>;
  /** 控制tab页不可关闭 */
  shieldCloseBtn = true;
  private $destroyed = new Subject<void>();
  searchKey: any;
  loading: boolean;
  spectrumServiceName: string;
  rbacServiceName: string;
  querier: FormGroup;
  page: PageRequest = { index: 1, size: 10 };
  datas: Page<SystemParameter> = { data: [], total: 0, totalPages: 0 };
  router: ActivatedRoute;
  queryProps: GenericQueryRequest;
  queryDatas: GenericQueryRequest;
  workflowStatus = [
    {
      code: ProcessInstanceStatus.Processing,
      name: '进行中',
      nameEn: ProcessInstanceStatus.Processing
    },
    {
      code: ProcessInstanceStatus.SuccessEnd,
      name: '正常结束',
      nameEn: ProcessInstanceStatus.SuccessEnd
    },
    {
      code: ProcessInstanceStatus.FailedEnd,
      name: '失败结束',
      nameEn: ProcessInstanceStatus.FailedEnd
    },
    {
      code: ProcessInstanceStatus.Aborted,
      name: '已终止',
      nameEn: ProcessInstanceStatus.Aborted
    },
  ];
  // 定义初始查询条件
  params = [
    {
      prop: 'assignee',
      operate: FilterOperator.Equals,
      value: this.passport.current.user.id,
    },
    // { prop: 'result', operate: FilterOperator.Null },
  ];

  public checked: boolean = false;
  public setOfCheckedId = new Set<string>();

  constructor(
    defSvc: MtSpectrumDefineService,
    dataSvc: MtSpectrumDataService,
    secSvc: MtSpectrumSecurityService,
    router: ActivatedRoute,
    private fb: FormBuilder,
    private passport: PassportService,
    private configSvc: MtConfigService,
    private svc: DashboardService,
    private translate: MtTranslateService,
    private tabSrv: MtTabsService,
    private repeatSubmitProcessSvc: RepeatSubmitProcessService,
  ) {
    super(defSvc, dataSvc, secSvc);
    this.router = router;
    this.spectrumServiceName = configSvc.getConfiguration('spectrumServiceName');
    this.rbacServiceName = configSvc.getConfiguration('rbacServiceName');
  }

  ngOnInit(): void {
    this.querier = this.fb.group({
      name: [null],
      status: [null],
      owner: [null],
      startTime: [null],
      // endTime: [null],
    });

    MtEventBus.events.pipe(
      takeUntil(this.$destroyed)
    ).subscribe(event => {
      if (event.name === MtEventName.WORKFLOW_DETAIL_CLOSE) {
        this.searchData();
      }
    });
    this.searchData();
    this.tabSrv.tabCompleteInitialized(MtTabNameEnum.TASK_TODO_LIST);

    // 处理从url直接进入单个待办
    this.router.queryParams.subscribe((data) => {
      // url中获取id
      if (data.id) {
        this.svc.getMyTaskById(data.id).subscribe((res) => {
          if(res && res.data.length > 0) {
            setTimeout(() => {
              MtEventBus.emit({
                name: 'task-activate',
                data: {
                  context: 'TASK_TODO_LIST',
                  taskId: data.id,
                  task: res.data[0],
                }
              });
            }, 100);
          }
        })
      }
    });

  }

  onActivated(): void { }
  onDeactivated(): void { }
  onDataChange(data: MtMap<any>): void { }
  activate(): void { }
  deactivate(): void { }

  openWorkflowDetail(task) {
    if (task) {
      MtEventBus.emit({
        name: 'task-activate',
        data: {
          context: 'TASK_TODO_LIST',
          taskId: task.id,
          task,
        }
      });
    }
  }

  reload() {
    this.setOfCheckedId = new Set<string>();
    // 清空输入框
    this.querier.reset();
    // 将查询条件恢复默认
    this.params = [
      {
        prop: 'assignee',
        operate: FilterOperator.Equals,
        value: this.passport.current.user.id,
      },
      // { prop: 'result', operate: FilterOperator.Null },
    ];
    this.searchData();
  }

  searchData() {
    // this.svc.getMyTaskList(this.page, [
    //   ...this.params, {
    //     prop: 'procIns.status',
    //     operate: FilterOperator.Equals,
    //     value: ProcessInstanceStatus.Processing,
    //   }, {
    //     prop: 'result',
    //     operate: FilterOperator.Null
    //   },
    // ]).subscribe(
    //   (res) => {
    //     this.datas.data = res.resultData.data;
    //     this.datas.total = res.resultData.total;
    //   },
    //   (err: Error) => { }
    // );
    this.repeatSubmitProcessSvc.getMyTaskAndObjectInfoList(this.page, [
      ...this.params, {
        prop: 'procIns.status',
        operate: FilterOperator.Equals,
        value: ProcessInstanceStatus.Processing,
      }, {
        prop: 'result',
        operate: FilterOperator.Null
      },
    ]).subscribe(
      (res) => {
        this.datas.data = res?.resultData?.data || [];
        this.datas.total = res?.resultData?.total || 0;
      },
      (err: Error) => { }
    );
  }
  search() {
    this.page.index = 1;
    // 点击查询先将查询条件恢复默认  然后再添加查询条件
    this.params = [
      {
        prop: 'assignee',
        operate: FilterOperator.Equals,
        value: this.passport.current.user.id,
      },
      // { prop: 'result', operate: FilterOperator.Null },
    ];
    const keys = Object.keys(this.querier.value);
    const value = Object.values(this.querier.value).some((item) => Boolean(item) == true);

    // 如果输入框有值就按照输入框查询
    if (value) {
      keys.map((key) => {
        if (CommonUtils.isNotBlank(this.querier.value[key])) {
          // 将输入框的时间格式化
          if (this.querier.value.startTime && key == 'startTime') {
            this.querier.value.startTime[0].setHours(0, 0, 0, 0);
            this.params.push({
              prop: 'startTime',
              operate: FilterOperator.GreatThan,
              value: this.querier.value.startTime[0],
            });
            this.querier.value.startTime[1].setHours(23, 59, 59, 999);
            this.params.push({
              prop: 'startTime',
              operate: FilterOperator.LessThan,
              value: this.querier.value.startTime[1],
            });
          } else if (key == 'status') {
            this.params.push({
              prop: 'procIns.' + key,
              operate: FilterOperator.Equals,
              value: this.querier.value[key].trim(),
            });
          } else {
            this.params.push({
              prop: 'procIns.' + key,
              operate: FilterOperator.Like,
              value: this.querier.value[key].trim(),
            });
          }
        }
      });
    }
    this.searchData();
  }
  isEn() {
    return this.translate.currentLang.startsWith('en');
  }
  // 流程状态类型
  loadStatus() { }

  getLanguageName(data: MtSafeAny) {
    if (data != null) {
      if (this.translate.currentLang.startsWith('en')) {
        return data.nameEn || data.name;
      } else {
        return data.name;
      }
    }
  }

  parseName(statusCode: string) {
    return this.getLanguageName(this.workflowStatus.find(item => item.code === statusCode));
  }

  ngOnDestroy(): void {
    this.$destroyed.next();
    this.$destroyed.complete();
    this.tabSrv.tabDestoryed(MtTabNameEnum.TASK_TODO_LIST);
  }
  getStyle() {
    const tableArr = document.getElementsByClassName('ant-table');
    const theadArr = document.getElementsByTagName('thead');
    if (tableArr && tableArr[0] && theadArr && theadArr[0]) {
      return { y: tableArr[0].clientHeight - theadArr[0].clientHeight - 10 + 'px' };
    } else {
      return {};
    }
  }

  public onAllChecked(value: boolean): void {
    this.datas.data.forEach(item => this.updateCheckedSet(item.id, value));
    this.refreshCheckedStatus();
  }

    // 选择单个的对像
  public  onItemChecked(itemId: string, checked: boolean): void {
      this.updateCheckedSet(itemId, checked);
      this.refreshCheckedStatus();
  }

  // 审批通过/批量审批通过
  public async approveSubmit(): Promise<void> {
    this.loading = true;
    let itemIds: Array<String> = [...this.setOfCheckedId];
    let params = [];
    this.datas.data.forEach((res: MtSafeAny) => {
      let param = {};
      if (itemIds.indexOf(res.id) !== -1) {
        param = {
          id: res.id,
          reqData: {
            copyTo: [],
            result: 'Approved'
          }
        };
        params.push(param);
      }
    });
    await this.repeatSubmitProcessSvc.repeatSubmit(params).toPromise().catch(() => {
      this.loading = false;
    });
    this.loading = false;
    this.reload();
  }

  private updateCheckedSet(itemId: string, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(itemId);
    } else {
      this.setOfCheckedId.delete(itemId);
    }
  }

  private refreshCheckedStatus(): void {
    this.checked = this.datas.data.every((res) => this.setOfCheckedId.has(res.id));
  }
}
