import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MtTranslateModule } from '@imerge22/core/translate';
import { MtObjectTitleModule } from '@imerge22/platform/object-title';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { EcrNoComponent } from './ctrls-module/ecr-no/ecr-no.component';
import { CostComponent } from './ctrls-module/ecr_computers';
import { PackSearchComponent } from './ctrls-module/ecr_no_search/ecr_no_search.component';
import { PackDictComponent } from './ctrls-module/ecr_pack_dictsort_input';
import { PackInfomationComponent } from './ctrls-module/ecr_pack_infomation';
import { UccsExampleCtrl } from './example/example.component';
import { MtChangeItemInformatinDataInputComponent } from './mt-change-item-information-data-input/mt-change-item-information-data-input';
import { MtChangeItemInfromationModelDownloaderComponent } from './mt-change-item-information-model-downloader/mt-change-item-information-model-downloader.component';
import { MtFinishTimeComponent } from './mt-finish-time/mt-finish-time.component';
import { MtImpactProductDataInputComponent } from './mt-impact-product-data-input/mt-impact-product-data-input.component';
import { MtImpactProductDownloaderComponent } from './mt-impact-product-downloader/mt-impact-product-downloader.component';
import { MtRelativeEcrNoComponent } from './mt-relative-ecr-no/mt-relative-ecr-no.component';
import { UserSelectComponent } from './user-select/user-select.component';
import { MtSpectrumProjectDetailOrgChangeComponent } from './project-detail-org-change/project-detail-org-change.component'
import { MtSpectrumUserSelectComponent } from './mt-user-select/mt-user-select.component';
import { KSelectProjectComponent } from './k-select-project/k-select-project.component';

const components = [
  UccsExampleCtrl,
  EcrNoComponent,
  CostComponent,
  MtChangeItemInfromationModelDownloaderComponent,
  MtImpactProductDownloaderComponent,
  MtChangeItemInformatinDataInputComponent,
  MtImpactProductDataInputComponent,
  MtFinishTimeComponent,
  MtRelativeEcrNoComponent,
  PackSearchComponent,
  PackDictComponent,
  PackInfomationComponent,
  UserSelectComponent,
  MtSpectrumProjectDetailOrgChangeComponent,
  MtSpectrumUserSelectComponent,
  KSelectProjectComponent,
];

@NgModule({
  declarations: [...components, MtFinishTimeComponent, MtRelativeEcrNoComponent],
  imports: [
    CommonModule,
    FormsModule,

    NzButtonModule,
    NzUploadModule,
    NzTableModule,
    NzDrawerModule,
    NzToolTipModule,
    NzInputModule,
    NzCheckboxModule,
    NzGridModule,
    NzInputModule,
    NzSelectModule,
    NzIconModule,
    NzFormModule,
    NzCardModule,
    NzPopconfirmModule,

    MtObjectTitleModule,
    MtTranslateModule,
  ],
  exports: [...components],
})
export class CtrlsModule {}
