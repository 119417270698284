import { MtEvent, MtEventBus, MtSafeAny } from '@imerge22/core';
import { MtDynamicTabsetActivateEventData } from '@imerge22/common';

interface ApprovedWorkHoursActivateEventData extends MtDynamicTabsetActivateEventData{
  id?: string;
  type?: string;
  extra?: MtSafeAny;
}

export class ApprovedWorkHoursActivateEvent {
  static $name = 'approved-work-hours-activate';
  static emit(data: ApprovedWorkHoursActivateEventData) {
    MtEventBus.emit({
      name: ApprovedWorkHoursActivateEvent.$name,
      data,
    });
  }

  static is(event: MtEvent<MtSafeAny>): boolean {
    return event != null && event.name === ApprovedWorkHoursActivateEvent.$name;
  }

  static get(event: MtEvent<MtSafeAny>): ApprovedWorkHoursActivateEventData {
    if (ApprovedWorkHoursActivateEvent.is(event)) {
      return event.data;
    }
    return undefined;
  }
}
