import { MtEvent, MtEventBus, MtSafeAny } from '@imerge22/core';
import { MtDynamicTabsetActivateEventData } from '@imerge22/common';


interface EcnDynamicTabsetActivateEventData extends MtDynamicTabsetActivateEventData{
  id?: string;
  type?: string;
  extra?: MtSafeAny;
}


export class EcnAllActivateEvent {
  static $name = 'ecn-all-activate';
  static emit(data: EcnDynamicTabsetActivateEventData) {
    MtEventBus.emit({
      name: EcnAllActivateEvent.$name,
      data,
    });
  }

  static is(event: MtEvent<MtSafeAny>): boolean {
    return event != null && event.name === EcnAllActivateEvent.$name;
  }

  static get(event: MtEvent<MtSafeAny>): EcnDynamicTabsetActivateEventData {
    if (EcnAllActivateEvent.is(event)) {
      return event.data;
    }
    return undefined;
  }
}

export class MyEcnActivateEvent {
  static $name = 'my-ecn-activate';
  static emit(data: EcnDynamicTabsetActivateEventData) {
    MtEventBus.emit({
      name: MyEcnActivateEvent.$name,
      data,
    });
  }

  static is(event: MtEvent<MtSafeAny>): boolean {
    return event != null && event.name === MyEcnActivateEvent.$name;
  }

  static get(event: MtEvent<MtSafeAny>): EcnDynamicTabsetActivateEventData {
    if (MyEcnActivateEvent.is(event)) {
      return event.data;
    }
    return undefined;
  }
}

export class FollowEcnActivateEvent {
  static $name = 'follow-ecn-activate';
  static emit(data: EcnDynamicTabsetActivateEventData) {
    MtEventBus.emit({
      name: FollowEcnActivateEvent.$name,
      data,
    });
  }

  static is(event: MtEvent<MtSafeAny>): boolean {
    return event != null && event.name === FollowEcnActivateEvent.$name;
  }

  static get(event: MtEvent<MtSafeAny>): EcnDynamicTabsetActivateEventData {
    if (FollowEcnActivateEvent.is(event)) {
      return event.data;
    }
    return undefined;
  }
}

export class EcnDetailActivateEvent {
  static $name = 'ecn-detail-activate';
  static emit(data: EcnDynamicTabsetActivateEventData) {
    console.log('EcnDetailActivateEvent');
    MtEventBus.emit({
      name: EcnDetailActivateEvent.$name,
      data,
    });
  }

  static is(event: MtEvent<MtSafeAny>): boolean {
    return event != null && event.name === EcnDetailActivateEvent.$name;
  }

  static get(event: MtEvent<MtSafeAny>): EcnDynamicTabsetActivateEventData {
    if (EcnDetailActivateEvent.is(event)) {
      return event.data;
    }
    return undefined;
  }
}
