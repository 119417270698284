import { Injectable } from '@angular/core';
import { MtNotificationBus } from '@imerge22/core';
import { MtWorkflowStarterService } from '@imerge22/workflow';
import { Observable } from 'rxjs';
import { delay, repeatWhen, takeWhile } from 'rxjs/operators';
import { WorkingHoursService } from './working-hours.service';

@Injectable({
    providedIn: 'root'
})
export class RepeatSubmitService {
    public constructor(private workHoursSvc: WorkingHoursService, private startSvc: MtWorkflowStarterService) {}
    public repeatSubmit(data: Array<any>): Observable<any> {
        let count = 0;
        let errorMessage;
        const source = new Observable<any>(observer => {
            this.startSvc.save(data[count]).subscribe(item => {
                count ++; // 模拟拿到数据,并修改状态，例如 进度条进度 todo....
                observer.complete(); // 请求完就complete，触发repeatWhen
            }, error => {
                // count ++; // 模拟拿到数据,并修改状态，例如 进度条进度 todo....
                // observer.complete(); // 请求完就complete，触发repeatWhen
                if (error?.countResultLessZero) {
                    MtNotificationBus.emit({
                        type: 'error',
                        title: 'i18n.platform.common.error',
                        message: error?.countResultLessZero.join(','),
                    });
                }
                errorMessage = 1;
                observer.error();
                throw error;
            });
        });
        return source.pipe(
            repeatWhen((notifications) => {
                return notifications.pipe(
                    delay(1000),
                    takeWhile(() => {
                        if (count > data.length - 1) {
                            MtNotificationBus.emit({
                                type: 'success',
                                title: 'i18n.platform.common.success',
                                message: 'i18n.platform.notification.success.submitSuccesss',
                            });
                        }
                        return count < data.length && !errorMessage;
                    }),
                    // 可设置20秒轮询超时时间，此20秒包含 请求时间 及 delay(2000) 的时间
                    // 例如在此处20秒大概是5次轮询后超时
                );
            })
        );
    }
}
