import { Injectable } from '@angular/core';
import { MtDynamicTabHandler, MtDynamicTabRegister } from '@imerge22/common';
import { MtEvent, MtSafeAny, MtMap } from '@imerge22/core';
import { ProjectAccountingActivateEvent } from './events';
import { ProjectAccountingListComponent } from './project-accounting-list/project-accounting-list.component';

@Injectable({ providedIn: 'root' })
export class ProjectAccountingTabHandler extends MtDynamicTabHandler {
  match(context: string, event: MtEvent<MtSafeAny>): boolean {
    if (event.name === ProjectAccountingActivateEvent.$name) {
      const data = ProjectAccountingActivateEvent.get(event);
      return data?.context === context;
    }
    return false;
  }

  same(context: string, exist: MtMap, event: MtEvent<MtSafeAny>): boolean {
    if (event.name === ProjectAccountingActivateEvent.$name) {
      const data = ProjectAccountingActivateEvent.get(event);
      return data?.context === context;
    }
    return false;
  }
}

export function registerProjectAccountingDynamicTab() {
  MtDynamicTabRegister.register(ProjectAccountingActivateEvent.$name, {
    component: ProjectAccountingListComponent,
    handler: ProjectAccountingTabHandler,
    initializeComponent: true,
  });
}
