import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class RefreshListTabService {
    public refreshTabSubject: Subject<any> = new Subject<any>();

    public sendRefresh(code: string): void {
        this.refreshTabSubject.next(code);
    }

    public register(): Observable<any> {
        return this.refreshTabSubject.asObservable();
    }

    public destroy(): void {
        this.refreshTabSubject.unsubscribe();
    }
}