import { MtDynamicTabsetActivateEventData } from '@imerge22/common';
import { MtEvent, MtEventBus, MtSafeAny } from '@imerge22/core';

export class MtTaskTodoListActivateEvent {
  static $name = 'task-todo-list-activate';
  static emit(data: MtDynamicTabsetActivateEventData) {
    MtEventBus.emit({
      name: MtTaskTodoListActivateEvent.$name,
      data,
    });
  }

  static is(event: MtEvent<MtSafeAny>): boolean {
    return event != null && event.name === MtTaskTodoListActivateEvent.$name;
  }

  static get(event: MtEvent<MtSafeAny>): MtDynamicTabsetActivateEventData {
    if (MtTaskTodoListActivateEvent.is(event)) {
      return event.data;
    }
    return undefined;
  }
}
