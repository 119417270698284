import { Component, OnInit, EventEmitter, forwardRef, Input } from '@angular/core';
import { ObjectDto } from '@imerge22/platform/base';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { CommonUtils } from '@imerge22/platform/utils';
import { MtNotificationBus } from '@imerge22/core/notification';
import { CreateObjectService } from 'src/app/services/create-object.service';

export interface SearchUpmnDTO {
  itemId?: string;
  objectName?: string;
  itemRevisionId?: string;
  searchType?: string;
  isChecked?: boolean;
}

@Component({
  selector: 'uccs-search-upmn',
  templateUrl: './search-upmn.component.html',
  styleUrls: ['./search-upmn.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchUpmnComponent),
      multi: true,
    },
  ],
})
export class SearchUpmnComponent implements OnInit {
  // 控制上一步下一步按钮是否显示
  @Input() notShowSetp: boolean;
  private result: EventEmitter<any>;
  visible: boolean;
  query = {
    index: 1,
    size: 10,
    total: 0,
  };
  model: SearchUpmnDTO;
  tableList: SearchUpmnDTO[];
  tableVM: {
    selectAll?: boolean;
    selectInvert?: boolean;
  };
  isSearch: boolean;

  constructor(private srv: CreateObjectService) { }

  ngOnInit() {
    this.reset();
  }

  search() {
    if (CommonUtils.isBlank(this.model.itemId) && CommonUtils.isBlank(this.model.objectName)) {
      MtNotificationBus.emit({
        type: 'error',
        title: 'i18n.platform.common.error',
        message: '编码和名称必须填写一个',
      });
      return;
    } else if (this.model.searchType === 'total' && new RegExp('[*,?]+').test(this.model.itemId)) {
      MtNotificationBus.emit({
        type: 'error',
        title: 'i18n.platform.common.error',
        message: '请输入明确的零件ID',
      });
      return;
    }
    this.isSearch = true;
    this.srv.queryUpmn(this.model, this.model.searchType).subscribe(
      (resp: any) => {
        if (resp && resp.resultInfo && resp.resultInfo.RESULT === 'S') {
          this.tableList = resp.resultInfo.DATAINFO;
          this.query.total = resp.queryInfo.totalRecord;
        } else {
          MtNotificationBus.emit({
            type: 'info',
            title: 'i18n.platform.common.info',
            message: '未找到数据',
          });
          this.tableList = [];
          this.query.total = 0;
        }
        this.isSearch = false;
      },
      (err: Error) => {
        MtNotificationBus.emit({
          type: 'error',
          title: 'i18n.platform.common.error',
          message: err.message,
        });
        this.isSearch = false;
      }
    );
  }

  reset() {
    this.model = {
      searchType: 'part',
    };
    // this.tableList = [];
    this.tableList = [
      { itemId: 'N-001', objectName: '对象11', itemRevisionId: '01' },
      { itemId: 'N-002', objectName: '对象12', itemRevisionId: '01' },
      { itemId: 'N-003', objectName: '对象13', itemRevisionId: '01' },
      { itemId: 'N-004', objectName: '对象14', itemRevisionId: '01' }
    ];
    this.tableVM = {
      selectAll: false,
      selectInvert: false,
    };
    this.isSearch = false;
  }

  open(item: ObjectDto, reset?: boolean) {
    if (this.result == null) {
      this.result = new EventEmitter<any>();
    }
    if (reset) {
      this.reset();
    }
    this.visible = true;
    return this.result;
  }

  prevStep() {
    this.visible = false;
    this.result.emit('prevStep');
  }

  cancel() {
    this.visible = false;
    this.result.emit();
  }

  save() {
    const seletedList = this.tableList
      .filter((item) => item.isChecked)
      .map((item) => `${item.itemId}/${item.itemRevisionId}-${item.objectName}`);
    this.visible = false;
    this.result.emit(seletedList);
  }

  checkSave() {
    const arr = this.tableList.filter((item) => item.isChecked);
    return CommonUtils.isBlank(arr);
  }

  changePage(e?: any) {
    console.log(e);
  }

  clickRow(data) {
    data.isChecked = !data.isChecked;
  }

  checkAll(isChecked: boolean) {
    this.tableList.map((item) => (item.isChecked = isChecked));
  }

  selecCheckChange(isChecked: boolean, type: string) {
    if (type === 'all') {
      // 全选
      this.checkAll(isChecked);
      if (isChecked) {
        this.tableVM.selectInvert = false;
      }
    } else {
      // 反选
      if (isChecked) {
        this.tableList.map((item) => (item.isChecked = !item.isChecked));
        this.tableVM.selectAll = false;
      }
    }
  }
}
