import { Component } from '@angular/core';
import { MtConfigService, MtCrossBrowserTabSessionStorage } from '@imerge22/core';

@Component({
  selector: 'uccs-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})

export class AppComponent {
  constructor(public mtConfigSrv: MtConfigService) {
    MtCrossBrowserTabSessionStorage.event.subscribe((e: StorageEvent) => {
      if (e != null && ['logout', 'login'].includes(e.key)) {
        location.reload();
      }
    });
  }
}
