<div class="modal-content">
  <div class="header">
    {{ 'koa.modal.addWorkHours' | translate }}
  </div>
  <div class="modal-body">
    <form style="margin-top: 20px;" nz-form [formGroup]="validateForm">
      <!-- <nz-form-item>
        <nz-form-label [nzSpan]="7" nzRequired>
          {{ 'platform.base.obj.objectType' | translate }}
        </nz-form-label>
        <nz-form-control [nzSpan]="12">
          <nz-tree-select
            [nzNodes]="nodes"
            nzAllowClear
            name="modelId"
            nzDropdownMatchSelectWidth="false"
            nzShowSearch
            [nzDropdownStyle]="{ 'max-height': '200px' }"
            formControlName="type"
          ></nz-tree-select>
        </nz-form-control>
      </nz-form-item> -->
      <!-- <nz-form-item>
        <nz-form-label [nzSpan]="7" nzRequired>
          {{ 'platform.base.obj.objectName' | translate }}
        </nz-form-label>
        <nz-form-control [nzSpan]="12">
          <input nz-input formControlName="name" />
        </nz-form-control>
      </nz-form-item> -->
      <!-- <nz-form-item *ngIf="srv.isItem(validateForm.get('type').value) || srv.isItemRevision(validateForm.get('type').value)">
        <nz-form-label [nzSpan]="7" nzRequired>
          {{ 'platform.base.obj.codingRules' | translate }}
        </nz-form-label>
        <nz-form-control [nzSpan]="12">
          <nz-select
            formControlName="codeRule"
            nzAllowClear
          >
            <nz-option
              *ngFor="let item of rulesList"
              [nzValue]="item.id"
              [nzLabel]="item.ruleName"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item> -->
      <!-- <nz-form-item *ngIf="srv.isItem(validateForm.get('type').value) || srv.isItemRevision(validateForm.get('type').value)">
        <nz-form-label [nzSpan]="7" nzRequired>
          {{ 'platform.base.obj.objectNumber' | translate }}
        </nz-form-label>
        <nz-form-control [nzSpan]="12" [nzErrorTip]="codeErrorTpl">
          <input nz-input formControlName="code" [maxlength]="validateForm.get('codeRule').value === '-1' ? '10' : null"/>
          <ng-template #codeErrorTpl let-control>
            <ng-container *ngIf="control.hasError('format')">
              {{ control.getError('msg') | translate }}
            </ng-container>
          </ng-template>
        </nz-form-control>
      </nz-form-item> -->
      <!-- <nz-form-item>
        <nz-form-label [nzSpan]="7">
          {{ 'platform.base.obj.remark' | translate }}
        </nz-form-label>
        <nz-form-control [nzSpan]="12">
          <textarea formControlName="remark" nz-input rows="4"></textarea>
        </nz-form-control>
      </nz-form-item> -->
      <nz-form-item *ngFor="let item of detail">
        <nz-form-label [nzSpan]="7" [nzRequired]="item?.required">
          {{ item?.label }}
        </nz-form-label>
        <nz-form-control [nzSpan]="12">

          <textarea *ngIf="item.key == 'comment'" rows="4" nz-input [formControlName]="item?.key"></textarea>
          
          <!-- <nz-select
              *ngIf="item?.key == 'CustID'"
              nzShowSearch
              nzServerSearch
              nzPlaceHolder="输入用户名查询"
              [formControlName]="item?.key"
              [nzShowArrow]="false"
              [nzFilterOption]="nzFilterOption"
              (nzOnSearch)="searchCustomer($event)"
            >
            <nz-option *ngFor="let o of listOfCustomerOption" [nzLabel]="o?.text" [nzValue]="o?.value"></nz-option>
          </nz-select> -->
          <nz-select
              *ngIf="item?.key == 'prjid'"
              nzShowSearch
              nzServerSearch
              nzPlaceHolder="输入项目名称查询"
              [formControlName]="item?.key"
              [nzShowArrow]="false"
              [nzFilterOption]="nzFilterOption"
              (nzOnSearch)="searchCustomerEmp($event)"
            >
            <nz-option *ngFor="let o of projectOption" [nzLabel]="o?.text" [nzValue]="o?.value"></nz-option>
          </nz-select>

          <nz-select
              *ngIf="item?.key == 'SUBMIT' || item?.key == 'workcheck'"
              [formControlName]="item?.key"
              nzAllowClear
              nzPlaceHolder=""
            >
              <nz-option
                *ngFor="let item of isSubmitCodeList"
                [nzValue]="item.code"
                [nzLabel]="item.name"
              ></nz-option>
          </nz-select>

          <nz-select
            *ngIf="item?.key == 'depid'"
            [formControlName]="item?.key"
            nzAllowClear
            nzPlaceHolder="请选择员工部门"
          >
            <nz-option
              *ngFor="let item of orgListOption"
              [nzValue]="item.code"
              [nzLabel]="item.name"
            ></nz-option>
        </nz-select>
          
          <input *ngIf="item.type == 'STRING' && item.key !== 'projectContent' && item.key !== 'comment' && item.key !== 'depid'" nz-input [formControlName]="item?.key" />
          <input *ngIf="item.type == 'NUMBER'" nz-input type="number" [formControlName]="item?.key" />
          <ng-container *ngIf="item.key == 'projectManagerId' || item.type == 'RELATION' && item.key !== 'prjid'">
            <mt-workflow-assignee-selector
              class="search-item"
              mtValueType="single-user"
              [formControlName]="item?.key"
            ></mt-workflow-assignee-selector>
          </ng-container>
          <nz-date-picker *ngIf="item.type == 'DATE'" [formControlName]="item?.key"></nz-date-picker>
        </nz-form-control>
    </nz-form-item>
    </form>
    <nz-alert nzType="info" 
      [nzMessage]="'platform.base.obj.initialView' | translate"
      *ngIf="panels.length"
    >
    </nz-alert>
    <nz-tabset
      *ngIf="panels?.length"
    >
      <nz-tab *ngFor="let panel of panels; let i = index" [nzTitle]="panelTabTitle">
        <!-- 加载中状态 -->
        <nz-spin *ngIf="mtObjectInited[i ? mtObjectId : mtObjectItemId] !== 'loaded'" [nzIndicator]="indicatorTemplate" [nzSpinning]="mtObjectInited[i ? mtObjectId : mtObjectItemId] !== 'loaded'">
          <ng-template #indicatorTemplate>
            <i style="font-size: 40px; position: fixed; top: 50%" nz-icon nzType="loading"></i>
          </ng-template>
        </nz-spin>
        <ng-template #panelTabTitle>
          <div class="mt-tab-title">
            {{panel.panel.model.code}}{{'platform.base.obj.initialView'| translate}}
          </div>
        </ng-template>
        <!--加载完成后-->
        <ng-container *ngIf="mtObjectInited[mtObjectId] === 'loaded'">
          <!-- 注入控件显示 -->
          <ng-container *ngIf="haveAuthority; else noPermission">
            <ng-container [mtPanelRenderer]="panel" [mtActivate]="true"></ng-container>
          </ng-container>

          <!-- 无权限时显示 -->
          <ng-template #noPermission>
            <nz-result nzStatus="warning" [nzTitle]="'platform.spectrum.noPermission' | translate"> </nz-result>
          </ng-template>
        </ng-container>
      </nz-tab>
    </nz-tabset>
  </div>
  <div class="footer-content">
    <button nz-button
      nzShape="round"
      class="ant-btn-md"
      nz-popconfirm
      [nzPopconfirmTitle]="'platform.spectrum.acm.cancelProText' | translate"
      (nzOnConfirm)="close()"
      nzPopconfirmPlacement="left">
      <span> {{ 'platform.core.dict.cancel' | translate }}</span>
    </button>
    <button
      nz-button
      nzShape="round"
      nzType="primary"
      type="button"
      [disabled]="!validateForm.valid"
      [nzLoading]="isEditloading"
      (click)="submitEdit()"
    >
      <span>{{ 'platform.core.dict.determine' | translate }}</span>
    </button>
  </div>
</div>