import { MtDynamicTabsetActivateEventData } from '@imerge22/common';
import { MtEvent, MtEventBus, MtSafeAny } from '@imerge22/core';

interface MtProjectHoursAccountingData extends MtDynamicTabsetActivateEventData {
  id?: string;
  type?: string;
  extra?: MtSafeAny;
}

export class ProjectHoursAccountingActivateEvent {
  static $name = 'project-hours-accounting';
  static emit(data: MtProjectHoursAccountingData) {
    MtEventBus.emit({
      name: ProjectHoursAccountingActivateEvent.$name,
      data,
    });
  }

  static is(event: MtEvent<MtSafeAny>): boolean {
    return event != null && event.name === ProjectHoursAccountingActivateEvent.$name;
  }

  static get(event: MtEvent<MtSafeAny>): MtProjectHoursAccountingData {
    if (ProjectHoursAccountingActivateEvent.is(event)) {
      return event.data;
    }
    return undefined;
  }
}
