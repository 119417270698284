import { Component, EventEmitter, OnInit, Optional, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MtDictCacheService, UserServiceService } from '@imerge22/base';
import { MtContextDirective, MtDynamicTabBase } from '@imerge22/common';
import {
  CommonUtils,
  FilterOperator,
  MtMap,
  MtNotificationBus,
  MtSafeAny,
  MtTranslateService,
  PageRequest,
  PassportService,
} from '@imerge22/core';
import { MtDataSourceService, MtObjectComponentBase } from '@imerge22/platform/base';
import {
  MtObjectData,
  MtSpectrumDataService,
  MtSpectrumDefineService,
  MtSpectrumSecurityService,
} from '@imerge22/spectrum';
import { NzModalService } from 'ng-zorro-antd/modal';
import { CustomerInfoService } from '../services/customer-info.service';
// import { MtWorkflowStarterComponent } from '../../starter';

import { GetModelByCodeService } from '../../../common/services/get-model-by-code.service';
import { RefreshListTabService } from '../../../working-hours/working-hours/services/refresh-list.service';
import { CustomerInfoActivateEvent } from '../customer-info-detail/events';
import { CustomerInfoModalComponent } from '../customer-info-modal/customer-info-modal.component';

@Component({
  templateUrl: './customer-info-list.component.html',
  styleUrls: ['./customer-info-list.component.scss'],
})
export class CustomerInfoListComponent
  extends MtObjectComponentBase
  implements MtDynamicTabBase, OnInit, MtObjectComponentBase {
  // @ViewChild('workflowStarter', { static: true }) flowStart: MtWorkflowStarterComponent;

  // 配置tab是否显示关闭按钮
  shieldCloseBtn = true;
  // 对象状态列表
  statusCodeList = [];
  // 搜索用户
  isSearchUserLoading = false;
  isUserLoading = false;
  checked = false;
  userList = [];
  userTotal = 0;
  querier: FormGroup;
  page: PageRequest = { index: 1, size: 10 };
  userPage: PageRequest = { index: 1, size: 10 };
  datas = { data: [], total: 0, totalPages: 0 };
  // 防抖
  searchNameShakeProof = null;
  // 存放选中的id
  setOfCheckedId = new Set<MtSafeAny>();
  public dynamicDataArr: Array<MtSafeAny> = [];
  private mtRevisionType: string = 'k8_customerRevision';
  constructor(
    defSvc: MtSpectrumDefineService,
    dataSvc: MtSpectrumDataService,
    secSvc: MtSpectrumSecurityService,
    private customerInfoService: CustomerInfoService,
    private fb: FormBuilder,
    private userSrv: UserServiceService,
    private modal: NzModalService,
    public srv: MtDataSourceService,
    private dictSrv: MtDictCacheService,
    private translate: MtTranslateService,
    private passport: PassportService,
    private getModelByCode: GetModelByCodeService,
    private refreshList: RefreshListTabService,
    @Optional() public data: MtObjectData,
    @Optional() private context: MtContextDirective
  ) {
    super(defSvc, dataSvc, secSvc);
  }
  @ViewChild('tabTitle') title: TemplateRef<void>;
  onCloseByInner = new EventEmitter<void>();
  onActivated(): void {}
  onDeactivated(): void {}
  onDataChange(data: MtMap): void {}
  activate(): void {}
  deactivate(): void {}
  ngOnInit(): void {
    this.querier = this.fb.group({
      CustCode: [null],
      Title: [null],
      depId: [null],
      SaleEmp: [null],
    });
    this.dictSrv.getItems('Object_state').subscribe((res) => {
      this.statusCodeList = res as MtSafeAny;
    });
    this.getModalInfo();
    this.setRefreshSubject();
    this.searchData();
  }
  // 批量删除
  onBatchDelClick() {
    const params = [];
    this.deleteObj([...this.setOfCheckedId].map((item) => item.muid));
    return;
  }
  // 选择单个的对像
  onItemChecked(item: MtSafeAny, checked: boolean): void {
    this.updateCheckedSet(item, checked);
    this.refreshCheckedStatus();
    this.checkRevisionObjects(item.revisionObjects, checked);
  }
  checkRevisionObjects(list: MtSafeAny[], checked: boolean) {
    if (list) {
      list.forEach((res, i) => {
        res.disabled = checked;
        this.updateCheckedSet(res, checked);
        if (i === list.length - 1) {
          this.refreshCheckedStatus();
        }
      });
    }
  }
  // 全选触发
  onAllChecked(checked: boolean): void {
    this.datas.data.forEach((item) => {
      this.updateCheckedSet(item, checked);
      this.checkRevisionObjects(item.revisionObjects, checked);
    });
    this.refreshCheckedStatus();
  }
  updateCheckedSet(item: MtSafeAny, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(item);
    } else {
      this.setOfCheckedId.delete(item);
    }
  }
  refreshCheckedStatus(): void {
    this.checked = this.datas.data.every((res) => this.setOfCheckedId.has(res));
  }
  onUserSearch(value: string): void {
    if (this.searchNameShakeProof !== null) {
      clearTimeout(this.searchNameShakeProof);
    }
    this.searchNameShakeProof = setTimeout(() => {
      this.isSearchUserLoading = true;
      this.userPage.index = 1;
      this.userTotal = 0;
      this.userList = [];
      this.loadMoreUser(value);
    }, 600);
  }

  loadMoreUser(value?: string) {
    this.isUserLoading = true;
    if (this.userTotal > this.userPage.index * this.userPage.size || !this.userTotal) {
      const filter = [];
      if (value) {
        filter.push({ prop: 'name', operate: FilterOperator.Like, value });
        filter.push({ prop: 'account', operate: FilterOperator.Like, value });
      }
      this.userSrv.getUsers(filter, this.userPage).subscribe(
        (res: MtSafeAny) => {
          this.isSearchUserLoading = false;
          this.isUserLoading = false;
          this.userList = [...this.userList, ...res.datas];
          this.userPage.index += 1;
          this.userTotal = res.total;
        },
        () => {
          this.isSearchUserLoading = false;
          this.isUserLoading = false;
        }
      );
    }
  }
  reload() {
    // 清空输入框
    this.querier.reset();
    // 将查询条件恢复默认
    this.page = { index: 1, size: 10 };
    this.searchData();
  }

  searchData() {
    const params: MtSafeAny = [];
    if (!this.currentUserIsAdmin()) {
      let orgIds = this.getCurrentUserIsDepartmentHeadOrgId();
      if (orgIds.length > 0) {
        this.querier.get('depId').setValue(orgIds);
      } else {
        this.querier.get('SaleEmp').setValue(this.passport.current.user.id);
      }
    }
    const keys = Object.keys(this.querier.value);
    const value = Object.values(this.querier.value).some((item) => Boolean(item) === true);

    // 如果输入框有值就按照输入框查询
    if (value) {
      keys.map((key) => {
        if (CommonUtils.isNotBlank(this.querier.value[key])) {
          let queryObj = {};
          if (this.querier.value.depId && key === 'depId') {
            // tslint:disable-next-line:no-string-literal
            // params['startTime'] = moment(this.querier.value.time[0]).format('YYYY-MM-DD') + ' 00:00:00';
            // tslint:disable-next-line:no-string-literal
            // params['endTime'] =  moment(this.querier.value.time[1]).format('YYYY-MM-DD') + ' 23:59:59';
            queryObj = {
              prop: key,
              operate: FilterOperator.In,
              values: this.formateQueryOrgs(this.querier.value[key]),
            };
            params.push(queryObj);
          }
          if (this.querier.value.SaleEmp && key === 'SaleEmp') {
            queryObj = {
              prop: 't2.id',
              operate: FilterOperator.In,
              values: [`'${this.querier.value[key]}'`],
            };
            params.push(queryObj);
          } else {
            queryObj = {
              prop: key,
              operate: FilterOperator.Contains,
              value: `'${this.querier.value[key]}'`,
            };
            params.push(queryObj);
          }
        }
      });
    }
    this.customerInfoService.getCustomerInfoList(this.page, params).subscribe(
      (res: MtSafeAny) => {
        const createUsers = [];
        res.resultData.data.forEach((item) => {
          if (item.createUser) {
            createUsers.push(item.createUser);
            if (item.revisionObjects && item.revisionObjects.length) {
              item.revisionObjects.forEach((ite) => {
                if (ite.createUser) {
                  createUsers.push(item.createUser);
                }
              });
            }
          }
        });
        const filter = [];
        if (createUsers && createUsers.length) {
          filter.push({ prop: 'id', operate: FilterOperator.In, values: createUsers });
          this.userSrv.getUsers(filter, { index: 1, size: 10 }).subscribe((item: MtSafeAny) => {
            res.resultData.data.forEach((element) => {
              const people = item.datas.find((va) => va.id === element.createUser);
              if (people) {
                element.createUserName = people.name + '/' + people.account;
              } else {
                element.createUserName = element.createUser;
              }
              if (element.revisionObjects && element.revisionObjects.length) {
                element.revisionObjects.forEach((ite) => {
                  const peo = item.datas.find((va) => va.id === ite.createUser);
                  if (peo) {
                    ite.createUserName = peo.name + '/' + peo.account;
                  } else {
                    ite.createUserName = ite.createUser;
                  }
                });
              }
            });
          });
        }

        res.resultData.data.forEach((item) => {
          let customerInfo = JSON.parse(item.value_info.value);
          item.value = JSON.parse(item.value_info.value);
        });
        this.datas.data = res.resultData.data;
        this.datas.total = res.resultData.total;
        this.refreshCheckedStatus();
      },
      (err: Error) => {}
    );
  }
  search() {
    this.page.index = 1;
    this.searchData();
  }
  deleteObj(item: string[]) {
    this.secSvc.delObj(item).subscribe((res) => {
      if (res.success) {
        MtNotificationBus.emit({
          type: 'success',
          title: 'i18n.platform.notification.success.success',
          message: 'i18n.platform.notification.success.deleteSuccess',
        });
        this.setOfCheckedId = null;
        this.setOfCheckedId = new Set<string>(); // 已选择的id
        this.searchData();
      }
    });
  }
  onStartProcessClick(item) {
    const parameter = [
      {
        objects: [
          {
            objectId: item.id,
            target: true,
            serial: 1,
          },
        ],
      },
    ];
    // this.flowStart.initName(item.code + '-' + item.name);
    // this.flowStart.open(parameter).subscribe(res => {
    //   if (res) {
    //     this.searchData();
    //   }
    // });
  }
  openModal(detail?) {
    let params = {};
    if (detail) {
      params = { ...detail };
    } else {
      // for (const item of this.dynamicDataArr) {
      //   params[item.key] = null;
      // };
      console.log('传入model的动态参数params', this.dynamicDataArr);
    }
    const drawerRef = this.modal.create({
      nzContent: CustomerInfoModalComponent,
      nzComponentParams: {
        detail: this.dynamicDataArr,
        // modalTitle: this.translate.currentLang.startsWith('en') ? 'Add Customer Info' : '新增客户信息'
      },
      nzFooter: null,
      nzWidth: '75%',
      nzBodyStyle: { height: document.body.clientHeight - 75 + 'px', padding: '0px' },
      nzClosable: false,
      nzMaskClosable: false,
      nzStyle: { top: '40px' },
    });
    drawerRef.afterClose.subscribe((res) => {
      if (res) {
        if (detail) {
          this.searchData();
        } else {
          this.reload();
        }
      }
    });
  }

  isEn() {
    return this.translate.currentLang.startsWith('en');
  }
  transferStatus(status: string) {
    let statusName = '';
    if (status) {
      const list = status.split(',');
      const objList = this.statusCodeList.filter((item) => list.includes(item.code));
      objList.forEach((item, i) => {
        statusName = statusName + (this.isEn() ? item.code : item.name);
        if (i !== objList.length - 1) {
          statusName += ',';
        }
      });
    }
    return statusName;
  }

  goToDetail(item) {
    CustomerInfoActivateEvent.emit({
      context: 'CustomerInformation',
      id: item?.value?._id,
      type: item?.value?.OBJECT_TYPE,
      extra: {
        id: item?.value?._id,
        type: item?.value?.OBJECT_TYPE,
        ...item?.value,
      },
    });
  }

  private currentUserIsAdmin(): boolean {
    const userInfo = JSON.parse(localStorage.getItem('user_info'));
    if (
      userInfo.roleRels.filter((item) => item == 'INIT_GROUP_ROLE' || item == 'INIT_ROLE ').length >
      0
    ) {
      return true;
    } else {
      return false;
    }
  }
  private getCurrentUserIsDepartmentHeadOrgId(): Array<string> {
    const userInfo = JSON.parse(localStorage.getItem('user_info'));
    let index = userInfo.roleRels.findIndex((item) => item == 'r0RM8YjjSOSk8ASPLnxgFQ');
    if (index !== -1) {
      return userInfo.orgRels;
    } else {
      return [];
    }
  }

  private getModalInfo(): void {
    this.dynamicDataArr = [];
    const isEnLang: boolean = this.translate.currentLang.startsWith('en');
    const modelCode: string = this.mtRevisionType;
    const filleterModelArr: Array<string> = [
      'CustCode',
      'Title',
      'CustAbbr',
      'Address',
      'Tel',
      'Xorder',
      'SaleEmp',
    ];
    let modelInfoList = [];
    this.getModelByCode.getModelByCode(modelCode).subscribe((res: MtSafeAny) => {
      if (!res || res.length < 1) {
        return;
      }
      modelInfoList = res;
      for (const key of filleterModelArr) {
        let basicInfo: any;
        modelInfoList.forEach((item) => {
          if (item.code == key) {
            basicInfo = {
              key: key,
              label: isEnLang ? item?.nameEn : item?.name,
              content: null,
              type: item?.type,
              seq: item?.seq,
              descEn: item?.descEn,
              arrayed: item?.arrayed,
              required: item?.validate?.required,
            };
            this.dynamicDataArr.push(basicInfo);
          }
        });
      }
    });
  }

  private formateQueryOrgs(arr: Array<string>): Array<string> {
    let newArr = arr.map((item) => `'${item}'`);
    return newArr;
  }

  private setRefreshSubject(): void {
    this.refreshList.register().subscribe((res) => {
      if (res && res == this.mtRevisionType) {
        this.reload();
      }
    });
  }
}
