import { Injectable } from '@angular/core';
import { MtDynamicTabHandler, MtDynamicTabRegister } from '@imerge22/common';
import { MtEvent, MtSafeAny, MtMap } from '@imerge22/core';
import { ApprovedWorkHoursActivateEvent } from './events';
import { ApprovedWorkHoursListComponent } from './approved-work-hours-list/approved-work-hours-list.component';

@Injectable({ providedIn: 'root' })
export class ApprovedWorkHoursTabHandler extends MtDynamicTabHandler {
  match(context: string, event: MtEvent<MtSafeAny>): boolean {
    if (event.name === ApprovedWorkHoursActivateEvent.$name) {
      const data = ApprovedWorkHoursActivateEvent.get(event);
      return data?.context === context;
    }
    return false;
  }

  same(context: string, exist: MtMap, event: MtEvent<MtSafeAny>): boolean {
    if (event.name === ApprovedWorkHoursActivateEvent.$name) {
      const data = ApprovedWorkHoursActivateEvent.get(event);
      return data?.context === context;
    }
    return false;
  }
}

export function registerApprovedWorkHoursDynamicTab() {
  MtDynamicTabRegister.register(ApprovedWorkHoursActivateEvent.$name, {
    component: ApprovedWorkHoursListComponent,
    handler: ApprovedWorkHoursTabHandler,
    initializeComponent: true,
  });
}
