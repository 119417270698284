import { Component, OnInit, Optional } from '@angular/core';
import { MtSafeAny } from '@imerge22/core';
import { MtSpectrumContrlBase, MtSpectrumControlRegister } from '@imerge22/spectrum/controls';
import { MtSpectrumDataBinding } from '@imerge22/spectrum/objects';
import { MtDictCacheService } from '@imerge22/base';
import { MtTranslateService } from '@imerge22/core/translate';
import { WorkingHoursService } from 'src/app/root/modules/working-hours/working-hours/services/working-hours.service';

type AOA = MtSafeAny[][];
@Component({
  selector: 'uccs-mt-data-input',
  templateUrl: './k-select-project.component.html',
  styleUrls: ['./k-select-project.component.scss'],
})
export class KSelectProjectComponent extends MtSpectrumContrlBase<MtSafeAny>
  implements OnInit {

  public selectProjectMuid: string = '';
  public currentProjectName: string = '';
  public projectOption: Array<MtSafeAny> = [];
  initConfig(): void {}
  constructor(
    @Optional() public dataBinding: MtSpectrumDataBinding,
    private workingHoursService: WorkingHoursService,
    protected translate: MtTranslateService
  ) {
    super();
  }

  ngOnInit(): void {
    this.selectProjectMuid = this.dataBinding.value as string;
    this.getProjectListByUser();
  }

  public setProjectMuid(event?: string): void {
    if (!event) {
        return;
    }
    this.dataBinding.value = event;
  }

  private getProjectListByUser(): void {
    this.workingHoursService.getProjectListByCurrentUser().subscribe(res => {
      if (!res || !res?.resultData?.data) {
        return;
      }
      const projectId: string = this.dataBinding.value as string;
      res?.resultData?.data.forEach(item => {
        item.projectLabel = item?.value_info.projectCode + '-' + item?.value_info.projectName;
        if (this.dataBinding.value && item.muid == projectId) {
            this.currentProjectName = item.projectLabel
        }
      })
      this.projectOption = res?.resultData?.data;
      if (!this.dataBinding.value) {
        return;
      }
    })
  }

}

export function registerKSelectProject() {
  MtSpectrumControlRegister.register('kSelectProject', KSelectProjectComponent);
}
