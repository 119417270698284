import { MtEvent, MtEventBus, MtSafeAny } from '@imerge22/core';
import { MtDynamicTabsetActivateEventData } from '@imerge22/common';

interface EwoDetailActivateEventData extends MtDynamicTabsetActivateEventData{
  id?: string;
  type?: string;
  extra?: MtSafeAny;
}

export class EwoDetailActivateEvent {
  static $name = 'ewo-detail-activate';
  static emit(data: EwoDetailActivateEventData) {
    MtEventBus.emit({
      name: EwoDetailActivateEvent.$name,
      data,
    });
  }

  static is(event: MtEvent<MtSafeAny>): boolean {
    return event != null && event.name === EwoDetailActivateEvent.$name;
  }

  static get(event: MtEvent<MtSafeAny>): EwoDetailActivateEventData {
    if (EwoDetailActivateEvent.is(event)) {
      return event.data;
    }
    return undefined;
  }
}
