import { HttpClient } from '@angular/common/http';
import { Component, HostBinding, OnInit } from '@angular/core';
import { FilterOperator, MtConfigService, MtSafeAny, MtWithConfig } from '@imerge22/core';
import { MtSpectrumContrlBase, MtSpectrumControlRegister } from '@imerge22/spectrum';
import { MtSpectrumDataBinding, MtSpectrumDataSource } from '@imerge22/spectrum/objects';

export interface MtSpectrumPropNameCtrlConfig {
  /** 设置水平方向对齐方式 */
  vAlign?: 'center' | 'top' | 'bottom';
  /** 设置垂直方向对齐方式 */
  hAlign?: 'center' | 'left' | 'right';
  /** 不需要默认背景色 */
  noneBackground?: boolean;
  /** 自定义背景色 */
  backgroundColor?: string;
  /** 显示序号 */
  showIndex?: boolean;
}

@Component({
  selector: 'mt-ctrl-project-detail-org-change',
  templateUrl: './project-detail-org-change.component.html',
  styleUrls: ['./project-detail-org-change.component.scss'],
})
export class MtSpectrumProjectDetailOrgChangeComponent extends MtSpectrumContrlBase<MtSpectrumPropNameCtrlConfig>
  implements OnInit {
  @MtWithConfig() private spectrumServiceName: string;
  @MtWithConfig() private rbacServiceName: string;

  public orgListOption: Array<MtSafeAny> = [];
  public showText: string = '';
  public value: string = '';
  nzFilterOption = (): boolean => true;
  
  constructor(
    public dataBinding: MtSpectrumDataBinding,
    public dataSource: MtSpectrumDataSource,
    private http: HttpClient,
    public mtConfigSrv: MtConfigService,
    ) {
    super();
  }

  initConfig(): void {}

  ngOnInit(): void {
    this.getOrgInfoList();
    this.value = this.dataBinding.value as string;
  }

  public changeContactId(event: string): void {
    if (!event) {
      return;
    }
    this.orgListOption.map(item => {
      if (item.code == event) {
        this.showText = item.name;
        this.dataSource.setValue(this.dataSource.mainObjectId, 'depName', event);
      }
    });
    this.dataSource.setValue(this.dataSource.mainObjectId, 'depId', event);
  }
  private getOrgInfoList(): void {
    let param = {
      "modelId": "Organization",
      "conditions": { "filters": [] },
      "cacheable": false,
      "sorts": [{ "prop": "name", "dir": "Ascend" }]
    }
    this.http.post(`srv://${this.rbacServiceName}/common/query`, param).subscribe((res: MtSafeAny) => {
      if (!res || !res?.resultData?.data) {
        return;
      }
      this.orgListOption = res?.resultData?.data.map(item => {
        if (item.id == this.dataBinding.value) {
          this.showText = item.name;
        }
        if (item.orgStatus) {
          return {
            code: item.id,
            name: item.name,
          }
        }
      })
    })
  }
}

export function registerProjectDetailOrgChangeCtrl() {
  MtSpectrumControlRegister.register('mtProjectDetailOrgChange', MtSpectrumProjectDetailOrgChangeComponent);
}
