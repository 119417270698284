<div class="mt-root-column">
  <form class="mt-form-query" nz-form [formGroup]="querier" [nzLayout]="'inline'">
    <!-- 对象编号 -->
    <nz-form-item>
      <nz-form-control>
        <input
          nz-input
          formControlName="projectCode"
          [placeholder]="'员工名称'"
        />
      </nz-form-control>
    </nz-form-item>
    <!-- 对象名称 -->
    <nz-form-item>
      <nz-form-control>
        <input
          nz-input
          formControlName="projectName"
          [placeholder]="'项目名称'"
        />
      </nz-form-control>
    </nz-form-item>
    <!-- 对象类型 -->
    <!-- <nz-form-item>
      <nz-form-control>
        <input
          nz-input
          formControlName="type"
          [placeholder]="'platform.base.obj.enterObjectType' | translate"
        />
      </nz-form-control>
    </nz-form-item> -->
    <!-- 开始结束时间 -->
    <!-- <nz-form-item id="date-picker">
      <nz-form-control>
        <nz-range-picker
          nzSize="default"
          formControlName="time"
          style="width: 100%; height: 32px"
          [nzPlaceHolder]="isEn() ? ['Creation date','Creation date'] : ['创建日期','创建日期']"
        ></nz-range-picker>
      </nz-form-control>
    </nz-form-item> -->
    <!-- 对象状态 -->
    <!-- <nz-form-item>
      <nz-form-control>
        <nz-select
          formControlName="statusCode"
          nzAllowClear
          [nzPlaceHolder]="'platform.base.obj.selectObjectStatus' | translate"
        >
          <nz-option
            *ngFor="let item of statusCodeList"
            [nzValue]="item.code"
            [nzLabel]="isEn() ? item.code : item.name"
          ></nz-option>
        </nz-select>
      </nz-form-control> -->
    <!-- </nz-form-item> -->
    <!-- 创建人 -->
    <!-- <nz-form-item>
      <nz-form-control>
        <nz-select
          nzMode="multiple"
          [nzPlaceHolder]="'platform.base.obj.selectUser' | translate"
          nzAllowClear
          nzShowSearch
          nzMaxTagCount="1"
          nzServerSearch
          formControlName="createUsers"
          (nzScrollToBottom)="loadMoreUser()"
          (nzOnSearch)="onUserSearch($event)"
          [nzDropdownRender]="renderTemplate"
        >
          <ng-container *ngFor="let o of userList">
            <nz-option *ngIf="!isSearchUserLoading" [nzValue]="o.id" [nzLabel]="o.name + '/' + o.account"></nz-option>
          </ng-container>
          <nz-option *ngIf="isSearchUserLoading" nzDisabled nzCustomContent>
            <i nz-icon nzType="loading" class="loading-icon"></i>
            {{ 'platform.common.loading' | translate }}
          </nz-option>
          <ng-template #renderTemplate>
            <nz-spin *ngIf="isUserLoading && !isSearchUserLoading"></nz-spin>
          </ng-template>
        </nz-select>
      </nz-form-control>
    </nz-form-item> -->

    <!-- 查询重置按钮 -->
    <nz-form-item>
      <nz-form-control class="mt-btns">
        <button nz-button nzType="primary" (click)="search()">
          <i nz-icon nzType="search"></i>
          {{ 'platform.core.dict.query' | translate }}
        </button>
        <button nz-button (click)="reload()">
          <i nz-icon nzType="reload"></i>
          {{ 'platform.core.dict.reset' | translate }}
        </button>
        <button nz-button nzType="primary" (click)="openModal()">
          <i nz-icon nzType="plus" nzTheme="outline"></i>
          {{ 'platform.core.dict.add' | translate }}
        </button>
        <button type="button"
          nz-button nzType="primary" nzDanger
          [disabled]="!setOfCheckedId.size"
          nz-popconfirm
          [nzPopconfirmTitle]="'platform.spectrum.object.deleteConfirm.messageAll' | translate"
          (nzOnConfirm)="onBatchDelClick()"
          nzPopconfirmPlacement="left"
        >
          <i nz-icon nzType="delete"></i>
          {{ 'platform.common.default.delete' | translate }}
        </button>
      </nz-form-control>
    </nz-form-item>
  </form>
  <nz-table
    #nzTable
    [nzData]="datas.data"
    [nzFrontPagination]="false"
    [nzTotal]="datas.total"
    [(nzPageIndex)]="page.index"
    [(nzPageSize)]="page.size"
    [nzBordered]="true"
    [nzShowTotal]="showTotal"
    nzSize="small"
    [nzShowSizeChanger]="true"
    [nzLoadingDelay]="100"
    (nzPageIndexChange)="searchData()"
    (nzPageSizeChange)="searchData()"
    nzBordered
  >
    <thead>
      <tr>
        <th
          [nzChecked]="checked"
          (nzCheckedChange)="onAllChecked($event)"
          class="mt-center"
        ></th>
        <ng-container *ngFor="let item of dynamicDataArr">
          <th class="mt-center">
            {{ item?.label }}
          </th>
        </ng-container>
      </tr>
    </thead>
    <tbody>
      <ng-template
        ngFor
        let-item
        [ngForOf]="nzTable.data"
      >
        <tr>
          <td *ngIf="item.CustCode"
            class="mt-center"
            [nzChecked]="setOfCheckedId.has(item)"
            [nzDisabled]="item.disabled"
            (nzCheckedChange)="onItemChecked(item, $event)"
          >
          </td>
          <td *ngIf="!item.CustCode"
            [nzChecked]="setOfCheckedId.has(item)"
            [nzDisabled]="item.disabled"
            (nzCheckedChange)="onItemChecked(item, $event)"
            class="mt-center"></td>

          <td class="mt-center">{{item?.eid | userSync: '${employeeNo}-${name}'}}</td>
          <td class="mt-center">{{item?.value?.depName}}</td>
          <td class="mt-center">{{item?.value?.projectName}}</td>
          <td class="mt-center">{{item?.value?.workdate | translateTime: "yyyy-MM-DD"}}</td>
          <td class="mt-center">{{item?.value?.workcheck | dictItemName: 'Yes_or_no' | async}}</td>
          <td class="mt-center">{{item?.value?.prjhours}}<span *ngIf="item?.value?.prjhours">天</span></td>
          <td class="mt-center">{{item?.value?.comment}}</td>
          <!-- <td class="mt-center">{{item?.value?.workstartdate | translateTime: "yyyy-MM-DD"}}</td>
          <td class="mt-center">{{item?.value?.workenddate | translateTime: "yyyy-MM-DD"}}</td> -->
          <td class="mt-center">{{ (!item?.value?.SUBMIT || item?.value?.SUBMIT == 'No') ? '否' : '是'}}</td>
          <td class="mt-center">{{item?.value?.SUBMITBY | userSync: '${employeeNo}-${name}'}}</td>
          <td class="mt-center">{{item?.value?.SUBMITTIME | translateTime: "yyyy-MM-DD HH:mm:ss"}}</td>
        </tr>
      </ng-template>
    </tbody>
  </nz-table>
</div>
<ng-template #tabTitle>
  {{ 'koa.dashboard.employeeInvolvedProjectsList' | translate }}
</ng-template>
<ng-template #showTotal let-range="range" let-total>
  {{ 'platform.core.dict.total' | translate: { total: total, range0: range[0], range1: range[1] } }}
</ng-template>