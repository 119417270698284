import { Injectable } from '@angular/core';
import { IMtDataBindingAction, MtSafeAny, MtStringUtil } from '@imerge22/core';
import { MtSpectrumDataBinding, MtSpectrumDataItem } from '@imerge22/spectrum/objects';

@Injectable()
export class ECRProductNoDataBindingAction
  implements IMtDataBindingAction<MtSpectrumDataItem, MtSafeAny> {
  constructor(public dataBinding: MtSpectrumDataBinding) {}

  inited() {
    if (this.dataBinding.value == null) {
      this.dataBinding.dataSource.setCustomEditable(
        false,
        this.dataBinding.mtObjectId,
        'Involved_item',
        this.dataBinding.mtRowIndex
      );
    }
  }

  change?(previousValue: string, currentValue: string) {
    this.dataBinding.dataSource.setCustomEditable(
      !MtStringUtil.isBlank(currentValue),
      this.dataBinding.mtObjectId,
      'Involved_item',
      this.dataBinding.mtRowIndex
    );
  }
}
