import { Injectable, Type } from '@angular/core';
import { of, Observable } from 'rxjs';
import { MtDataSourceService } from '@imerge22/platform/base';
import { MtObjectDetailPanelHandler, MtObjectDetailContext, MtObjectPanelContext } from '@imerge22/spectrum';
import { PanelMBomComponent } from './panel-m-bom.component';

@Injectable({ providedIn: 'root' })
export class PanelMBomHandler implements MtObjectDetailPanelHandler {
  constructor(private srv: MtDataSourceService) { }

  getComponent(detailContext: MtObjectDetailContext): Observable<Type<MtObjectPanelContext>> {
    return of(PanelMBomComponent);
  }
}
