import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MtTranslateModule } from '@imerge22/core/translate';
import { MtObjectDetailChildRegister } from '@imerge22/spectrum';
import { MtRbacModule } from '@imerge22/platform/mt-rbac';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { UccsCreateObjectModule } from '../create-object';
import { PanelEBomComponent } from './panel-e-bom/panel-e-bom.component';
import { PanelEBomHandler } from './panel-e-bom/panel-e-bom.handler';
import { PanelEcnComponent } from './panel-ecn/panel-ecn.component';
import { PanelEcnHandler } from './panel-ecn/panel-ecn.handler';
import { PanelImapactedComponent } from './panel-imapacted/panel-imapacted.component';
import { PanelImapactedHandler } from './panel-imapacted/panel-imapacted.handler';
import { PanelMBomComponent } from './panel-m-bom/panel-m-bom.component';
import { PanelMBomHandler } from './panel-m-bom/panel-m-bom.handler';
import { PanelPBomComponent } from './panel-p-bom/panel-p-bom.component';
import { PanelPBomHandler } from './panel-p-bom/panel-p-bom.handler';
import { PanelSBomComponent } from './panel-s-bom/panel-s-bom.component';
import { PanelSBomHandler } from './panel-s-bom/panel-s-bom.handler';
import { MtValueRenderersModule } from '@imerge22/platform/value-renderers';

const components = [
  PanelImapactedComponent,
  PanelEcnComponent,
  PanelMBomComponent,
  PanelSBomComponent,
  PanelPBomComponent,
  PanelEBomComponent,
];

@NgModule({
  imports: [
    CommonModule,
    NzIconModule,
    NzToolTipModule,
    NzPopconfirmModule,
    NzButtonModule,
    NzGridModule,
    NzDrawerModule,
    NzTableModule,
    NzSpinModule,
    NzIconModule,
    MtTranslateModule,
    MtRbacModule,
    FormsModule,
    UccsCreateObjectModule,
    MtValueRenderersModule
  ],
  exports: [...components],
  entryComponents: [...components],
  declarations: [...components],
})
export class UccsPanelModule {}

export function registerImapactedComponent() {
  MtObjectDetailChildRegister.registerPanel('Imapacted', PanelImapactedHandler);
}
export function registerEcnComponent() {
  MtObjectDetailChildRegister.registerPanel('ecn', PanelEcnHandler);
}
export function registerMBomComponent() {
  MtObjectDetailChildRegister.registerPanel('mbom', PanelMBomHandler);
}
export function registerSBomComponent() {
  MtObjectDetailChildRegister.registerPanel('sbom', PanelSBomHandler);
}
export function registerPBomComponent() {
  MtObjectDetailChildRegister.registerPanel('pbom', PanelPBomHandler);
}
export function registerEBomComponent() {
  MtObjectDetailChildRegister.registerPanel('ebom', PanelEBomHandler);
}
