<!--
 * @Author: your name
 * @Date: 2021-09-12 20:38:06
 * @LastEditTime: 2021-09-24 14:27:43
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \web\src\app\root\changes\ewo\my-ewo\my-ewo.component.html
-->
<div class="mt-root-column">
  <form class="mt-form-query" nz-form [formGroup]="querier" nzLayout="inline">
    <nz-form-item>
      <nz-form-control>
        <input nz-input formControlName="itemId" [placeholder]="'common-del.ewo.ewoNo' | translate">
      </nz-form-control>
    </nz-form-item>
    <nz-form-item id="date-picker">
      <nz-form-control>
        <nz-range-picker nzSize="default" formControlName="createTime" style="width: 100%; height: 32px"
          [nzPlaceHolder]="['common-del.createTime' | translate, 'common-del.createTime' | translate]"></nz-range-picker>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item id="date-picker">
      <nz-form-control>
        <nz-range-picker nzSize="default" formControlName="plannedCompleteDate" style="width: 100%; height: 32px"
          [nzPlaceHolder]="['common-del.plannedCompleteDate' | translate, 'common-del.plannedCompleteDate' | translate]">
        </nz-range-picker>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-control class="mt-btns">
        <button nz-button nzType="primary" (click)="buttonSearch()">
          <i nz-icon nzType="search"></i>
          {{ 'root-del.processTiming.search' | translate }}
        </button>
        <button nz-button nzType="default" (click)="reload()">
          <i nz-icon nzType="reload"></i>
          {{ 'root-del.processTiming.reset' | translate }}
        </button>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-control class="mt-btns">
        <button nz-button nzType="primary" (click)="addEwo()">
          {{ 'root-del.ewo.createewo' | translate }}
        </button>
        <button nz-button nzType="primary" [disabled]="selectRow == null" (click)="launchWorkflow()">
          {{ 'root-del.ewo.submit' | translate }}
        </button>
      </nz-form-control>
    </nz-form-item>
  </form>

  <nz-table [nzData]="datas.data" [nzFrontPagination]="false" [nzTotal]="datas.total" [(nzPageIndex)]="page.index"
    [(nzPageSize)]="page.size" [nzBordered]="true" [nzShowTotal]="showTotal" nzSize="small" [nzShowSizeChanger]="true"
    [nzLoadingDelay]="100" (nzPageIndexChange)="search()" (nzPageSizeChange)="search()" [nzPageSizeOptions]="pagenumber">
    <thead>
      <tr>
        <!-- <th class="mt-center">{{ 'root-del.ewo.num' | translate }}</th> -->
        <th class="mt-center">{{ 'common-del.ewo.ewoNo' | translate }}</th>
        <!-- <th class="mt-center">{{ 'common-del.changeSource' | translate }}</th> -->
        <th class="mt-center">{{ 'common-del.changeTopic' | translate }}</th>
        <th class="mt-center">{{ 'common-del.creator' | translate }}</th>
        <th class="mt-center">{{ 'common-del.creatorDept' | translate }}</th>
        <th class="mt-center">{{ 'common-del.status' | translate }}</th>
        <th class="mt-center" [nzShowSort]="true" [nzSortFn]="true" [(nzSortOrder)]="sortOrder.createTime" (nzSortOrderChange)="sortChange('createTime')">{{ 'common-del.createTime' | translate }}</th>
        <th class="mt-center" [nzShowSort]="true" [nzSortFn]="true" [(nzSortOrder)]="sortOrder.plan" (nzSortOrderChange)="sortChange('plan')">{{ 'common-del.plannedCompleteDate' | translate }}</th>
      </tr>
    </thead>
    <tbody>
      <!-- <tr *ngFor="let item of datas.data"> -->
      <tr *ngFor="let item of datas.data" (click)="oneItemChecked(item)"
      [class.mt-selected]="selectRow?._id == item?._id">
        <!-- <td class="mt-center">{{ item.id }}</td> -->
        <!-- <td class="mt-center" style="width: fit-content;"><a nz-button nzType="link" (click)="editEwo(item)">
            {{ item.ITEM_ID }}
          </a></td> -->
        <td class="mt-center"><a nz-button nzType="link" (click)="editEwo(item)">
            {{ item.OBJECT_NAME }}
          </a></td>
        <!-- <td class="mt-center">{{ item?.change_source || '-'}}</td> -->
         <!-- 更改来源-->
        <td class="mt-center">{{ item?.change_topic || '-'}}</td> <!-- 更改主题-->
        <td class="mt-center">{{ item.CREATOR_NAME || '-' }}</td>
        <td class="mt-center">{{ item?.DEPT_NAME || '-' }}</td>

        <td class="mt-center primary" style="color: #1890ff;min-width: 75px;font-size: 1.05em;">
            {{ item?.state || '-' | dictItemName : 'state' | async }}
        </td>
        <td class="mt-center">{{ (item.CREATE_TIME | translateTime: 'YYYY-MM-DD HH:mm') || '-' }}</td>
        <!-- <td class="mt-center" style="width: fit-content;">{{ item.CREATOR | user:'account' | async }}</td> -->
        <!-- <td class="mt-center" style="width: fit-content;">{{ item.CREATE_TIME | translateTime: 'yyyy-MM-dd' }}</td> -->
        <td class="mt-center">{{ (item?.planned_complete_date | translateTime: 'YYYY-MM-DD') || '-' }}</td>
      </tr>
    </tbody>
  </nz-table>
</div>

<ng-template #showTotal let-range="range" let-total>
  {{ 'platform.core.dict.total' | translate: { total: total, range0: range[0], range1: range[1] } }}
</ng-template>

<mt-workflow-starter (mtSubmit)="startWorkflow()"></mt-workflow-starter>

<ng-template #tabTitle>
  {{ 'root-del.ewo.myewo' | translate }}
</ng-template>
