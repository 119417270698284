import { MtDynamicTabsetActivateEventData } from '@imerge22/common';
import { MtEvent, MtEventBus, MtSafeAny } from '@imerge22/core';

interface MtCustomerData extends MtDynamicTabsetActivateEventData {
  id?: string;
  type?: string;
  extra?: MtSafeAny;
}

export class ContractInfoActivateEvent {
  static $name = 'contract-info';
  static emit(data: MtCustomerData) {
    MtEventBus.emit({
      name: ContractInfoActivateEvent.$name,
      data,
    });
  }

  static is(event: MtEvent<MtSafeAny>): boolean {
    return event != null && event.name === ContractInfoActivateEvent.$name;
  }

  static get(event: MtEvent<MtSafeAny>): MtCustomerData {
    if (ContractInfoActivateEvent.is(event)) {
      return event.data;
    }
    return undefined;
  }
}
