<div class="mt-root-column">
  <form class="mt-form-query" nz-form [formGroup]="querier" [nzLayout]="'inline'">
    <!-- 查询重置按钮 -->
    <nz-form-item>
      <nz-form-control class="mt-btns">
        <button nz-button nzType="primary" (click)="openModal()" [disabled]="(!setOfCheckedId.size || setOfCheckedId.size !== 1) ">
          <i nz-icon nzType="plus" nzTheme="outline"></i>
          <!-- {{ 'platform.core.dict.add' | translate }} -->
          补填工时
        </button>
      </nz-form-control>
    </nz-form-item>
  </form>
  <nz-table
    #nzTable
    [nzData]="datas.data"
    [nzTotal]="datas.total"
    [nzBordered]="true"
    [nzShowTotal]="showTotal"
    nzSize="small"
    [nzShowSizeChanger]="true"
    [nzLoadingDelay]="100"
    nzBordered
    [nzFrontPagination]="false"
    [(nzPageIndex)]="page.pageNo"
    [(nzPageSize)]="page.pageSize"
    (nzPageIndexChange)="searchData()"
    (nzPageSizeChange)="searchData()"
  >
    <thead>
      <tr>
        <th
          [nzChecked]="checked"
          (nzCheckedChange)="onAllChecked($event)"
          class="mt-center"
          nzWidth="50px"
        ></th>
        <th class="mt-center" nzWidth="150px">
          员工名称
        </th>
        <th class="mt-center" nzWidth="150px">
          缺少工时数
        </th>
        <th class="mt-center" nzWidth="75%">
          缺少工时日期
        </th>
      </tr>
    </thead>
    <tbody>
      <ng-template
        ngFor
        let-item
        [ngForOf]="nzTable.data"
      >
        <tr>
          <td
            [nzChecked]="setOfCheckedId.has(item)"
            [nzDisabled]="currentUserId !== item.userId"
            (nzCheckedChange)="onItemChecked(item, $event)"
            class="mt-center"></td>
          <td class="mt-center">{{item?.userId | userSync: '${employeeNo}-${name}'}}</td>
          <td class="mt-center">{{item?.manHour}}天</td>
          <td class="mt-center">{{item?.workTime | translateTime: 'yyyy-MM-DD'}}</td>
        </tr>
      </ng-template>
    </tbody>
  </nz-table>
</div>
<ng-template #tabTitle>
  {{ 'koa.dashboard.makeUpWorkHoursList' | translate }}
</ng-template>
<ng-template #showTotal let-range="range" let-total>
  {{ 'platform.core.dict.total' | translate: { total: total, range0: range[0], range1: range[1] } }}
</ng-template>