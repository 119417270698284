<div class="ebom-container">
  <mt-table-render
    #tablePCN
    [queryProps]="queryProps"
    [queryDatas]="queryDatas"
    [showExportExcel]="true"
    [sortFilterCode]="'OBJECT_NAME'"
    [onlyShowBaseCol]="['_id', 'OBJECT_NAME', 'OBJECT_TYPE', 'ITEM_REVISION_ID', 'OWNER']"
    [pageSize]="pageSize"
    [footer]="footer"
    (selectChange)="selectChange($event)"
  >
  </mt-table-render>
  <ng-template #footer>
    <button nz-button nzType="primary" (click)="add()">
      <i nz-icon nzType="plus"></i>{{ 'root.bom.addBom' | translate }}
    </button>
    <button
      nz-button
      nzType="primary"
      style="margin-left: 16px"
      (click)="edit()"
      [disabled]="!selectRow"
    >
      <i nz-icon nzType="edit"></i>{{ 'root.bom.editBom' | translate }}
    </button>
    <button
      nz-button
      nzType="danger"
      style="margin-left: 16px"
      (click)="$event.stopPropagation()"
      nz-popconfirm
      [nzPopconfirmTitle]="'platform.common.default.deleteText' | translate"
      nzPopconfirmPlacement="top"
      (nzOnConfirm)="deleteRow()"
      [disabled]="!selectRow"
    >
      <i nz-icon nzType="delete"></i> {{'platform.common.default.delete'| translate }}
    </button>
  </ng-template>
</div>

<nz-drawer
  [nzWidth]="800"
  [nzClosable]="true"
  [nzVisible]="openType"
  nzPlacement="right"
  [nzTitle]="(openType === 'add' ? 'root.bom.addBom' : 'root.bom.editBom') | translate"
  (nzOnClose)="openType = null"
  nzWrapClassName="mt-with-footer"
>
  <div>
    <table class="mt-table">
      <tr>
        <th>
          <span class="mt-danger"> * </span>
          {{ 'platform.spectrum.object.name' | translate }}
        </th>
        <td>
          <input
            nz-input
            style="width: 100%"
            [disabled]="openType === 'edit'"
            [(ngModel)]="model.objectName"
          />
        </td>
      </tr>
    </table>
    <ng-container *ngIf="openType" [mtPanelRenderer]="panelData" [mtActivate]="true"></ng-container>
  </div>
  <div class="mt-drawer-footer">
    <button (click)="openType = null" type="button" nz-button nzShape="round">
      <i nz-icon nzType="close"></i> {{ 'platform.core.dict.cancel' | translate }}
    </button>
    <button
      nz-button
      nzShape="round"
      nzType="primary"
      type="button"
      [disabled]="isBlank(model.objectName) || saving"
      (click)="complete()"
    >
      <i nz-icon [nzType]="saving ? 'loading' : 'check'"></i>
      {{ 'platform.core.dict.determine' | translate }}
    </button>
  </div>
</nz-drawer>
