import { Component, EventEmitter, OnInit } from '@angular/core';
import { MtDynamicTabBase } from '@imerge22/common';
import {
  GenericQueryRequest,
  MtWithConfig,
  MtConfigService,
  FilterOperator,
  MtMap,
} from '@imerge22/core';

@Component({
  selector: 'uccs-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss'],
})
export class ProjectComponent implements MtDynamicTabBase {
  constructor(public mtConfigSrv: MtConfigService) {}

  @MtWithConfig() private spectrumServiceName: string;

  queryProps: GenericQueryRequest;
  queryDatas: GenericQueryRequest = {
    modelId: 'ProjectPEFP',
  };
  pageSize: number;

  baseCol: string[] = ['_id', 'OBJECT_NAME', 'OBJECT_TYPE', 'ITEM_REVISION_ID', 'OWNER'];

  data: MtMap;
  title = '工程PFEP';
  onCloseByInner = new EventEmitter<void>();
  onActivated(): void {}
  onDeactivated(): void {}
  onDataChange(data: MtMap): void {}

  // tslint:disable-next-line: use-lifecycle-interface
  ngOnInit() {
    this.queryProps = {
      dataSource: this.spectrumServiceName,
      modelId: 'Property',
      conditions: {
        filters: [
          {
            prop: 'model.code',
            operate: FilterOperator.Equals,
            value: 'ProjectPEFP',
          },
        ],
      },
    };
  }
}
