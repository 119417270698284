<div class="mt-root-column">
  <!-- 查询区域-->
  <yc-search-form (submitSearch)="onloadData($event)" [type]="type" [selectRow]="selectRow"></yc-search-form>

  <!-- 表格显示区域-->
  <div class="main__body">
    <yc-ecn-list
      [thHeader]="thHeader"
      [tableData]="datas"
      [total]="dataTotal"
      [type]="type"
      (clickSelectRow)="clickSelectRow($event)"
      (pageChangeEfffect)="onloadData($event)"
      [page]="page"
      [(sort)]="sort"
    ></yc-ecn-list>
  </div>
</div>
<ng-template #tabTitle>
  <ng-container *ngIf="type==='my'">
    {{ 'root-del.ecn.myecn' | translate }}
  </ng-container>
  <ng-container *ngIf="type==='all'">
    {{ 'root-del.ecn.allecn' | translate }}
  </ng-container>
  <ng-container *ngIf="type==='follow'">
    {{ 'root-del.ecn.myfollowecn' | translate }}
  </ng-container>

</ng-template>
