<nz-drawer
  [nzWidth]="520"
  [nzClosable]="true"
  [nzVisible]="visible"
  nzPlacement="right"
  [nzTitle]="'newObject.ecn.title' | translate"
  (nzOnClose)="cancel()"
  nzWrapClassName="mt-with-footer"
>
  <div>
    <div nz-row class="select-ecn-line">
      <div nz-col nzSpan="8" class="select-ecn-text">
        <span class="mt-danger"> * </span>{{ 'newObject.ecn.changeType' | translate }}:
      </div>
      <div nz-col nzSpan="15">
        <!-- <nz-select
          [(ngModel)]="model.type"
          nzAllowClear
          [nzPlaceHolder]="'newObject.ecn.changeType' | translate"
          class="select-ecn-input"
        >
          <nz-option
            *ngFor="let item of changeTypeList"
            [nzValue]="item.code"
            [nzLabel]="item.name"
          ></nz-option>
        </nz-select> -->

        <nz-checkbox-group
          [(ngModel)]="changeTypeList"
          (ngModelChange)="checkboxChange('type')"
        ></nz-checkbox-group>
      </div>
    </div>
    <div nz-row class="select-ecn-line">
      <div nz-col nzSpan="8" class="select-ecn-text">
        {{ 'newObject.ecn.changeTypeInfo' | translate }}:
      </div>
      <div nz-col nzSpan="15">
        <!-- <nz-select
          [(ngModel)]="model.info"
          nzAllowClear
          [nzPlaceHolder]="'newObject.ecn.changeTypeInfo' | translate"
          class="select-ecn-input"
        >
          <nz-option
            *ngFor="let item of technicalInfoList"
            [nzValue]="item.code"
            [nzLabel]="item.name"
          ></nz-option>
        </nz-select> -->
        <nz-checkbox-group
          [(ngModel)]="technicalInfoList"
          (ngModelChange)="checkboxChange('info')"
        ></nz-checkbox-group>
      </div>
    </div>
  </div>

  <div class="mt-drawer-footer">
    <button (click)="cancel()" type="button" nz-button nzShape="round">
      <i nz-icon nzType="close"></i>{{ 'platform.common.cancel' | translate }}
    </button>
    <button (click)="prevStep()" type="button" nzType="primary" nz-button nzShape="round">
      <i nz-icon nzType="check"></i>{{ 'platform.common.prevStep' | translate }}
    </button>
    <button
      nz-button
      nzShape="round"
      nzType="primary"
      type="button"
      (click)="save()"
      [disabled]="isBlank(model.type)"
    >
      <i nz-icon nzType="check"></i>{{ 'platform.common.confirm' | translate }}
    </button>
  </div>
</nz-drawer>
