import { Injectable, Type } from '@angular/core';
import { MtObjectDetailContext, MtObjectDetailPanelHandler, MtObjectPanelContext } from '@imerge22/spectrum';
import { Observable, of } from 'rxjs';
import { PanelImapactedComponent } from './panel-imapacted.component';

@Injectable({ providedIn: 'root' })
export class PanelImapactedHandler implements MtObjectDetailPanelHandler {

  getComponent(detailContext: MtObjectDetailContext): Observable<Type<MtObjectPanelContext>> {
    return of(PanelImapactedComponent);
  }
}
