import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FilterOperator, GenericQueryService, MtConfigService, MtSafeAny, MtWithConfig } from '@imerge22/core';
import { MtDynamicPropertyValueProvider } from '@imerge22/platform/base';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class GetModelByCodeService {
    @MtWithConfig() private adminServiceName: string;
    @MtWithConfig() private workflowServiceName: string;
    constructor(
        private http: HttpClient,
        public mtConfigSrv: MtConfigService,
        private uqSrv: GenericQueryService,
        private propValueProvider: MtDynamicPropertyValueProvider
    ) {}
    
    public getModel(code: string): Observable<MtSafeAny> {
        let param = {"modelId":"Property","conditions":{"filters":[{"prop":"model.code","operate":"Equals","value": code}]}};
        return this.http.post('srv://mainName/common/query', param);
    }

    public getModelByCode(code: string): Observable<MtSafeAny> {
        return this.getModel(code).pipe(
            switchMap(res => {
                if (!res || !res?.resultData?.data) {
                    return of(res);
                }
                let models = res.resultData?.data;
                let modelValIds = res.resultData?.data.map(item => item.validationId).filter(Boolean);
                if (!modelValIds || modelValIds.length < 1) {
                    return of(models);
                }
               return this.uqSrv.query({
                  modelId: 'ValueValidation',
                  dataSource: this.adminServiceName,
                  conditions: {
                    filters: [{ prop: 'id', operate: FilterOperator.In, values: modelValIds }],
                  },
                }).pipe(
                    map((result: MtSafeAny) => {
                        let validates = result?.resultData?.data;
                        if (!validates || validates.length < 1) {
                            return models;
                        }
                        for (const validate of validates) {
                            models.forEach(item  => {
                                if (item.validationId == validate.id) {
                                    item.validate = validate;
                                }
                            })
                        }
                        return models;
                    })
                )
            })
        )
    }
}