import { Component, EventEmitter, forwardRef, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MtDictCacheService } from '@imerge22/base';
import { MtSafeAny, PassportService } from '@imerge22/core';
import { ObjectDto } from '@imerge22/platform/base';
import { CommonUtils } from '@imerge22/platform/utils';

@Component({
  selector: 'uccs-select-ecn',
  templateUrl: './select-ecn.component.html',
  styleUrls: ['./select-ecn.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectEcnComponent),
      multi: true,
    },
  ],
})
export class SelectEcnComponent implements OnInit {
  private result: EventEmitter<MtSafeAny>;
  isBlank = CommonUtils.isBlank;
  visible: boolean;
  model: MtSafeAny = {};
  changeTypeList: [];
  technicalInfoList: [];

  constructor(
    private dictSrv: MtDictCacheService,
    private passport: PassportService
  ) { }

  ngOnInit() { }

  loadDict(code: string, propertiesName: string) {
    if (CommonUtils.isBlank(this[propertiesName])) {
      this.dictSrv.getItems(code).subscribe(items => {
        this[propertiesName] = items.map(item => ({ label: item.name, value: item.code }));
      });
    }
  }

  checkboxChange(type) {
    let list: MtSafeAny[];
    if (type === 'type') {
      list = this.changeTypeList;
    } else {
      list = this.technicalInfoList;
    }
    this.model[type] = list.filter(item => item.checked).map(item => item.value);
  }

  open(item: ObjectDto, reset?: boolean) {
    if (reset) {
      this.model = {};
    }
    this.loadDict('u6_Change_Type', 'changeTypeList');
    this.loadDict('u6_Change_Datum', 'technicalInfoList');
    if (this.result == null) {
      this.result = new EventEmitter<MtSafeAny>();
    }
    this.visible = true;
    return this.result;
  }

  cancel() {
    this.visible = false;
    this.result.emit();
  }

  save() {
    this.visible = false;
    this.result.emit('complete');
  }

  prevStep() {
    this.visible = false;
    this.result.emit('prevStep');
  }
}
