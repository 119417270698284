import { Component, OnInit, Optional, EventEmitter } from '@angular/core';
import {
  MtSpectrumContrlBase,
  MtSpectrumDataBinding,
  MtSpectrumDataSource,
  MtSpectrumControlRegister,
  MtSpectrumDataService,
} from '@imerge22/spectrum';
import { MtSafeAny, MtConfigService } from '@imerge22/core';
import { MtDictCacheService } from '@imerge22/base';
import { MtTranslateService } from '@imerge22/core/translate';
import { RelativeEcrService } from 'src/app/services/relative-ecr.service';
import { MtNotificationBus } from '@imerge22/core/notification';
import { EncodingService } from '@imerge22/encoding';

@Component({
  selector: 'uccs-code',
  templateUrl: './ecr_no_search.component.html',
  styleUrls: ['./ecr_no_search.component.scss'],
})
export class PackSearchComponent extends MtSpectrumContrlBase<MtSafeAny> implements OnInit {
  constructor(
    @Optional() public dataBinding: MtSpectrumDataBinding,
    public codeSrv: EncodingService,
    private srv: MtDictCacheService,
    public mtSpectrumDataSource: MtSpectrumDataSource,
    private translate: MtTranslateService,
    public mtConfigSrv: MtConfigService,
    public ecrSrv: RelativeEcrService,
    private dataSvc: MtSpectrumDataService
  ) {
    super();
    this.init();
  }
  dictArr = [];
  visible: boolean;
  ecrnosearchval: '';
  ecrnoval: '';
  searchListData = [];
  searchData = {
    productDepartment: '',
    pageSize: 10,
    pageIndex: 1,
    total: 0,
  };
  ecrnoListData = [];
  searchListDataCopy = [];
  listOfCurrentPageData = [];
  tablerow: undefined;
  initConfig(): void {
    // throw new Error("Method not implemented.");
  }
  ngOnInit() {
    this.search();
  }

  init() {
    this.ecrSrv.getEcr().subscribe((res) => {
      this.ecrnoListData = res.resultData.data;
      this.searchListData = [];
      for (const iterator of res.resultData.data) {
        if (iterator.ECR_no !== undefined) {
          if (iterator.ECR_no !== '') {
            this.searchListData.push({ ecrno: iterator.ECR_no, id: iterator.id, style: '' });
          }
        }
      }
      this.searchListDataCopy = JSON.parse(JSON.stringify(this.searchListData));
      this.searchData.total = this.searchListData.length;
    });
  }

  open() {
    this.searchListData = JSON.parse(JSON.stringify(this.searchListDataCopy));
    this.searchListData = [...this.searchListData];
    this.visible = true;
  }

  blur(val?: string) {
    const ecrpackobjectId = this.mtSpectrumDataSource.getValue(
      this.mtSpectrumDataSource.mainObjectId,
      'ECR_packaged'
    ) as MtSafeAny;
    let count = 0;
    for (const iterator of ecrpackobjectId) {
      if (iterator.ECR_no === val) {
        count++;
      }
    }
    if (count !== 0) {
      // this.dataBinding.value = '';
      MtNotificationBus.emit({
        type: 'error',
        title: 'i18n.platform.common.error',
        message: 'i18n.ctrl.repeatErr',
      });
      return false;
    }
    return true;
  }

  save() {
    const row = this.tablerow as MtSafeAny;
    const result = this.blur(row.ecrno);
    if (!result) {
      return;
    }
    this.dataBinding.value = row.ecrno;
    this.ecrnoval = row.ecrno;
    const ecrpackobjectId = this.mtSpectrumDataSource.getValue(
      this.mtSpectrumDataSource.mainObjectId,
      'ECR_packaged'
    ) as MtSafeAny;
    const changeTheme = [];
    for (const iterator of this.ecrnoListData) {
      if (row.id === iterator.id) {
        if (iterator.Change_theme_update !== undefined) {
          changeTheme.push(iterator.Change_theme_update);
        }
        if (iterator.Change_rep !== undefined) {
          this.dataSvc.getObjectPropertyValues(iterator.Change_rep).subscribe((res) => {
            for (const iterator1 of ecrpackobjectId.entries()) {
              if (iterator1[1].ECR_no === this.dataBinding.value) {
                ecrpackobjectId[iterator1[0]].Change_Rep = res.id;
              }
            }
          });
        } else {
          for (const iterator1 of ecrpackobjectId.entries()) {
            if (iterator1[1].ECR_no === this.dataBinding.value) {
              ecrpackobjectId[iterator1[0]].Change_Rep = '';
            }
          }
        }
      }
    }
    const thethemearr = [];
    if ( changeTheme.length !== 0) {
      for (const iterator of changeTheme) {
        this.dataSvc.getObjectPropertyValues(iterator).subscribe((res) => {
          if (res.The_theme !== undefined) {
            thethemearr.push(res.The_theme);
            for (const iterator1 of ecrpackobjectId.entries()) {
              if (iterator1[1].ECR_no === this.dataBinding.value) {
                ecrpackobjectId[iterator1[0]].Brief_description_of_change = thethemearr;
              }
            }
          }
        });
      }
    } else {
      for (const iterator1 of ecrpackobjectId.entries()) {
        if (iterator1[1].ECR_no === this.dataBinding.value) {
          ecrpackobjectId[iterator1[0]].Brief_description_of_change = '';
        }
      }
    }
    this.close();
  }

  close() {
    for (const iterator of this.searchListData as MtSafeAny) {
      iterator.style = '';
    }
    this.tablerow = undefined;
    this.ecrnosearchval = '';
    this.visible = false;
  }

  reset() {
    this.ecrnosearchval = '';
    this.searchListData = JSON.parse(JSON.stringify(this.searchListDataCopy));
    this.searchListData = [...this.searchListData];
    this.searchData.total = this.searchListData.length;
  }

  search() {
    if (this.ecrnosearchval !== undefined) {
      this.ecrnosearchval = this.ecrnosearchval.trim() as MtSafeAny;
    }
    this.searchListData = JSON.parse(JSON.stringify(this.searchListDataCopy));
    if (this.ecrnosearchval === '' || this.ecrnosearchval === undefined) {
      this.searchListData = [...this.searchListData];
      this.searchData.total = this.searchListData.length;
      return;
    }
    const arr = [];
    for (const iterator of this.searchListData.entries()) {
      if (iterator[1].ecrno.indexOf(this.ecrnosearchval) !== -1) {
        arr.push(this.searchListData[iterator[0]]);
      }
    }
    this.searchListData = arr;
    this.searchListData = [...this.searchListData];
    this.searchData.total = this.searchListData.length;
  }

  clickrow(obj: MtSafeAny, index: MtSafeAny) {
    for (const iterator of this.searchListData.entries() as MtSafeAny) {
      iterator[1].style = '';
      if (iterator[1].id === obj.id) {
        index = iterator[0];
      }
    }
    this.tablerow = obj;
    const arr: MtSafeAny[] = this.searchListData as MtSafeAny[];
    arr[index].style = '#ECF5FF';
    this.searchListData = arr;
    this.searchListData = [...this.searchListData];
  }

  onCurrentPageDataChange(listOfCurrentPageData: []): void {
    this.listOfCurrentPageData = listOfCurrentPageData;
  }
}
export function registerPackSearchCtrl() {
  MtSpectrumControlRegister.register('mtEcrNoSearch', PackSearchComponent);
}
