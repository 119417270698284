import { Injectable } from '@angular/core';

import { MtDynamicTabHandler, MtDynamicTabRegister } from '@imerge22/common';
import { MtEvent, MtSafeAny, MtMap } from '@imerge22/core';
import { ProjectAccountingComponent } from './project-accounting.component';
import { ProjectAccountingActivateEvent } from './events';


@Injectable({ providedIn: 'root' })
export class ProjectAccountingDetailHandler extends MtDynamicTabHandler {
  match(context: string, event: MtEvent<MtSafeAny>): boolean {
    if (event.name === ProjectAccountingActivateEvent.$name) {
      const data = ProjectAccountingActivateEvent.get(event);
      return data?.context === context;
    }
    return false;
  }

  same(context: string, exist: MtMap, event: MtEvent<MtSafeAny>): boolean {
    if (event.name === ProjectAccountingActivateEvent.$name && exist.id === event.data.id) {
      const data = ProjectAccountingActivateEvent.get(event);
      return data?.context === context;
    }
    return false;
  }
}

export function registerProjectAccountingDetailDynamicTab() {
  MtDynamicTabRegister.register(ProjectAccountingActivateEvent.$name, {
    component: ProjectAccountingComponent,
    handler: ProjectAccountingDetailHandler,
  });
}
