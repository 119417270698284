import { Component, OnInit, Optional } from '@angular/core';
import { MtSafeAny } from '@imerge22/core';
import { MtSpectrumContrlBase, MtSpectrumControlRegister } from '@imerge22/spectrum/controls';
import { MtSpectrumDataBinding } from '@imerge22/spectrum/objects';
import * as XLSX from 'xlsx';
import { MtDictCacheService } from '@imerge22/base';
import { MtNotificationBus } from '@imerge22/core/notification';
import { MtTranslateService } from '@imerge22/core/translate';

type AOA = MtSafeAny[][];
@Component({
  selector: 'uccs-mt-data-input',
  templateUrl: './mt-change-item-information-data-input.html',
  styleUrls: ['./mt-change-item-information-data-input.scss'],
})
export class MtChangeItemInformatinDataInputComponent extends MtSpectrumContrlBase<MtSafeAny>
  implements OnInit {
  fileName: string;
  searchModel: MtSafeAny;
  dict: MtSafeAny;
  language: string;

  initConfig(): void {}
  constructor(
    @Optional() public dataBinding: MtSpectrumDataBinding,
    private srv: MtDictCacheService,
    protected translate: MtTranslateService
  ) {
    super();
  }

  reset() {
    this.searchModel = {};
    this.fileName = null;
  }

  ngOnInit(): void {
    const dict = [];
    this.srv.getItems('Action_dict').subscribe((res) => {
      res.map((data) => {
        dict.push({ code: data.code, name: data.name });
      });
      this.dict = dict;
    });
    this.reset();
  }

  // 获取文件后缀判断文件类型是否为xls、xlsx
  getFileType(fileName: MtSafeAny) {
    let suffix = '';
    let result = '';
    const fileArr = fileName.split('.');
    suffix = fileArr[fileArr.length - 1];
    if (!suffix) {
      return false;
    }
    suffix = suffix.toLocaleLowerCase();
    // 匹配excel
    const excelList = ['xls', 'xlsx'];
    result = excelList.find((item) => {
      return item === suffix;
    });
    if (result) {
      return 'excel';
    }
  }

  onFileChange(evt: MtSafeAny) {
    const name = evt.target.value as MtSafeAny;
    const fileType = this.getFileType(name);
    if (fileType !== 'excel') {
      MtNotificationBus.emit({
        type: 'error',
        title: 'i18n.platform.common.error',
        message: 'i18n.ctrl.typeErr',
      });
      return;
    }
    /* wire up file reader */
    const target: DataTransfer = evt.target as DataTransfer;
    if (target.files.length !== 1) {
      // throw new Error('Cannot use multiple files');
      this.fileName = null;
      return;
    }

    const reader: FileReader = new FileReader();
    const data = [];
    reader.onload = (e: MtSafeAny) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save dataList */
      const value = XLSX.utils.sheet_to_json(ws, { header: 1, raw: true }) as AOA;
      value.splice(0, 2);
      const exit = value.findIndex((res) => res.length === 0);
      if (exit >= 0) {
        value.splice(exit, 1);
      }
      // 获取当前页面系统语言
      this.language = this.translate.currentLang;
      // 判断当前页面的语言与文档标识是否匹配
      // case1:当前语言为English
      if (this.language === 'en-US') {
        if (ws.A1.v !== 'en') {
          MtNotificationBus.emit({
            type: 'error',
            title: 'i18n.platform.common.error',
            message: 'Document identification error,please import documents identified as "en"',
          });
          data.push({});
          return;
        } else if (ws.A1.v === 'en') {
          value.map((res) => {
            data.push({
              No: res[0],
              Original_no: res[1],
              Original_name: res[2],
              Original_rev: res[3],
              Changed_no: res[4],
              Changed_name: res[5],
              Changed_rev: res[6],
              Action: res[7],
              Associated_part: res[8],
              Associated_name: res[9],
            });
          });
          data.forEach((item: MtSafeAny) => {
            item.Action = item.Action.toLowerCase();
          });
          this.dict.forEach((item: MtSafeAny) => {
            item.name = item.name.toLowerCase();
          });
          const dataArr = [];
          for (const dataItem of data) {
            for (const dictObj of this.dict.entries()) {
              const dictItem = dictObj[1];
              const index = dictObj[0];
              if (dataItem.Action === dictItem.name) {
                dataItem.Action = dictItem.code;
                dataArr.push(dataItem);
                break;
              }
              if (index === this.dict.length - 1) {
                dataItem.Action = '';
                dataArr.push(dataItem);
                MtNotificationBus.emit({
                  type: 'error',
                  title: 'i18n.platform.common.error',
                  message: 'Please enter the correct action properties',
                });
              }
            }
          }
        }
      } else if (this.language === 'zh-CN') {
        // case2：当前语言为中文
        if (ws.A1.v !== 'zn') {
          // 当前文档标识不匹配
          MtNotificationBus.emit({
            type: 'error',
            title: 'i18n.platform.common.error',
            message: '文档标识有误，请导入标识为"zn"的文档',
          });
          data.push({});
          return;
        } else if (ws.A1.v === 'zn') {
          value.map((res) => {
            data.push({
              No: res[0],
              Original_no: res[1],
              Original_name: res[2],
              Original_rev: res[3],
              Changed_no: res[4],
              Changed_name: res[5],
              Changed_rev: res[6],
              Action: res[7],
              Associated_part: res[8],
              Associated_name: res[9],
            });
          });
          const dataArr = [];
          for (const dataItem of data) {
            for (const dictObj of this.dict.entries()) {
              const dictItem = dictObj[1];
              const index = dictObj[0];
              if (dataItem.Action === dictItem.name) {
                dataItem.Action = dictItem.code;
                dataArr.push(dataItem);
                break;
              }
              if (index === this.dict.length - 1) {
                dataItem.Action = '';
                dataArr.push(dataItem);
                MtNotificationBus.emit({
                  type: 'error',
                  title: 'i18n.platform.common.error',
                  message: '请输入正确的操作属性',
                });
              }
            }
          }
        }
      }
      this.fileName = target.files[0].name;
    };
    reader.readAsBinaryString(target.files[0]);
    this.dataBinding.value = data;
  }
}

export function registerChangeItemInformatinDataInput() {
  MtSpectrumControlRegister.register('mtDataInput', MtChangeItemInformatinDataInputComponent);
}
