<span *ngIf="!dataBinding.editing">
  {{showText}}
</span>

<nz-select
  *ngIf="dataBinding.editing"
  nzShowSearch
  nzServerSearch
  nzPlaceHolder="输入用户名查询"
  [nzShowArrow]="false"
  [nzFilterOption]="nzFilterOption"
  [(ngModel)]="value"
  (ngModelChange)="userSelectChanges($event)"
  (nzOnSearch)="getUserListBySubject($event)"
>
  <nz-option *ngFor="let o of userListOption" [nzLabel]="o?.label" [nzValue]="o?.id"></nz-option>
</nz-select>