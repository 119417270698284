import { Injectable } from '@angular/core';

import { MtDynamicTabHandler, MtDynamicTabRegister } from '@imerge22/common';
import { MtEvent, MtSafeAny, MtMap } from '@imerge22/core';

import { EwoFollowActivateEvent } from './events';
import { EwoFollowComponent } from './ewo-follow.component';

@Injectable({ providedIn: 'root' })
export class EwoFollowHandler extends MtDynamicTabHandler {
  match(context: string, event: MtEvent<MtSafeAny>): boolean {
    if (event.name === EwoFollowActivateEvent.$name) {
      const data = EwoFollowActivateEvent.get(event);
      return data?.context === context;
    }
    return false;
  }

  same(context: string, exist: MtMap, event: MtEvent<MtSafeAny>): boolean {
    if (event.name === EwoFollowActivateEvent.$name) {
      const data = EwoFollowActivateEvent.get(event);
      return data?.context === context;
    }
    return false;
  }
}

export function registerEwoFollowDynamicTab() {
  MtDynamicTabRegister.register(EwoFollowActivateEvent.$name, {
    component: EwoFollowComponent,
    handler: EwoFollowHandler,
    initializeComponent: true,
  });
}
