import { Component, OnInit, Optional } from '@angular/core';
import { MtSafeAny } from '@imerge22/core';
import { MtSpectrumContrlBase, MtSpectrumControlRegister } from '@imerge22/spectrum/controls';
import { MtSpectrumDataBinding } from '@imerge22/spectrum/objects';
import { EncodingService } from '../../handlers/encoding.service';

@Component({
  selector: 'uccs-ctrl-example',
  template: '<button nz-button nzType="primary" (click)="getCode()">指派</button>',
  styleUrls: ['./example.component.scss'],
})
export class UccsExampleCtrl extends MtSpectrumContrlBase<MtSafeAny> implements OnInit {
  code: MtSafeAny;
  json = {
    ruleId: 'Lnc2MEB_TjmHtZy_7xdH5Q',
    listData: [
      {
        fileId: '5oRVh74jQKCIcbcRj0bZhQ',
        fileName: 'ECR固定段',
        fileTypeName: '固定值',
        lovId: '',
        lovPId: '',
        ruleFieldId: '4SdhJ9feSk67qFOF4cmAOA',
        ruleFieldOrder: 1,
        ruleFieldValue: 'ECR',
        ruleId: 'Lnc2MEB_TjmHtZy_7xdH5Q',
        valueInput: 'ECR',
        rowNo: 1,
      },
      {
        fileId: 'IRqiR1-tTXe9VV4nyFFrrg',
        fileName: '-',
        fileTypeName: '固定值',
        lovId: '',
        lovPId: '',
        ruleFieldId: 'dNFzRmBJRoa5S5RGqApxNQ',
        ruleFieldOrder: 2,
        ruleFieldValue: '-',
        ruleId: 'Lnc2MEB_TjmHtZy_7xdH5Q',
        valueInput: '-',
        rowNo: 2,
      },
      {
        fileId: 'odQDS9CyRJKDn4wduEd_ng',
        fileName: 'ECR流水码',
        fileTypeName: '流水码',
        lovId: '',
        lovPId: '',
        ruleFieldId: 'l_bCFFH8RsSDTbUn6t1XVw',
        ruleFieldOrder: 3,
        ruleFieldValue: '',
        ruleId: 'Lnc2MEB_TjmHtZy_7xdH5Q',
        valueInput: '',
        rowNo: 3,
      },
    ],
  };

  constructor(
    @Optional() public dataBinding: MtSpectrumDataBinding,
    public codeSrv: EncodingService
  ) {
    super();
  }

  ngOnInit(): void {}

  initConfig() {}

  getCode() {
    this.codeSrv.generateEncoding().subscribe((res: MtSafeAny) => {
      if (res.success) {
        this.code = res.resultData.data;
      }
    });
  }
}

export function registerExampleCtrl() {
  MtSpectrumControlRegister.register('uccsExample', UccsExampleCtrl);
}
