<div class="body">
  <div class="body-content">
    <nz-alert nzType="info" 
      [nzMessage]="'platform.core.dict.basicInformation' | translate"
    ></nz-alert>
    <div class="card-content" *ngIf="object && haveAuthority; else noPermission">
      <div nz-row>
        <div nz-col nzSpan="8" class="item-content">
          <div class="label">{{'platform.base.obj.objectNumber' | translate}}:</div>
          <div class="item">{{object.item.itemId}}</div>
        </div>
        <div nz-col nzSpan="8" class="item-content"> 
          <div class="label">{{'platform.base.obj.objectName' | translate}}:</div>
          <div class="item">{{object.objectName}}</div>
        </div>
        <div nz-col nzSpan="8" class="item-content">
          <div class="label">{{'platform.base.obj.objectType' | translate}}:</div>
          <div class="item">{{object.objectType}}</div>
        </div>
      </div>
      <div nz-row>
        <div nz-col nzSpan="8" class="item-content">
          <div class="label">{{'platform.base.obj.createUser' | translate}}:</div>
          <div class="item">{{object.createUser.name}}/{{object.createUser.account}}</div>
        </div>
        <div nz-col nzSpan="8" class="item-content">
          <div class="label">{{'platform.base.obj.createTime' | translate}}:</div>
          <div class="item">{{object.createTime | translateTime: 'YYYY-MM-DD HH:mm:ss'}}</div>
        </div>
        <div nz-col nzSpan="8" class="item-content">
          <div class="label">{{'platform.base.obj.updateTime' | translate}}:</div>
          <div class="item">{{object.updateTime | translateTime: 'YYYY-MM-DD HH:mm:ss'}}</div>
        </div>
        <div nz-col nzSpan="8" class="item-content">
          <div class="label">{{'platform.base.obj.objectStatus' | translate}}:</div>
          <div class="item">{{ transferStatus(data?.extra?.statusCode) }}</div>
        </div>
        <div nz-col nzSpan="16" class="item-content">
          <div class="label">{{ 'platform.base.obj.process' | translate }}:</div>
          <div class="item" *ngIf="data?.extra?.processInsObjecs">
            <a
              *ngFor="let item of data?.extra?.processInsObjecs"
              (click)="openProcessView(item.procInsId, processViewTitle, processViewContent, processViewFooter)"
            >
              {{ item.procInsName }}
            </a>
          </div>
          
        </div>
      </div>
    </div>
    <nz-alert nzType="info" 
      [nzMessage]="'platform.core.dict.detailedInformation' | translate"
    ></nz-alert>
    <div class="card-content">
      <ng-container *ngIf="haveAuthority; else noPermission">
        <nz-tabset
          *ngIf="panels?.length > 0 && mtObjects[mtObjectId] != null"
          [(nzSelectedIndex)]="panelIndex"
          [nzTabBarExtraContent]="panelExtra"
          [nzCanDeactivate]="canDeactivate"
        >
          <!-- 我的桌面面板Tab -->
          <nz-tab *ngFor="let panel of panels; let i = index" [nzTitle]="panelTabTitle">
            <ng-template #panelTabTitle>
              <div class="mt-tab-title" (dblclick)="modal.toggle()">
                {{ panel.panel | propI18n: 'name' }}
              </div>
            </ng-template>
            <ng-template #modalTitle>
              <ng-template [ngTemplateOutlet]="title"></ng-template>
              <div>/ {{ panel.panel | propI18n: 'name' }}</div>
            </ng-template>
            <ng-template #modalClose>
              <div class="modal-close-area" (click)="stopPropagation($event)">
                <ng-template [ngTemplateOutlet]="panel.instance?.extraContent"></ng-template>
              </div>
              <button class="ant-modal-close test">
                <span class="ant-modal-close-x">
                  <i class="ant-modal-close-icon" nz-icon nzType="close"></i>
                </span>
              </button>
            </ng-template>
            <ng-template
              [mtModalable]
              [mtModalMask]="false"
              [mtModalTitle]="modalTitle"
              [mtModalCloseArea]="modalClose"
              #modal="mtModalable"
            >
              <ng-container *ngIf="panel.panel.visible; else noPermission" [mtPanelRenderer]="panel" [mtActivate]="i == panelIndex"></ng-container>
            </ng-template>
          </nz-tab>
        </nz-tabset>
        <ng-template #panelExtra>
          <!-- <div class="mt-panel-extra" *ngIf="panelIndex !== -1 && panels[panelIndex].panel.editable"> -->
          <div class="mt-panel-extra" *ngIf="isShowButton && this.mtEditable && panels[panelIndex].panel.editable">
            <ng-template [ngTemplateOutlet]="panels[panelIndex]?.instance?.extraContent"></ng-template>
          </div>
        </ng-template>
      </ng-container>
      <!-- 无权限时显示 -->
      <ng-template #noPermission>
        <nz-result style="height: 300px;" nzStatus="warning" [nzTitle]="'platform.spectrum.noPermission' | translate">
        </nz-result>
      </ng-template>
    </div>
  </div>
</div>
<ng-template #tabTitle>{{data?.extra?.CustCode}}{{ data?.extra?.Title ? ('-' + data?.extra?.Title) : ''}}</ng-template>
<!-- 流程视图 -->
<ng-template #processViewTitle>
  <span>{{ 'platform.workflow.workTable.processView' | translate }}</span>
</ng-template>
<ng-template #processViewContent let-params>
  <mt-workflow-bpmn-viewer [processInsId]="params.id"></mt-workflow-bpmn-viewer>
</ng-template>
<ng-template #processViewFooter let-ref="modalRef">
  <button nz-button nzShape="round" nzType="danger" (click)="ref.destroy()">
    <i nz-icon nzType="close"></i>
    {{ 'platform.common.close' | translate }}
  </button>
</ng-template>