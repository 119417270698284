<div>
  <div class="divBtns" *ngIf="isAdd || allowEdit">
    <button *ngIf="!isAdd && editing" nz-button type="button" class="ant-btn-md" nzShape="round" (click)="canleEdit()">
      <i nz-icon nzType="undo" nzTheme="outline"></i><span>{{ 'platform.common.cancel' | translate }}</span>
    </button>
    <button *ngIf="!editing" nz-button type="button" class="ant-btn-md" nzShape="round" (click)="startEdit()">
      <i nz-icon nzType="edit" nzTheme="outline"></i><span>{{ 'platform.common.default.edit' | translate }}</span>
    </button>

    <button
      nz-button
      *ngIf="editing"
      nzShape="round"
      nzType="primary"
      type="button"
      class="ant-btn-md"
      [nzLoading]="isEditloading"
      (click)="submitEdit()"
      [disabled]="isEditloading"
    >
      <i nz-icon nzType="check" nzTheme="outline"></i>
      <span>{{ 'platform.common.save' | translate }}</span>
    </button>
  </div>
  <!-- 基础信息 -->
  <nz-card class="mt-autoheight" [nzTitle]="'common-del.basicInfo' | translate">
    <form nz-form [formGroup]="validateForm">
      <div nz-row [nzGutter]="24">
        <div nz-col [nzSpan]="6">
          <nz-form-item>
            <nz-form-label [nzSm]="8" [nzXs]="24"> {{ 'common-del.ecn.ecnNo' | translate }}</nz-form-label>
            <nz-form-control [nzSm]="16" [nzXs]="24">
              <nz-select *ngIf="isAdd" id="ecn_no" formControlName="ecn_no" nzAllowClear>
                <nz-option [nzLabel]="item" [nzValue]="item" *ngFor="let item of object?.ecn_list"></nz-option>
              </nz-select>
              <!-- <input *ngIf="isAdd" nz-input id="ecn_no" formControlName="ecn_no" maxlength="64" /> -->
              <span *ngIf="!isAdd" class="mt-fill"> {{ object?.objectName }} </span>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div nz-col [nzSpan]="6">
          <nz-form-item>
            <nz-form-label [nzSm]="8" [nzXs]="24"> {{ 'common-del.ecn.relatedEwo' | translate }}</nz-form-label>
            <nz-form-control [nzSm]="16" [nzXs]="24">
              <a nz-button nzType="link" (click)="openEwo()" *ngIf="object?.ewo_no">
                {{ object?.ewo_no }}
              </a>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div nz-col [nzSpan]="6">
          <nz-form-item>
            <nz-form-label [nzSm]="8" [nzXs]="24">{{ 'common-del.creator' | translate }} </nz-form-label>
            <nz-form-control [nzSm]="16" [nzXs]="24">
              <ng-container *ngIf="object?.creator != null">
                {{ object?.creator | userSync: '${name}(${account})' }}
              </ng-container>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div nz-col [nzSpan]="6">
          <nz-form-item>
            <nz-form-label [nzSm]="8" [nzXs]="24">{{ 'common-del.creatorDept' | translate }} </nz-form-label>
            <nz-form-control [nzSm]="16" [nzXs]="24">
              <span>{{ object?.deptName }}</span>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div nz-col [nzSpan]="6">
          <nz-form-item>
            <nz-form-label [nzSm]="8" [nzXs]="24">{{ 'common-del.ecn.plannedCompletionTime' | translate }} </nz-form-label>
            <nz-form-control [nzSm]="16" [nzXs]="24">
              <nz-date-picker style="width: 100%" *ngIf="checkEditProps()" formControlName="planned_complete_date"></nz-date-picker>
              <span *ngIf="!checkEditProps()" class="mt-fill"> {{ object?.planned_complete_date | translateTime: 'YYYY-MM-DD' }} </span>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="6">
          <nz-form-item>
            <nz-form-label [nzSm]="8" [nzXs]="24">{{ 'common-del.ecn.valid_date' | translate }} </nz-form-label>
            <nz-form-control [nzSm]="16" [nzXs]="24">
              <nz-date-picker style="width: 100%" *ngIf="checkEditProps()" formControlName="valid_date"></nz-date-picker>
              <span *ngIf="!checkEditProps()" class="mt-fill"> {{ object?.valid_date | translateTime: 'YYYY-MM-DD' }} </span>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div nz-col [nzSpan]="6" *ngIf="!isAdd">
          <nz-form-item>
            <nz-form-label [nzSm]="8" [nzXs]="24">{{ 'common-del.createTime' | translate }} </nz-form-label>
            <nz-form-control [nzSm]="16" [nzXs]="24">
              <span>{{ object?.createTime | translateTime: 'yyyy-MM-DD HH:mm' }}</span>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div nz-col [nzSpan]="12">
          <nz-form-item>
            <nz-form-label [nzSm]="4" [nzXs]="24" nzFor="distribution_range">{{ 'common-del.distributionRange' | translate }}</nz-form-label>
            <nz-form-control [nzSm]="20" [nzXs]="24">
              <nz-select *ngIf="checkEditProps()" nzMode="multiple" formControlName="distribution_range">
                <nz-option
                  *ngFor="let option of distributionRangeListOfOption"
                  [nzLabel]="option.name"
                  [nzValue]="option.nameEn"
                ></nz-option>
              </nz-select>
              <span *ngIf="!checkEditProps()" class="mt-fill">
                <ng-container *ngFor="let item of object?.distribution_range">
                  {{ item | dictItemName: 'Distribution_scope' | async }}
                </ng-container>
              </span>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div nz-col [nzSpan]="12" *ngIf="mtProcInsId != null">
          <nz-form-item>
            <nz-form-label [nzSm]="6" [nzXs]="24">{{ 'common-del.workflowName' | translate }} </nz-form-label>
            <nz-form-control [nzSm]="18" [nzXs]="24">
              <a style="white-space: break-spaces; text-align: left" nzType="link" (click)="showWorkflowViewer()">{{ mtProcInsName }}</a>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </form>
  </nz-card>

  <!-- 表单信息 -->
  <nz-card class="mt-autoheight" [nzTitle]="'common-del.detailedInformation' | translate">
    <div class="steps-content">
      <div *ngFor="let panel of panels; index as i">
        <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="mtObjectInited[mtObjectId] !== 'loaded'">
          <ng-template #indicatorTemplate>
            <i style="font-size: 40px; position: fixed; top: 50%" nz-icon nzType="loading"></i>
          </ng-template>
        </nz-spin>

        <!--加载完成后-->
        <ng-container *ngIf="mtObjectInited[mtObjectId] === 'loaded'">
          <h3>{{ panel.panel | propI18n: 'name' }}</h3>
          <!-- 注入控件显示 -->
          <ng-container *ngIf="haveAuthority; else noPermission">
            <ng-container [mtPanelRenderer]="panel" [mtActivate]="i == 0"></ng-container>
          </ng-container>

          <!-- 无权限时显示 -->
          <ng-template #noPermission>
            <nz-result nzStatus="warning" [nzTitle]="'platform.spectrum.noPermission' | translate"> </nz-result>
          </ng-template>
        </ng-container>
      </div>
    </div>
  </nz-card>
</div>

<!-- 查看流程 -->
<yc-workflow-viewer [currentProcessIns]="currentProcessIns" [isAdmin]="isAdmin"></yc-workflow-viewer>

<ng-template #tabTitle>
  <ng-container *ngIf="isAdd"> {{ 'common-del.ecn.createEcn' | translate }} </ng-container>
  <ng-container *ngIf="!isAdd">{{ object?.objectName }}</ng-container>
</ng-template>
