import { Injectable } from '@angular/core';

import { MtDynamicTabHandler, MtDynamicTabRegister } from '@imerge22/common';
import { MtEvent, MtSafeAny, MtMap } from '@imerge22/core';

import { MtTaskTodoListComponent } from '../task-todo-list/task-todo-list.component';
import { MtTaskTodoListActivateEvent } from './events';

@Injectable({ providedIn: 'root' })
export class MtTaskTodoListTabsHandler extends MtDynamicTabHandler {
  match(context: string, event: MtEvent<MtSafeAny>): boolean {
    if (event.name === MtTaskTodoListActivateEvent.$name) {
      const data = MtTaskTodoListActivateEvent.get(event);
      return data?.context === context;
    }
    return false;
  }

  same(context: string, exist: MtMap, event: MtEvent<MtSafeAny>): boolean {
    if (event.name === MtTaskTodoListActivateEvent.$name) {
      const data = MtTaskTodoListActivateEvent.get(event);
      return data?.context === context;
    }
    return false;
  }
}

export function registerTaskTodoListTabsDynamicTab() {
  MtDynamicTabRegister.register(MtTaskTodoListActivateEvent.$name, {
    component: MtTaskTodoListComponent,
    handler: MtTaskTodoListTabsHandler,
  });
}
