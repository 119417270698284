import { Component, OnInit, Optional, ViewChild, EventEmitter, TemplateRef } from '@angular/core';
import { FilterOperator, GenericQueryRequest, MtMap, MtNotificationBus } from '@imerge22/core';
import {
  MtConfigService,
  MtDataSourceService,
  MtObjectComponentBase,
  MtObjectData,
  MtWithConfig,
  ObjectDto
} from '@imerge22/platform/base';
import { TableRenderComponent } from '@imerge22/platform/mt-spectrum';
import { MtDynamicTabBase } from '@imerge22/common';

@Component({
  selector: 'uccs-super-query-result',
  templateUrl: './super-query-result.component.html',
  styleUrls: ['./super-query-result.component.scss']
})
export class SuperQueryResultComponent implements MtDynamicTabBase, OnInit {
  @ViewChild('tablePCN', { static: true }) tablePCN: TableRenderComponent;
  @MtWithConfig() private spectrumServiceName: string;
  queryProps: GenericQueryRequest;
  queryDatas: GenericQueryRequest;
  selectFolderVisible = false; // 控制选择文件夹弹框显示
  selectedFolder;
  pageSize: number;
  isUaesQuery: boolean;

  constructor(
    public srv: MtDataSourceService,
    public mtConfigSrv: MtConfigService
  ) {
  }
  data: MtMap;
  // title = '查询结果';

  get title() {
    return `查询结果${this.data.extra.titleParam.count}`;
  }
  onCloseByInner = new EventEmitter<void>();
  onActivated(): void {
  }
  onDeactivated(): void {
  }
  onDataChange(data: MtMap): void {
  }

  ngOnInit() {
    this.tablePCN.tableVM.config.checkbox = true;
    this.tablePCN.tableVM.config.pageSizeOptions = [
      100,
      200,
      500,
      1000,
      2000,
      3000,
      5000,
      10000,
      20000,
      30000,
      50000,
      60000
    ];

    this.pageSize = this.data.extra.pageSize;
    this.queryDatas = this.data.extra.queryData;
    this.isUaesQuery = this.data.extra.isUaesQuery;
    this.queryProps = {
      dataSource: this.spectrumServiceName,
      modelId: 'Property',
      conditions: {
        filters: [
          {
            prop: 'model.code',
            operate: FilterOperator.Equals,
            value: this.data.extra.queryData.modelId
          }
        ]
      }
    };
  }

  activate() {
    this.tablePCN.activate();
  }

  deactivate() {
    this.tablePCN.deactivate();
  }

  checkSelectedData() {
    return !!this.tablePCN.tableVM.data.rawDatas.filter(row => row.checked).length;
  }

  copyObject() {
    this.selectFolderVisible = true;
  }

  selectedCallBack(obj: ObjectDto) {
    if (obj == null) {
      return;
    }
    this.selectedFolder = obj;
  }

  complete() {
    const list = this.tablePCN.tableVM.data.rawDatas;
    const ids = list.filter(row => row.checked).map(row => row.ITEM_MUID || row._id);
    this.srv.addObjsToFolder(this.selectedFolder.id, ids).subscribe(
      resp => {
        this.selectFolderVisible = false;
        this.selectedFolder = null;
        this.tablePCN.updateRowAllChecked(false);
        MtNotificationBus.emit({
          type: 'success',
          title: 'i18n.platform.common.success',
          message: 'i18n.platform.spectrum.search.copySuccess'
        });
      },
      error => {
        if (error != null && error.isExtend != null) {
          MtNotificationBus.emit({
            type: 'error',
            title: this.srv.getObjectTitle(this.srv.clipboard),
            message: 'i18n.platform.spectrum.object.objectExistInFolder'
          });
        }
      }
    );
  }
}
