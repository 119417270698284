import { Injectable } from '@angular/core';

import { MtDynamicTabHandler, MtDynamicTabRegister } from '@imerge22/common';
import { MtEvent, MtSafeAny, MtMap } from '@imerge22/core';

import { EcnAllActivateEvent, EcnDetailActivateEvent, FollowEcnActivateEvent, MyEcnActivateEvent } from './events';
import { EcnDetailComponent } from './pages/ecn-detail/ecn-detail.component';
import { EcnPagesComponent } from './pages/ecn-pages/ecn-pages.component';

@Injectable({ providedIn: 'root' })
export class EcnHandler extends MtDynamicTabHandler {
  match(context: string, event: MtEvent<MtSafeAny>): boolean {
    if (event.name === EcnAllActivateEvent.$name) {
      const data = EcnAllActivateEvent.get(event);
      return data?.context === context;
    }

    if (event.name === MyEcnActivateEvent.$name) {
      const data = MyEcnActivateEvent.get(event);
      return data?.context === context;
    }

    if (event.name === FollowEcnActivateEvent.$name) {
      const data = FollowEcnActivateEvent.get(event);
      return data?.context === context;
    }

    if (event.name === EcnDetailActivateEvent.$name) {
      const data = EcnDetailActivateEvent.get(event);
      return data?.context === context;
    }
    return false;
  }

  same(context: string, exist: MtMap, event: MtEvent<MtSafeAny>): boolean {
    if (event.name === EcnAllActivateEvent.$name) {
      const data = EcnAllActivateEvent.get(event);
      return data?.context === context;
    }

    if (event.name === MyEcnActivateEvent.$name) {
      const data = MyEcnActivateEvent.get(event);
      return data?.context === context;
    }

    if (event.name === FollowEcnActivateEvent.$name) {
      const data = FollowEcnActivateEvent.get(event);
      return data?.context === context;
    }

    return false;
  }
}

export function registerEcnAllDynamicTab() {
  MtDynamicTabRegister.register(EcnAllActivateEvent.$name, {
    component: EcnPagesComponent,
    handler: EcnHandler,
    initializeComponent: true,
  });
}

export function registerMyEcnDynamicTab() {
  MtDynamicTabRegister.register(MyEcnActivateEvent.$name, {
    component: EcnPagesComponent,
    handler: EcnHandler,
    initializeComponent: true,
  });
}

export function registerFollowEcnDynamicTab() {
  MtDynamicTabRegister.register(FollowEcnActivateEvent.$name, {
    component: EcnPagesComponent,
    handler: EcnHandler,
    initializeComponent: true,
  });
}

export function registerEcnDetailDynamicTab() {
  MtDynamicTabRegister.register(EcnDetailActivateEvent.$name, {
    component: EcnDetailComponent,
    handler: EcnHandler,
    initializeComponent: true,
  });
}

