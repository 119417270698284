import { Injectable } from '@angular/core';
import { FilterOperator, GenericQueryService } from '@imerge22/core';
import { MtConfigService, MtWithConfig } from '@imerge22/platform/base';
import { Observable } from 'rxjs';

export interface UesrDTO {
  userId?: string;
  account?: string;
}

@Injectable({ providedIn: 'root' })
export class MtUserService {
  constructor(private querySrv: GenericQueryService, public mtConfigSrv: MtConfigService) {}

  @MtWithConfig() private rbacServiceName: string;

  getUserById(user: UesrDTO): Observable<any> {
    const req = {
      modelId: 'User',
      dataSource: this.rbacServiceName,
      conditions: { filters: [] },
    };
    if (user.userId) {
      req.conditions.filters.push({
        prop: 'id',
        operate: FilterOperator.Equals,
        value: user.userId,
      });
    }
    if (user.account) {
      req.conditions.filters.push({
        prop: 'account',
        operate: FilterOperator.Equals,
        value: user.account,
      });
    }
    return this.querySrv.query(req);
  }
}
