import { Component, OnInit } from '@angular/core';
import { MtObjectPanelContext, MtObjectDetailContext } from '@imerge22/spectrum';

@Component({
  selector: 'uccs-panel-s-bom',
  templateUrl: './panel-s-bom.component.html',
  styleUrls: ['./panel-s-bom.component.scss']
})
export class PanelSBomComponent extends MtObjectPanelContext implements OnInit {

  constructor(
    public mtObjectDetailContext: MtObjectDetailContext
  ) {
    super();
  }

  ngOnInit() {
  }

}
