import { Component, OnInit, Optional } from '@angular/core';
import { MtSpectrumContrlBase, MtSpectrumControlRegister } from '@imerge22/spectrum/controls';
import { MtSpectrumDataBinding, MtSpectrumDataSource } from '@imerge22/spectrum/objects';
import { MtTranslateService } from '@imerge22/core/translate';
import { MtSafeAny } from '@imerge22/core';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'uccs-mt-finish-time',
  templateUrl: './mt-finish-time.component.html',
  styleUrls: ['./mt-finish-time.component.scss'],
})
export class MtFinishTimeComponent extends MtSpectrumContrlBase<MtSafeAny> implements OnInit {
  currentDate: MtSafeAny;
  flag = 1;
  initConfig(): void {}

  constructor(
    @Optional() public dataBinding: MtSpectrumDataBinding,
    protected translate: MtTranslateService,
    public dataSource: MtSpectrumDataSource
  ) {
    super();
  }

  ngOnInit(): void {}

  addFinishTime() {
    if (this.flag === 1) {
      const da = new Date();
      const year = da.getFullYear();
      const month = da.getMonth() + 1;
      const day = da.getDate();
      const date = [year, month, day].join('-');
      this.currentDate = date;
      const mainObjId = this.dataSource.mainObjectId;
      const qassurancePlanAction = this.dataSource.getValue(
        mainObjId,
        'Q_Assurance_plan_action'
      ) as string;
      const development = this.dataSource.getValue(qassurancePlanAction, 'Development') as string;
      const offerDrawing = this.dataSource.getValue(development, 'Offer_drawing') as string;
      const finishTimeAz = this.dataSource.getValue(offerDrawing, 'Updated_on');
      const testSpecTestTechQz = this.dataSource.getValue(
        development,
        'Test_spec_test_tech_QZ'
      ) as string;
      const finishTimeQz = this.dataSource.getValue(testSpecTestTechQz, 'Updated_on');
      const updateBv = this.dataSource.getValue(development, 'Update_BV') as string;
      // const UpdateBv2 = this.dataSource.getValue(development, 'Update_BV2') as string;
      const finishTimeBv = this.dataSource.getValue(updateBv, 'Updated_on');
      const technicCustomerInformation = this.dataSource.getValue(
        development,
        'Technic_Customer_Information'
      ) as string;
      const finishTimeTku = this.dataSource.getValue(technicCustomerInformation, 'Updated_on');
      if (finishTimeAz === undefined) {
        this.dataSource.setValue(offerDrawing, 'Updated_on', date);
      }
      if (finishTimeQz === undefined) {
        this.dataSource.setValue(testSpecTestTechQz, 'Updated_on', date);
      }
      if (finishTimeBv === undefined) {
        this.dataSource.setValue(updateBv, 'Updated_on', date);
      }
      if (finishTimeTku === undefined) {
        this.dataSource.setValue(technicCustomerInformation, 'Updated_on', date);
      }
      this.flag = 0;
    }
    if (this.flag === 0) {
      console.log('请勿重复生成');
    }
  }
}

export function registerFinishTimeCtrl() {
  MtSpectrumControlRegister.register('mtFinishTime', MtFinishTimeComponent);
}
