import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { MtWithConfig, MtConfigService, MtDynamicPropertyValueProvider, MtObjectEditExtra } from '@imerge22/platform/base';
import {
  AggregateType,
  FilterOperator,
  GenericQueryResult,
  GenericQueryService,
  MtHttpResult,
  MtSafeAny,
  PageRequest,
  PassportService,
  ProcessDef,
  ProcessTaskDef,
  SortDirection
} from '@imerge22/core';
import { map, switchMap } from 'rxjs/operators';
import { IObjParam } from '@imerge22/platform/workflow/services';
@Injectable({
  providedIn: 'root',
})
export class CustomerInfoService {
  @MtWithConfig() private spectrumServiceName: string;
  @MtWithConfig() private workflowServiceName: string;
  constructor(
    private http: HttpClient,
    public mtConfigSrv: MtConfigService,
    private propValueProvider: MtDynamicPropertyValueProvider
  ) { }
  getCustomerInfoList(page: PageRequest, params?: any) {
    const param: MtSafeAny = {
      'modelId': 'k8_customerRevision',
      "modelAlias":"t1",
      "select":[
          "t1.*",
          "t2.id as SaleEmp",
      ],
      'conditions': {
        'filters': params,
      },
      'sorts':[
        {
          prop: 'cast(Xorder as integer)',
          dir: SortDirection.Ascend
        }
      ],
      "joins":[
        {
            "property":"SaleEmp",
            "alias":"t2",
            "operate":"left_join"           
        }
      ],
      'page': {
        'skip': (page.index - 1) * page.size,
        'take': page.size,
        'verifyStart':1,
        'verifyEnd':10000
      },

    };
    
    return this.http.post(`srv://${this.spectrumServiceName}/mongodb/common/query/v3`, param);
  }
  
  getObjList(page: PageRequest, params?: IObjParam) {
    const param = {
      page: page.index,
      size: page.size,
      ...params
    };
    return this.http.post(`srv://${this.spectrumServiceName}/common/object/objects`, param);
  }

    /**
   * 创建对象
   * @param objectInfo 对象信息
   */
    createobjectInfo(objectInfo: any, extra?: MtObjectEditExtra): Observable<string> {
      const data: any = {
        commonObject: {
          ...objectInfo
        },
        codeRules: objectInfo.ruleId ? [objectInfo.ruleId] : [],
      };
      if (objectInfo.item != null && objectInfo.item.itemId != null) {
        data.item = objectInfo.item;
      }
      if (objectInfo.prop) {
        data.prop = objectInfo.prop;
      }
      delete data.ruleId;
      return this.http.post(`srv://${this.spectrumServiceName}/base/model/add`, data).pipe(
        switchMap((res: MtHttpResult) => {
          if (res.success) {
            objectInfo.id = res.resultData.toString();
            return this.propValueProvider
              .saveObjectPropertyValue(objectInfo, extra)
              .pipe(map(() => {
                return res.resultData.toString();
              }));
          } else {
            of(null);
          }
        })
      );
    }
}
