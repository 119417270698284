import { Component, OnInit, Optional } from '@angular/core';
import { EncodingService } from '../../handlers/encoding.service';
import { MtSafeAny } from '@imerge22/core';
import { MtSpectrumContrlBase, MtSpectrumControlRegister } from '@imerge22/spectrum/controls';
import { MtSpectrumDataBinding, MtSpectrumDataSource } from '@imerge22/spectrum/objects';
import { MtDictCacheService } from '@imerge22/base';
import { MtTranslateService } from '@imerge22/core/translate';

@Component({
    selector: 'uccs-code',
    template: `<div nz-row>
                <label nz-col
                 nzSpan="8" *ngFor="let item of dictArr" nz-checkbox nzDisabled [ngModel]="true">{{ getLanguageName(item) }}
                <input [(ngModel)]="inputval" style="width:60%" nz-input [disabled]="true"
                    *ngIf="item.code === 'Other'" nzSize="small">
                </label>
                </div> `,
    styleUrls: ['./ctrs.css'],
})
export class PackInfomationComponent extends MtSpectrumContrlBase<MtSafeAny> implements OnInit {
    inputval = '' as MtSafeAny;

    constructor(
        @Optional() public dataBinding: MtSpectrumDataBinding,
        public codeSrv: EncodingService,
        private srv: MtDictCacheService,
        private translate: MtTranslateService,
        public mtSpectrumDataSource: MtSpectrumDataSource
    ) {
        super();
        this.init();
    }
    dictArr = [];
    initConfig(): void {
        // throw new Error("Method not implemented.");
    }
    ngOnInit() { }

    init() {
        this.inputval = this.mtSpectrumDataSource.getValue(this.mtSpectrumDataSource.mainObjectId, 'Orther_remark');
        this.srv.getItems('Package_implementation_phase').subscribe((res) => {
            const valarr = this.dataBinding.value as MtSafeAny;
            if (valarr === undefined) {
                return;
            }
            for (const iterator of res) {
                for (const iterator1 of valarr) {
                    if (iterator1 === iterator.code) {
                        this.dictArr.push(iterator);
                    }
                }
            }
        });
    }

     // 字典中英互换
    getLanguageName(column: MtSafeAny) {
        if (this.translate.currentLang.startsWith('en')) {
            return column.origin.nameEn || column.iterator.origin.name;
        } else {
            return column.origin.name;
        }
    }

}
export function registerPackInfomationCtrl() {
    MtSpectrumControlRegister.register('mtPackInfomation', PackInfomationComponent);
}
