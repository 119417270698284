import { MtDynamicTabsetActivateEventData } from '@imerge22/common';
import { MtEvent, MtEventBus, MtSafeAny } from '@imerge22/core';

interface MtMakeUpWorkHoursData extends MtDynamicTabsetActivateEventData {
  id?: string;
  type?: string;
  extra?: MtSafeAny;
}

export class MakeUpWorkHoursActivateEvent {
  static $name = 'make-up-work-hours';
  static emit(data: MtMakeUpWorkHoursData) {
    MtEventBus.emit({
      name: MakeUpWorkHoursActivateEvent.$name,
      data,
    });
  }

  static is(event: MtEvent<MtSafeAny>): boolean {
    return event != null && event.name === MakeUpWorkHoursActivateEvent.$name;
  }

  static get(event: MtEvent<MtSafeAny>): MtMakeUpWorkHoursData {
    if (MakeUpWorkHoursActivateEvent.is(event)) {
      return event.data;
    }
    return undefined;
  }
}
