import { Injectable } from '@angular/core';

import { MtDynamicTabHandler, MtDynamicTabRegister } from '@imerge22/common';
import { MtEvent, MtSafeAny, MtMap } from '@imerge22/core';
import { CustomerInfoDetailComponent } from './customer-info-detail.component';
import { CustomerInfoActivateEvent } from './events';


@Injectable({ providedIn: 'root' })
export class CustomerInfoDetailHandler extends MtDynamicTabHandler {
  match(context: string, event: MtEvent<MtSafeAny>): boolean {
    if (event.name === CustomerInfoActivateEvent.$name) {
      const data = CustomerInfoActivateEvent.get(event);
      return data?.context === context;
    }
    return false;
  }

  same(context: string, exist: MtMap, event: MtEvent<MtSafeAny>): boolean {
    if (event.name === CustomerInfoActivateEvent.$name && exist.id === event.data.id) {
      const data = CustomerInfoActivateEvent.get(event);
      return data?.context === context;
    }
    return false;
  }
}

export function registerCustomerInfoDetailDynamicTab() {
  MtDynamicTabRegister.register(CustomerInfoActivateEvent.$name, {
    component: CustomerInfoDetailComponent,
    handler: CustomerInfoDetailHandler,
  });
}
