import { Injectable } from '@angular/core';

import { MtDynamicTabHandler, MtDynamicTabRegister } from '@imerge22/common';
import { MtEvent, MtSafeAny, MtMap } from '@imerge22/core';

import { EwoDetailActivateEvent } from './events';
import { EwoDetailComponent } from './ewo-detail.component';

@Injectable({ providedIn: 'root' })
export class EwoDetailHandler extends MtDynamicTabHandler {
  match(context: string, event: MtEvent<MtSafeAny>): boolean {
    if (event.name === EwoDetailActivateEvent.$name) {
      const data = EwoDetailActivateEvent.get(event);
      return data?.context === context;
    }
    return false;
  }

  same(context: string, exist: MtMap, event: MtEvent<MtSafeAny>): boolean {
    if (event.name === EwoDetailActivateEvent.$name && exist.id === event.data.id) {
      const data = EwoDetailActivateEvent.get(event);
      return data?.context === context;
    }
    return false;
  }
}

export function registerEwoDetailDynamicTab() {
  MtDynamicTabRegister.register(EwoDetailActivateEvent.$name, {
    component: EwoDetailComponent,
    handler: EwoDetailHandler,
  });
}
