<mt-panel [data]="data" (afterSaved)="isSave($event)"></mt-panel>
<ng-template #tabTitle>{{data?.extra?.conCode}}{{ data?.extra?.conName ? ('-' + data?.extra?.conName) : ''}}</ng-template>
<!-- 流程视图 -->
<ng-template #processViewTitle>
  <span>{{ 'platform.workflow.workTable.processView' | translate }}</span>
</ng-template>
<ng-template #processViewContent let-params>
  <mt-workflow-bpmn-viewer [processInsId]="params.id"></mt-workflow-bpmn-viewer>
</ng-template>
<ng-template #processViewFooter let-ref="modalRef">
  <button nz-button nzShape="round" nzType="danger" (click)="ref.destroy()">
    <i nz-icon nzType="close"></i>
    {{ 'platform.common.close' | translate }}
  </button>
</ng-template>