import { Component, OnInit, Optional } from '@angular/core';
import { MtSafeAny } from '@imerge22/core';
import { MtSpectrumContrlBase, MtSpectrumControlRegister } from '@imerge22/spectrum/controls';
import { MtSpectrumDataBinding } from '@imerge22/spectrum/objects';
import * as XLSX from 'xlsx';
import { MtTranslateService } from '@imerge22/core/translate';

@Component({
  selector: 'uccs-mt-impact-product-downloader',
  templateUrl: './mt-impact-product-downloader.component.html',
  styleUrls: ['./mt-impact-product-downloader.component.scss'],
})
export class MtImpactProductDownloaderComponent extends MtSpectrumContrlBase<MtSafeAny>
  implements OnInit {
  initConfig(): void {}

  constructor(
    @Optional() public dataBinding: MtSpectrumDataBinding,
    protected translate: MtTranslateService
  ) {
    super();
  }

  ngOnInit(): void {}

  ModelDownloader2() {
    if (this.translate.currentLang === 'zh-CN') {
      this.ModelDownloader2Zn();
    } else {
      this.ModelDownloader2En();
    }
  }

  ModelDownloader2Zn() {
    const aoa = [['PH2缩写', '产品总成号码', '涉及更改对象', '客户', '工厂']];
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(aoa);
    ws['!cols'] = [{ wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }];
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    console.log(wb);
    /* save to file */
    XLSX.writeFile(wb, '影响产品模板.xlsx');
  }
  ModelDownloader2En() {
    const aoa = [['PH2 Abbreviation', 'Product No', 'Involved Item', 'Product Customer', 'Plants']];
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(aoa);
    ws['!cols'] = [{ wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }];
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    console.log(wb);
    /* save to file */
    XLSX.writeFile(wb, 'Impact_product.xlsx');
  }
}

export function registerImpactProductModelDownloaderCtrl() {
  MtSpectrumControlRegister.register('mtModelDownloader2', MtImpactProductDownloaderComponent);
}
