import { MtDynamicTabsetActivateEventData } from '@imerge22/common';
import { MtEvent, MtEventBus, MtSafeAny } from '@imerge22/core';

interface MtEmployeeInvolvedProjectsData extends MtDynamicTabsetActivateEventData {
  id?: string;
  type?: string;
  extra?: MtSafeAny;
}

export class EmployeeInvolvedProjectsActivateEvent {
  static $name = 'employee-involved-projects';
  static emit(data: MtEmployeeInvolvedProjectsData) {
    MtEventBus.emit({
      name: EmployeeInvolvedProjectsActivateEvent.$name,
      data,
    });
  }

  static is(event: MtEvent<MtSafeAny>): boolean {
    return event != null && event.name === EmployeeInvolvedProjectsActivateEvent.$name;
  }

  static get(event: MtEvent<MtSafeAny>): MtEmployeeInvolvedProjectsData {
    if (EmployeeInvolvedProjectsActivateEvent.is(event)) {
      return event.data;
    }
    return undefined;
  }
}
