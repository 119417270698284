import { MtDynamicTabsetActivateEventData } from '@imerge22/common';
import { MtEvent, MtEventBus, MtSafeAny } from '@imerge22/core';

interface MtCustomerData extends MtDynamicTabsetActivateEventData {
  id?: string;
  type?: string;
  extra?: MtSafeAny;
}

export class CustomerInfoActivateEvent {
  static $name = 'customer-info';
  static emit(data: MtCustomerData) {
    MtEventBus.emit({
      name: CustomerInfoActivateEvent.$name,
      data,
    });
  }

  static is(event: MtEvent<MtSafeAny>): boolean {
    return event != null && event.name === CustomerInfoActivateEvent.$name;
  }

  static get(event: MtEvent<MtSafeAny>): MtCustomerData {
    if (CustomerInfoActivateEvent.is(event)) {
      return event.data;
    }
    return undefined;
  }
}
