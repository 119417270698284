import { CommonUtils } from '@imerge22/core';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MtNotificationBus } from '@imerge22/core';
import { MtWorkflowStarterComponent } from '@imerge22/platform/workflow/starter';
import { debounceTime } from 'rxjs/operators';
import { UserServiceService } from '@imerge22/base';
import { OrganizationService } from 'src/app/services/organization.service';
interface searchForm{
  itemId?:string;
  createTime?:Date[];
  updateTime?:Date[];
  deptId?:string,
  account?:string,


}
@Component({
  selector: 'yc-search-form',
  templateUrl: './search-form.component.html',
  styleUrls: ['./search-form.component.scss']
})
export class SearchFormComponent implements OnInit {
  searchFrom:searchForm = {};
  @Output() submitSearch = new EventEmitter<searchForm>();
  @Input() selectRow;
  @Input() type = null;
  @ViewChild(MtWorkflowStarterComponent, { static: true }) flowStart: MtWorkflowStarterComponent;
  userOptions: string[] = [];
  orgList = [];
  constructor(
    private userSrv: UserServiceService,
    private orgSvc: OrganizationService,
  ) { }

  ngOnInit(): void {
    this.orgSvc.getOrganizationList().subscribe(item => {
      this.orgList = item.resultData.data;
    });

  }
  formSubmit(){
    console.log(this.selectRow);

    let form = {...this.searchFrom};
    form.itemId = form.itemId?.trim(),
    this.searchFrom = {...form}
    this.submitSearch.emit(this.searchFrom);
  }
  formReset(){
    this.searchFrom = {
      itemId : null,
      updateTime : null,
      createTime: null
    }
    this.submitSearch.emit(this.searchFrom);
  }

  onInput(event: Event): void {
    const value = (event.target as HTMLInputElement).value;

    if (CommonUtils.isNotBlank(value)) {
      this.userOptions = value ? [value, value + value, value + value + value] : [];

      this.userSrv.selectUserPage({ searchKey: value, requestCount: 100 }).pipe(debounceTime(200)).subscribe(res => {
        if (res && res.success) {
          this.userOptions = [];

          for (const user of res.resultData.users) {
            this.userOptions.push(user);
          }
        }
      });
    }
  }

  launchWorkflow() {

    //判断的是否可以发起流程
    var canSumbitWorkflow = true;
    if (!canSumbitWorkflow) {
      MtNotificationBus.emit({
        type: 'warning',
        title: 'i18n.platform.notification.errors.checkAudit',
        message: 'i18n.platform.notification.errors.fistPcn',
      });
      return;
    }
    const parameter = [{ objects: [
      {
        objectId: this.selectRow._id,
        target: true,
        serial: 1
      }
    ] }];
    console.log(this.selectRow);

    this.flowStart.initName(this.selectRow.OBJECT_NAME + '-' + (this.selectRow?.ewo_no || ''));

    this.flowStart.open(parameter);
  }

  //流程发起后需要执行的方法
  startWorkflow() {
    // MtNotificationBus.emit({
    //   type: 'success',
    //   title: 'i18n.platform.notification.success.submitSuccesss',
    //   message: 'i18n.platform.notification.success.submitSuccesssText',
    // });
  }
}
