import { Component, OnInit, Optional } from '@angular/core';
import { MtSafeAny } from '@imerge22/core';
import { MtSpectrumContrlBase, MtSpectrumControlRegister } from '@imerge22/spectrum/controls';
import { MtSpectrumDataBinding } from '@imerge22/spectrum/objects';
import * as XLSX from 'xlsx';
import { MtTranslateService } from '@imerge22/core/translate';

@Component({
  selector: 'uccs-mt-change-item-information-model-downloader',
  templateUrl: './mt-change-item-information-model-downloader.component.html',
  styleUrls: ['./mt-change-item-information-model-downloader.component.scss'],
})
export class MtChangeItemInfromationModelDownloaderComponent extends MtSpectrumContrlBase<MtSafeAny>
  implements OnInit {
  initConfig(): void { }

  constructor(
    @Optional() public dataBinding: MtSpectrumDataBinding,
    protected translate: MtTranslateService
  ) {
    super();
  }

  ngOnInit(): void { }

  // 模板下载
  ModelDownloader() {
    if (this.translate.currentLang === 'zh-CN') {
      this.ModelDownloaderZn();
    } else {
      this.ModelDownloaderEn();
    }
  }
  ModelDownloaderZn() {
    const aoa = [
      ['zn', '作为标识用于解析文档，请勿操作此行'],
      [
        '序号',
        '原更改对象编码',
        '原更改对象名称',
        '原更改对象版本',
        '更改后编码',
        '更改后名称',
        '更改后版本',
        '操作',
        '关联零部件号',
        '关联零部件名称',
      ],
    ];
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(aoa);
    ws['!cols'] = [
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 }
    ];
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    /* save to file */
    XLSX.writeFile(wb, '涉及更改对象信息模板.xlsx');
  }
  ModelDownloaderEn() {
    const aoa = [
      ['en', 'Do not manipulate this line as an identifier for parsing a document'],
      [
        'No',
        'Original_no',
        'Original_name',
        'Original_rev',
        'Changed_no',
        'Changed_name',
        'Changed_rev',
        'Action',
        'Associated_part',
        'Associated_name',
      ],
    ];
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(aoa);
    ws['!cols'] = [
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 }
    ];
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    /* save to file */
    XLSX.writeFile(wb, 'Change Item Information.xlsx');
  }
}

export function registerChangeItemInformationModelDownloaderCtrl() {
  MtSpectrumControlRegister.register(
    'mtModelDownloader',
    MtChangeItemInfromationModelDownloaderComponent
  );
}
