import { MtEvent, MtEventBus, MtSafeAny, GenericQueryRequest } from '@imerge22/core';

interface TitleParam {
  count: number;
}

interface ProjectActivateEventData {
  context?: string;
}

export class ProjectActivateEvent {
  static $name = 'uccs-project';
  static emit(data: ProjectActivateEventData) {
    MtEventBus.emit({
      name: ProjectActivateEvent.$name,
      data,
    });
  }

  static is(event: MtEvent<MtSafeAny>): boolean {
    return event != null && event.name === ProjectActivateEvent.$name;
  }

  static get(event: MtEvent<MtSafeAny>): ProjectActivateEventData {
    if (ProjectActivateEvent.is(event)) {
      return event.data;
    }
    return undefined;
  }
}
