import { MtDynamicTabsetActivateEventData } from '@imerge22/common';
import { MtEvent, MtEventBus, MtSafeAny } from '@imerge22/core';

interface MtProjectAccountingData extends MtDynamicTabsetActivateEventData {
  id?: string;
  type?: string;
  extra?: MtSafeAny;
}

export class ProjectAccountingActivateEvent {
  static $name = 'project-accounting';
  static emit(data: MtProjectAccountingData) {
    MtEventBus.emit({
      name: ProjectAccountingActivateEvent.$name,
      data,
    });
  }

  static is(event: MtEvent<MtSafeAny>): boolean {
    return event != null && event.name === ProjectAccountingActivateEvent.$name;
  }

  static get(event: MtEvent<MtSafeAny>): MtProjectAccountingData {
    if (ProjectAccountingActivateEvent.is(event)) {
      return event.data;
    }
    return undefined;
  }
}
