import { Component, OnInit, Optional } from '@angular/core';
import { MtSafeAny } from '@imerge22/core';
import { MtSpectrumContrlBase, MtSpectrumControlRegister } from '@imerge22/spectrum/controls';
import { MtSpectrumDataBinding, MtSpectrumDataSource } from '@imerge22/spectrum/objects';
import * as XLSX from 'xlsx';
import { MtDictCacheService } from '@imerge22/base';
import { MtNotificationBus } from '@imerge22/core/notification';
import { CostComponent } from '../ctrls-module/ecr_computers';

type AOA = MtSafeAny[][];

@Component({
  selector: 'uccs-mt-impact-product-data-input',
  templateUrl: './mt-impact-product-data-input.component.html',
  styleUrls: ['./mt-impact-product-data-input.component.scss'],
})
export class MtImpactProductDataInputComponent extends MtSpectrumContrlBase<MtSafeAny>
  implements OnInit {
  fileName: string;
  searchModel: MtSafeAny;
  Plant: MtSafeAny;

  initConfig(): void {}
  constructor(
    @Optional() public dataBinding: MtSpectrumDataBinding,
    private srv: MtDictCacheService,
    public dataSource: MtSpectrumDataSource
  ) {
    super();
  }
  ngOnInit(): void {}

  // 获取文件后缀判断文件类型是否为xls、xlsx
  getFileType(fileName: MtSafeAny) {
    let suffix = '';
    let result = '';
    const fileArr = fileName.split('.');
    suffix = fileArr[fileArr.length - 1];
    console.log(suffix);
    if (!suffix) {
      return false;
    }
    suffix = suffix.toLocaleLowerCase();
    // 匹配excel
    const excelList = ['xls', 'xlsx'];
    result = excelList.find((item) => {
      return item === suffix;
    });
    if (result) {
      return 'excel';
    }
  }

  onFileChange(evt: MtSafeAny) {
    const name = evt.target.value as MtSafeAny;
    const fileType = this.getFileType(name);
    if (fileType !== 'excel') {
      MtNotificationBus.emit({
        type: 'error',
        title: 'i18n.platform.common.error',
        message: 'i18n.ctrl.typeErr',
      });
      return;
    }
    /* wire up file reader */
    const target: DataTransfer = evt.target as DataTransfer;
    if (target.files.length !== 1) {
      // throw new Error('Cannot use multiple files');
      this.fileName = null;
      return;
    }

    const reader: FileReader = new FileReader();
    const data = [];
    reader.onload = (e: MtSafeAny) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];
      /* save dataList */
      const value = XLSX.utils.sheet_to_json(ws, { header: 1, raw: true }) as AOA;
      value.splice(0, 1);
      const exit = value.findIndex((res) => res.length === 0);
      if (exit >= 0) {
        value.splice(exit, 1);
      }
      value.map((res) => {
        data.push({
          Product_category: res[0],
          Product_no: res[1],
          Involved_item: res[2],
          Product_Customer: res[3],
          Plants: res[4],
        });
      });
      const plant = [];
      data.map((res) => {
        plant.push(res.Plants);
      });
      this.Plant = plant;
      this.ChangePlant(plant);
      this.fileName = target.files[0].name;
    };
    reader.readAsBinaryString(target.files[0]);
    this.dataBinding.value = data;
  }
  // 根据工厂列修改涉及工厂的显示
  ChangePlant(plant: MtSafeAny) {
    const arr = [];
    for (const iterator of plant) {
      const strArr = iterator.split('/');
      strArr.forEach((item) => {
        arr.push(item);
      });
    }
    plant = arr;
    // 将导入数据中的plant值转换为小写
    for (let i = 0; i < plant.length; i++) {
      plant[i] = plant[i].toLowerCase();
    }
    const dictArr = [];
    this.srv.getItems('Affected_plants_dict').subscribe((res) => {
      res.map((dictArrItem) => {
        dictArr.push({ name: dictArrItem.name, code: dictArrItem.code });
        // dictArrLowercase.push(res.code)
      });
      // 取出字典中name值全部转换为小写
      dictArr.map((dictArrItem) => {
        dictArrItem.name = dictArrItem.name.toLowerCase();
        if (dictArrItem.name === '其他') {
          dictArrItem.name = 'other';
        }
      });
    });
    const plantDataArr = [];
    // 对工厂导入数据与字典中数据进行处理
    plant.map((plantItem, i: MtSafeAny) => {
      dictArr.map((dictArrItem, j: MtSafeAny) => {
        if (plantItem === dictArrItem.name) {
          const flag = plantDataArr.find((res) => {
            return res === plantItem;
          });
          if (flag === undefined) {
            plantDataArr.push(plantItem);
          }
        }
        if (j === dictArr.length - 1) {
          const flag = plantDataArr.findIndex((res) => {
            return res === 'other';
          });
          if (flag === -1) {
            plantDataArr.push('other');
          }
        }
      });
    });
    const plantData = [];
    plantDataArr.map((plantDataArrItem, i: MtSafeAny) => {
      dictArr.map((dictArrItem, j: MtSafeAny) => {
        if (plantDataArrItem === dictArrItem.name) {
          plantData.push(dictArrItem.code);
        }
      });
    });
    this.dataSource.setValue(this.dataSource.mainObjectId, 'Affected_plants', plantData);
  }
}

export function registerImpactProductDataInput() {
  MtSpectrumControlRegister.register('mtDataInput2', MtImpactProductDataInputComponent);
}
