import { MtDynamicTabsetActivateEventData } from '@imerge22/common';
import { MtEvent, MtEventBus, MtSafeAny } from '@imerge22/core';

interface TitleParam {
  count: number;
}

export class EbomActivateEvent {
  static $name = 'uccs-ebom';
  static emit(data: MtDynamicTabsetActivateEventData) {
    MtEventBus.emit({
      name: EbomActivateEvent.$name,
      data,
    });
  }

  static is(event: MtEvent<MtSafeAny>): boolean {
    return event != null && event.name === EbomActivateEvent.$name;
  }

  static get(event: MtEvent<MtSafeAny>): MtDynamicTabsetActivateEventData {
    if (EbomActivateEvent.is(event)) {
      return event.data;
    }
    return undefined;
  }
}
