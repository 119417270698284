import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, forwardRef, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ClassifiedManagementService } from '@imerge22/base';
import { MtDynamicTabBase } from '@imerge22/common';
import {
  CommonUtils,
  FilterOperator,
  GenericQueryService,
  MtConfigService, MtMap,
  MtNotificationBus,
  MtObjectUtil,
  MtSafeAny, MtValidators, MtWithConfig,
  Panel, PassportService, SortDirection
} from '@imerge22/core';
import { EncodingService } from '@imerge22/encoding';
import { MtDataSourceService, MtObjectComponentBase, ObjectDto } from '@imerge22/platform/base';
import {
  IMtSpectrumDataSaveDTO,
  MtObjectData, MtObjectDetailChildRegister,
  MtObjectDetailContext, MtObjectDetailPanelInfo,
  MtObjectTreeNode,
  MtSpectrumDataService, MtSpectrumDefineService,
  MtSpectrumSecurityService
} from '@imerge22/spectrum';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzTreeNodeOptions } from 'ng-zorro-antd/tree';
import { combineLatest, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, skipWhile, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { PanelImapactedComponent } from 'src/app/root/panel/panel-imapacted/panel-imapacted.component';
import { CustomerInfoService } from '../services/customer-info.service';

@Component({
  templateUrl: './customer-info-modal.component.html',
  styleUrls: ['./customer-info-modal.component.scss'],
  providers: [
    {
      provide: MtObjectDetailContext,
      useExisting: forwardRef(() => CustomerInfoModalComponent),
    },
  ],
})
export class CustomerInfoModalComponent extends MtObjectDetailContext implements MtObjectComponentBase, MtDynamicTabBase, OnInit {
  @MtWithConfig() encodingServiceName: string;
  @MtWithConfig() spectrumServiceName: string;
  @MtWithConfig() rbacServiceName: string;
  @Input() detail;
    /** 数据对象 */
  @Input() data: MtObjectData;
  @ViewChild('tabTitle') title: TemplateRef<void>;
  editing = true;
  onCloseByInner: EventEmitter<void>;
  isEditloading = false;
  /** 当前编辑对象 */
  object: ObjectDto;
  mtRevisionType: string;
  validateForm: FormGroup;
  nodes = [];
  rulesList = [];
  objList = [];
  isAllowEditProps = false;
  /** 对象显示的面板/视图 */
  allPanels: MtObjectDetailPanelInfo[];
  public modalType: string = 'k8_customer';
  constructor(
    private passport: PassportService,
    private codeSvc: EncodingService,
    private http: HttpClient,
    public mtConfigSrv: MtConfigService,
    private CustomerInfoService: CustomerInfoService,
    private modal: NzModalRef,
    private fb: FormBuilder,
    private svc: ClassifiedManagementService,
    public srv: MtDataSourceService,
    private querySrv: GenericQueryService,
    defSvc: MtSpectrumDefineService,
    dataSvc: MtSpectrumDataService,
    secSvc: MtSpectrumSecurityService,
    private register: MtObjectDetailChildRegister,
  ) {
    super(defSvc, dataSvc, secSvc);
  }
  onActivated(): void {}
  onDeactivated(): void {}
  activate(): void {}
  deactivate(): void {}
  ngOnInit(): void {

    let formData = {};
    // 配置是否为必填的数组
    for (const item of this.detail) {
      formData[item.key] = [item.content ?? null, item.required ? [Validators.required] : []];
    }
    
    let formObj = {
      type: [this.detail?.type],
      name: [this.detail?.name],
      // codeRule: [this.detail?.codeRule, []],
      codeRule: ['-1', []],
      code: [null, []],
      depId: [null, []],
      ...formData,
    };
    this.validateForm = this.fb.group(formObj);
    this.getCode();
    this.loadObjRules(this.modalType);
    this.loadTree();
    this.validateForm.get('SaleEmp').valueChanges.pipe(
      // 防抖时间，单位毫秒
      debounceTime(1000),
      // 过滤掉重复的元素
      distinctUntilChanged(),
    ).subscribe(res => {
      if (!res) {
        this.validateForm.get('SaleEmp').setValue(null);
        return;
      } else {
        let userIds: Array<string>;
        if (res.indexOf(',') !== -1) {
          userIds = res.split(',');
        } else {
          userIds = [res];
        }
        userIds = userIds.map(item => item.replace('U:', ''));
        let id = userIds.toString();
        this.setUserOrg(id);
      }
    });
    // this.validateForm.get('CustCode').valueChanges.subscribe(res => {
    //   console.log(res);
    //   if (!res) {
    //     this.validateForm.get('code').setValue('');
    //     // this.validateForm.controls.code.setValidators([MtValidators.validRuleCode]);
    //     this.validateForm.get('code').enable();
    //   } else {
    //     this.validateForm.get('code').setValue(res);
    //     this.validateForm.get('code').disable();
    //     this.getCode(res);
    //   }
    // });
  }
  onDataChange(data: MtMap): void {
    if (data.id != null && this.data !== data.id) {
      // this.$dataChange.next(this.data);
    }
  }
  close() {
    this.modal.close(false);
  }
  //  生成编码
  getCode(id?: string) {
    // 生成一个新的code
    let modalRule = this.rulesList.find(res => res.ruleClass === this.modalType + '_id');
    let rule = {
        ruleClass: modalRule ? modalRule : 'generalC',
        ruleSystem: 'iMerge'
    }
    this.codeSvc.generateEncodingToObj(rule?.ruleSystem, rule?.ruleClass).subscribe((res: MtSafeAny) => {

      if (res.success && CommonUtils.isNotBlank(res.resultData)) {
        // 得到编码
        this.validateForm.get('code').setValue(res.resultData);
        this.validateForm.get('CustCode').setValue(res.resultData);
        this.validateForm.get('CustCode').disable();
      } else {
        this.validateForm.get('code').setValue('');
        this.validateForm.get('CustCode').setValue('');
        this.validateForm.get('CustCode').enable();
      }
    });
  }
  // 查询对象关联的规则
  loadObjRules(obj: string) {
    this.querySrv.query({
      modelId: 'ModelRuleRel',
      dataSource: this.spectrumServiceName,
      conditions: {
        filters: [
          {
            prop: 'modelCode',
            operate: FilterOperator.Equals,
            value: obj
          }
        ],
      },
      page: {
        skip: 0, // 分页参数
        take: 99999,
      },
    }).subscribe((res: MtSafeAny) => {

      if (res && res.success) {
        this.rulesList = res.resultData.data;
        this.rulesList.unshift({
          id: '-1',
          ruleName: '自定义编码',
          ruleNameEn: 'Custom encoding'
        });
      }
    });
  }
  loadTree() {
    this.nodes = [];
    this.svc.getModalTree().subscribe(trees => {
      if (trees?.total > 0) {
        const list = trees.data.filter(res => res.frontendCreation);
        this.objList = [...list];
        this.initData(this.modalType);
        this.handleTreeData(list);
      }
    });
  }
  /**
   * 把模型的查询结果集处理成树
   * @param trees 所有模型数据
   * @param parentModelId 父模型id
   * @param parentNode 父节点
   */
   handleTreeData(trees: MtSafeAny[], parentModelId?: string, parentNode?: NzTreeNodeOptions) {
    const children = trees.filter((m) =>
      parentModelId == null ? CommonUtils.isBlank(m.parentId) : m.parentId === parentModelId
    );
    // 当存在父级节点并且此节点不作为任何元素的父级时它是叶子节点
    if ((!children || children.length === 0) && !!parentNode) {
      parentNode.isLeaf = true;
    }
    const nodes = children.map((child) => {
      const node: NzTreeNodeOptions = {
        key: child.code,
        title: child.code,
        origin: child,
        // 展开所有历史展开的节点
        parent: parentNode,
      };
      this.handleTreeData(trees, child.id, node);
      return node;
    });
    if (!!parentNode) {
      parentNode.children = nodes;
      parentNode.disabled = !!nodes.length || !parentNode.parent?.parent;
    } else {
      this.nodes = nodes;
    }
  }
  initData(code: string) {
    const obj = this.objList.find(res => res.code === code);
    this.mtRevisionType = this.srv.isItem(obj.code) ? obj.code + 'Revision' : obj.code;
    this.mtObjectChanges = {};
    this.mtPropertyValueChanges = {};
    this.mtObjectId = this.createTempObject(this.mtRevisionType); // Item版本id
    if (this.srv.isItem(obj.code)) {
      this.mtObjectItemId = this.createTempObject(obj.code); // Item版本id
    }
    this.isAllowEditProps = true;
    setTimeout(() => {
      this.loadPanels();
    }, 500);
  }
  private loadPanels() {
    console.log(123);
    
    this.mtObjectInitedEvent[this.mtObjectId]
    .pipe(
      skipWhile((status) => {
        return status !== 'loaded';
      }),
      switchMap((status) => {
        if (this.mtRevisionType == null) {
          const panelDefs: Panel[] = [];
          return of({ status, panelDefs });
        }
        return this.defSvc.getPanels(this.mtRevisionType).pipe(
          map((panelDefs) => {
            return {
              status,
              // 创建新对象，浅拷贝panel数据
              panelDefs: panelDefs.map((panel) => ({ ...panel })),
            };
          })
        );
      }),
      switchMap((data) => {
        if (data.panelDefs == null || data.panelDefs.length === 0) {
          const panels: MtObjectDetailPanelInfo[] = [];
          return of({ ...data, panels });
        }
        return combineLatest(
          data.panelDefs.map((m) =>
            this.register.getPanel(this, this.dataSvc.getPanelKey(m)).pipe(
              map((component) => {
                const info: MtObjectDetailPanelInfo = {
                  panel: m,
                  component,
                };
                return info;
              })
            )
          )
        ).pipe(
          map((panels) => {
            return { ...data, panels };
          })
        );
      }),
      switchMap((data) => {
        if (this.detail.id) {
          return this.secSvc.getPanelAuthentication(this.mtObjectId, this.object?.objectType, this.mtObjectId, null, this.taskId).pipe(
            map((acls1) => {
              const acls = acls1 ? JSON.parse(JSON.stringify(acls1)) : acls1;
              data.panels
                .filter((m) => !!m.panel.status)
                .forEach((item) => {
                  if (item.panel) {
                    let editable: boolean;
                    if (acls != null && acls[item.panel.code] != null) {
                      editable = acls[item.panel.code].Write;
                    }
                    if (editable == null) {
                      editable = acls?.$object?.Write;
                    }
                    editable = !!editable;
                    item.panel.editable = editable;
                    if (editable) {
                      item.panel.visible = true;
                    } else {
                      let visible: boolean;
                      if (acls != null && acls[item.panel.code] != null) {
                        visible = acls[item.panel.code].Read;
                      }
                      if (visible == null) {
                        visible = acls?.$object?.Read;
                      }
                      visible = !!visible;
                      item.panel.visible = visible;
                    }
                  }
                });
              return data;
            })
          );
        } else {
          data.panels.filter((m) => !!m.panel.status).forEach((item) => {
            if (item.panel) {
              item.panel.editable = true;
              item.panel.visible = true;
            }
          });
          return of(data);
        }
      }),
      takeUntil(this.$destroy)
    )
    .subscribe((data) => {
      const oldPanels = this.panels;
      const panels: MtObjectDetailPanelInfo[] = [];
      const newPanels = data.panels.filter((m) => m.component != null && !!m.panel.visible);
      for (const panel of newPanels) {
        const existPanel = oldPanels.find((m) => m.panel.id === panel.panel.id);
        if (existPanel != null) {
          panels.push(existPanel);
        } else {
          panels.push(panel);
        }
      }

      this.allPanels = panels; // panels.sort((a,b) => a.panel.seq - b.panel.seq);
      // 过滤，只显示当前阶段的面板
      this.panels = this.allPanels.filter((p) => p.panel.code === 'initial_view');
      if (this.mtObjectItemId) {
        this.mtObjectInitedEvent[this.mtObjectItemId]
        .pipe(
          skipWhile((status) => {
            return status !== 'loaded';
          }),
          switchMap((status) => {
            if (this.mtRevisionType == null) {
              const panelDefs: Panel[] = [];
              return of({ status, panelDefs });
            }
            const code = this.mtRevisionType.replace('Revision', '');
            return this.defSvc.getPanels(code).pipe(
              map((panelDefs) => {
                return {
                  status,
                  // 创建新对象，浅拷贝panel数据
                  panelDefs: panelDefs.map((panel) => ({ ...panel })),
                };
              })
            );
          }),
          switchMap((item) => {
            if (item.panelDefs == null || item.panelDefs.length === 0) {
              const panels1: MtObjectDetailPanelInfo[] = [];
              return of({ ...item, panels1 });
            }
            return combineLatest(
              item.panelDefs.map((m) =>
                this.register.getPanel(this, this.dataSvc.getPanelKey(m)).pipe(
                  map((component) => {
                    const info: MtObjectDetailPanelInfo = {
                      panel: m,
                      component,
                    };
                    return info;
                  })
                )
              )
            ).pipe(
              map((panels1) => {
                return { ...item, panels1 };
              })
            );
          }),
          switchMap((data1) => {
            data1.panels1.filter((m) => !!m.panel.status).forEach((item) => {
              if (item.panel) {
                item.panel.editable = true;
                item.panel.visible = true;
              }
            });
            return of(data1);
          }),
          takeUntil(this.$destroy)
        ).subscribe((data1) => {
          const newPanels1 = data1.panels1.filter((m) => m.component != null && !!m.panel.visible);
          for (const panel of newPanels1) {
            const existPanel = oldPanels.find((m) => m.panel.id === panel.panel.id);
            if (existPanel != null) {
              panels.push(existPanel);
            } else {
              panels.push(panel);
            }
          }
          this.allPanels = panels; // panels.sort((a,b) => a.panel.seq - b.panel.seq);
          // 过滤，只显示当前阶段的面板
          this.panels = this.allPanels.filter((p) => p.panel.code === 'initial_view');
          setTimeout(() => {
            this.panels.forEach((p) => {
              if (p.instance) {
                p.instance.mtEditing = true;
              }
            });
          }, 100);
        });
      } else {
        setTimeout(() => {
          this.panels.forEach((p) => {
            if (p.instance) {
              p.instance.mtEditing = true;
            }
          });
        }, 100);
      }
    });
  }

  submitEdit() {
    this.isEditloading = true;
    const value = this.validateForm.value;
    // 新增
    const object: ObjectDto = {
      objectType: this.modalType,
      objectName: value.Title,
      ruleId: '-1',
      objectDesc: null,
      createSrc: 'OBJ_MANAGEMENT',
    };
    if (this.srv.isDoc(value.type)) {
      // object.props = this.uploadedFile;
    }
    // if (this.srv.isItem(value.type)) {
      
    // }
    object.item = { itemId: this.validateForm.get('code').value };
    this.srv.createObject(object).subscribe(
      (objectId) => {
        this.isEditloading = false;
        const id = this.mtObjectItemId || this.mtObjectId;
        if (this.mtObjectItemId) {
          this.searchData(this.validateForm.get('code').value);
        }
        this.srv.getObject(objectId).pipe(take(1)).subscribe((newObject) => {
          const objId = newObject.id;
          this.mtObjects[objId] = this.mtObjects[id];
          this.mtObjectChanges[objId] = this.mtObjectChanges[id];
          this.mtPropertyValueChanges[objId] = this.mtPropertyValueChanges[id];
          delete this.mtObjects[id];
          delete this.mtObjectChanges[id];
          delete this.mtPropertyValueChanges[id];
          if (this.mtObjectItemId) {
            this.mtObjectItemId = objId;
          } else {
            this.mtObjectId = objId;
          }
          this.savePanelData();
          MtNotificationBus.emit({
            type: 'success',
            title: this.srv.getObjectTitle(newObject),
            message: 'i18n.platform.notification.success.addObjectSuccess',
          });
        });
      }, () => this.isEditloading = false);
  }
  searchData(code: string) {
    const params = { code };
    this.CustomerInfoService.getObjList({index: 1, size: 10}, params).subscribe(
      (res: MtSafeAny) => {
        const objId = res.resultData.data[0].revisionObjects[0].id;
        this.srv.getObject(objId).pipe(take(1)).subscribe((newObject) => {
          this.mtObjects[objId] = this.mtObjects[this.mtObjectId];
          this.mtObjectChanges[objId] = this.mtObjectChanges[this.mtObjectId];
          this.mtPropertyValueChanges[objId] = this.mtPropertyValueChanges[this.mtObjectId];
          delete this.mtObjects[this.mtObjectId];
          delete this.mtObjectChanges[this.mtObjectId];
          delete this.mtPropertyValueChanges[this.mtObjectId];
          this.mtObjectId = objId;
          this.savePanelRevData();
          MtNotificationBus.emit({
            type: 'success',
            title: this.srv.getObjectTitle(newObject),
            message: 'i18n.platform.notification.success.addObjectSuccess',
          });
        });
      },
      (err: Error) => { }
    );
  }
  savePanelRevData() {
    const ids = new Set<string>();
    const changes = this.getChanges(ids);
    if (this.isAllowEditProps) {
      if (!changes.data[this.mtObjectId]) {
        changes.data[this.mtObjectId] = { changes: [], data: {} };
      }
      let addCustomerInfoKeys = this.detail.map(item =>item.key);
      addCustomerInfoKeys.push('depId');
      changes.data[this.mtObjectId].changes.push(...addCustomerInfoKeys);

      // // tslint:disable-next-line: no-string-literal
      // changes.data[this.mtObjectId].data['type'] = this.validateForm.controls.type.value ? this.validateForm.controls.type.value + 'Revision' : '';
      // // tslint:disable-next-line: no-string-literal
      // changes.data[this.mtObjectId].data['name'] = this.validateForm.controls.name.value || '';
      // // tslint:disable-next-line: no-string-literal
      // changes.data[this.mtObjectId].data['codeRule'] = this.validateForm.controls.codeRule.value || [];
      // // tslint:disable-next-line: no-string-literal
      // changes.data[this.mtObjectId].data['code'] = this.validateForm.controls.code.value || [];
      // // tslint:disable-next-line: no-string-literal
      // changes.data[this.mtObjectId].data['remark'] = this.validateForm.controls.remark.value || [];
      
      for (const item of this.detail) {
        if (item.key == 'SaleEmp' && this.validateForm.controls.SaleEmp.value) {
          changes.data[this.mtObjectId].data['SaleEmp'] = this.validateForm.controls.SaleEmp.value.replace('U:', '') || null;
        } else {
          changes.data[this.mtObjectId].data[item.key] =  this.validateForm.controls[item.key].value || null;
        }
      }
      // 格式化处理销售人员组织
      changes.data[this.mtObjectId].data['depId'] = this.validateForm.controls['depId'].value || null;
      ids.add(this.mtObjectId);
    }
    if (ids.size === 0) {
      this.isEditloading = false;
      this.editing = false;
      this.panels.forEach((p) => {
        if (p.instance) {
          p.instance.mtEditing = false;
        }
      });
      return;
    }
    this.dataSvc
      .save(changes)
      .pipe(
        tap(() => {
          // 重新加载修改对象最新的数据
          for (const objId of ids) {
            this.dataSvc.getObject(objId, true).pipe(take(1)).subscribe();
            this.dataSvc.getObjectPropertyValues(objId, true).pipe(take(1)).subscribe();
          }
          this.objectDataInit(this.mtObjectId, this.mtObject.objectType, true);
        }),
        map((id) => true),
        catchError((err) => {
          this.isEditloading = false;
          return of(false);
        })
      )
      .subscribe((res) => {
        if (res) {
          this.mtObjectChanges = {};
          this.mtPropertyValueChanges = {};
          this.isEditloading = false;
          this.editing = false;
          this.modal.close(true);
        }
      }, err => this.isEditloading = false);
  }
  // 保存面板的内容
  // tslint:disable-next-line: no-any
  savePanelData() {
    const id = this.mtObjectItemId || this.mtObjectId;
    const ids = new Set<string>();
    const changes = this.getChanges(ids);
    if (this.isAllowEditProps) {
      if (!changes.data[id]) {
        changes.data[id] = { changes: [], data: {} };
      }
      let addCustomerInfoKeys = this.detail.map(item => item.key);
      addCustomerInfoKeys.push('depId');
      changes.data[id].changes.push(...addCustomerInfoKeys);
      // // tslint:disable-next-line: no-string-literal
      // changes.data[id].data['type'] = this.validateForm.controls.type.value || '';
      // // tslint:disable-next-line: no-string-literal
      // changes.data[id].data['name'] = this.validateForm.controls.name.value || '';
      // // tslint:disable-next-line: no-string-literal
      // changes.data[id].data['codeRule'] = this.validateForm.controls.codeRule.value || [];
      // // tslint:disable-next-line: no-string-literal
      // changes.data[id].data['code'] = this.validateForm.controls.code.value || [];
      // // tslint:disable-next-line: no-string-literal
      // changes.data[id].data['remark'] = this.validateForm.controls.remark.value || [];
      
      for (const item of this.detail) {
        if (item.key == 'SaleEmp' && this.validateForm.controls.SaleEmp.value) {
          changes.data[id].data['SaleEmp'] = this.validateForm.controls.SaleEmp.value.replace('U:', '') || null;
        } else {
          changes.data[id].data[item.key] = this.validateForm.controls[item.key].value || null;
        }
      }
      
      // 格式化处理销售人员组织
      changes.data[id].data['depId'] = this.validateForm.controls['depId'].value || null;
      ids.add(id);
    }
    if (ids.size === 0) {
      this.isEditloading = false;
      this.editing = false;
      this.panels.forEach((p) => {
        if (p.instance) {
          p.instance.mtEditing = false;
        }
      });
      return;
    }
    const params = {
      id,
      data: {}
    };
    params.data[id] = changes.data[this.mtObjectId];
    this.dataSvc
      .save(params)
      .pipe(
        tap(() => {
          // 重新加载修改对象最新的数据
          for (const objId of ids) {
            this.dataSvc.getObject(objId, true).pipe(take(1)).subscribe();
            this.dataSvc.getObjectPropertyValues(objId, true).pipe(take(1)).subscribe();
          }
          this.objectDataInit(this.mtObjectId, this.mtObject.objectType, true);
        }),
        map(() => true),
        catchError((err) => {
          this.isEditloading = false;
          return of(false);
        })
      )
      .subscribe((res) => {
        if (res) {
          this.mtObjectChanges = {};
          this.mtPropertyValueChanges = {};
          this.isEditloading = false;
          this.editing = false;
          this.modal.close(true);
        }
      }, err => this.isEditloading = false);
  }

  getChanges(ids: Set<string>) {
    const changes: IMtSpectrumDataSaveDTO = { id: this.mtObjectId, data: {} };
    let objIds = Object.keys(this.mtObjectChanges);
    for (const objId of objIds) {
      const change = this.mtObjectChanges[objId];
      let hasChange = false;
      if (change != null) {
        if (change.objectName != null) {
          if (changes.data[objId] == null) {
            changes.data[objId] = { changes: [], data: {} };
          }
          changes.data[objId].changes.push('$.objectName');
          changes.data[objId].data['$.objectName'] = change.objectName;
          hasChange = true;
        }
        if (change.item?.itemId != null) {
          if (changes.data[objId] == null) {
            changes.data[objId] = { changes: [], data: {} };
          }
          changes.data[objId].changes.push('$.item.itemId');
          changes.data[objId].data['$.item.itemId'] = change.item.itemId;
          hasChange = true;
        }
        if (MtObjectUtil.isNew(objId)) {
          if (changes.data[objId] == null) {
            changes.data[objId] = { changes: [], data: {} };
          }
          changes.data[objId].changes.push('$.objectType');
          changes.data[objId].data['$.objectType'] = this.mtObjects[objId].objectType;
        }
      }
      if (hasChange && !MtObjectUtil.isNew(objId)) {
        ids.add(objId);
      }
    }
    objIds = Object.keys(this.mtPropertyValueChanges);
    for (const objId of objIds) {
      const change = this.mtPropertyValueChanges[objId];
      if (change != null && Object.keys(change).length > 0) {
        const props = Object.keys(change);
        if (MtObjectUtil.isNew(objId) && props.length > 1) {
          if (changes.data[objId] == null) {
            changes.data[objId] = { changes: [], data: {} };
            changes.data[objId].changes.push('$.objectType');
            changes.data[objId].data['$.objectType'] = this.mtObjects[objId].objectType;
          }
        }

        // 只有$.objectType属性时，不需要添加到changes
        // 处理PartChanges 只有IsNewPart属性时，不需要添加到changes
        if (props.length === 1 && (props[0] === '$.objectType' || props[0] === 'IsNewPart')) {
          continue;
        }

        for (const prop of props) {
          if (changes.data[objId] == null) {
            changes.data[objId] = { changes: [], data: {} };
          }

          // 处理表格属性为空内容时不加入changes，如："part_changes", "impact_scope", "service_solution"]
          if (Array.isArray(change[prop]) && change[prop].length === 0) {
            continue;
          }

          if (change[prop] !== null) {
            changes.data[objId].changes.push(prop);
            changes.data[objId].data[prop] = change[prop];
          }
        }

        if (props.length > 0 && !MtObjectUtil.isNew(objId)) {
          ids.add(objId);
        }
      }

      // 移除中的空数据，及changes.data[objId] = { changes: [], data: {} };
      if (changes.data[objId] && changes.data[objId]?.changes.length === 0) {
        delete changes.data[objId];
        ids.delete(objId);
      }
    }

    return changes;
  }

  private setUserOrg(userId: string): void {
    let query = {
     dataSource: this.rbacServiceName,
     modelId: 'User',
     conditions: {
       filters: [
         { prop: 'id', operate: FilterOperator.Equals, value: userId },
       ],
     },
     cascades: ['roleRels', 'orgRels'],
     sorts: [{ prop: 'createTime', dir: SortDirection.Descend }],
   };
   this.validateForm.controls.depId.setValidators([Validators.required]);
   this.http.post('srv://rbac/common/query', query).subscribe((res: MtSafeAny) => {
     if (!res || !res.resultData || !res?.resultData?.data) {
       return;
     }
     let userRoles =  {
       orgRels: res.resultData.data[0]?.orgRels?.map(el => el.orgId),
       roleRels: res.resultData.data[0]?.roleRels?.map(el => el.roleId),
     } 
     this.validateForm.get('depId').setValue(userRoles.orgRels[0]);
     this.validateForm.controls.depId.clearValidators();
   })
 }
}
