import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MtTranslateModule } from '@imerge22/core/translate';
import { MtModelTitleModule } from '@imerge22/platform/model-title';
import { MtObjectCreatorModule } from '@imerge22/platform/object-creator';
import { MtObjectTitleModule } from '@imerge22/platform/object-title';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NewObjectComponent } from './new-object/new-object.component';
import { SearchUpmnComponent } from './search-upmn/search-upmn.component';
import { SelectEcnComponent } from './select-ecn/select-ecn.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MtObjectCreatorModule,
    MtTranslateModule,
    MtModelTitleModule,
    MtObjectTitleModule,
    NzButtonModule,
    NzInputModule,
    NzRadioModule,
    NzIconModule,
    NzGridModule,
    NzTableModule,
    NzCheckboxModule,
    NzSelectModule,
    NzDrawerModule,
  ],
  declarations: [NewObjectComponent, SearchUpmnComponent, SelectEcnComponent],
  exports: [NewObjectComponent, SearchUpmnComponent, SelectEcnComponent],
  entryComponents: [NewObjectComponent, SearchUpmnComponent, SelectEcnComponent],
})
export class UccsCreateObjectModule {}
