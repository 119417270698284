<ng-template #mtExtraActions>
  <i
    nz-icon
    nz-tooltip
    nzType="sync"
    nzTheme="outline"
    (click)="refresh()"
    [nzSpin]="loading"
    nzTooltipTitle="刷新"
    style="cursor: pointer"
  ></i>
</ng-template>

<div echarts [options]="options" ></div>
