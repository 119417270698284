import { Component, Optional, OnInit } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { CommonUtils, MtSafeAny, MtTranslateService } from '@imerge22/core';
import { MtNotificationBus } from '@imerge22/core/notification';
import { MtSpectrumDataService } from '@imerge22/spectrum';
import { MtSpectrumContrlBase, MtSpectrumControlRegister } from '@imerge22/spectrum/controls';
import { MtObjectPanelContext, MtSpectrumDataBinding, MtSpectrumDataSource } from '@imerge22/spectrum/objects';
import { ParamsService } from '@imerge22/base';
import { EncodingService } from '../../../handlers/encoding.service';


@Component({
  selector: 'uccs-ecr-no',
  templateUrl: './ecr-no.component.html',
  styleUrls: ['./ecr-no.component.scss']
})
export class EcrNoComponent extends MtSpectrumContrlBase<MtSafeAny> implements OnInit {

  loading: boolean;
  showBtn: boolean;
  taskCode: string;
  paramSubject: BehaviorSubject<string[]> = new BehaviorSubject(null);

  constructor(
    @Optional() public dataBinding: MtSpectrumDataBinding,
    public codeSrv: EncodingService,
    private dataSrv: MtSpectrumDataService,
    private translate: MtTranslateService,
    private panelContext: MtObjectPanelContext,
    private paramSrv: ParamsService,
    public mtSpectrumDataSource: MtSpectrumDataSource
  ) {
    super();
  }

  ngOnInit(): void {
    // 为空才显示获取按钮
    this.showBtn = this.dataBinding.value == null;
    // 如果是在代办任务中打开，获取当前任务code
    this.taskCode = (this.mtSpectrumDataSource as any)?.detailContext?.taskDef?.code;

    this.panelContext.mtEditStatusChange.subscribe((flag) => {
      if (flag === false) {
        setTimeout(() => {
          this.showBtn = this.dataBinding.value == null;
        }, 500);
      }
    });
    this.loadParam();
  }

  /**
   * 加载系统参数，判断当前任务节点是否是系统参数中配置的特殊节点
   * 只有在待办任务中打开才会用到
   */
  loadParam() {
    this.paramSrv.queryByCode('MODIFY_ECR_NO_TASKS').subscribe((resp) => {
      if (resp && resp.value) {
        const codes: string[] = [];
        resp.value.split(',').map(code => {
          if (CommonUtils.isNotBlank(code)) {
            codes.push(code.trim());
          }
        });
        this.paramSubject.next(codes);
      }
    }, err => {
      let errMsg = '';
      if (err == null) {
        errMsg = 'i18n.platform.common.callApiFail';
      } else if (typeof err === 'string') {
        errMsg = err;
      } else if (CommonUtils.isNotBlank(Object.keys(err))) {
        errMsg = err[Object.keys(err)[0]][0];
      }
      if (errMsg == null) {
        errMsg = err?.message || 'i18n.platform.common.error';
      }
      MtNotificationBus.emit({
        type: 'error',
        title: 'i18n.platform.common.error',
        message: errMsg
      });
    });
  }

  initConfig() { }

  getCode() {
    this.loading = true;
    // 获取编码
    this.codeSrv.getChangeOrder().subscribe((code: MtSafeAny) => {
      this.dataBinding.value = code.resultData;
      // 只能点一次
      this.showBtn = false;
      this.loading = false;
    }, err => {
      this.loading = false;
      let errMsg = '';
      if (err == null) {
        errMsg = 'i18n.platform.common.callApiFail';
      } else if (typeof err === 'string') {
        errMsg = err;
      } else if (CommonUtils.isNotBlank(Object.keys(err))) {
        errMsg = err[Object.keys(err)[0]][0];
      }
      if (errMsg == null) {
        errMsg = err?.message || 'i18n.platform.common.error';
      }
      MtNotificationBus.emit({
        type: 'error',
        title: 'i18n.platform.common.error',
        message: errMsg
      });
    });
  }

  showInput() {
    if (this.taskCode) {
      return this.paramSubject.pipe(take(1), map(codes => {
        if (codes) {
          // 只有当当前任务是系统参数中配置的特殊节点时才显示input框
          return codes.includes(this.taskCode);
        }
        return false;
      }))
    }
    return of(false);
  }

  getDesc() {
    if (this.dataBinding.property) {
      if (this.translate.currentLang.startsWith('en')) {
        return this.dataBinding.property.descEn;
      } else {
        return this.dataBinding.property.desc;
      }
    }
  }

  isShowTips() {
    return (
      CommonUtils.isBlank(this.dataBinding.value) &&
      CommonUtils.isNotBlank(this.getDesc()) &&
      !this.mtConfig.notDisplayDesc
    );
  }

}

export function registerChangeCtrl() {
  MtSpectrumControlRegister.register('mtCode', EcrNoComponent, { powerControlled: true });
}
