import { NzModalService } from 'ng-zorro-antd/modal';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PageRequest, PassportService } from '@imerge22/core';
import { EcnDetailComponent } from '../../pages/ecn-detail/ecn-detail.component';
import { EcnService } from '../../service/ecn.service';
import { EcnDetailActivateEvent } from '../../events';
import { Sort } from '../../../model/common';
@Component({
  selector: 'yc-ecn-list',
  templateUrl: './ecn-list.component.html',
  styleUrls: ['./ecn-list.component.scss'],
})
export class EcnListComponent implements OnInit {
  @Input() thHeader: string[];
  @Input() tableData: [];
  @Input() type: string;
  @Input() total: number;
  @Output() clickSelectRow:EventEmitter<any> = new EventEmitter<any>();
  @Output() pageChangeEfffect: EventEmitter<any> = new EventEmitter<any>();
  selectRow = null;
  pagenumber: number[] = [10, 20, 30, 50, 100];
  @Input() page: PageRequest = { index: 1, size: 10 };
  sortOrder = {plan:null,createTime:null}
  @Input() sort;
  isAdd = false;

  constructor(private nzModalService: NzModalService,private passport: PassportService,private srv: EcnService) {}

  ngOnInit(): void {}

  pageChange() {
    this.pageChangeEfffect.emit(this.page);
  }

  pageSizeNumber() {
    this.pageChangeEfffect.emit(this.page);
  }

  showDetail(id: string): void {
    this.isAdd = false;
    this.showEcnEdit(id);
  }

  sortChange(type?){
    // for(let item in this.sortOrder){
    //   console.log(item);

    // }
    if(this.sortOrder[type]){
      let params = {
        dir:Sort[this.sortOrder[type]],
        prop:null
      }
      if(type === 'plan'){
        params.prop = 'COMPLETE_TIME'
      }else if(type === 'createTime'){
        params.prop = 'CREATE_TIME'
      }
      this.sort = [params]
    }else{
      this.sort = [];
    }


    this.pageChangeEfffect.emit({sort:this.sort});
    // this.search();
  }

  switchChange(item) {
    const params = {
      followType: 'ecn',
      followObjId: item._id, //ewoid
      followUserId: this.passport.current.user.id,
      isFollow: !item.follow,
    };
    item.loading = true;
    this.srv.attentionChange(params).subscribe((res) => {
      if (res.success) {
        item.loading = false;
        item.follow = !item.follow;
        // this.loadData();
      }
    });
  }

  oneItemChecked(row) {
    if(this.selectRow && this.selectRow._id === row._id) {
      this.selectRow = null
      this.clickSelectRow.emit(null)
    }
    else{
      this.selectRow = row
      this.clickSelectRow.emit(row)
    }
  }


  //显示ECN详情
  showEcnEdit(objId: string) {
    EcnDetailActivateEvent.emit({
      context: 'ECN',
      id: objId,
      type: 'ECNRevision',
      extra: {
        mtModelCode: 'ECN',
        isAdd: this.isAdd
      }
    });
  }

  showEcnEdit_old(objId: string) {
    this.nzModalService.create({
      nzTitle: this.isAdd ? '创建ECN' : 'ECN详情',
      nzContent: EcnDetailComponent,
      nzComponentParams: {
        isAdd: this.isAdd,
        mtModelCode: 'ECN',
        data: { id: objId, type: 'ECNRevision' },
      },
      nzFooter: null,
      nzWidth: '90%',
      nzBodyStyle: { height: document.body.clientHeight - 75 + 'px' },
      nzMaskClosable: false,
      nzStyle: { top: '5px' },
    });
  }
}
