<nz-input-group *ngIf="dataBinding.editing" nzSearch [nzAddOnAfter]="suffixIconButton">
  <input disabled type="text" nz-input (blur)="blur()"  [(ngModel)]="dataBinding.value"/>
</nz-input-group>
<ng-template #suffixIconButton>
  <button nz-button nzType="primary" (click)="open()"  nzSearch><i nz-icon nzType="search"></i></button>
</ng-template>

<label *ngIf="!dataBinding.editing">{{dataBinding.value}}</label>

<nz-drawer [nzWidth]="500" [nzClosable]="true" [nzVisible]="visible" nzPlacement="right" [nzMaskClosable]="true"
  [nzTitle]=" 'root.ecrpack.drawTitle' | translate " (nzOnClose)="close()" nzWrapClassName="mt-with-footer">
  <nz-card [nzTitle]="'root.department.queryCondition' | translate" class="mt-autoheight">
    <div nz-row>
      <div nz-row class="searchField">
        <div nzSpan="14" nz-col>
          <nz-form-item nz-row>
            <nz-form-label nz-col nzSpan="11">{{ 'root.ecrpack.enrno' | translate }}</nz-form-label>
            <nz-form-control nz-col nzSpan="12">
              <input nz-input [(ngModel)]="ecrnosearchval" />
            </nz-form-control>
          </nz-form-item>
        </div>
        <nz-form-item nz-col nzSpan="10">
          <nz-form-control>
            <button nz-button nzshape="round" class="but-right" (click)="search()">
              <i nz-icon nzType="search"></i>{{ 'root.department.search' | translate }}
            </button>
            <button nz-button nzshape="round" (click)="reset()">
              <i nz-icon nzType="reload"></i>{{ 'root.department.reload' | translate }}
            </button>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
  </nz-card>
  <nz-card [nzTitle]="'root.department.searchResult' | translate" class="mt-nopadding">
    <nz-table #basicTable  [nzData]="searchListData"
      nzShowPagination nzShowSizeChanger (nzCurrentPageDataChange)="onCurrentPageDataChange($event)">
      <thead>
        <tr>
          <th class="mt-fill">{{ 'root.ecrpack.enrno' | translate }}</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of basicTable.data,let i = index"  (click)="clickrow(data,i)">
          <td [style.background-color]="data.style">{{data.ecrno}}</td>
          <td [style.background-color]="data.style"></td>
        </tr>
      </tbody>
    </nz-table>
  </nz-card>
  

  <div class="mt-drawer-footer">
    <button (click)="close()" type="button" nz-button nzShape="round">
      <i nz-icon nzType="close"></i>{{ 'root.department.cancel' | translate }}
    </button>
    <button nz-button nzShape="round" nzType="primary" type="button"
      [disabled]="tablerow == undefined" (click)="save()">
      <i nz-icon nzType="check"></i>{{ 'root.department.confim' | translate }}
    </button>
  </div>
</nz-drawer>