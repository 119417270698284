import { Injectable } from '@angular/core';

import { MtDynamicTabHandler, MtDynamicTabRegister } from '@imerge22/common';
import { MtEvent, MtSafeAny, MtMap } from '@imerge22/core';
import { ProjectDetailDetailComponent } from './project-detail.component';
import { ProjectDetailActivateEvent } from './events';


@Injectable({ providedIn: 'root' })
export class ProjectDetailDetailHandler extends MtDynamicTabHandler {
  match(context: string, event: MtEvent<MtSafeAny>): boolean {
    if (event.name === ProjectDetailActivateEvent.$name) {
      const data = ProjectDetailActivateEvent.get(event);
      return data?.context === context;
    }
    return false;
  }

  same(context: string, exist: MtMap, event: MtEvent<MtSafeAny>): boolean {
    if (event.name === ProjectDetailActivateEvent.$name && exist.id === event.data.id) {
      const data = ProjectDetailActivateEvent.get(event);
      return data?.context === context;
    }
    return false;
  }
}

export function registerProjectDetailDynamicTab() {
  MtDynamicTabRegister.register(ProjectDetailActivateEvent.$name, {
    component: ProjectDetailDetailComponent,
    handler: ProjectDetailDetailHandler,
  });
}
