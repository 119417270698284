import { HttpClient } from '@angular/common/http';
import { Component, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { FilterOperator, MtConfigService, MtSafeAny, MtWithConfig } from '@imerge22/core';
import { MtSpectrumContrlBase, MtSpectrumControlRegister } from '@imerge22/spectrum';
import { MtSpectrumDataBinding, MtSpectrumDataSource } from '@imerge22/spectrum/objects';
import { Subject } from 'rxjs';
import { distinctUntilChanged, throttleTime } from 'rxjs/operators';

export interface MtSpectrumPropNameCtrlConfig {
  /** 设置水平方向对齐方式 */
  vAlign?: 'center' | 'top' | 'bottom';
  /** 设置垂直方向对齐方式 */
  hAlign?: 'center' | 'left' | 'right';
  /** 不需要默认背景色 */
  noneBackground?: boolean;
  /** 自定义背景色 */
  backgroundColor?: string;
/** 显示序号 */
  showIndex?: boolean;
}

@Component({
  selector: 'mt-ctrl-user-select',
  templateUrl: './mt-user-select.component.html',
  styleUrls: ['./mt-user-select.component.scss'],
})
export class MtSpectrumUserSelectComponent extends MtSpectrumContrlBase<MtSpectrumPropNameCtrlConfig>
  implements OnInit, OnChanges {
  @MtWithConfig() private spectrumServiceName: string;
  @MtWithConfig() private rbacServiceName: string;

  public userListOption: Array<MtSafeAny> = [];
  public showText: string = '';
  public value: string = '';
  nzFilterOption = (): boolean => true;
  public currentIndex: number = 0;
  private debounceGetList = new Subject<string>();
  constructor(
    public dataBinding: MtSpectrumDataBinding,
    private dataSource: MtSpectrumDataSource,
    private http: HttpClient,
    public mtConfigSrv: MtConfigService,
    ) {
    super();
  }

  initConfig(): void {}

  ngOnInit(): void {
    this.debounceGetList.pipe(
      throttleTime(500),
      distinctUntilChanged(),
    ).subscribe(res => {
      this.getUserList(res);
    })
    this.value = this.dataBinding.value as string;
    if (!this.value) {
      return; 
    }
    this.getUserList('', this.value);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.value = this.dataBinding.value as string;
    if (!this.value) {
      return;
    }
    this.getUserList('', this.value);
  }

  public userSelectChanges(userId: string): void {
    if (!userId) {
      return;
    }
    this.userListOption.map(item => {
      if (item.id == userId) {
        this.showText = item.employeeNo + '-' + item.name;
      }
    });
    this.dataSource.create(this.dataBinding.mtObjectId);
    this.dataSource.setValue(this.dataBinding.mtObjectId, this.dataBinding.mtProperty, userId, this.dataBinding.mtRowIndex);
  }

  public getUserListBySubject(event?: string): void {
    this.debounceGetList.next(event)
  }

  public getUserList(event?: string, userId?: string, index?: number): void {
    if (!event && !userId) {
      return;
    }
    let filters: Array<MtSafeAny> = [{
      "prop":"deleted",
      "operate":"Equals",
      "value":false
    }];
    if (event) {
      filters.push({
        "prop": 'name',
        "operate": FilterOperator.Like,
        "value": event
      });
    }
    if (userId) {
      filters.push({
        "prop": 'id',
        "operate": FilterOperator.Equals,
        "value": `${userId}`
      });
    }
    let params = {
      "dataSource":"rbac",
      "modelId":"User",
      "conditions":{
        filters
      },
      "cascades":["roleRels","orgRels"],
      "sorts":[{"prop":"createTime","dir":"Descend"}],"page":{"skip":0,"take":99999}
    };
    this.http.post(`srv://${this.rbacServiceName}/common/query/user/v3`, params).pipe(
      distinctUntilChanged(),
    ).subscribe((res: MtSafeAny) => {
      if (!res || !res?.resultData?.data) {
        return;
      }
      this.currentIndex = index;
      if (userId) {
        this.value = res?.resultData?.data[0].id;
        this.showText = res?.resultData?.data[0].employeeNo + '-' + res?.resultData?.data[0].name;
      }
      res?.resultData?.data.forEach(item => item.label = item.employeeNo + '-' + item.name);
      this.userListOption = res?.resultData?.data;
    });
  }
}

export function registerUserSelectCtrl() {
  MtSpectrumControlRegister.register('mtUserSelect', MtSpectrumUserSelectComponent);
}
