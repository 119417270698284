import { ApplicationRef, ComponentFactoryResolver, Injectable, Injector } from '@angular/core';
import {
  MtDataSourceService,
  MtObjectEditableService,
  MtObjectTreeNode,
  ObjectDto,
} from '@imerge22/platform/base';
import {
  MtObjectContextCommandEvent,
  MtObjectContextCommandHandler,
  MtObjectContextCommands,
} from '@imerge22/platform/object-context';
import { Observable, of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { NewObjectComponent } from './public-api';
import { SearchUpmnComponent } from './search-upmn/search-upmn.component';
import { SelectEcnComponent } from './select-ecn/select-ecn.component';
import { MtNotificationBus } from '@imerge22/core/notification';
import { PanelImapactedComponent } from '../panel/panel-imapacted/panel-imapacted.component';
import { CommonUtils } from '@imerge22/platform/utils';
import { MtSafeAny } from '@imerge22/core';
import { MtSpectrumSecurityService } from '@imerge22/spectrum';

export interface SaveModel {
  oneData?: MtSafeAny;
  twoData?: MtSafeAny;
  threeData?: MtSafeAny;
}

@Injectable({ providedIn: 'root' })
export class MtObjectAddCommandHandler implements MtObjectContextCommandHandler {
  constructor(
    private srv: MtDataSourceService,
    private injector: Injector,
    private applicationRef: ApplicationRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private securitySrv: MtSpectrumSecurityService,
    private editableSrv: MtObjectEditableService
  ) { }

  private static starterInstance: NewObjectComponent = null;
  private static upmnInstance: SearchUpmnComponent = null;
  private static ecnInstance: SelectEcnComponent = null;
  private isDisabled;
  private securityObservable: Observable<boolean>;
  private MODEL_CODE = 'ECN';

  available(commandId: string, event: MtObjectContextCommandEvent) {
    if (
      event.objectId.startsWith('@@NEW@@') ||
      event.source == null ||
      event.source.mtBindNode == null
    ) {
      return of(false);
    }
    return of(this.srv.isFolder(event.source.mtBindNode.origin.objectType)).pipe(
      switchMap((isFolder) => {
        if (isFolder) {
          return this.editableSrv.editable(event.object, { context: event.context });
        }
        return of(isFolder);
      })
    );
  }

  execute(commandId: string, event: MtObjectContextCommandEvent) {
    const result = this.openAddObj(event, true);
    result.subscribe((data) => {
      const saveData: SaveModel = {};
      if (data) {
        saveData.oneData = data;
        const upmnResult = this.openSearchUpmn(event, true);
        upmnResult.subscribe((upmn) => {
          if (upmn === 'prevStep') {
            // 上一步
            this.openAddObj(event, false);
          } else if (upmn != null) {
            // 下一步
            saveData.twoData = upmn;
            const ecnResult = this.openEcnIn(event, true);
            ecnResult.subscribe((ecn) => {
              if (ecn === 'prevStep') {
                // 上一步
                this.openSearchUpmn(event, false);
              } else if (ecn != null) {
                // 保存
                saveData.threeData = ecn;
                this.save(saveData, event);
              }
            });
          }
        });
      }
    });
    return result;
  }

  enabled(commandId: string, event: MtObjectContextCommandEvent) {
    // 控制只请求一次接口
    if (this.isDisabled == null) {
      if (this.securityObservable == null) {
        // 根据模型code获取当选中前用户是否有权限创建此模型对象
        this.securityObservable = this.securitySrv.getObjectCreateAuthentication(this.MODEL_CODE).pipe(
          tap(res => {
            this.isDisabled = res;
          },
            catchError(err => {
              this.isDisabled = false;
              return err;
            })
          ));
      }
      return this.securityObservable;
    } else {
      return of(this.isDisabled);
    }
  }

  save(saveData: SaveModel, event: MtObjectContextCommandEvent) {
    const object: ObjectDto = {
      objectType: this.MODEL_CODE,
      objectName: saveData.oneData.name,
      objectDesc: saveData.oneData.desc,
      owningGroupId: saveData.oneData.organizationId,
      item: { itemId: saveData.oneData.code },
    };
    this.srv.createObject(object).subscribe(
      (objectId) => {
        this.srv.getObjectRevisions(objectId).subscribe((resp) => {
          if (CommonUtils.isNotBlank(resp)) {
            // 调用编辑接口同步ECN
            if (resp[0].props == null) {
              resp[0].props = {};
            }
            resp[0].props[PanelImapactedComponent.COED_NAME] = saveData.twoData;
            this.srv.editObject(resp[0]).subscribe();
          }
        });
        const messageParam = { folder: event.source.mtBindNode.origin.title };
        this.srv.getObject(objectId).subscribe((newObject) => {
          MtNotificationBus.emit({
            type: 'success',
            title: this.srv.getObjectTitle(newObject),
            message: 'i18n.platform.spectrum.object.createSuccess',
            messageParam
          });
        });
        this.srv
          .addToFolder(event.source.mtBindNode.origin.objectId, objectId)
          .subscribe((workspaceDto) => {
            const node: MtObjectTreeNode = {
              id: workspaceDto.childObjectMuid,
              type: 'object',
              folderRelId: workspaceDto.id,
            };
            event.source.addChild(node);
          });
      },
      (error: MtSafeAny) => {
        MtNotificationBus.emit({
          type: 'error',
          title: 'i18n.platform.notification.errors.addObjectLose',
          message: error.exists[0],
        });
      }
    );
  }

  /**
   * 打开第一个创建对象抽屉
   */
  openAddObj(event: MtObjectContextCommandEvent, reset?: boolean) {
    if (MtObjectAddCommandHandler.starterInstance == null) {
      const starter = document.createElement('uccs-new-object');
      const factory = this.componentFactoryResolver.resolveComponentFactory(NewObjectComponent);
      const starterComponentRef = factory.create(this.injector, [], starter);
      this.applicationRef.attachView(starterComponentRef.hostView);
      document.body.appendChild(starter);
      MtObjectAddCommandHandler.starterInstance = starterComponentRef.instance;
    }
    return MtObjectAddCommandHandler.starterInstance.open(event.object, reset);
  }

  /**
   * 打开第二个查询UPLM抽屉
   */
  openSearchUpmn(event: MtObjectContextCommandEvent, reset?: boolean) {
    if (MtObjectAddCommandHandler.upmnInstance == null) {
      const starter = document.createElement('uccs-new-object');
      const factory = this.componentFactoryResolver.resolveComponentFactory(SearchUpmnComponent);
      const starterComponentRef = factory.create(this.injector, [], starter);
      this.applicationRef.attachView(starterComponentRef.hostView);
      document.body.appendChild(starter);
      MtObjectAddCommandHandler.upmnInstance = starterComponentRef.instance;
    }
    return MtObjectAddCommandHandler.upmnInstance.open(event.object, reset);
  }

  /**
   * 打开第三个ECN抽屉
   */
  openEcnIn(event: MtObjectContextCommandEvent, reset?: boolean) {
    if (MtObjectAddCommandHandler.ecnInstance == null) {
      const starter = document.createElement('uccs-select-ecn');
      const factory = this.componentFactoryResolver.resolveComponentFactory(SelectEcnComponent);
      const starterComponentRef = factory.create(this.injector, [], starter);
      this.applicationRef.attachView(starterComponentRef.hostView);
      document.body.appendChild(starter);
      MtObjectAddCommandHandler.ecnInstance = starterComponentRef.instance;
    }
    return MtObjectAddCommandHandler.ecnInstance.open(event.object, reset);
  }
}

export function registerObjectCommandAdd(
  id: string,
  name?: string,
  options?: {
    modelCode?: string;
    skipModelSelect?: boolean;
  }
) {
  MtObjectContextCommands.registerCommand({
    id,
    name: name || 'common.contextMenu.addObject',
    icon: 'file-add',
    handlerDefine: MtObjectAddCommandHandler,
    extra: options,
  });
}
