import { MtEvent, MtEventBus, MtSafeAny } from '@imerge22/core';
import { MtDynamicTabsetActivateEventData } from '@imerge22/common';

interface WorkingHoursDetailActivateEventData extends MtDynamicTabsetActivateEventData{
  id?: string;
  type?: string;
  extra?: MtSafeAny;
}

export class WorkingHoursDetailActivateEvent {
  static $name = 'working-hours-detail-activate';
  static emit(data: WorkingHoursDetailActivateEventData) {
    MtEventBus.emit({
      name: WorkingHoursDetailActivateEvent.$name,
      data,
    });
  }

  static is(event: MtEvent<MtSafeAny>): boolean {
    return event != null && event.name === WorkingHoursDetailActivateEvent.$name;
  }

  static get(event: MtEvent<MtSafeAny>): WorkingHoursDetailActivateEventData {
    if (WorkingHoursDetailActivateEvent.is(event)) {
      return event.data;
    }
    return undefined;
  }
}
