import { MtEvent, MtEventBus, MtSafeAny } from '@imerge22/core';
import { MtDynamicTabsetActivateEventData } from '@imerge22/common';

interface ProjectDetailActivateEventData extends MtDynamicTabsetActivateEventData{
  id?: string;
  type?: string;
  extra?: MtSafeAny;
}

export class ProjectDetailActivateEvent {
  static $name = 'project-detail-detail-activate';
  static emit(data: ProjectDetailActivateEventData) {
    MtEventBus.emit({
      name: ProjectDetailActivateEvent.$name,
      data,
    });
  }

  static is(event: MtEvent<MtSafeAny>): boolean {
    return event != null && event.name === ProjectDetailActivateEvent.$name;
  }

  static get(event: MtEvent<MtSafeAny>): ProjectDetailActivateEventData {
    if (ProjectDetailActivateEvent.is(event)) {
      return event.data;
    }
    return undefined;
  }
}
